import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  WorkspaceCreateData,
  WorkspaceMembershipResponse,
  WorkspaceResponse,
  WorkspaceUpdateData
} from "./types/workspace"
import {
  removeWorkspace,
  setCurrentWorkspace,
  setMemberships,
  setWorkspace,
  setWorkspaceList
} from "../features/workspace.slice";

const transformResponse = (item: WorkspaceResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}

export const workspaceApi = createApi({
  reducerPath: "workspaceApi",
  baseQuery: customFetchBase,
  tagTypes: ["Workspaces"],
  endpoints: (builder) => ({
    listWorkspace: builder.query<WorkspaceResponse[], void>({
      query() {
        return {
          url: "v1/workspace",
          credentials: "include",
        };
      },
      transformResponse: (response: { items: WorkspaceResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "Workspaces" as const, id })),
            { type: "Workspaces", id: "LIST" },
          ]
          : [{ type: "Workspaces", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspaceList(data));
        } catch (error) {
        }
      },
    }),
    fetchWorkspace: builder.query<WorkspaceResponse, string>({
      query(workspace: string) {
        return {
          url: `v1/workspace/${workspace}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      providesTags: (result, error, id) => [{ type: "Workspaces", id }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspace(data));
        } catch (error) {
        }
      },
    }),
    loadCurrentWorkspace: builder.query<WorkspaceResponse, string>({
      query(workspace: string) {
        return {
          url: `v1/workspace/${workspace}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspace(data));
          dispatch(setCurrentWorkspace(data.slug));
        } catch (error) {
        }
      },
    }),
    createWorkspace: builder.mutation<WorkspaceResponse, WorkspaceCreateData>({
      query(data) {
        return {
          url: "v1/workspace",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      invalidatesTags: [{ type: "Workspaces", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspace(data));
          dispatch(setCurrentWorkspace(data.slug));
        } catch (error) {
        }
      },
    }),
    updateWorkspace: builder.mutation<WorkspaceResponse, { id: string; data: WorkspaceUpdateData }>({
      query({ id, data }) {
        return {
          url: `/workspace/${id}`,
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
            { type: "Workspaces", id },
            { type: "Workspaces", id: "LIST" },
          ]
          : [{ type: "Workspaces", id: "LIST" }],
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspace(data));
        } catch (error) {
        }
      },
    }),
    deleteWorkspace: builder.mutation<void, string>({
      query(workspace) {
        return {
          url: `/workspace/${workspace}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: [{ type: "Workspaces", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(removeWorkspace(args[0]));
        } catch (error) {
        }
      },
    }),
    loadMemberships: builder.query<WorkspaceMembershipResponse[], string>({
      query(workspace) {
        return {
          url: `v1/workspace/${workspace}/membership`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: WorkspaceMembershipResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setMemberships({ workspace: args, memberships: data }));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListWorkspaceQuery,
  useFetchWorkspaceQuery,
  useLoadCurrentWorkspaceQuery,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useLoadMembershipsQuery,
} = workspaceApi;

