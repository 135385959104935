import { FC, useState } from "react";
import { OnChangeValue } from "react-select";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

type IProps = {
  button?: string
  data: {
    name: string
    template: string
  }
  onChange: (data: { name: string, payload: string }) => void
};

const nameFormat = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9_\s]+/g, "")
}


const OnCallIntegrationTemplate: FC<IProps> = ({ data, onChange, button }) => {
  const [name, setName] = useState<string>(data?.name || "")
  const [state, setState] = useState<string>(data?.template || "")
  function onChangeValue(val: OnChangeValue<any, any>) {
    setState(val)
  }
  function saveTemplate () {
    onChange({ name, payload: state })
  }
  return (
    <div className="w-100 h-100">
      <div className="mb-2 d-flex justify-content-between border-bottom">
        <div className="my-2 w-75">
          <label htmlFor="nameInput">Name</label>
          <input
            id="nameInput"
            className="form-control form-control-sm" type="text"
            onChange={(e) => setName(nameFormat(e.target.value))} value={name} />
        </div>
        <div className="mb-2 d-flex align-items-end">
          <button className="btn btn-sm btn-success" onClick={saveTemplate} disabled={name.length < 1}>
            {button || "Create"}
          </button>
        </div>
      </div>
      <AceEditor
        mode="golang"
        theme="github"
        width={"100%"}
        fontSize="14px"
        onChange={onChangeValue}
        value={state}
        setOptions={{
          useWorker: false,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2
        }}
      />
    </div>
  )
};

export default OnCallIntegrationTemplate;
