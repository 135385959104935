import { ChangeEvent, FC } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { object, string, TypeOf } from "zod";

const nameFormat = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9_\s]+/g, "")
}
const slugFormat = (str: string) => {
  return str
    .replace(/ +/g, " ")
    .trim()
    .replace(/\s/gi, "_")
    .toLowerCase()
}

const FormSchema = object({
  name: string()
    .min(3, "Name can not be empty")
    .transform(val => nameFormat(val).replace(/ +/g, " ")),
  slug: string()
    .min(3, "Slug can not be empty")
    .transform(val => slugFormat(nameFormat(val))),
  description: string()
    .max(1024, "Description is not valid")
    .optional(),
});

export type FormData = TypeOf<typeof FormSchema>;

interface Data {
  name: string
  slug: string
  description?: string
}

export interface IProps {
  onSubmit?: (data: Data) => void;
}

const FormInfrastructureCreate: FC<IProps> = ({ onSubmit }) => {

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = nameFormat(e.target.value)
  }

  const handleChangeSlug = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = slugFormat(nameFormat(e.target.value))
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    onSubmit && onSubmit(values);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="mb-2">
            <label className="form-label" htmlFor="nameInput">Name</label>
            <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}
              onChange={handleChangeName}/>
            {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="slugInput">Slug</label>
            <input type="text" id="slugInput" className="form-control" placeholder="Enter slug"
              {...register("slug")}
              onChange={handleChangeSlug}/>
            {errors.slug?.message && <small className="text-danger">{errors.slug?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="descriptionInput">Description</label>
            <textarea id="descriptionInput" className="form-control" placeholder="Enter description" rows={4}
              {...register("description")}/>
            {errors.description?.message && <small className="text-danger">{errors.description?.message}</small>}
          </div>

          <div className="d-flex">
            <button type="submit" className="btn btn-primary">Apply</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormInfrastructureCreate;
