import React from "react";
import { Breadcrumbs } from "../../components";

const WorkspaceInfoPage = () => {
  return (
    <section>
      <div>
        <Breadcrumbs />
      </div>
      WorkspaceInfo
    </section>
  );
};

export default WorkspaceInfoPage;
