import { FC, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import { TeamSchedule } from "../../../store/features/oncall.team.slice";

interface SelectOption {
  label: string;
  value: string;
}

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
  schedules: TeamSchedule[];
}

export interface IProps {
  team: string;
  value: string;
  onChange?: (data: string) => void
}

type Props = StateProps & IProps;

const AssignFromSchedule: FC<Props> = ({ value, schedules, onChange }) => {
  const [schedule, setSchedule] = useState<string>(value || "");

  useEffect(() => {
    setSchedule(value || "")
  }, [value, setSchedule])

  const onChangeHandler = (val: SingleValue<any>) => {
    setSchedule(val.value)
    if (onChange) onChange(val.value)
  }

  const options: SelectOption[] = (schedules || [])
    .map(item => ({ value: item.id, label: item.name }))
  const selectOption = options.find(option => option.value === schedule)

  return (
    <Select value={selectOption}
      options={options.filter(item => item.value !== schedule)}
      onChange={onChangeHandler}/>
  );
};

const mapStateToProps = function (state: RootState, ownProps: IProps): StateProps {
  const workspace = state.workspaceState?.current || "";
  const schedules = state.onCallTeamState.team_schedules[ownProps.team] || []
  return { ...ownProps, workspace, schedules }
}

export default connect(mapStateToProps)(AssignFromSchedule);
