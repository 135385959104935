import React from "react";
import { ToastContainer } from "react-toastify";
import { Navigate, Route, Routes } from "react-router-dom";
import OAuthPage from "./pages/oauth.page";
import HomePage from "./pages/home.page";
import SignInPage from "./pages/signin.page";
import SignUpPage from "./pages/signup.page";
import RecoveryPage from "./pages/recovery.page";
import { ProtectedRoute } from "./components/routes";
import WaitListPage from "./pages/waitlist.page";
import NotFoundPage from "./pages/notfound.page";
import AccountLayout from "./components/layout/AccountLayout";
import WorkspaceLayout from "./components/layout/WorkspaceLayout";
import {
  AccountProfilePage,
  AccountSettingsPage,
} from "./pages/account";
import {
  WorkspaceCreatePage,
  WorkspaceInfoPage,
  WorkspaceInfrastructuresPage,
  WorkspaceIntegrationsPage,
  WorkspaceSettingsPage,
  WorkspaceInfrastructureInfoPage,
} from "./pages/workspace";
import {
  OnCallAlertInfoPage,
  OnCallAlertListPage,
  OnCallIncidentInfoPage,
  OnCallIncidentListPage,
  OnCallIntegrationInfoPage,
  OnCallIntegrationListPage,
  OnCallUserInfoPage,
  OnCallUserListPage,
  OnCallTeamListPage,
  OnCallTeamInfoPage,
} from "./pages/oncall";

const App = () => {
  return (
    <div>
      <ToastContainer/>
      <Routes>
        <Route path="/" element={<ProtectedRoute/>}>
          <Route path="">
            <Route index element={<HomePage/>}/>
            <Route path="account" element={<AccountLayout/>}>
              <Route path="" element={<Navigate to="/" replace/>}/>
              <Route index element={<AccountProfilePage/>}/>
              <Route path="-/settings" element={<AccountSettingsPage/>}/>
            </Route>
            <Route path="workspace">
              <Route path="" element={<Navigate to="/" replace/>}/>
              <Route path=":workspace" element={<WorkspaceLayout/>}>
                <Route index element={<WorkspaceInfoPage/>}/>
                <Route path="-/settings" element={<WorkspaceSettingsPage/>}/>
                <Route path="-/integrations" element={<WorkspaceIntegrationsPage/>}/>
                <Route path="-/infrastructures" element={<WorkspaceInfrastructuresPage/>}/>
                <Route path="-/infrastructures/:infrastructure" element={<WorkspaceInfrastructureInfoPage/>}/>
                <Route path="-/oncall">
                  <Route path="alerts" element={<OnCallAlertListPage/>}/>
                  <Route path="alerts/:alert" element={<OnCallAlertInfoPage/>}/>
                  <Route path="incidents" element={<OnCallIncidentListPage/>}/>
                  <Route path="incidents/:incident" element={<OnCallIncidentInfoPage/>}/>
                  <Route path="integrations" element={<OnCallIntegrationListPage/>}/>
                  <Route path="integrations/:integration" element={<OnCallIntegrationInfoPage/>}/>
                  <Route path="users" element={<OnCallUserListPage/>}/>
                  <Route path="users/:user" element={<OnCallUserInfoPage/>}/>
                  <Route path="teams" element={<OnCallTeamListPage/>}/>
                  <Route path="teams/:team" element={<OnCallTeamInfoPage/>}/>
                </Route>
              </Route>
            </Route>

            <Route path="onboarding" element={<WorkspaceCreatePage/>}/>
          </Route>

          <Route path="waitlist" element={<WaitListPage/>}/>
        </Route>

        <Route path="signin" element={<SignInPage/>}/>
        <Route path="signup" element={<SignUpPage/>}/>
        <Route path="recovery" element={<RecoveryPage/>}/>
        <Route path="recovery/:code" element={<RecoveryPage/>}/>
        <Route path="oauth/:provider" element={<OAuthPage/>}/>
        <Route path="404" element={<NotFoundPage/>}/>
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
    </div>
  );
}

export default App;
