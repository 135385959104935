import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  AlertFetchRequest,
  AlertListRequest,
  AlertResponse,
  AlertUpdateRequest,
  AlertEventRequest,
  AlertEventResponse,
  AlertAssignResponderRequest,
  AlertRemoveResponderRequest,
} from "./types/oncall"
import { setAlert, setAlertList, setAlertEventList } from "../features/oncall.alert.slice";

const transformResponse = (item: AlertResponse) => {
  if (item.closed_at) item.closed_at *= 1000
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformResponseEvent = (item: AlertEventResponse) => {
  item.created_at *= 1000;
  return item
}

export const onCallAlertApi = createApi({
  reducerPath: "onCallAlertApi",
  baseQuery: customFetchBase,
  tagTypes: ["OnCallAlerts"],
  endpoints: (builder) => ({
    listAlert: builder.query<AlertResponse[], AlertListRequest>({
      query(req: AlertListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert`,
          params: req.filter,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: AlertResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "OnCallAlerts" as const, id })),
            { type: "OnCallAlerts", id: "LIST" },
          ]
          : [{ type: "OnCallAlerts", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlertList(data));
        } catch (error) {
        }
      },
    }),
    fetchAlert: builder.query<AlertResponse, AlertFetchRequest>({
      query(req: AlertFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlert(data));
        } catch (error) {
        }
      },
    }),
    fetchAlertEventList: builder.query<AlertEventResponse[], AlertEventRequest>({
      query(req: AlertEventRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}/events`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: AlertEventResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponseEvent)
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlertEventList({ alert: args.alert, events: data }));
        } catch (error) {
        }
      },
    }),
    updateAlert: builder.mutation<AlertResponse, AlertUpdateRequest>({
      query(req: AlertUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}/close`,
          method: "POST",
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlert(data));
        } catch (error) {
        }
      },
    }),
    ackAlert: builder.mutation<AlertResponse, AlertUpdateRequest>({
      query(req: AlertUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}/ack`,
          method: "POST",
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlert(data));
        } catch (error) {
        }
      },
    }),
    unAckAlert: builder.mutation<AlertResponse, AlertUpdateRequest>({
      query(req: AlertUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}/ack`,
          method: "DELETE",
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlert(data));
        } catch (error) {
        }
      },
    }),
    assignResponder: builder.mutation<AlertResponse, AlertAssignResponderRequest>({
      query(req: AlertAssignResponderRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}/responder`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlert(data));
        } catch (error) {
        }
      },
    }),
    removeResponder: builder.mutation<AlertResponse, AlertRemoveResponderRequest>({
      query(req: AlertRemoveResponderRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/alert/${req.alert}/responder/${req.responder}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAlert(data));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListAlertQuery,
  useLazyListAlertQuery,
  useFetchAlertQuery,
  useUpdateAlertMutation,
  useAckAlertMutation,
  useUnAckAlertMutation,
  useFetchAlertEventListQuery,
  useAssignResponderMutation,
  useRemoveResponderMutation,
} = onCallAlertApi;

