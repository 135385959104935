import React, { FC } from "react";
import { Modal } from "../../../components";
import {
  FormUserEmailConnect,
  FormUserPhoneConnect,
  FormUserSlackConnect,
  FormUserTelegramConnect
} from "../../../components/forms";
import { AccountIntegrations } from "../../../store/features/integration.slice";
import Tooltip from "../../Tooltip";
import { LocalStorage } from "../../../utils/storage";
import { useLazyListForAccountQuery } from "../../../store/api/integration.api";

type IProps = {
  user: string;
  workspace: string;
  readOnly: boolean;
  integrations: AccountIntegrations;
};

const UserInfo: FC<IProps> = ({ user, workspace, integrations, readOnly }) => {
  const [fetchIntegrationsList] = useLazyListForAccountQuery();

  return (
    <div className="list-group list-group-flush">

      <div className="list-group-item d-flex justify-content-between align-items-center py-2 p-0">
        <div className="ms-2 me-auto">
          <div className="fw-bold">
            <i className="fa fa-user pe-2"/>
            &nbsp;username
          </div>
        </div>
        <span>{user}</span>
      </div>

      <div className="list-group-item d-flex justify-content-between align-items-center py-2 p-0">
        <div className="ms-2 me-auto">
          <div className="fw-bold">
            <i className="fa-solid fa-envelope pe-2"/>
            &nbsp;email
          </div>
        </div>
        {
          integrations?.email && (
            <span>{integrations.email.email}&nbsp;
              {!integrations?.email.verified && (
                <Tooltip text="not verifieed">
                  <i className="fa-solid fa-circle-exclamation text-warning">&nbsp;</i>
                </Tooltip>
              )}
            </span>
          )}
        {!readOnly && (
          <Modal title="Email connect" btnOpenName={integrations?.email ? "edit" : "connect"}
            id="user-email-connect-modal"
            className="modal-dialog-centered"
            btnOpenClassName="btn btn-link text-decoration-none p-0">
            <FormUserEmailConnect workspace={workspace} account={user}
              email={integrations.email?.email || ""}
              onConnect={Modal.closeModal("user-email-connect-modal")}/>
          </Modal>
        )}
      </div>

      <div className="list-group-item d-flex justify-content-between align-items-center py-2 p-0">
        <div className="ms-2 me-auto">
          <div className="fw-bold">
            <i className="fa fa-phone pe-2"/>
            &nbsp;phone
          </div>
        </div>
        {
          integrations?.phone && (
            <span>{integrations.phone.phone}&nbsp;
              {!integrations?.phone.verified && (
                <Tooltip text="not verifieed">
                  <i className="fa-solid fa-circle-exclamation text-warning">&nbsp;</i>
                </Tooltip>
              )}
            </span>
          )}
        {!readOnly && (
          <Modal title="Email connect" btnOpenName={integrations?.phone ? "edit" : "connect"}
            id="user-phone-connect-modal"
            className="modal-dialog-centered"
            btnOpenClassName="btn btn-link text-decoration-none p-0">
            <FormUserPhoneConnect workspace={workspace} account={user}
              phone={integrations.phone?.phone || ""}
              onConnect={Modal.closeModal("user-phone-connect-modal")}/>
          </Modal>
        )
        }
      </div>

      <div className="list-group-item d-flex justify-content-between align-items-center py-2 p-0">
        <div className="ms-2 me-auto">
          <div className="fw-bold">
            <i className="fa fa-brands fa-telegram pe-2"/>
            &nbsp;telegram
          </div>
        </div>
        <div>
          {integrations?.telegram && <span>{integrations.telegram.name}</span>}
          {!readOnly && !integrations?.telegram && (
            <Modal title="Telegram connect" btnOpenName="connect"
              id="user-telegram-connect-modal"
              className="modal-dialog-centered"
              btnOpenClassName="btn btn-link text-decoration-none p-0">
              <FormUserTelegramConnect workspace={workspace}/>
            </Modal>
          )}
        </div>
      </div>

      <div className="list-group-item d-flex justify-content-between align-items-center py-2 p-0">
        <div className="ms-2 me-auto">
          <div className="fw-bold">
            <i className="fa fa-brands fa-slack pe-2"/>
            &nbsp;slack
          </div>
        </div>
        {integrations?.slack && <span>{integrations.slack.name}</span>}
        {!readOnly && !integrations?.slack && (
          <Modal title="Slack connect" btnOpenName="connect"
            id="user-slack-connect-modal"
            className="modal-dialog-centered"
            beforeOpen={() => {
              const fn = () => {
                fetchIntegrationsList({ workspace: workspace ?? "", account: user ?? "" })
                LocalStorage().off("integrations", fn)
                LocalStorage().removeItem("integrations")
                Modal.closeModal("user-slack-connect-modal")()
              }
              LocalStorage().on("integrations", fn)
            }}
            btnOpenClassName="btn btn-link text-decoration-none p-0">
            <FormUserSlackConnect workspace={workspace}/>
          </Modal>
        )
        }
      </div>
    </div>
  );
};

export default UserInfo;
