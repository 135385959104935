import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import UsersList from "./Users.view"
import { useListUserQuery, useDeleteUserMutation } from "../../../store/api/oncall.user.api";
import { useAppSelector } from "../../../app/hooks";
import Loader from "../OnCallIncidentList/Loader";
import { Membership } from "../../../store/features/workspace.slice";
import Suspense from "../../Suspense";

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
  memberships: Membership[];
}

type Props = StateProps;

const UsersContainer = (props: Props) => {
  const query = useListUserQuery({ workspace: props.workspace });
  const userState = useAppSelector((state) => state.onCallUserState);
  const [deleteUser] = useDeleteUserMutation()

  const removeUserHandler = (id: string) => {
    deleteUser({ workspace: props.workspace, account: id })
  }

  return (
    <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <UsersList {...props} items={userState.users} onRemove={removeUserHandler}/>
    </Suspense>
  );
};

const mapStateToProps = function (state: RootState): StateProps {
  const workspace = state.workspaceState?.current || "";
  const memberships = state.workspaceState.memberships[workspace] || []

  return { workspace, memberships }
}

export default connect(mapStateToProps)(UsersContainer);
