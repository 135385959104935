import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Team {
  id: string
  workspace: string
  slug: string
  name: string
  description: string
  created_at: number
  updated_at: number
}

export interface Member {
  id: string
  account: string
  team: string
  created_at: number
  updated_at: number
}

export interface EscalationPolicy {
  id: string
  workspace: string
  team: string
  rules: EscalationPolicyRules[]
  created_at: number
  updated_at: number
}

export interface EscalationPolicyStep {
  type: string
  delay: string
  enabled: boolean
}

export interface EscalationPolicyRules {
  name: string
  enabled: boolean
  filter: EscalationPolicyFilter
  steps: EscalationPolicyStep[]
}

export interface EscalationPolicyConditions {
  field: string
  operation: { any?: string[] } | { equals?: string } | { matches?: string } | { contains?: string }
  not: boolean
}

export interface EscalationPolicyFilter {
  type: string
  conditions: EscalationPolicyConditions[]
}

export interface TeamSchedule {
  id: string
  team: string
  name: string
  description: string
  disabled: boolean
  created_at: number
  updated_at: number
}

export interface TeamScheduleRotation {
  id: string
  name: string
  schedule: string
  start_date: number
  end_date: number
  type: string
  participants: TeamScheduleRotationParticipants[]
  time_restriction: {
    type: string
    restrictions: TeamScheduleRotationRestrictions[]
  }
  created_at: number
  updated_at: number
}

export interface TeamScheduleRotationPeriod {
  title: string,
  start: number,
  end: number,
  width: number,
  offsetLeft: number
}
export interface TeamScheduleRotationRestrictions {
  start_day?: string
  start_hour: number
  start_minute: number
  end_day?: string
  end_hour: number
  end_minute: number
}
export interface TeamScheduleRotationParticipants {
  kind: string
  id: string
}

export interface TeamScheduleOverride {
  id: string
  schedule: string
  user: string
  start_date: number
  end_date: number
  rotations: string[]
  created_at: number
  updated_at: number
}

export interface IOnCallTeamState {
  error: string | null;
  loading: boolean;
  matchTeamSlugs: { [id: string]: string };
  teams: { [id: string]: Team };
  escalation_policies: { [id: string]: EscalationPolicy };
  team_members: { [id: string]: Member[] }
  team_schedules: { [id: string]: TeamSchedule[] }
  team_schedule_rotations: { [schedule: string]: TeamScheduleRotation[] }
  team_schedule_overrides: { [schedule: string]: TeamScheduleOverride[] }
}

const initialState: IOnCallTeamState = {
  error: null,
  loading: true,
  matchTeamSlugs: {},
  teams: {},
  escalation_policies: {},
  team_members: {},
  team_schedules: {},
  team_schedule_rotations: {},
  team_schedule_overrides: {}
};

export const onCallTeamSlice = createSlice({
  initialState,
  name: "onCallTeam",
  reducers: {
    setTeamList: (state, action: PayloadAction<Team[]>) => {
      state.teams = {}
      for (let key in action.payload) {
        state.matchTeamSlugs[action.payload[key].slug] = action.payload[key].id
        state.teams[action.payload[key].id] = action.payload[key]
      }
      state.loading = false
    },
    setTeam: (state, action: PayloadAction<Team>) => {
      state.matchTeamSlugs[action.payload.slug] = action.payload.id
      state.teams[action.payload.id] = action.payload;
      state.loading = false
    },
    removeTeam: (state, action: PayloadAction<string>) => {
      delete state.teams[action.payload];
    },
    setEscalationPolicy: (state, action: PayloadAction<EscalationPolicy>) => {
      state.escalation_policies[action.payload.team] = action.payload;
    },
    setMemberList: (state, action: PayloadAction<Member[]>) => {
      state.team_members = {};
      (action.payload || []).forEach((item) => {
        if (!state.team_members[item.team]) state.team_members[item.team] = [];
        state.team_members[item.team].push(item)
      })
      state.loading = false
    },
    setMember: (state, action: PayloadAction<{ team: string, member: Member }>) => {
      state.team_members[action.payload.team].push(action.payload.member)
      state.loading = false
    },
    removeMember: (state, action: PayloadAction<{ team: string, account: string }>) => {
      state.team_members[action.payload.team] = state.team_members[action.payload.team].filter(elem => {
        return elem.account !== action.payload.account
      })
      state.loading = false
    },
    setScheduleList: (state, action: PayloadAction<TeamSchedule[]>) => {
      state.team_schedules = {};
      (action.payload || []).forEach((item) => {
        if (!state.team_schedules[item.team]) state.team_schedules[item.team] = [];
        state.team_schedules[item.team].push(item)
      })
      state.loading = false
    },
    setSchedule: (state, action: PayloadAction<TeamSchedule>) => {
      state.team_schedules[action.payload.team].push(action.payload)
      state.loading = false
    },
    updateSchedule: (state, action: PayloadAction<TeamSchedule>) => {
      state.team_schedules[action.payload.team] = state.team_schedules[action.payload.team].map(elem => {
        if (elem.id === action.payload.id) {
          return action.payload
        }
        return elem
      })
    },
    removeSchedule: (state, action: PayloadAction<{ team: string, schedule: string }>) => {
      state.team_schedules[state.matchTeamSlugs[action.payload.team]] =
        state.team_schedules[state.matchTeamSlugs[action.payload.team]].filter(elem => {
          return elem.id !== action.payload.schedule
        })
      state.loading = false
    },
    setScheduleRotationList: (state, action: PayloadAction<TeamScheduleRotation[]>) => {
      state.team_schedule_rotations = {};
      (action.payload || []).forEach((item) => {
        if (!state.team_schedule_rotations[item.schedule])
          state.team_schedule_rotations[item.schedule] = [];
        state.team_schedule_rotations[item.schedule].push(item)
      })
      state.loading = false
    },
    setScheduleRotation: (state, action: PayloadAction<TeamScheduleRotation>) => {
      if (!state.team_schedule_rotations[action.payload.schedule]) {
        state.team_schedule_rotations[action.payload.schedule] = []
      }
      state.team_schedule_rotations[action.payload.schedule].push(action.payload)
      state.loading = false
    },
    removeScheduleRotation: (state, action: PayloadAction<{ schedule: string, rotation: string }>) => {
      state.team_schedule_rotations[action.payload.schedule] =
        state.team_schedule_rotations[action.payload.schedule].filter(elem => {
          return elem.id !== action.payload.rotation
        })
      state.loading = false
    },
    updateScheduleRotation: (state, action: PayloadAction<TeamScheduleRotation>) => {
      state.team_schedule_rotations[action.payload.schedule] = state.team_schedule_rotations[action.payload.schedule]
        .map(elem => {
          if (elem.id === action.payload.id) {
            return action.payload
          }
          return elem
        })
    },
    setScheduleOverrideList: (state, action: PayloadAction<TeamScheduleOverride[]>) => {
      state.team_schedule_overrides = {};
      (action.payload || []).forEach((item) => {
        if (!state.team_schedule_overrides[item.schedule])
          state.team_schedule_overrides[item.schedule] = [];
        state.team_schedule_overrides[item.schedule].push(item)
      })
      state.loading = false
    },
    setScheduleOverride: (state, action: PayloadAction<TeamScheduleOverride>) => {
      if (!state.team_schedule_overrides[action.payload.schedule]) {
        state.team_schedule_overrides[action.payload.schedule] = []
      }
      state.team_schedule_overrides[action.payload.schedule].push(action.payload)
      state.loading = false
    },
    removeScheduleOverride: (state, action: PayloadAction<{ schedule: string, override: string }>) => {
      state.team_schedule_overrides[action.payload.schedule] =
        state.team_schedule_overrides[action.payload.schedule].filter(elem => {
          return elem.id !== action.payload.override
        })
      state.loading = false
    },
    updateScheduleOverride: (state, action: PayloadAction<TeamScheduleOverride>) => {
      state.team_schedule_overrides[action.payload.schedule] = state.team_schedule_overrides[action.payload.schedule]
        .map(elem => {
          if (elem.id === action.payload.id) {
            return action.payload
          }
          return elem
        })
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = onCallTeamSlice;
// Extract and export each action creator by name
export const {
  setTeamList,
  setTeam,
  removeTeam,
  setEscalationPolicy,
  setMember,
  setMemberList,
  removeMember,
  setScheduleList,
  setSchedule,
  removeSchedule,
  updateSchedule,
  setScheduleRotation,
  setScheduleRotationList,
  removeScheduleRotation,
  updateScheduleRotation,
  setScheduleOverrideList,
  setScheduleOverride,
  removeScheduleOverride,
  updateScheduleOverride,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

