import React, { FC, useEffect, useRef } from "react";
import moment from "moment/moment";
import { IntegrationChat, IntegrationsChatFilterConditions } from "../../store/features/oncall.integration.slice";
import { Modal } from "../index";
import { FormOnCallIntegrationChatRule } from "./index";
import { useUpdateIntegrationRulesMutation } from "../../store/api/oncall.integration.api";
import { toast } from "react-toastify";

type IProps = {
  items: IntegrationChat[]
  onRemove: (id: string) => void
  workspace: string
  integration: string
};

function channelType(item: string) {
  const icon = item?.toLowerCase();
  switch (icon) {
    case "slack":
      return "fa-slack";
    case "discord":
      return "fa-discord";
    case "telegram":
      return "fa-telegram";
    default:
      return "fa-font-awesome";
  }
}

const FormIntegrationChatsList: FC<IProps> = ({ items, workspace, integration, onRemove }) => {
  const [updateRules, { isError, error, isSuccess }] = useUpdateIntegrationRulesMutation()
  const updateRulesHandler = (item_id: string, rules: {
    description: string, filter: {type: string, conditions: IntegrationsChatFilterConditions[]}}) => {
    updateRules({ workspace: workspace, chat: item_id, integration: integration, data: rules })
  };

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      refSuccess.current = true;
      Modal.closeModal("chat-rule-modal")()
      toast.success("change rules successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
            <th scope="col">Rules</th>
            <th scope="col"/>
          </tr>
        </thead>
        <tbody>
          {
            (items || []).map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <i className={`fa-brands ${channelType(item.provider)}`}/>&nbsp;{item.name}
                    </div>
                  </td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                  <td>
                    <Modal id="chat-rule-modal" title="Chat rules"
                      btnOpenClassName="btn btn-sm btn-outline-primary"
                      btnOpenName="change rules" className="modal-dialog-centered integration-rules"
                      hideFooter={true}>
                      <FormOnCallIntegrationChatRule item={item} updateRulesHandler={updateRulesHandler} />
                    </Modal>
                  </td>
                  <td>
                    <i className="far fa-trash-alt cursor-pointer" onClick={() => onRemove(item.id)}/>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default FormIntegrationChatsList;
