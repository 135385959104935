import { FC } from "react";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";
import { useDeleteDeviceMutation } from "../../store/api/integration.api";

type IProps = {};

const OnCallUserDeviceList: FC<IProps> = () => {
  const integrationState = useAppSelector((state) => state.integrationState);
  const [removeDevice] = useDeleteDeviceMutation();
  const handlerRemove = (id: string) => {
    removeDevice(id);
  }

  return (
    <div>
      {Object.keys(integrationState.devices || []).map((key, index) => {
        const item = integrationState.devices[key];
        return (
          <div key={key} className="d-flex">
            <div className="col-1">{index + 1}.</div>
            <div className="col-6">{item.name}</div>
            <div className="col-4">{moment(item.created_at).format("DD/MM/YYYY")}</div>
            <div className="col-1">
              <i className="fa-solid fa-xmark cursor-pointer" onClick={() => handlerRemove(item.id)}/>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default OnCallUserDeviceList;
