import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import AlertsList from "./Alerts.view"
import Loader from "./Loader";
import { useListAlertQuery } from "../../../store/api/oncall.alert.api";
import { useAppSelector } from "../../../app/hooks";
import Suspense from "../../Suspense";
import { selectAlertsByFilter } from "../../../app/selectors";
import { useListIntegrationQuery } from "../../../store/api/oncall.integration.api";
import AlertListAll from "./AlertListAll";

// props from connect mapStateToProps
interface StateProps {
  workspace: string
}

export type ComponentType = "all" | "general"

interface Props {
  limit?: number
  status?: string
  priority?: string
  integration?: string
  component?: ComponentType
  fetch?: boolean | undefined
  onUpdate?: (time: number) => void
  cursor?: number
  cursor_limit?: number
  cursor_start?: number
  cursor_end?: number
}

type IProps = StateProps & Props;

const AlertsContainer = (props: IProps) => {
  const integrationQuery = useListIntegrationQuery({ workspace: props.workspace });

  const query = useListAlertQuery({
    workspace: props.workspace,
    filter: {
      cursor_limit: 100,
      integration: props.integration,
      priority: props.priority,
      status: props.status,
    }
  }, { pollingInterval: 300000 });

  const alerts = useAppSelector((state) => selectAlertsByFilter(state, props));

  return (
    <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <Suspense query={integrationQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
        {props.component
          ? <AlertListAll {...props} items={alerts}/>
          : <AlertsList {...props} items={alerts}/>
        }
      </Suspense>
    </Suspense>
  );
};

const mapStateToProps = function (state: RootState, ownProps: Props): IProps {
  return {
    ...ownProps,
    workspace: state.workspaceState?.current || "",
  }
}

export default connect(mapStateToProps)(AlertsContainer);
