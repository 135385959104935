import { useAppSelector } from "../../app/hooks";
import { useFetchTeamQuery, useListTeamMemberQuery } from "../../store/api/oncall.team.api";
import { useListUserQuery } from "../../store/api/oncall.user.api";
import { Breadcrumbs, OnCallTeamEscalationPolicy, Suspense } from "../../components";
import { useParams } from "react-router-dom";
import React from "react";
import {
  FormOnCallTeamInfo,
  FormOnCallTeamMemberAdd,
} from "../../components/forms";
import { OnCallTeamMemberList, OnCallTeamScheduleList } from "../../components/oncall";

const OnCallTeamInfoPage = () => {
  let { team } = useParams();
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current ?? ""
  const memberships = workspaceState?.memberships[workspace]
  const teamId = useAppSelector((state) => state.onCallTeamState)?.matchTeamSlugs[team ?? ""];
  const teamMembersState = useAppSelector((state) => state.onCallTeamState).team_members[teamId];

  // Content fetching
  const teamFetchQuery = useFetchTeamQuery({
    workspace: workspace,
    team: team ?? ""
  }, { refetchOnMountOrArgChange: true });

  const membersQuery = useListUserQuery({ workspace: workspace })
  const teamMembersFetchQuery = useListTeamMemberQuery({
    workspace: workspace,
    team: team ?? ""
  }, { refetchOnMountOrArgChange: true });

  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "teams", slug: "teams" }, { name: team ?? "" }]}/>
      </div>
      <div className="mb-4">
        <h2>Team Info</h2>
      </div>
      <Suspense query={membersQuery} loader={<div>Loading</div>} fallback={<div>Failed data loading</div>}>
        <Suspense query={teamFetchQuery} loader={<div>Loading</div>} fallback={<div>Failed data loading</div>}>
          <div className="row d-flex mb-4">
            <div className="col-lg-4 col-md-6 col-12">
              <FormOnCallTeamInfo workspace={workspace} team={teamId}/>
            </div>
            <div className="col-lg-8 col-md-6 col-12">
              <div className="d-flex align-items-center">
                <strong>Members</strong>
                <FormOnCallTeamMemberAdd team={teamId}/>
              </div>
              <div className="mt-2">
                <OnCallTeamMemberList workspace={workspace} items={teamMembersState || []} memberships={memberships} />
                <Suspense
                  query={teamMembersFetchQuery}
                  loader={<div>Loading</div>}
                  fallback={<div>Failed members loading</div>}>
                </Suspense>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <OnCallTeamEscalationPolicy workspace={ workspace } team={ team ?? "" }/>
          </div>
          <div className="mb-2">
            <OnCallTeamScheduleList workspace={workspace} team={team ?? ""}/>
          </div>
        </Suspense>
      </Suspense>
    </div>
  );
};

export default OnCallTeamInfoPage;
