import { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Incident } from "../../../store/features/oncall.incident.slice";

type IProps = {
  workspace: string
  items: { [id: string]: Incident }
};

const IncidentsList: FC<IProps> = ({ workspace, items }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Status</th>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(items).map((key, index) => {
              const item = items[key]
              return (
                <tr key={index}>
                  <td>[{item.priority}] {item.status}</td>
                  <td>
                    <Link to={`/workspace/${workspace}/-/oncall/incidents/${item.id}`}>{item.title}</Link>
                  </td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default IncidentsList;
