import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import IntegrationsList from "./Integrations.view"
import Loader from "./Loader";
import { useListIntegrationQuery } from "../../../store/api/oncall.integration.api";
import { useAppSelector } from "../../../app/hooks";
import Suspense from "../../Suspense";

// props from connect mapStateToProps
interface StateProps {
  loading: boolean;
  workspace: string;
}

type Props = StateProps;

const IntegrationsContainer = (props: Props) => {
  const query = useListIntegrationQuery({ workspace: props.workspace });
  const integrationState = useAppSelector((state) => state.onCallIntegrationState);

  return (
    <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <IntegrationsList {...props} items={integrationState.integrations} />
    </Suspense>
  );
};

const mapStateToProps = function (state: RootState): StateProps {
  return {
    loading: state.workspaceState.loading,
    workspace: state.workspaceState?.current || "",
  }
}

export default connect(mapStateToProps)(IntegrationsContainer);
