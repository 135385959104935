import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useCreateScheduleMutation } from "../../store/api/oncall.team.api";

const nameFormat = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9_\s]+/g, "")
}

const FormSchema = object({
  name: string()
    .min(1, "Name can not be empty")
    .transform(val => nameFormat(val).replace(/ +/g, " ")),
  description: string()
    .max(1024, "Description is not valid")
    .optional(),
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string
  team: string
  onSubmit?: () => void
}

const FormOnCallTeamScheduleCreate: FC<IProps> = ({ workspace, team, onSubmit }) => {
  const [teamScheduleCreate, { isError, error, isSuccess }] = useCreateScheduleMutation();
  const [state, setState] = useState("");

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      onSubmit && onSubmit()
      refSuccess.current = true;
      toast.success("Schedule create successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess, onSubmit]);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = nameFormat(e.target.value)
    setState(e.target.value)
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    teamScheduleCreate({
      workspace,
      team,
      data: values
    });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="mb-2">
            <label className="form-label" htmlFor="nameInput">Name</label>
            <input
              type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}
              onChange={handleChangeName}/>
            {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="descriptionInput">Description</label>
            <textarea
              id="descriptionInput" className="form-control" placeholder="Enter description" rows={4}
              {...register("description")}/>
            {errors.description?.message && <small className="text-danger">{errors.description?.message}</small>}
          </div>

          <div className="d-flex">
            <button type="submit" className="btn btn-primary" disabled={!state}>Apply</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormOnCallTeamScheduleCreate;
