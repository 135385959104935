import { FormFilterRules, FormUserNotificationPolicy } from "../../components/forms";
import React, { FC, useState } from "react";
import {
  NotificationPolicyFilter,
  NotificationPolicyRules,
  NotificationPolicyStep
} from "../../store/features/oncall.user.slice";
import { OnChangeValue } from "react-select";

interface IProps {
  rules: NotificationPolicyRules
  workspace: string
  updateHandler: (rules: NotificationPolicyRules) => void
}

const FormOnCallUserNotificationRules: FC<IProps> = ({ rules, workspace, updateHandler }) => {
  const [name, setName] = useState(rules.name || "")
  const [notificationSteps, setNotificationSteps] = useState<NotificationPolicyStep[]>(rules.steps)
  const [notificationFilters, setNotificationFilters] = useState<NotificationPolicyFilter>(rules.filter)

  const conditionOptions = {
    field: [
      { value: "alert:priority", label: "Alert priority" },
      { value: "pattern", label: "Pattern" },
      { value: "integration:name", label: "Integration name" },
    ],
    not: [{ value: true, label: "NOT" }, { value: false, label: "IS" }],
    operation: {
      "alert:priority": [{ value: "equals", label: "Equals" }],
      "pattern": [{ value: "matches", label: "Matches" }],
      "integration:name": [{ value: "equals", label: "Equals" }, { value: "any", label: "Any" }, {
        value: "contains",
        label: "Contains"
      }],
    },
    priorityValue: [{ value: "critical", label: "Critical" }, { value: "warning", label: "Warning" }, {
      value: "info",
      label: "Info"
    }, { value: "none", label: "None" }],
  }

  const changeNameHandler =(e: OnChangeValue<any, any>) => {
    e.preventDefault();
    setName(e.target.value)
  }
  const changeStepsHandler =(val: OnChangeValue<any, any>) => {
    setNotificationSteps(val)
  }
  const changeFilterHandler = (val: OnChangeValue<any, any>) => {
    setNotificationFilters(val)
  }

  function updateNotificationHandler() {
    const rules: NotificationPolicyRules = {
      name: name,
      steps: notificationSteps,
      filter: notificationFilters
    }
    updateHandler(rules)
  }

  return (
    <div>
      <div className="mb-4">
        <label htmlFor="nameInput" className="form-label m-0">Enter name</label>
        <input type="text" className="form-control my-2" placeholder="Enter Name" defaultValue={name}
          onChange={changeNameHandler}/>
      </div>
      <div className="mb-4">
        <label htmlFor="nameInput" className="form-label m-0">Notification filters</label>
        <FormFilterRules workspace={workspace} item={rules.filter || []} options={conditionOptions}
          onChange={changeFilterHandler}/>
      </div>
      <div className="mb-4">
        <label htmlFor="nameInput" className="form-label m-0">Notification steps</label>
        <FormUserNotificationPolicy steps={rules.steps || []} onChange={changeStepsHandler}/>
      </div>
      <div className="d-flex justify-content-between">
        <button className="btn btn-primary" onClick={updateNotificationHandler}>Apply</button>
      </div>
    </div>
  );
};

export default FormOnCallUserNotificationRules;
