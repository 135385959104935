import { createApi } from "@reduxjs/toolkit/query/react";
import { setAccount } from "../features/account.slice";
import customFetchBase from "./customFetchBase";
import type { AccountResponse } from "./types/account"

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: customFetchBase,
  tagTypes: ["Account"],
  endpoints: (builder) => ({
    getMe: builder.query<AccountResponse, void>({
      query() {
        return {
          url: "v1/account",
          credentials: "include",
        };
      },
      transformResponse: (response: AccountResponse) => {
        response.created_at *= 1000;
        response.updated_at *= 1000;
        return response
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccount(data));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useGetMeQuery,
} = accountApi;

