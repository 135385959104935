import { OnCallUserInfo } from "../../components/oncall/OnCallUserInfo";
import { useAppSelector } from "../../app/hooks";
import { useListDeviceQuery, useListForAccountQuery } from "../../store/api/integration.api";
import { useNotificationPolicyQuery } from "../../store/api/oncall.user.api";
import { Breadcrumbs, OnCallUserNotificationPolicyInfo, Suspense } from "../../components";
import { useParams } from "react-router-dom";
import { OnCallUserDeviceList } from "../../components/oncall";

const OnCallUserInfoPage = () => {
  let { user } = useParams();

  const accountState = useAppSelector((state) => state.accountState);
  const account = accountState.account?.username ?? ""
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current ?? ""

  // Content fetching
  const integrationListQuery = useListForAccountQuery({
    workspace: workspace,
    account: user ?? ""
  }, { refetchOnMountOrArgChange: true });
  const notificationPolicyQuery = useNotificationPolicyQuery({
    workspace: workspace,
    account: user ?? ""
  }, { refetchOnMountOrArgChange: true });
  const notificationPolicy = useAppSelector((state) => state.onCallUserState)?.notification_policies[user ?? ""];
  const deviceListQuery = useListDeviceQuery(undefined, { refetchOnMountOrArgChange: true });

  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "users", slug: "users" }, { name: user ?? "" }]}/>
      </div>
      <div className="mb-4">
        <h2>User Info</h2>
      </div>
      <Suspense query={integrationListQuery} loader={<div>Loading</div>} fallback={<div>Failed data loading</div>}>
        <div className="mb-2">
          <h5>User integrations {user}</h5>
        </div>
        <div className="row row-cols-auto mb-4">
          <div className="col-lg-4 col-md-6 col-12">
            <OnCallUserInfo user={user ?? ""}/>
          </div>
        </div>

        <div className="row">
          {(!!account && account === user) && (
            <Suspense query={deviceListQuery} loader={<div>Loading</div>} fallback={<div>Failed data loading</div>}>
              <div>
                <div className="mb-2">
                  <h5 className="m-0">Devices</h5>
                </div>
                <div className="row row-cols-auto mb-4">
                  <div className="col-lg-6 col-12">
                    <Suspense query={deviceListQuery} loader={<div>Loading</div>}
                      fallback={<div>Failed data loading</div>}>
                      <OnCallUserDeviceList/>
                    </Suspense>
                  </div>
                </div>
              </div>
            </Suspense>
          )}
        </div>
        <div className="row">
          <h5 className="m-0">Notification Policy</h5>
          <Suspense query={notificationPolicyQuery ?? ""} loader={<div>Loading</div>}
            fallback={<div>Failed data loading</div>}>
            {notificationPolicy &&
              <OnCallUserNotificationPolicyInfo
                notification={notificationPolicy}
                workspace={workspace}
                account={account}
                user={user ?? ""} />
            }
          </Suspense>
        </div>
      </Suspense>
    </div>
  );
};

export default OnCallUserInfoPage;
