import React, { FC, useState } from "react";
import Select, { OnChangeValue } from "react-select";
import { NotificationPolicyConditions } from "../../../store/features/oncall.user.slice";

export interface IProps {
  values: NotificationPolicyConditions;
  onChange: (data: {not: boolean, operation: string, value: string}) => void
}

const AlertPrioritySelect: FC<IProps> = ({ values, onChange }) => {
  const obj = {
    not: values.not || false,
    operation: Object.keys(values.operation)[0] || "",
    value: Object.values(values.operation)[0] || "",
  }
  const [stateValue, setStateValue] = useState(obj)

  const onStepConditionNotHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.not = val.value
      onChange(state)
      return state
    })
  }
  const onStepConditionOperationHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.operation = val.value
      onChange(state)
      return state
    })
  }
  const onStepConditionValueHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.value = val.value
      onChange(state)
      return state
    })
  }

  const optionsNot = [{ value: false, label: "IS" }, { value:true, label: "NOT" }]
  const selectedOptionNot = optionsNot.find(item => {
    return stateValue.not === item.value
  })
  const optionsOperations = [{ value: "equals", label: "Equals" }]
  const selectedOptionOperation = optionsOperations.find((item )=> {
    return stateValue.operation === item.value
  })
  const priorityValue = [
    { value: "critical", label: "Critical" },
    { value: "warning", label: "Warning" },
    { value: "info", label: "Info" },
    { value: "none", label: "None" },
  ]
  const selectedOperationValue = priorityValue.find((item )=> {
    return stateValue.value === item.value
  })

  return (
    <div className="d-flex flex-wrap col-8 justify-content-between">
      <Select
        className="col-3"
        onChange={onStepConditionNotHandler}
        defaultValue={selectedOptionNot}
        options={optionsNot} />
      <Select
        className="col-3"
        onChange={onStepConditionOperationHandler}
        defaultValue={selectedOptionOperation}
        options={optionsOperations} />
      <Select
        className="col-5"
        onChange={onStepConditionValueHandler}
        defaultValue={selectedOperationValue}
        options={priorityValue} />
    </div>
  );
};

export default AlertPrioritySelect;
