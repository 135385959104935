import { FC, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { connect } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Membership } from "../../../store/features/workspace.slice";
import { Member } from "../../../store/features/oncall.team.slice";

interface SelectOption {
  label: string;
  value: string;
}

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
  memberships: Membership[];
  members: Member[];
}

export interface IProps {
  team: string;
  value: string;
  onChange?: (data: string) => void
}

type Props = StateProps & IProps;

const SetResponder: FC<Props> = ({ team, value, memberships, members, onChange }) => {
  const teamState = useAppSelector((state) => state.onCallTeamState);
  const [member, setUser] = useState<string>(value || "");

  useEffect(() => {
    setUser(value || "")
  }, [value, setUser])

  const mbsMaps: Record<string, string> = {};
  memberships.forEach(item => mbsMaps[item.account] = item.username)

  const onChangeHandler = (val: SingleValue<any>) => {
    setUser(val.value)
    if (onChange) onChange(val.value)
  }

  const options: SelectOption[] = (teamState.team_members[team] || [])
    .map(item => ({ value: item.account, label: mbsMaps[item.account], toString: () => item.account }))
  const selectOption = options.find(option => option.value === member)

  return (
    <Select value={selectOption}
      options={options.filter(item => item.value !== member)}
      onChange={onChangeHandler}/>
  );
};

const mapStateToProps = function (state: RootState, ownProps: IProps): StateProps {
  const workspace = state.workspaceState?.current || "";
  const memberships = state.workspaceState.memberships[workspace] || []
  const members = state.onCallTeamState.team_members[ownProps.team] || []
  return { ...ownProps, workspace, memberships, members }
}

export default connect(mapStateToProps)(SetResponder);
