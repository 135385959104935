import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { logout } from "../../../store/features/session.slice";
import { Gravatar } from "../../Gravatar";
import React from "react";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const accountState = useAppSelector((state) => state.accountState).account;
  const email: string | undefined = accountState?.email
  if (email === null) {
    return <></>
  }

  const onLogoutHandler = async () => {
    await dispatch(logout())
    navigate("/signin")
  };

  return (
    <>
      <nav className="navbar text-bg-dark py-0 vw-100 header-content">
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Link to="/" className="navbar-brand active d-flex align-items-center justify-content-center ms-1"
              aria-current="page">
              <img src="/images/logo-small.svg" alt="Logo" width="30" height="24"
                className="d-inline-block align-text-top img-fluid ms-2"/>
            </Link>
            <div className="toggle-menu ms-3">
              <Link to="#" className="text-white">
                <i className="fa fa-bars"/>
              </Link>
            </div>
          </div>
          <div className="dropstart">
            <Link to="#"
              type="button"
              className="text-white text-decoration-none"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside, inside"
            >
              <Gravatar email={email || ""} size={32} imageClassName={"rounded-circle"} />
            </Link>
            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
              <li className="w-100"><Link to="/onboarding" className="dropdown-item">New workspace</Link></li>
              <li className="w-100"><Link to="/account/-/settings" className="dropdown-item">Settings</Link></li>
              <li className="w-100"><Link to="/account" className="dropdown-item">Profile</Link></li>
              <li className="w-100">
                <hr className="dropdown-divider"/>
              </li>
              <li className="w-100">
                <Link to="/signin" className="dropdown-item" onClick={onLogoutHandler}>Sign out</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
