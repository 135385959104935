import { primaryKey } from "@mswjs/data"
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { Account } from "../../store/features/account.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

interface MockAccount extends Account {
  password: string;
}

export const AccountModel = defineModel<MockAccount>(() => ({
  id: primaryKey(String),
  username: String,
  password: String,
  email: String,
  active: Boolean,
  created_at: Number,
  updated_at: Number,
}))
