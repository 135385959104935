import React, { cloneElement, FC, useEffect, useRef } from "react";
import { Tooltip as BsTooltip } from "bootstrap";

export type PopoverPlacement = "auto"
  | "top"
  | "bottom"
  | "left"
  | "right"

export type PopoverTrigger = | "click"
  | "hover"
  | "focus"
  | "manual"
  | "click hover"
  | "click focus"
  | "hover focus"
  | "click hover focus"

interface IProps {
  children: React.JSX.Element,
  text: string,
  placement?: PopoverPlacement
  trigger?: PopoverTrigger
}

const Tooltip: FC<IProps> = (p) => {
  const childRef = useRef(undefined as unknown as Element)
  const placement = p.placement || "auto"
  const trigger = p.trigger || "hover"

  useEffect(() => {
    const t = new BsTooltip(childRef.current, {
      title: p.text || "-",
      placement: placement,
      trigger: trigger
    })
    return () => t.dispose()
  }, [p.text, placement, trigger])

  return cloneElement(p.children, { ref: childRef })
}

export default Tooltip
