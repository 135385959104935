import Loader from "../../oncall/OnCallAlertList/Loader";
import { useAppSelector } from "../../../app/hooks";
import Suspense from "../../Suspense";
import FormInfrastructureChannels from "./index";
import {
  useDeleteInfrastructureChannelMutation,
  useFetchInfrastructureChannelListQuery,
  useUpdateInfrastructureChannelRulesMutation
} from "../../../store/api/infrastrucrture.api";
import { useEffect, useRef } from "react";
import { Modal } from "../../index";
import { toast } from "react-toastify";

interface StateProps {
  workspace: string;
  infrastructure?: string;
}

type Props = StateProps;

const FormChannelsContainer = (props: Props) => {
  const channelsQuery = useFetchInfrastructureChannelListQuery({
    workspace: props.workspace,
    infrastructure: props.infrastructure ?? ""
  });
  const channelState = useAppSelector((state) => state.infrastructureState)?.channels[props?.infrastructure ?? ""]
  const [removeChannel, removeChannelResp] = useDeleteInfrastructureChannelMutation();
  const [updateRules, { isError, error, isSuccess }] = useUpdateInfrastructureChannelRulesMutation()
  const updateRulesHandler = (data: any) => {
    const updates = {
      name: data.name,
      filter: data.filter
    }
    updateRules({
      workspace: props.workspace ?? "",
      chat: data.chat,
      infrastructure: props.infrastructure ?? "",
      data: updates })
    Modal.closeModal("channel-rule-modal")()
  };
  function removeHandler(id: string) {
    removeChannel({ workspace: props.workspace, infrastructure: props.infrastructure ?? "", chat: id })
    Modal.closeModal("delete-channel-modal")()
  }

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      refSuccess.current = true;
      Modal.closeModal("channel-rule-modal")()
      toast.success("change rules successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    if (removeChannelResp.isSuccess) {
      Modal.closeModal("delete-channel-modal")()
      toast.success("delete channel successfully", {
        position: "bottom-right",
      });
    }
    if (removeChannelResp.isError) {
      if (Array.isArray((removeChannelResp.error as any)?.data?.error)) {
        (removeChannelResp.error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((removeChannelResp.error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [removeChannelResp]);

  return (
    <Suspense query={channelsQuery} loader={<Loader/>} fallback={<div>Failed channels loading</div>}>
      <FormInfrastructureChannels
        workspace={props.workspace}
        items={channelState}
        onUpdate={(val: any) => updateRulesHandler(val)}
        onRemove={(id) => removeHandler(id)} />
    </Suspense>
  );
};

export default FormChannelsContainer;

