import React, { FC, useState } from "react";
import { IntegrationChat, IntegrationsChatFilterConditions } from "../../store/features/oncall.integration.slice";
import Select, { OnChangeValue } from "react-select";

interface IProps {
  item: IntegrationChat
  updateRulesHandler: (item_id: string, rules: Rules) => void
}
interface Rules {
  description: string,
  filter: {
    type: string,
    conditions: IntegrationsChatFilterConditions[]
  }
}
interface TypeOptions {
  value: string
  label: string
}
interface ConditionOptions {
  field: TypeOptions[]
  not: {value: boolean, label: string}[]
  operation: {
    priority: TypeOptions[]
    pattern: TypeOptions[]
  }
  expected_value: TypeOptions[]
}
const FormOnCallIntegrationChatRule: FC<IProps> = ({ item, updateRulesHandler }) => {
  const [typeValue, setTypeValue] = useState(item.filter?.type || "match-all");
  const [conditions, setConditions] = useState(item.filter?.conditions || [])
  const [descriptionChat, setDescriptionChat] = useState(item.description || "");

  const conditionOptions: ConditionOptions = {
    field: [{ value: "priority", label: "Priority" }, { value: "pattern", label: "Pattern" }],
    not: [{ value: true, label: "NOT" }, { value: false, label: "---" }],
    operation: {
      priority: [{ value: "equals", label: "Equals" }],
      pattern: [{ value: "matches", label: "Matches" }],
    },
    expected_value: [
      { value: "critical", label: "Critical" },
      { value: "warning", label: "Warning" },
      { value: "info", label: "Info" },
      { value: "none", label: "None" },
    ],
  }

  const typeOptions: TypeOptions[] = [
    { value: "match-all", label: "Match all" },
    { value: "match-any-condition", label: "Match any condition" },
    { value: "match-all-conditions", label: "Match all conditions" },
  ];

  const onStepChangeHandler = (selectedOption: any) => {
    setTypeValue(selectedOption.value)
  }
  const onConditionAddHandler = () => {
    setConditions(state => {
      return [...state, { field: "", not: false, operation: "", expected_value: "" }]
    })
  };
  const onStepConditionFieldHandler = (index: number, val: OnChangeValue<any, any>) => {
    setConditions(state => {
      return [
        ...state.slice(0, index),
        { ...state[index], field: val.value, not: false, operation: "", expected_value: "" },
        ...state.slice(index + 1),
      ]
    })
  }
  const onStepConditionNotHandler = (index: number, val: OnChangeValue<any, any>) => {
    setConditions(state => {
      return [...state.slice(0, index), { ...state[index], not: val.value }, ...state.slice(index + 1)]
    })
  }
  const onStepConditionOperationHandler = (index: number, val: OnChangeValue<any, any>) => {
    setConditions(state => {
      return [...state.slice(0, index), { ...state[index], operation: val.value }, ...state.slice(index + 1)]
    })
  }
  const onStepConditionExpectedHandler = (index: number, val: OnChangeValue<any, any>) => {
    setConditions(state => {
      return [...state.slice(0, index), { ...state[index], expected_value: val.value }, ...state.slice(index + 1)]
    })
  }
  const onStepConditionExpectedPatternHandler = (index: number, val: string) => {
    setConditions(state => {
      return [...state.slice(0, index), { ...state[index], expected_value: val }, ...state.slice(index + 1)]
    })
  }
  const onStepRemoveHandler = (index: number) => {
    setConditions(state => {
      return [...state.slice(0, index), ...state.slice(index + 1)]
    })
  };
  function onSubmitHandler() {
    const elem = { description: descriptionChat, filter: { type: typeValue, conditions: conditions } }
    updateRulesHandler(item.id, elem)
  }

  const selectedOption = typeOptions.find(val => val.value === typeValue)

  return (
    <div>
      <div className="flex-grow-1 my-2">
        <Select
          value={selectedOption}
          onChange={onStepChangeHandler}
          options={typeOptions} />
      </div>
      <div className="flex-grow-1 my-3">
        { typeValue === "match-all" ? null
          : conditions.map((elem, index) => {
            const selectedField = conditionOptions.field
              .find(val => val.value === elem.field);
            const selectedNot = conditionOptions.not
              .find(val => val.value === elem.not);
            const selectedOperationPriority = conditionOptions.operation.priority
              .find(val => val.value === elem.operation);
            const selectedOperationPattern = conditionOptions.operation.pattern
              .find(val => val.value === elem.operation);
            const selectedExpected = conditionOptions.expected_value
              .find(val => val.value === elem.expected_value);
            return (
              <div className="row mt-2 align-items-center justify-content-between d-flex" key={index}>
                <Select
                  className="col-sm-3"
                  onChange={(val) => onStepConditionFieldHandler(index, val)}
                  value={selectedField || { label: "Select Field" }}
                  options={conditionOptions.field} />
                <Select
                  className="col-sm-2"
                  onChange={(val) => onStepConditionNotHandler(index, val)}
                  value={selectedNot || { label: "---", value: false }}
                  options={conditionOptions.not} />
                <Select
                  className="col-sm-3"
                  onChange={(val) => onStepConditionOperationHandler(index, val)}
                  value={(elem.field === "priority"
                    ? selectedOperationPriority
                    : selectedOperationPattern) || { label: "Select Operation" }}
                  options={elem.field === "priority"
                    ? conditionOptions.operation.priority
                    : conditionOptions.operation.pattern} />
                {
                  elem.field === "priority"
                    ? <Select
                      className="col-sm-3"
                      onChange={(val) => onStepConditionExpectedHandler(index, val)}
                      value={selectedExpected || { label: "Select priority" }}
                      options={conditionOptions.expected_value} />
                    : <input
                      value={elem.expected_value || ".*"}
                      onChange={value=> onStepConditionExpectedPatternHandler(index, value.target.value)}
                      type="text"
                      id="ruleInput"
                      className="form-control col mx-sm-2 mx-3"
                      placeholder="Enter rule" />
                }
                <div className="col-sm-1 d-flex justify-content-end cursor-pointer" 
                  onClick={() => onStepRemoveHandler(index)}>
                  <i className="far fa-trash-alt" />
                </div>
              </div>
            )
          })
        }
      </div>
      {typeValue !== "match-all" &&
          <div className="text-secondary cursor-pointer">
            <i className="fa fa-plus"/>
            <span className="text-primary-hover ms-1"
              onClick={onConditionAddHandler}>Add condition</span>
          </div>
      }
      <div className="form-floating my-2">
        <input type="text" id="descriptionInput" className="form-control" placeholder="Enter description" 
          value={descriptionChat} 
          onChange={e => setDescriptionChat(e.target.value)} />
        <label className="form-label" htmlFor="descriptionInput">
          <small className="text-secondary">Enter description</small>
        </label>
      </div>
      <div className="d-grid gap-2 mt-3">
        <button type="submit" className="btn btn-primary" onClick={onSubmitHandler}>Apply</button>
      </div>
    </div>
  );
};

export default FormOnCallIntegrationChatRule;
