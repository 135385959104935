import React, { FC } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Infrastructure } from "../../../store/features/infrastructure.slice";

type IProps = {
  workspace: string;
  items: Infrastructure[];
};

const InfrastructureList: FC<IProps> = ({ workspace, items }) => {

  if (!items) return null
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
          </tr>
        </thead>
        <tbody>
          {
            (Object.keys(items) || []).map((key, index) => {
              const item = items[index]
              return (
                <tr key={index}>
                  <td>
                    <Link to={`/workspace/${workspace}/-/infrastructures/${item.slug}`}>
                      {item.name}
                    </Link>
                  </td>
                  <td>{moment.unix(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default InfrastructureList;
