import { rest } from "msw";
import { db } from "../db";
import { WorkspaceCreateData, WorkspaceResponse, WorkspaceUpdateData } from "../../store/api/types/workspace";
import { faker } from "@faker-js/faker";

const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const getWorkspaceList = rest.get("/v1/workspace", async (req, res, ctx) => {
  const list = db.workspace.getAll()
  return res(
    ctx.json({ items: list }),
  );
});

export const getWorkspaceInfo = rest.get<WorkspaceResponse>("/v1/workspace/:slugOrID", async (req, res, ctx) => {
  const { slugOrID } = req.params
  const filed = regexExp.test(slugOrID as string) ? "id" : "slug"
  const workspace = db.workspace.findFirst({
    where: {
      [filed]: {
        equals: slugOrID
      },
    }
  })
  return res(
    ctx.json(workspace),
  );
});

export const postWorkspaceCreate = rest.post<WorkspaceCreateData>("/v1/workspace", async (req, res, ctx) => {
  const { name, slug, description } = await req.json()
  const workspace = db.workspace.create ({
    id: faker.datatype.uuid(),
    name: name,
    slug: slug,
    description: description,
    created_at: 1524379940,
    updated_at: 1524379940,
  })
  return res(
    ctx.json(workspace),
  );
});

export const putWorkspaceInfo = rest.put<WorkspaceUpdateData>("/v1/workspace/:slugOrID", async (req, res, ctx) => {
  const { name, description } = await req.json()
  const { slugOrID } = req.params
  const filed = regexExp.test(slugOrID as string) ? "id" : "slug"
  const workspace = db.workspace.update({
    where: {
      [filed]: {
        equals: slugOrID
      },
    },
    data: {
      // Nullable properties can be updated to null.
      name: name,
      description: description,
    },
  })
  return res(
    ctx.json(workspace),
  );
});
export const deleteWorkspaceInfo = rest.delete("/v1/workspace/:slugOrID", async (req, res, ctx) => {
  return res(
    ctx.json({}),
  );
});
