import React, { ChangeEvent, FC, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { object, string, TypeOf } from "zod";

const nameFormat = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9_\s]+/g, "")
}

const FormSchema = object({
  name: string()
    .min(3, "Name can not be empty")
    .transform(val => nameFormat(val).replace(/ +/g, " ")),
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  name: string
  onSubmit: () => void;
  onCancel: () => void;
}

const FormDelete: FC<IProps> = ({ onSubmit, onCancel, name }) => {
  const [state, setState] = useState("");
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = nameFormat(e.target.value)
    setState(e.target.value)
  }

  const cancelHandler = () => {
    onCancel && onCancel()
  }
  const submitHandler: SubmitHandler<FormData> = () => {
    onSubmit && onSubmit();
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>

          <div className="mb-4 alert alert-warning">
            <i className="fa-solid fa-triangle-exclamation"/>&nbsp;
            <span>This action deletes <code>{name}</code>. <strong>There is no going back.</strong></span>
          </div>

          <div className="mb-3">
            <label className="form-label" htmlFor="nameInput">Enter the following to
              confirm: <code>{name}</code></label>
            <input
              type="text" id="nameInput" className="form-control"
              {...register("name")}
              onChange={handleChangeName}/>
            {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
          </div>


          <div className="d-flex justify-content-between">
            <button type="button" className="btn btn-outline-secondary" onClick={cancelHandler}>Cancel</button>
            <button type="submit" className="btn btn-danger" disabled={state !== name}>Yes, delete</button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormDelete;
