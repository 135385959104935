import { Navigate, Outlet } from "react-router-dom";
import AuthMiddleware from "./AuthMiddleware";
import { useAppSelector } from "../../app/hooks";
import WorkspaceMiddleware from "./WorkspaceMiddleware";

const ProtectedRoute = () => {
  const session = useAppSelector((state) => state.sessionState);

  if (!session.isLogged) {
    return <Navigate to={"/signin"}/>;
  }

  return (
    <AuthMiddleware>
      <WorkspaceMiddleware>
        <Outlet/>
      </WorkspaceMiddleware>
    </AuthMiddleware>
  )
};

export default ProtectedRoute
