import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type User = {
  id: string;
  account: string;
  created_at?: number;
  updated_at?: number;
}

export interface NotificationPolicy {
  id: string
  workspace: string
  account: string
  rules: NotificationPolicyRules[]
  created_at: number
  updated_at: number
}

export interface NotificationPolicyStep {
  type: string
  delay: string
  enabled: boolean
}

export interface NotificationPolicyRules {
  name: string
  filter: NotificationPolicyFilter
  steps: NotificationPolicyStep[]
}

export interface NotificationPolicyConditions {
  field: string
  operation: { any?: string[] } | { equals?: string } | { matches?: string } | { contains?: string }
  not: boolean
}

export interface NotificationPolicyFilter {
  type: string
  conditions: NotificationPolicyConditions[]
}

export interface IOnCallUserState {
  error: string | null;
  loading: boolean;
  users: { [id: string]: User };
  notification_policies: { [id: string]: NotificationPolicy };
}

const initialState: IOnCallUserState = {
  error: null,
  loading: true,
  users: {},
  notification_policies: {},
};

export const onCallUserSlice = createSlice({
  initialState,
  name: "onCallUser",
  reducers: {
    setUserList: (state, action: PayloadAction<User[]>) => {
      state.users = {}
      for (let key in action.payload) {
        state.users[action.payload[key].id] = action.payload[key]
      }
      state.loading = false
    },
    attachUserList: (state, action: PayloadAction<User[]>) => {
      for (let key in action.payload) {
        state.users[action.payload[key].id] = action.payload[key]
      }
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.users[action.payload.id] = action.payload;
      state.loading = false
    },
    removeUser: (state, action: PayloadAction<string>) => {
      delete state.users[action.payload];
    },
    setNotificationPolicy: (state, action: PayloadAction<{ account: string, policy: NotificationPolicy }>) => {
      state.notification_policies[action.payload.account] = action.payload.policy;
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = onCallUserSlice;
// Extract and export each action creator by name
export const {
  setUserList,
  setUser,
  attachUserList,
  removeUser,
  setNotificationPolicy,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

