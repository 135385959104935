import { Breadcrumbs } from "../../components";
import React, { useEffect, useRef, useState } from "react";
import AlertsContainer from "../../components/oncall/OnCallAlertList/Alerts.container";
import { useSearchParams } from "react-router-dom"
import Loader from "../../components/oncall/OnCallIntegrationList/Loader";
import { useListTeamQuery } from "../../store/api/oncall.team.api";
import Suspense from "../../components/Suspense";
import { useAppSelector } from "../../app/hooks";
import moment from "moment/moment";
import { useLazyListAlertQuery } from "../../store/api/oncall.alert.api";

const OnCallAlertListPage = () => {
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current
  const teamListQuery = useListTeamQuery({ workspace: workspace ?? "" });
  const refStarted = useRef<number>(moment().unix())
  let [tabParams, setTabParams] = useSearchParams()
  const refUpdated = useRef<number>(moment().unix())
  const [updateQuery] = useLazyListAlertQuery()
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      updateQuery({
        workspace: workspace ?? "",
        filter: {
          cursor_limit: 50,
          cursor_start: refStarted.current,
          cursor: refUpdated.current,
        }
      }).then(() => {
        refUpdated.current = moment().unix();
      })
    }, 10000);

    return () => clearInterval(interval);
  }, [count, setCount, refUpdated, updateQuery, workspace, refStarted]);

  useEffect(() => {
    const handlerParams = () => {
      if (!tabParams.get("t")) {
        setTabParams("t=gen")
      }
    };
    handlerParams();
  }, [tabParams, setTabParams,])

  function tabHandler(param: string) {
    setTabParams("t=" + param)
  }

  return (
    <div>
      <Breadcrumbs component={"oncall"} entities={[{ name: "alerts", slug: "alerts" }]}/>
      <div className="mb-4 d-flex justify-content-between">
        <h2>Alert List</h2>
        <small className="text-secondary">
          Last Update: {moment.unix(refUpdated.current || 0).format("DD.MM.YYYY [at] hh:mm:ss")}
        </small>
      </div>
      <div>
        <nav className="sticky-top bg-white">
          <div className="nav nav-tabs d-flex justify-content-between flex-nowrap" id="nav-tab" role="tablist">
            <div className="d-flex">
              <button className={tabParams.get("t") === "gen" ? "active nav-link" : "nav-link"} id="general"
                data-bs-toggle="tab" data-bs-target="#nav-work"
                type="button" role="tab" aria-controls="nav-work" aria-selected="true"
                onClick={() => tabHandler("gen")}>General
              </button>
              <button className={tabParams.get("t") === "all" ? "active nav-link" : "nav-link"} id="all"
                data-bs-toggle="tab" data-bs-target="#nav-all"
                type="button" role="tab" aria-controls="nav-all" aria-selected="false"
                onClick={() => tabHandler("all")}>All
              </button>
            </div>
          </div>
        </nav>
        <Suspense query={teamListQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
          <div className="tab-content" id="nav-tabContent">
            {tabParams.get("t") === "gen" && (
              <div className={tabParams.get("t") === "gen" ? "tab-pane fade show active" : "fade tab-pane"}
                id="nav-work"
                role="tabpanel" aria-labelledby="general">
                <div className="mt-2">
                  <AlertsContainer status={"firing"} priority={"critical"}/>
                  <AlertsContainer status={"firing"}/>
                </div>
              </div>
            )}
            {tabParams.get("t") === "all" && (
              <div className="mt-2">
                <div className={tabParams.get("t") === "all" ? "tab-pane fade show active" : "fade tab-pane"}
                  id="nav-all"
                  role="tabpanel" aria-labelledby="all">
                  <AlertsContainer cursor_limit={100} component={"all"}/>
                </div>
              </div>
            )}
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default OnCallAlertListPage;
