import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "./store";
import moment from "moment";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useHistoryStack() {
  const defArr: string[] = []
  const [stack, setStack] = useState(defArr);
  const { pathname } = useLocation();
  const type = useNavigationType();

  useEffect(() => {
    if (type === "POP") {
      setStack(prev => prev.slice(0, prev.length - 1));
    } else if (type === "PUSH") {
      setStack(prev => [...prev, pathname]);
    } else {
      setStack(prev => [...prev.slice(0, prev.length - 1), pathname]);
    }
  }, [pathname, type]);

  return stack;
}


type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported")
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      return true
    } catch (error) {
      console.warn("Copy failed", error)
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy]
}

export function useStateCallback<S>(initialState: S) {
  const [state, setState] = useState<S>(initialState);
  const cbRef = useRef<((state: S) => void) | null>(null);

  function setStateCallback(state: S | ((state: S) => S), cb?: (state: S) => void) {
    if (cb) cbRef.current = cb
    setState(state)
  }

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [cbRef, state]);

  return [state, setStateCallback] as const;
}

export function useLocalStorage<T>(key: string) {
  // pull the initial value from local storage if it is already set
  const [state, setState] = useState<T | null>(() => {
    const exValue = localStorage.getItem(key)
    if (exValue) {
      return JSON.parse(exValue) as T
    }
    return null
  })

  // save the new value when it changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  // memoize a storage watcher callback back because everything in hooks should be memoized
  const storageWatcher = useCallback(
    (e: StorageEvent) => {
      if (e.newValue) {
        // update ours if we
        setState((currState) => {
          const newDat = JSON.parse(e.newValue || "null")
          return newDat === state ? newDat : currState
        })
      }
    },
    [state]
  )

  // install the watcher
  useEffect(() => {
    window.addEventListener("storage", storageWatcher)
    // stop listening on remove
    return () => {
      window.removeEventListener("storage", storageWatcher)
    }
  }, [state, storageWatcher])

  return { state, setState }
}

export function typeTimeStamp(time: number) {
  if (time) {
    let now = moment()
    const hours = now.diff(time, "hours")
    const minutes = now.diff(time, "minutes")
    const seconds = now.diff(time, "seconds")
    if (seconds < 60) return seconds + " sec ago"
    if (minutes < 60) return minutes + " min ago"
    if (hours < 4) return hours + " hours ago"
    if (hours < 24) return moment(time).format("MMM DD HH:mm");
    if (now.year() !== (new Date(time)).getFullYear()) return moment(time).format("YYYY MMM DD HH:mm");
    else return moment(time).format("MMM DD HH:mm");
  }
}

