import React, { FC, useState } from "react";
import Select, { OnChangeValue } from "react-select";
import { NotificationPolicyConditions } from "../../../store/features/oncall.user.slice";


export interface IProps {
  values: NotificationPolicyConditions;
  onChange: (data: {not: boolean, operation: string, value: string }) => void
}

const PatternSelect: FC<IProps> = ({ values, onChange }) => {
  const obj = {
    not: values.not || false,
    operation: Object.keys(values.operation)[0] || "",
    value: Object.values(values.operation)[0] || "",
  }
  const [stateValue, setStateValue] = useState(obj)

  const onStepConditionNotHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.not = val.value
      onChange(state)
      return state
    })
  }
  const onStepConditionOperationHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.operation = val.value
      onChange(state)
      return state
    })
  }
  const onStepConditionValueHandler = (val: string) => {
    setStateValue(state => {
      state.value = val
      onChange(state)
      return state
    })
  }

  const optionsNot = [{ value: false, label: "IS" }, { value:true, label: "NOT" }]
  const selectedOptionNot = optionsNot.find(item => {
    return item.value === stateValue.not
  })
  const optionsOperations = [{ value: "matches", label: "Matches" }]
  const selectedOptionOperation = optionsOperations.find((item, index )=> {
    return stateValue.operation === item.value
  })

  return (
    <div className="d-flex flex-wrap col-8 justify-content-between">
      <Select
        className="col-3"
        onChange={onStepConditionNotHandler}
        defaultValue={selectedOptionNot}
        options={optionsNot} />
      <Select
        className="col-3"
        onChange={onStepConditionOperationHandler}
        defaultValue={selectedOptionOperation}
        options={optionsOperations} />
      <div className="col-5">
        <input
          defaultValue={stateValue.value}
          onChange={value=> onStepConditionValueHandler(value.target.value)}
          type="text"
          id="ruleInput"
          className="form-control"
          placeholder="Enter rule" />
      </div>
    </div>
  );
};

export default PatternSelect;
