import React, { FC, useEffect, useState } from "react";
import Modal from "../Modal";
import Suspense from "../Suspense";
import { toast } from "react-toastify";
import {
  useListTeamScheduleRotationsQuery,
  useDeleteTeamScheduleRotationMutation,
} from "../../store/api/oncall.team.api";
import Loader from "./OnCallAlertList/Loader";
import { useAppSelector } from "../../app/hooks";
import { FormDelete, FormScheduleRotation } from "../forms";
import { TeamScheduleRotation } from "../../store/features/oncall.team.slice";
import FormScheduleRotationWeek from "../forms/FormScheduleRotationsWeek";

type IProps = {
  workspace: string
  team: string
  schedule: string
};

const OnCallTeamScheduleRotationList: FC<IProps> = ({ workspace, team, schedule }) => {
  const scheduleRotationListQuery = useListTeamScheduleRotationsQuery(
    { workspace: workspace, team: team, schedule: schedule }
  );
  const rotationsState = useAppSelector((state) => state.onCallTeamState)?.team_schedule_rotations[schedule] || []

  const [deleteTeamRotation, { isError, error, isSuccess }] = useDeleteTeamScheduleRotationMutation()
  const [scheduleCount, setScheduleCount] = useState<number>(7)
  const removeTeamRotationHandler = (data: TeamScheduleRotation) => {
    deleteTeamRotation({ workspace: workspace, team: team, schedule: schedule, rotation: data.id })
    Modal.closeModal("delete-team-rotation-modal")()
  }

  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("delete-team-rotation-modal")()
      toast.success("Delete schedule rotation successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  return (
    <div className="">
      <Suspense query={scheduleRotationListQuery} loader={<Loader/>} fallback={<div>Failed rotation loading</div>}>
        <div className="m-1 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h6 className="mb-0">
              Rotations
            </h6>
            <Modal
              id="rotation-team-schedule-modal" title="Rotation create"
              btnOpenClassName="btn btn-sm btn-outline-primary cursor-pointer mx-2"
              btnOpenName="rotation create" className="modal-dialog-centered integration-rules"
              hideFooter={true}>
              <FormScheduleRotation
                rotation={{} as TeamScheduleRotation}
                workspace={workspace}
                method={"create"}
                onSubmit={Modal.closeModal("rotation-team-schedule-modal")}
                onCancel={Modal.closeModal("rotation-team-schedule-modal")}
                team={team} schedule={schedule} />
            </Modal>
          </div>
          <div className="d-flex">
            <button
              className={`mx-1 btn btn-sm btn-light ${scheduleCount === 7 ? "active" : ""}`}
              onClick={() => setScheduleCount(7)}>Work Week</button>
            <button
              className={`btn btn-sm btn-light ${scheduleCount === 14 ? "active" : ""}`}
              onClick={() => setScheduleCount(14)}>2 Weeks</button>
          </div>
        </div>
        <hr className="m-0 p-0" />
        <div className="d-flex">
          <div className="rotation-header border-end">
            {
              Object.keys(rotationsState).map((key, index) => {
                const item = rotationsState[index]
                return (
                  <div key={index} className="d-flex align-items-center border-top">
                    <div className="rotation-list d-flex">
                      <div className="d-flex align-items-center text-truncate">
                        <span className="text-truncate">{item.name}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <Modal
                          id="delete-team-rotation-modal" title="Delete team"
                          btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0"
                          btnOpenName=" " className="modal-dialog-centered"
                          hideFooter={true}>
                          <FormDelete
                            name={item.name || "confirm"}
                            onSubmit={() => removeTeamRotationHandler(item)}
                            onCancel={Modal.closeModal("delete-team-rotation-modal")} />
                        </Modal>
                        <Modal
                          id="info-team-rotation-modal" title="Rotation info"
                          btnOpenClassName="fa fa-pen cursor-pointer bg-transparent border-0"
                          btnOpenName=" " className="modal-dialog-centered integration-rules"
                          hideFooter={true}>
                          <FormScheduleRotation
                            rotation={item}
                            method={"update"}
                            workspace={workspace}
                            onSubmit={Modal.closeModal("info-team-rotation-modal")}
                            onCancel={Modal.closeModal("info-team-rotation-modal")}
                            team={team} schedule={schedule} />
                        </Modal>
                      </div>
                    </div>
                  </div>
                )
              }
              )
            }
          </div>
          <div className="rotation-schedule">
            <FormScheduleRotationWeek rotations={rotationsState} count={scheduleCount} workspace={workspace} />
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default OnCallTeamScheduleRotationList;
