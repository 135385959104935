import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";

const SidebarContent = () => {
  const workspace = useAppSelector((state) => state.workspaceState.current);
  const location = useLocation();

  return (
    <>
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <NavLink to="/"
            aria-current="page"
            className={({ isActive }) => (isActive ? "active nav-link text-white" : "nav-link text-white")}>
            <div className="text-start text-nowrap d-flex">
              <div className="text-center"><i className="fa fa-home"/></div>
              <span className="ms-2">&nbsp;Home</span></div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/assistant caseSensitive"
            className="nav-link text-white disabled">
            <div className="text-start text-nowrap d-flex">
              <div className="text-center"><i className="fa fa-search"/></div>
              <span className="ms-2">&nbsp;Assistant</span></div>
          </NavLink>
        </li>
        <li className="dropdown dropdown-menu-end">
          <NavLink to={`/workspace/${workspace}/-/oncall/alerts/`}
            className={location.pathname.startsWith(`/workspace/${workspace}/-/oncall`)
              ? "active-link nav-link text-white"
              : "nav-link text-white"}>
            <div className="text-start d-flex text-nowrap"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <div className="text-center"><i className="fa fa-bell"/></div>
              <span className="ms-2">&nbsp;OnCall</span></div>
          </NavLink>
          <ul
            className={location.pathname.startsWith(`/workspace/${workspace}/-/oncall`)
              ? "nav drop-item flex-column ms-0"
              : "flex-column dropdown-menu dropdown-menu-dark p-0 m-0"}>
            <li className="nav-item">
              <NavLink to={`/workspace/${workspace}/-/oncall/alerts`}
                className={({ isActive }) => (isActive ? "active nav-link text-white" : "nav-link text-white")}>
                <span className="text-nowrap">Alerts</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/workspace/${workspace}/-/oncall/incidents`}
                className={({ isActive }) => (isActive ? "active nav-link text-white" : "nav-link text-white")}>
                <span className="text-nowrap">Incidents</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/workspace/${workspace}/-/oncall/integrations`}
                className={({ isActive }) => (isActive ? "active nav-link text-white" : "nav-link text-white")}>
                <span className="text-nowrap">Integrations</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/workspace/${workspace}/-/oncall/users`}
                className={({ isActive }) => (isActive ? "active nav-link text-white" : "nav-link text-white")}>
                <span className="text-nowrap">Users</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`/workspace/${workspace}/-/oncall/teams`}
                className={({ isActive }) => (isActive ? "active nav-link text-white" : "nav-link text-white")}>
                <span className="text-nowrap">Teams</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <Link to={`/workspace/${workspace}/-/infrastructures`}
            className="nav-link text-white">
            <div className="text-start d-flex text-nowrap">
              <div className="text-center">
                <i className="fa fa-cloud"/>
              </div>
              <span className="ms-2">&nbsp;Infrastructure</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to={`/workspace/${workspace}/-/integrations`}
            className="nav-link text-white">
            <div className="text-start d-flex text-nowrap">
              <div className="text-center">
                <i className="fa-solid fa-layer-group"/>
              </div>
              <span className="ms-2"> &nbsp;Integrations</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to={`/workspace/${workspace}/-/settings`}
            className="nav-link text-white">
            <div className="text-start d-flex text-nowrap">
              <div className="text-center">
                <i className="fa fa-duotone fa-grip"/>
              </div>
              <span className="ms-2"> &nbsp;Settings</span>
            </div>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default SidebarContent;
