import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect } from "react";
import { Infrastructure } from "../../store/features/infrastructure.slice";

const FormSchema = object({
  name: string()
    .min(1, "Name can not be empty"),
  description: string()
    .max(1024, "Description is not valid")
    .optional(),
});

export type FormData = TypeOf<typeof FormSchema>;

interface Data {
  name: string
  description?: string
}

export interface IProps {
  infrastructure: Infrastructure
  onSubmit?: (data: Data) => void
}

const FormInfrastructureInfo: FC<IProps> = ({ infrastructure, onSubmit }) => {

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("name", infrastructure?.name || "")
    setValue("description", infrastructure?.description || "")
  }, [infrastructure, setValue])

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    onSubmit && onSubmit(values)
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="mb-2">
            <label className="form-label" htmlFor="nameInput">Name</label>
            <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}/>
            {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="descriptionInput">Description</label>
            <textarea id="descriptionInput" className="form-control" placeholder="Enter description" rows={4}
              {...register("description")}/>
            {errors.description?.message && <small className="text-danger">{errors.description?.message}</small>}
          </div>

          <div className="d-flex">
            <button type="submit" className="btn btn-primary">Apply</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormInfrastructureInfo;
