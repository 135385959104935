import { FC } from "react";
import { paraisoLight, CopyBlock } from "react-code-blocks";

type IProps = {
  token: string
};

const AlertManager: FC<IProps> = ({ token }) => {

  function getUrl() {
    return `https://app.lstbknd.net/api/v1/oncall/alertmanager/${token}`
  }

  const yamlBlock1 = `routes:
 - receiver: lastbackend
continue: true
`

  const yamlBlock2 = `- name: 'lastbackend'
webhook_configs:
 - url: https://app.lstbknd.net/api/v1/oncall/alertmanager/${token}`

  return (
    <div>
      <p>This is the unique webhook URL for the integration:</p>

      <CopyBlock
        text={getUrl()}
        language={"text"}
        showLineNumbers={false}
        startingLineNumber={1}
        theme={paraisoLight}
        codeBlock
      />

      <p>How to start sending alerts to Grafana OnCall from AlertManager</p>
      <p>1. Add the new receiver to the AlertManager configuration file, for example:</p>

      <CopyBlock
        text={yamlBlock1}
        language={"yaml"}
        showLineNumbers={false}
        startingLineNumber={1}
        theme={paraisoLight}
        codeBlock
      />
      <p>and</p>
      <CopyBlock
        text={yamlBlock2}
        language={"yaml"}
        showLineNumbers={false}
        startingLineNumber={1}
        theme={paraisoLight}
        codeBlock
      />
    </div>
  );
};

export default AlertManager;
