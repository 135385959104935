import { FC } from "react";
import { useAppSelector } from "../../../app/hooks";
import AlertManager from "./AlertManager";

type IProps = {
  integration: string
};

const OnCallAlertInfo: FC<IProps> = ({ integration }) => {
  const integrationState = useAppSelector((state) => state.onCallIntegrationState);

  const data = integrationState.integrations[integration]

  if (!data) return <h1>Can not load data</h1>

  if (data.type === "alertmanager") return <AlertManager token={data.hash}/>

  return <div/>
};

export default OnCallAlertInfo;
