import React, { FC } from "react";
import { TeamScheduleRotation, TeamScheduleRotationPeriod } from "../../store/features/oncall.team.slice";
import moment from "moment";
import { DutyScheduleItem, makeDutySchedules, Rotation } from "../../utils/schedule";
import { useAppSelector } from "../../app/hooks";
import { Tooltip } from "../index";

type IProps = {
  rotations: TeamScheduleRotation[]
  count: number
  workspace: string
};

const FormScheduleRotationWeek: FC<IProps> = ({ rotations, count, workspace  }) => {
  const userState = useAppSelector((state) => state.workspaceState)?.memberships[workspace];

  const backgrounds = ["bg-danger", "bg-primary", "bg-secondary", "bg-success", "bg-warning"];

  const start  = moment().weekday(1).hour(0).minute(0).second(0)
  const end  = moment().weekday(1).hour(0).minute(0).second(0).add(count, "day")
  const total = end.unix() - start.unix()
  const days = []
  for (let i = 0; i < count; i++) {
    let day = {
      start: start.unix() + i * 86400,
      end: start.unix() + (i + 1) * 86400,
      width: (86400/total) * 100,
      offsetLeft: (((start.unix() + i * 86400) - start.unix()) / total) * 100
    }
    days.push(day)
  }
  const mbsMaps: Record<string, string> = {};
  const periods: Record<string,TeamScheduleRotationPeriod[]> = {};
  const userBackgrounds: Record<string, string> = {}
  userState.forEach((item, index) => userBackgrounds[item.username] = backgrounds[index])

  userState.forEach(item => mbsMaps[item.account] = item.username)
  rotations.forEach(rotation => {
    let res: TeamScheduleRotationPeriod[] = makeDutySchedules(start.unix(), end.unix(), rotation as Rotation)
      .reduce<DutyScheduleItem[]>((prev, current) => {
        if (prev.length > 0 && prev[prev.length - 1].user === current.user) {
          prev[prev.length - 1].end = current.end
          return prev;
        }
        prev.push(current)
        return prev;
      }, [])
      .map(item => {
        const st = (item.start < start.unix()) ? start.unix() : item.start
        const width = ((item.end - st)/total) * 100;
        const offsetLeft = ((st - start.unix()) / total) * 100
        return {
          title: mbsMaps[item.user],
          start: st,
          end: item.end,
          width: width,
          offsetLeft: offsetLeft,
        } as TeamScheduleRotationPeriod
      });
    if (res) {
      periods[rotation.id] = res
    }
  });

  return (
    <div className="h-100 position-relative">
      <ul className="p-0 m-0 d-flex w-100 h-100">
        {
          days.map((item, index) => {
            return (
              <li className="list-unstyled border-end" key={"rotation" + index} style={
                {
                  width: `${item.width}%`,
                  left: `${item.offsetLeft}%`,
                }
              }>
                <div className="border-bottom rotation-item d-flex justify-content-center align-items-center">
                  <small>
                    {moment.unix(item.start).format("DD/MM dd")}
                  </small>
                </div>
              </li>
            )
          })
        }
      </ul>
      <ul className="p-0 m-0 d-flex w-100 h-100 rotations-layers d-flex flex-nowrap">
        {
          rotations.map((rotation, index) => {
            return (
              <li
                className="list-unstyled border-end rotations-layer border-bottom w-100 overflow-hidden"
                key={"rotation-list" + index}
                style={{
                  top: `${index * 31}px`
                }}>
                <ul className="w-100" style={{ position: "absolute" }}>
                  {
                    periods[rotation.id].map((period, index) => {
                      return (
                        <li className="list-unstyled" key={"period-" + index} style={
                          {
                            top: "3px",
                            position: "absolute",
                            width: `${period.width}%`,
                            left: `${period.offsetLeft}%`,
                            textAlign: "center"
                          }
                        }>
                          <Tooltip
                            placement="top"
                            text={`
                            Username: ${period.title}
                            start: ${moment.unix(period.start).format("LLL")}
                            end: ${moment.unix(period.end).format("LLL")}
                            `}
                            children={
                              <div className={`
                            bg-gradient bg-primary period-item rounded text-truncate text-white 
                            ${userBackgrounds[period.title]}
                            `}>
                                <span>{period.title}</span>
                              </div>
                            }/>
                        </li>
                      )
                    })
                  }
                </ul>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
};

export default FormScheduleRotationWeek;
