import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import IncidentsList from "./Incidents.view"
import Loader from "./Loader";
import { useListIncidentQuery } from "../../../store/api/oncall.incident.api";
import { useAppSelector } from "../../../app/hooks";
import Suspense from "../../Suspense";

// props from connect mapStateToProps
interface StateProps {
  loading: boolean;
  workspace: string;
}

type Props = StateProps;

const IncidentsContainer = (props: Props) => {
  const query = useListIncidentQuery({ workspace: props.workspace });
  const incidentState = useAppSelector((state) => state.onCallIncidentState);

  return (
    <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <IncidentsList {...props} items={incidentState.incidents} />
    </Suspense>
  );
};

const mapStateToProps = function (state: RootState): StateProps {
  return {
    loading: state.workspaceState.loading,
    workspace: state.workspaceState?.current || "",
  }
}

export default connect(mapStateToProps)(IncidentsContainer);
