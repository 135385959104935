import { primaryKey } from "@mswjs/data"
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { Team } from "../../store/features/oncall.team.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export interface TeamScheduleMock {
  id: string
  name: string
  team: string
  description: string
  created_at: number
  updated_at: number
}

export interface TeamScheduleRotationRestriction {
  start_day?: string
  start_hour: number
  start_minute: number
  end_day?: string
  end_hour: number
  end_minute: number
}
export interface TeamScheduleRotationParticipants {
  kind: string
  id: string
}

export interface TeamScheduleRotation {
  id: string
  name: string
  schedule: string
  start_date: number
  end_date: number
  type: string
  participants: TeamScheduleRotationParticipants[]
  time_restriction: {
    type: string
    restrictions: TeamScheduleRotationRestriction[]
  }
  created_at: number
  updated_at: number
}

export interface TeamScheduleRotationMock {
  name: string
  start_date: number
  end_date: number
  type: string
  participants: TeamScheduleRotationParticipants[]
  time_restriction: {
    type: string
    restrictions: TeamScheduleRotationRestriction[]
  }
}

export interface TeamScheduleOverrideMock {
  id: string
  schedule: string
  user: string
  start_date: number
  end_date: number
  rotations: string[]
  created_at: number
  updated_at: number
}

export const TeamModel = defineModel<Team>(() => ({
  id: primaryKey(String),
  workspace: String,
  slug: String,
  name: String,
  description: String,
  created_at: Number,
  updated_at: Number
}))

export const TeamSchedule = defineModel<TeamScheduleMock>(() => ({
  id: primaryKey(String),
  name: String,
  team: String,
  description: String,
  created_at: Number,
  updated_at: Number
}))

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamScheduleRotation = defineModel<TeamScheduleRotation>
(() => ({
  id: primaryKey(String),
  name: String,
  schedule: String,
  start_date: Number,
  end_date: Number,
  type: String,
  participants: Array,
  time_restriction: {
    type: String,
    restrictions: Array
  },
  created_at: Number,
  updated_at: Number,
}))

export const TeamScheduleOverride = defineModel<TeamScheduleOverrideMock>
(() => ({
  id: primaryKey(String),
  schedule: String,
  user: String,
  start_date: Number,
  end_date: Number,
  rotations: Array,
  created_at: Number,
  updated_at: Number,
}))