const WaitListPage = () => {
  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong rounded-sm">
              <div className="card-body p-5 text-center">
                <div>
                  <h3><strong>Welcome to Last.Backend</strong></h3>
                </div>
                <div className="p-5">
                  <img
                    className="align-top spinner-border"
                    height="100"
                    src="/images/logo-small.svg"
                    alt="Last.Backend"/>
                </div>
                <div>WAIT LIST</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WaitListPage;
