import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import TeamsList from "./Teams.view";
import { useListTeamQuery } from "../../../store/api/oncall.team.api";
import Suspense from "../../Suspense";
import Loader from "../OnCallIncidentList/Loader";
import { useAppSelector } from "../../../app/hooks";

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
}

type Props = StateProps;

const TeamsContainer = (props: Props) => {
  const query = useListTeamQuery({ workspace: props.workspace });
  const teamState = useAppSelector((state) => state.onCallTeamState);

  return (
    <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <TeamsList {...props} items={teamState.teams} />
    </Suspense>
  );
};

const mapStateToProps = function (state: RootState): StateProps {
  const workspace = state.workspaceState?.current || "";
  return { workspace }
}
export default connect(mapStateToProps)(TeamsContainer);
