import { FC } from "react";
import { useGetAuthorizeURLSlackQuery } from "../../store/api/integration.api";

interface IProps {
  workspace: string;
}

const FormUserSlackConnect: FC<IProps> = ({ workspace }) => {
  const { data } = useGetAuthorizeURLSlackQuery({ workspace: workspace })
  return (
    <div className="text-center">
      <div className="mb-2"><i className="fa-brands fa-slack fa-4x"/></div>
      <div>
        <p className="py-1">Personal Slack connection will allow you to manage alert groups in your connected team's
          Internal Slack workspace.</p>
        <p className="py-1">To setup personal Slack click the button below, choose workspace and click Allow.</p>
      </div>
      <a href={data?.url} target="_blank" className="text-primary text-decoration-none" rel="noopener noreferrer">
        <i className="fa-solid fa-arrow-up-right-from-square"/>&nbsp;Open Slack connection page
      </a>
    </div>
  );
};

export default FormUserSlackConnect;
