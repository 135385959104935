import { FC, useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useController, useForm } from "react-hook-form";
import { useCreateIntegrationChatMutation } from "../../store/api/oncall.integration.api";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useAppSelector } from "../../app/hooks";
import { useLazySearchChatListQuery } from "../../store/api/integration.api";

interface SelectOption {
  label: string;
  value: string;
}

const FormSchema = object({
  name: string()
    .min(1, "Name is required"),
  service: string()
    .min(1, "Service is required"),
  chat: string()
    .min(1, "Chat is required"),
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string;
  integration: string;
  onSubmit?: () => void;
}

const FormOnCallIntegrationAddChat: FC<IProps> = ({ workspace, onSubmit, integration }) => {
  const integrationState = useAppSelector((state) => state.integrationState);
  const [createChat, { data, isError, error, isSuccess }] = useCreateIntegrationChatMutation();

  const [searchQuery, result] = useLazySearchChatListQuery()

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    resetField,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const {
    field: {
      value: integrationValue,
      onChange: integrationOnChange,
      ...restLangIntegrationField
    }
  } = useController({ name: "service", control });

  const { field: { value: chatValue, onChange: chatOnChange, ...restLangChatField } } = useController({
    name: "chat",
    control
  });

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      refSuccess.current = true;
      onSubmit && onSubmit()
      toast.success("Add chat successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess, data, onSubmit]);

  const providerOptions = Object.keys(integrationState.workspaceIntegrations).map((key) => {
    const item = integrationState.workspaceIntegrations[key]
    return { value: item.id, label: key }
  });

  const changeServiceHandler = (option: any) => {
    resetField("chat");
    integrationOnChange((option as SelectOption).value)
  }

  const changeChatHandler = (option: any) => {
    chatOnChange((option as SelectOption).value)
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    createChat({ workspace: workspace, data: values, integration: integration })
  };

  const chatPromiseOptions = (inputValue: string) => {
    const provider = Object.keys(integrationState.workspaceIntegrations)
      .find(key => integrationState.workspaceIntegrations[key].id === getValues("service"))

    return new Promise<SelectOption[]>((resolve) => {
      if (!getValues("service")) {
        return resolve([])
      }
      searchQuery({
        workspace: workspace,
        provider: provider || "",
        service: getValues("service"),
        filter: { search: inputValue || "", limit: 25 }
      })
        .then((v) => {
          if (v?.error) return resolve([])
          resolve((v?.data || []).map(item => ({ value: item.id, label: item.name })));
        })
    });
  };

  const chatOptions = (result?.data || []).map(item => ({ value: item.id, label: item.name }));

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="form-floating mb-4">
            <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}/>
            <label className="form-label" htmlFor="nameInput">
              {errors.name?.message
                ? <small className="text-danger">{errors.name?.message}</small>
                : <small className="text-secondary">Enter name</small>
              }
            </label>
          </div>

          <div className="form-floating mb-4">
            <Select
              className="select-input"
              placeholder="Choose service"
              options={providerOptions}
              value={integrationValue ? providerOptions.find(x => x.value === integrationValue) : integrationValue}
              onChange={changeServiceHandler}
              {...restLangIntegrationField}
            />
            {errors.service && <small className="text-danger">{errors.service?.message}</small>}
          </div>

          <div className="form-floating mb-4">
            <AsyncSelect
              key={"chat_" + (getValues("service") || "")}
              className="select-input"
              placeholder="Choose chat"
              defaultOptions
              loadOptions={chatPromiseOptions}
              value={chatValue ? chatOptions.find(x => x.value === chatValue) : chatValue}
              onChange={changeChatHandler}
              {...restLangChatField}
            />
            {errors.chat && <small className="text-danger">{errors.chat?.message}</small>}
          </div>

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">Add</button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormOnCallIntegrationAddChat;
