import React, { FC, useEffect } from "react";
import Modal from "../Modal";
import Suspense from "../Suspense";
import Loader from "./OnCallAlertList/Loader";
import {
  FormDelete,
  FormOnCallTeamScheduleCreate,
  FormOnCallTeamScheduleInfo
} from "../forms";
import { toast } from "react-toastify";
import { OnCallTeamScheduleRotationList, OnCallTeamScheduleOverrideList } from "./index";
import {
  useDeleteTeamScheduleMutation,
  useListTeamScheduleQuery
} from "../../store/api/oncall.team.api";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";
import { TeamSchedule } from "../../store/features/oncall.team.slice";

type IProps = {
  workspace: string
  team: string
};

const OnCallTeamScheduleList: FC<IProps> = ({ workspace, team }) => {
  const scheduleListQuery = useListTeamScheduleQuery({ workspace: workspace, team: team });
  const [deleteTeamSchedule, { isError, error, isSuccess }] = useDeleteTeamScheduleMutation()
  const teamId = useAppSelector((state) => state.onCallTeamState)?.matchTeamSlugs[team ?? ""];
  const teamState = useAppSelector((state) => state.onCallTeamState)?.team_schedules[teamId]

  const removeTeamScheduleHandler = (data: TeamSchedule) => {
    deleteTeamSchedule({ workspace: workspace, team: team, schedule: data.id })
    Modal.closeModal("delete-team-schedule-modal")()
  }

  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("delete-team-schedule-modal")()
      toast.success("Delete team schedule successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  return (
    <div className="mb-2">
      <div className="d-flex mb-2">
        <div className="flex-grow-1">
          <h5>Schedules</h5>
        </div>
        <div>
          <Modal
            id="create-team-schedule-modal" title="Create schedule"
            btnOpenClassName="btn btn-sm btn-outline-primary mx-2"
            btnOpenName="Create schedule" className="modal-dialog-centered"
            hideFooter={true}>
            <FormOnCallTeamScheduleCreate
              workspace={workspace} team={team ?? ""} onSubmit={Modal.closeModal("create-team-schedule-modal")}/>
          </Modal>
        </div>
      </div>
      <Suspense query={scheduleListQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
        <div className="accordion accordion-flush border" id="accordionFlushExample">
          {
            teamState &&
                Object.keys(teamState).map((key, index) => {
                  const item = teamState[index]
                  return (
                    <div key={index} className="accordion-item">
                      <div className="accordion-header cursor-pointer d-flex flex-nowrap align-items-center">
                        <div className="accordion-button bg-transparent collapsed shadow-none" data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          <div className="d-flex justify-content-between">
                            <div>
                              {item.name}
                            </div>
                            <div className="mx-2">{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Modal
                            id="delete-team-schedule-modal" title="Delete schedule"
                            btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0 mx-2"
                            btnOpenName=" " className="modal-dialog-centered"
                            hideFooter={true}>
                            <FormDelete
                              name={item.name || "confirm"}
                              onSubmit={() => removeTeamScheduleHandler(item)}
                              onCancel={Modal.closeModal("delete-team-schedule-modal")}/>
                          </Modal>
                          <Modal
                            id="info-team-schedule-modal" title="Schedule info"
                            btnOpenClassName="fas fa-ellipsis cursor-pointer bg-transparent border-0 mx-2"
                            btnOpenName=" " className="modal-dialog-centered"
                            hideFooter={true}>
                            <FormOnCallTeamScheduleInfo
                              onSubmit={Modal.closeModal("info-team-schedule-modal")}
                              workspace={workspace} team={team} schedule={item.id} />
                          </Modal>
                        </div>
                      </div>
                      <div
                        id="flush-collapseOne" className="accordion-collapse collapse show"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body p-1 pb-2">
                          <hr className="m-0"/>
                          <OnCallTeamScheduleRotationList workspace={workspace} team={team} schedule={item.id} />
                          <hr className="m-0"/>
                          <OnCallTeamScheduleOverrideList workspace={workspace} team={team} schedule={item.id} />
                        </div>
                      </div>
                    </div>
                  )
                })
          }
        </div>
      </Suspense>
    </div>
  );
};

export default OnCallTeamScheduleList;
