import { primaryKey } from "@mswjs/data"
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { Alert, AlertEvent } from "../../store/features/oncall.alert.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}
interface payloadAlerts {
  "alerts": [
    {
      "annotations": {
        "description": string
        "runbook_url": string
        "summary": string
      },
      "endsAt": string
      "fingerprint": string
      "generatorURL": string
      "labels": {
        "alertname": string
        "condition": string
        "container": string
        "endpoint": string
        "instance": string
        "job": string
        "job_name": string
        "namespace": string
        "pod": string
        "prometheus": string
        "service": string
        "severity": string
      },
      "startsAt": string
      "status": string
    }
  ],
  "commonAnnotations": {
    "description": string
    "runbook_url": string
    "summary": string
  },
  "commonLabels": {
    "alertname": string
    "condition": string
    "container": string
    "endpoint": string
    "instance": string
    "job": string
    "job_name": string
    "namespace": string
    "pod": string
    "prometheus": string
    "service": string
    "severity": string
  },
  "externalURL": string
  "groupKey": string
  "groupLabels": {
    "alertname": string
    "service": string
  },
  "receiver": string
  "status": string
  "truncatedAlerts": number
  "version": string
}
interface MockAlert extends Alert {
  payload: payloadAlerts
}
export const AlertModel = defineModel<MockAlert>(() =>
  ({
    id: primaryKey(String),
    number: Number,
    description: String,
    title: String,
    workspace: String,
    account: String,
    integration: String,
    priority: String,
    count: Number,
    status: String,
    service: String,
    responder:
      {
        id: String,
        name: String,
      },
    payload: {
      alerts: Array
    },
    source: String,
    started_at: Number,
    created_at: Number,
    updated_at: Number,
    closed_at: Number,
  }))

export const AlertEventsModel = defineModel<AlertEvent>(() => ({
  id: primaryKey(String),
  type: String,
  alert_id: String,
  data: String,
  created_at: Number,
}))