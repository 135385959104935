import { FC } from "react";
import { Infrastructure } from "../../../store/features/infrastructure.slice";
import FormInfrastructureInfo from "../../forms/FormInfrastructureInfo";

type IProps = {
  infrastructure: Infrastructure
  onSubmit?: (data: Data) => void
};
interface Data {
  name: string
  description?: string
}

const OnCallIncidentInfo: FC<IProps> = ({ infrastructure , onSubmit }) => {
  const onChangeHandler = (data: Data) => {
    onSubmit && onSubmit(data)
  }

  return (
    <div>
      <FormInfrastructureInfo infrastructure={infrastructure} onSubmit={(data) => onChangeHandler(data)} />
    </div>
  );
};

export default OnCallIncidentInfo;
