import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  AccountIntegrationConnectEmailRequest,
  AccountIntegrationConnectPhoneRequest,
  AccountIntegrationConnectTelegramRequest,
  AccountIntegrationListRequest,
  AccountIntegrationListResponse,
  AccountIntegrationRemoveEmailRequest,
  AccountIntegrationRemovePhoneRequest,
  AccountIntegrationResponse,
  AccountIntegrationVerifyEmailRequest,
  AccountIntegrationVerifyPhoneRequest,
  ChatResponse,
  ConnectRequest,
  ConnectResponse,
  TelegramInfoResponse,
  WorkspaceIntegrationListRequest,
  WorkspaceIntegrationListResponse,
  AccountIntegrationConnectSlackRequest,
  FetchTelegramBotInfoRequest,
  SearchChatRequest,
  TelegramConnectResponse,
  DeviceResponse,
} from "./types/integration";
import {
  setAccountIntegration,
  setAccountIntegrationList,
  setWorkspaceIntegrationList,
  setDeviceList,
  removeDevice,
} from "../features/integration.slice";

export const integrationApi = createApi({
  reducerPath: "integrationApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    listForWorkspace: builder.query<WorkspaceIntegrationListResponse, WorkspaceIntegrationListRequest>({
      query(req: WorkspaceIntegrationListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration`,
          credentials: "include",
        };
      },
      transformResponse: (response: { value: WorkspaceIntegrationListResponse }) => {
        if (!response?.value) return {};
        Object.keys(response.value).forEach(key => {
          response.value[key].connected_at *= 1000
        })
        return response.value
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspaceIntegrationList(data));
        } catch (error) {
        }
      },
    }),
    listForAccount: builder.query<AccountIntegrationListResponse, AccountIntegrationListRequest>({
      query(req: AccountIntegrationListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/account/${req.account}/integration`,
          credentials: "include",
        };
      },
      transformResponse: (response: { value: AccountIntegrationListResponse }) => {
        if (!response?.value) return {};
        Object.keys(response.value).forEach(key => {
          response.value[key].connected_at *= 1000
        })
        return response.value
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountIntegrationList(data));
        } catch (error) {
        }
      },
    }),
    connectEmail: builder.mutation<AccountIntegrationResponse, AccountIntegrationConnectEmailRequest>({
      query(req: AccountIntegrationConnectEmailRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/email`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountIntegration({ type: "email", data: data }));
        } catch (error) {
        }
      },
    }),
    verifyEmail: builder.query<AccountIntegrationResponse, AccountIntegrationVerifyEmailRequest>({
      query(req: AccountIntegrationVerifyEmailRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/email/${req.integration}/${req.code}`,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountIntegration({ type: "email", data: data }));
        } catch (error) {
        }
      },
    }),
    removeEmail: builder.mutation<AccountIntegrationListResponse, AccountIntegrationRemoveEmailRequest>({
      query(req: AccountIntegrationVerifyEmailRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/phone/${req.integration}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    connectPhone: builder.mutation<AccountIntegrationResponse, AccountIntegrationConnectPhoneRequest>({
      query(req: AccountIntegrationConnectPhoneRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/phone`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountIntegration({ type: "phone", data: data }));
        } catch (error) {
        }
      },
    }),
    verifyPhone: builder.query<AccountIntegrationResponse, AccountIntegrationVerifyPhoneRequest>({
      query(req: AccountIntegrationVerifyPhoneRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/phone/${req.integration}/${req.code}`,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccountIntegration({ type: "phone", data: data }));
        } catch (error) {
        }
      },
    }),
    removePhone: builder.mutation<AccountIntegrationListResponse, AccountIntegrationRemovePhoneRequest>({
      query(req: AccountIntegrationVerifyPhoneRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/phone/${req.integration}`,
          method: "DELETE",
          credentials: "include",
        };
      },
    }),
    connectTelegram: builder.mutation<TelegramConnectResponse, AccountIntegrationConnectTelegramRequest>({
      query(req: AccountIntegrationConnectTelegramRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/telegram`,
          method: "POST",
          credentials: "include",
        };
      },
    }),
    connectChatTelegram: builder.mutation<TelegramConnectResponse, AccountIntegrationConnectTelegramRequest>({
      query(req: AccountIntegrationConnectTelegramRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/telegram/chat`,
          method: "POST",
          credentials: "include",
        };
      },
    }),
    infoTelegramBot: builder.query<TelegramInfoResponse, FetchTelegramBotInfoRequest>({
      query(req: FetchTelegramBotInfoRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/telegram/authorize`,
          credentials: "include",
        };
      },
    }),
    getAuthorizeURLSlack: builder.query<TelegramConnectResponse, AccountIntegrationConnectSlackRequest>({
      query(req: AccountIntegrationConnectSlackRequest) {
        return {
          url: `v1/workspace/${req.workspace}/integration/slack/authorize`,
          credentials: "include",
        };
      },
    }),
    connect: builder.mutation<ConnectResponse, ConnectRequest>({
      query(req: ConnectRequest) {
        return {
          url: `/v1/workspace/${req.workspace}/integration/${req.service}/authorize/${req.code}`,
          method: "POST",
          credentials: "include",
        };
      },
    }),
    searchChatList: builder.query<ChatResponse[], SearchChatRequest>({
      query(req: SearchChatRequest) {
        if (req.provider === "telegram") {
          return {
            url: `/v1/workspace/${req.workspace}/integration/${req.provider}/${req.service}/chat`,
            credentials: "include",
          };
        }
        return {
          url: `/v1/workspace/${req.workspace}/integration/${req.provider}/${req.service}/chat_search`,
          params: req.filter,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: ChatResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
    }),
    listDevice: builder.query<DeviceResponse[], void>({
      query() {
        return {
          url: "v1/integration/device",
          credentials: "include",
        };
      },
      transformResponse: (response: { items: DeviceResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map((item: DeviceResponse) => {
          item.created_at *= 1000;
          item.updated_at *= 1000;
          return item
        })
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDeviceList(data));
        } catch (error) {
        }
      },
    }),
    deleteDevice: builder.mutation<void, string>({
      query(req: string) {
        return {
          url: `v1/integration/device/${req}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeDevice(args));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useConnectMutation,
  useListForWorkspaceQuery,
  useListForAccountQuery,
  useLazyListForAccountQuery,
  useConnectEmailMutation,
  useVerifyEmailQuery,
  useLazyVerifyEmailQuery,
  useRemoveEmailMutation,
  useConnectPhoneMutation,
  useVerifyPhoneQuery,
  useLazyVerifyPhoneQuery,
  useRemovePhoneMutation,
  useConnectTelegramMutation,
  useConnectChatTelegramMutation,
  useInfoTelegramBotQuery,
  useGetAuthorizeURLSlackQuery,
  useLazySearchChatListQuery,
  useListDeviceQuery,
  useDeleteDeviceMutation,
} = integrationApi;

