import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { logout } from "../features/session.slice";
import type { RootState } from "../../app/store";

const baseUrl = process.env.REACT_APP_ENV !== "mocks"
  ? window._env_?.API_HOST
  : window.location.origin;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const session = (getState() as RootState).sessionState
    if (session) {
      headers.set("Authorization", `Bearer ${session?.token}`)
      return headers
    }
  },
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if ((result.error?.data as any)?.code === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      api.dispatch(logout());
      window.location.href = "/signin";
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
