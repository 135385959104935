import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateWorkspaceMutation } from "../../store/api/workspace.api";
import { useAppSelector } from "../../app/hooks";

const FormSchema = object({
  name: string()
    .min(1, "Name is required"),
  slug: string()
    .min(1, "Slug is required"),
  description: string()
    .max(1024, "Description must be less than 1024 characters")
    .optional(),
});

export type FormData = TypeOf<typeof FormSchema>;

const WorkspaceCreatePage = () => {
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const workspaceState = useAppSelector((state) => state.workspaceState);
  const isFirst = workspaceState.workspaces && !Object.keys(workspaceState.workspaces).length

  const [createWorkspace, { isError, error, isSuccess }] = useCreateWorkspaceMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully create", {
        position: "bottom-right",
      });
      navigate(from);
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [navigate, error, isError, isSuccess, from]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    createWorkspace(values);
  };

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong rounded-sm">
              <div className="card-body p-5">

                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <h3 className="mb-5 text-center">Create Workspace</h3>

                    <div className="form-floating mb-4">
                      <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
                        {...register("name")}/>
                      <label className="form-label" htmlFor="nameInput">
                        {errors.name?.message
                          ? <small className="text-danger">{errors.name?.message}</small>
                          : <small className="text-secondary">Enter name</small>
                        }
                      </label>
                    </div>

                    <div className="form-floating mb-4">
                      <input type="text" id="slugInput" className="form-control" placeholder="Enter slug"
                        {...register("slug")}/>
                      <label className="form-label" htmlFor="slugInput">
                        {errors.slug?.message
                          ? <small className="text-danger">{errors.slug?.message}</small>
                          : <small className="text-secondary">Enter slug</small>
                        }
                      </label>
                    </div>

                    <div className="form-floating mb-4">
                      <textarea id="descriptionInput" className="form-control" placeholder="Enter description" rows={4}
                        {...register("description")}/>
                      <label className="form-label" htmlFor="descriptionInput">
                        {errors.description?.message
                          ? <small className="text-danger">{errors.description?.message}</small>
                          : <small className="text-secondary">Enter description</small>
                        }
                      </label>
                    </div>

                    <div className="d-grid gap-2">
                      <button type="submit" className="btn btn-primary">Create</button>
                    </div>

                    <hr className="my-4"/>
                  </form>
                </FormProvider>

                {
                  (!isFirst) && (
                    <div className="d-flex justify-content-center mt-2">
                      <Link to="/" className="text-decoration-none">Go to Home</Link>
                    </div>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkspaceCreatePage;
