import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InfrastructureResponder = {
  kind: string,
  id: string,
}

export interface Infrastructure {
  id: string
  workspace: string
  slug: string
  name: string
  responder?: InfrastructureResponder;
  description?: string
  created_at: number
  updated_at: number
}

export interface InfrastructureChannel {
  id: string
  name: string
  infrastructure: string
  provider: string
  chat: string
  filter?: InfrastructureChannelRulesFilter
  created_at: number
  updated_at: number
}
export interface InfrastructureChannelRulesFilter {
  type: string
  conditions: RulesConditions[]
}

export interface RulesConditions {
  field: string
  operation: { any?: string[] } | { equals?: string } | { matches?: string } | { contains?: string }
  not: boolean
}

export interface IInfrastructureState {
  error: string | null;
  loading: boolean;
  infrastructures: { [id: string]: Infrastructure[] };
  channels: { [id: string]: InfrastructureChannel[] }
}

const initialState: IInfrastructureState = {
  error: null,
  loading: true,
  infrastructures: {},
  channels: {}
};

export const infrastructureSlice = createSlice({
  initialState,
  name: "infrastructure",
  reducers: {
    setInfrastructureList: (state, action: PayloadAction<Infrastructure[]>) => {
      state.infrastructures = {}
      for (let key in action.payload) {
        state.infrastructures[action.payload[key].workspace] = action.payload
      }
      state.loading = false
    },
    setInfrastructure: (state, action: PayloadAction<Infrastructure>) => {
      if (!state.infrastructures[action.payload.workspace]) state.infrastructures[action.payload.workspace] = [];
      if (state.infrastructures[action.payload.workspace].some(item => item.slug === action.payload.slug)) {
        return
      } else state.infrastructures[action.payload.workspace].push(action.payload)
      state.loading = false
    },
    setInfrastructureUpdate: (state, action: PayloadAction<Infrastructure>) => {
      state.infrastructures[action.payload.workspace] = state.infrastructures[action.payload.workspace].map(elem => {
        if (elem.id === action.payload.id) {
          return action.payload
        }
        return elem
      })
    },
    setInfrastructureChannelList: (state, action: PayloadAction<InfrastructureChannel[]>) => {
      state.channels = {}
      for (let key in action.payload) {
        state.channels[action.payload[key].infrastructure] = action.payload
      }
      state.loading = false
    },
    setInfrastructureChanel: (state, action: PayloadAction<InfrastructureChannel>) => {
      if (!state.channels[action.payload.infrastructure]) state.infrastructures[action.payload.infrastructure] = [];
      if (state.channels[action.payload.infrastructure].some(item => item.id === action.payload.id)) {
        return
      } else state.channels[action.payload.infrastructure].push(action.payload)
      state.loading = false
    },
    removeInfrastructureChannel: (state, action: PayloadAction<{ infrastructure: string, chat: string }>) => {
      state.channels[action.payload.infrastructure] = state.channels[action.payload.infrastructure].filter(elem => {
        return elem.id !== action.payload.chat
      })
      state.loading = false
    },
    updateInfrastructureChannel: (
      state,
      action: PayloadAction<{ infrastructure: string, channel: InfrastructureChannel }>) => {
      state.channels[action.payload.infrastructure] =
        state.channels[action.payload.infrastructure].map(elem => {
          if (elem.id === action.payload.channel.id) {
            return action.payload.channel
          }
          return elem
        })
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = infrastructureSlice;
// Extract and export each action creator by name
export const {
  setInfrastructureList,
  setInfrastructure,
  setInfrastructureUpdate,
  setInfrastructureChanel,
  setInfrastructureChannelList,
  removeInfrastructureChannel,
  updateInfrastructureChannel,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

