import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import Header from "./navigation/Header";
import Sidebar from "./navigation/Sidebar";
import PagePreloader from "../PagePreloader";
import SidebarOver from "./navigation/SidebarOver";
import { useLoadCurrentWorkspaceQuery, useLoadMembershipsQuery } from "../../store/api/workspace.api";

const WorkspaceLayout = () => {
  let { workspace } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { isLoading: isWorkspaceLoading, isError: isWorkspaceError } = useLoadCurrentWorkspaceQuery(workspace ?? "");
  const { isLoading: isMembershipLoading, isError: isMembershipError } = useLoadMembershipsQuery(workspace ?? "");

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  if (!workspace) return <Navigate to="/" replace/>

  if (isWorkspaceLoading && isMembershipLoading) {
    return <PagePreloader/>;
  }
  if (isWorkspaceError || isMembershipError) {
    return <Navigate to={"/404"}/>;
  }

  return (
    <div className="vh-100">
      <div className="header-content d-inline-block">
        <Header/>
      </div>
      <div className="d-flex">
        <div className="">
          {(windowWidth > 768)
            ? <Sidebar/>
            : <SidebarOver/>
          }
        </div>
        <div className="container-fluid px-4 container-info">
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceLayout;
