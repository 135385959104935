import { Link } from "react-router-dom"
import { FC, useState, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";

type BreadcrumbItem = {
  slug?: string
  path?: string
  name: string
}

export interface IProps {
  component?: string,
  entities?: Array<BreadcrumbItem>;
}

const components: Record<string, BreadcrumbItem> = {
  "oncall": {
    name: "oncall",
    slug: "oncall",
  },
  "infrastructures": {
    name: "infrastructures",
    slug: "infrastructures",
  },
}

const Breadcrumbs: FC<IProps> = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const workspaceState = useAppSelector((state) => state.workspaceState);
  const current: string | null = workspaceState?.current
  const separator = "/";
  if (current === null) {
    return <></>
  }

  let workspaceCrumb: BreadcrumbItem = {
    slug: workspaceState.workspaces[current].slug,
    name: workspaceState.workspaces[current].name,
    path: ["", "workspace", workspaceState.workspaces[current].slug].join(separator)
  }
  let path = workspaceCrumb.path;

  let componentCrumb = (props.component) ? components[props.component] : null;
  if (componentCrumb && componentCrumb?.path !== "") {
    componentCrumb.path = [workspaceCrumb.path, "-", componentCrumb?.slug].join(separator)
    path = componentCrumb?.path
  }

  const entitiesMap = (props.entities) ? props.entities.map(item => {
    if (item.slug == null) return item

    item.path = [path, item.slug].join(separator);
    path = item.path;
    return item;
  }) : [];

  return (
    <div className="container-fluid px-0 pt-3">
      <nav className="d-flex" aria-label="breadcrumb">
        {(windowWidth < 768)
          ? <div className="cursor-pointer me-3">
            <Link className="nav-link breadcrumb-button" to="#" id="toggle-sidebar-over">
              <i className="fa fa-bars-staggered"></i>
            </Link>
          </div>
          : ""
        }
        <ol className="breadcrumb">
          <li className="breadcrumb-item" aria-current="page">
            <Link to={`${workspaceCrumb.path}`} className="text-dark-emphasis">
              <span className="text-capitalize">{workspaceCrumb.name}</span>
            </Link>
          </li>
          {entitiesMap.map((item, id) => (
            <li key={id} className="breadcrumb-item" aria-current="page">
              {item.path ?
                <Link to={`${item.path}`} className="text-dark-emphasis">
                  <span className="text-capitalize">{item.name}</span>
                </Link>
                :
                <span className="text-capitalize">{item.name}</span>
              }
            </li>
          ))}
        </ol>
      </nav>
      <hr className="m-0 mb-2"/>
    </div>
  );
}

export default Breadcrumbs;
