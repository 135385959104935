import React, { FC, useEffect } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Member } from "../../store/features/oncall.team.slice";
import { Membership } from "../../store/features/workspace.slice";
import { Modal } from "../index";
import { FormDelete } from "../forms";
import { useDeleteTeamMemberMutation } from "../../store/api/oncall.team.api";
import { toast } from "react-toastify";

type IProps = {
  workspace: string;
  items: Member[];
  memberships: Membership[];
};

const OnCallTeamMemberList: FC<IProps> = ({ workspace, memberships, items }) => {
  const [deleteTeamMember, { isError, error, isSuccess }] = useDeleteTeamMemberMutation()

  const removeTeamMemberHandler = (data: Member) => {
    deleteTeamMember({ workspace: workspace, team: data.team, account: data.account })
    Modal.closeModal("delete-team-member-modal")()
  }

  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("delete-team-member-modal")()
      toast.success("Delete team successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
            <th scope="col"/>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(items).map((key, index) => {
              const item = items[index]
              const username = memberships.find(mbs => mbs.account === item.account)?.username
              return (
                <tr key={index}>
                  <td>
                    <Link to={`/workspace/${workspace}/-/oncall/users/${username}`}>
                      {username}
                    </Link>
                  </td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                  <td>
                    <Modal id="delete-team-member-modal" title="Delete team"
                      btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0 mx-2"
                      btnOpenName=" " className="modal-dialog-centered"
                      hideFooter={true}>
                      <FormDelete
                        name={username || "confirm"}
                        onSubmit={() => removeTeamMemberHandler(item)}
                        onCancel={Modal.closeModal("delete-team-member-modal")} />
                    </Modal>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default OnCallTeamMemberList;
