import { PagePreloader } from "../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useConnectMutation } from "../store/api/integration.api";
import { useEffect, useRef } from "react";
import { LocalStorage } from "../utils/storage";

const OAuthPage = () => {
  const [connect] = useConnectMutation()
  const navigate = useNavigate();
  // const {provider} = useParams();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const parts = (state || "").split(":");

  const firstRender = useRef<boolean>(false)

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      switch (parts[0]) {
        case "auth":
          break;
        case "connect":
          let data = {
            service: parts[1] || "",
            workspace: parts[2] || "",
            code: code || "",
          };
          connect(data)
            .then((d: any) => {
              if ((d?.error)) {
                window.close()
              } else {
                LocalStorage().setItem("integrations", JSON.stringify({ service: parts[1] }))
                window.close()
              }
            })
          break;
        default:
          navigate("/")
      }
    }
  })

  return (
    <PagePreloader/>
  );
};

export default OAuthPage;
