import React from "react";
import PagePreloader from "../PagePreloader";
import { useGetMeQuery } from "../../store/api/account.api";
import { useAppDispatch } from "../../app/hooks";
import { logout } from "../../store/features/session.slice";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

type IAuthMiddleware = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<IAuthMiddleware> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, data, isError } = useGetMeQuery();

  if (isError) {
    dispatch(logout())
    navigate("/signin")
    return null;
  }
  if (isLoading) {
    return <PagePreloader/>;
  }

  if (!!data && !data.active && location.pathname !== "/waitlist") {
    return <Navigate to={"/waitlist"}/>;
  }
  if (!!data && data.active && location.pathname === "/waitlist") {
    return <Navigate to={"/"}/>;
  }

  return children;
};

export default AuthMiddleware;
