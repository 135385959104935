import { FC, useState } from "react";
import { TimePicker } from "../../../components";
import Tooltip from "../../Tooltip";
import Select, { components, OnChangeValue, OptionProps, SingleValueProps } from "react-select";
import { useAppSelector } from "../../../app/hooks";

interface SelectOption {
  label: string;
  value: string;
  connected: boolean;
}

const actions: { [id: string]: SelectOption } = {
  push: { value: "notify_push", label: "Notify by push", connected: false },
  slack: { value: "notify_slack", label: "Notify by slack", connected: false },
  telegram: { value: "notify_telegram", label: "Notify by telegram", connected: false },
  // discord: { value: "notify_discord", label: "Notify by discord" },
  phone: { value: "notify_phone", label: "Notify by phone call", connected: false },
  email: { value: "notify_email", label: "Notify by email", connected: false },
};

interface IProps {
  onChange: (steps: NotificationPolicyStepItem[]) => void
  steps: NotificationPolicyStepItem[]
}

interface NotificationPolicyStepItem {
  type: string
  delay: string
  enabled?: boolean
  deleted?: boolean
}

const SingleValue = ({ children, ...props }: SingleValueProps<SelectOption>) => (
  <components.SingleValue {...props}>
    <div className="d-flex">
      <div className="w-100">{children}</div>
      <div className="flex-shrink-1" style={{ zIndex: 0 }}>
        {!props.data.connected && (
          <Tooltip text="Integration not connected" placement="right" trigger="hover focus">
            <i className="fa-solid fa-circle-exclamation text-warning"/>
          </Tooltip>
        )}
      </div>
    </div>
  </components.SingleValue>
);

const Option = ({ children, ...props }: OptionProps<SelectOption>) => (
  <components.Option {...props}>
    <div className="d-flex">
      <div className="w-100">{children}</div>
      <div className="flex-shrink-1">
        {props.data.connected && (
          <Tooltip text="Ready to use" placement="right" trigger="hover focus">
            <i className="fa-solid fa-circle-check text-success"/>
          </Tooltip>
        )
        }
      </div>
    </div>
  </components.Option>
);

const FormUserNotificationPolicy: FC<IProps> = ({ steps, onChange }) => {
  const integrationState = useAppSelector((state) => state.integrationState);
  const [rules, setRules] = useState<NotificationPolicyStepItem[]>(steps || []);
  const devices = useAppSelector((state) => state.integrationState).devices

  const handleChangeEvent = (index: number, val: string) => {
    setRules(state => {
      onChange([...state.slice(0, index), { ...state[index], delay: val }, ...state.slice(index + 1)])
      return [...state.slice(0, index), { ...state[index], delay: val }, ...state.slice(index + 1)]
    })
  }

  const onChangeHandler = (index: number, val: OnChangeValue<any, any>) => {
    setRules(state => {
      onChange([...state.slice(0, index), { ...state[index], type: val.value }, ...state.slice(index + 1)])
      return [...state.slice(0, index), { ...state[index], type: val.value }, ...state.slice(index + 1)]
    })
  }

  const onAddHandler = () => {
    setRules(state => {
      onChange([...state, { type: "", delay: "00h05m" }])
      return [...state, { type: "", delay: "00h05m" }]
    })
  };

  const onRemoveHandler = (index: number) => {
    setRules(state => {
      return [...state.slice(0, index), { ...state[index], deleted: true }, ...state.slice(index + 1)]
    })
  };

  const options = Object.keys(actions).map((key) => {
    return { ...actions[key], connected: !!integrationState.accountIntegrations[key]?.id || !!devices[key]?.id };
  });

  return (
    <div className="form-notification-policy">
      <div className="mb-1">
        <div className="list-group list-group-flush">
          {(rules || []).map((item, index) => {
            const selectedOption = options.find(
              option => option.value === item.type
            );
            return (
              <div key={index}
                className={`d-flex align-items-center list-group-item px-0 ${item.deleted ? "d-none" : ""}`}>
                <div className="flex-grow-1 me-5">
                  <Select
                    value={selectedOption}
                    onChange={(v) => onChangeHandler(index, v)}
                    components={{ Option, SingleValue }}
                    options={options}/>
                </div>
                <div className="mx-2">after</div>
                <div>
                  <TimePicker value={item.delay} onChange={(v) => handleChangeEvent(index, v)}/>
                </div>
                <div className="ms-4">
                  <i className="far fa-trash-alt cursor-pointer" onClick={() => onRemoveHandler(index)}/>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="cursor-pointer">
        <i className="fa fa-plus text-secondary"/>
        <span className="ms-1 text-secondary" onClick={onAddHandler}>Add notification step</span>
      </div>
    </div>
  );
};

export default FormUserNotificationPolicy;
