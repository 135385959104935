import FormAccountUpdatePassword from "../../components/forms/FormAccountUpdatePassword";

const AccountSettingsPage = () => {
  return (
    <section>
      <div className="mb-4">
        <h2>Account Settings</h2>
      </div>
      <div className="row">
        <div className="col-6">
          <div>Update password</div>
          <FormAccountUpdatePassword/>
        </div>
      </div>
    </section>
  );
};

export default AccountSettingsPage;
