import { Navigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import PagePreloader from "../components/PagePreloader";

const HomePage = () => {
  const workspaceState = useAppSelector((state) => state.workspaceState);

  if (!workspaceState.loading) {
    if (workspaceState.current && workspaceState.workspaces[workspaceState.current]) {
      return <Navigate to={`/workspace/${workspaceState.current}`}/>;
    } else {
      if (Object.keys(workspaceState.workspaces).length) {
        return <Navigate to={`/workspace/${Object.keys(workspaceState.workspaces)[0]}`}/>
      }
    }
  }

  return (
    <PagePreloader/>
  );
};

export default HomePage;
