import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateAccountMutation } from "../store/api/auth.api";

const FormSchema = object({
  username: string()
    .min(4, "Username is not valid"),
  email: string()
    .min(1, "Email is not valid")
    .email("Email is not valid"),
  password: string()
    .min(1, "Password is not valid")
    .min(6, "Password must be more than 6 characters")
    .max(32, "Password must be less than 32 characters"),
  confirmPwd: string()
    .min(1, "Password is not valid")
    .min(6, "Password must be more than 6 characters"),
}).refine((data) => data.password === data.confirmPwd, {
  message: "Password doesn't match",
  path: ["confirmPwd"]
});

export type FormData = TypeOf<typeof FormSchema>;

const SignUpPage = () => {
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const [createAccount, { isError, error, isSuccess }] = useCreateAccountMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("You successfully registered in", {
        position: "bottom-right",
      });
      navigate(from);
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [navigate, error, isError, isSuccess, from]);

  useEffect(() => {
    if (!isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset, isError]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    createAccount(values);
  };

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong rounded-sm">
              <div className="card-body p-5">

                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <h3 className="mb-5 text-center">Sign up</h3>

                    <div className="form-floating mb-4">
                      <input type="text" id="usernameInput" className="form-control" placeholder="Enter username"
                        {...register("username")}/>
                      <label className="form-label" htmlFor="usernameInput">
                        {errors.username?.message
                          ? <small className="text-danger">{errors.username?.message}</small>
                          : <small className="text-secondary">Enter username</small>
                        }
                      </label>
                    </div>

                    <div className="form-floating mb-4">
                      <input type="text" id="emailInput" className="form-control" placeholder="Enter email"
                        {...register("email")}/>
                      <label className="form-label" htmlFor="emailInput">
                        {errors.email?.message
                          ? <small className="text-danger">{errors.email?.message}</small>
                          : <small className="text-secondary">Enter email</small>
                        }
                      </label>
                    </div>

                    <div className="form-floating mb-4">
                      <input type="password" id="passwordInput" className="form-control" placeholder="Enter password"
                        {...register("password")}/>
                      <label className="form-label" htmlFor="passwordInput">
                        {
                          errors.password?.message
                            ? <small className="text-danger">{errors.password?.message}</small>
                            : <small className="text-secondary">Enter password</small>
                        }
                      </label>
                    </div>
                    <div className="form-floating mb-4">
                      <input
                        type="password" id="confirmPasswordInput" className="form-control"
                        placeholder="Confirm password"
                        {...register("confirmPwd")}/>
                      <label className="form-label" htmlFor="confirmPasswordInput">
                        {
                          errors.confirmPwd?.message
                            ? <small className="text-danger">{errors.confirmPwd?.message}</small>
                            : <small className="text-secondary">Confirm password</small>
                        }
                      </label>
                    </div>

                    <div className="d-grid gap-2">
                      <button type="submit" className="btn btn-primary">Register</button>
                    </div>

                    <hr className="my-4"/>
                    <div className="d-flex justify-content-center">
                      <div><i className="fa-brands fa-github fa-2x cursor-pointer me-4"></i></div>
                      <div><i className="fa-brands fa-gitlab fa-2x cursor-pointer"></i></div>
                    </div>
                  </form>
                </FormProvider>

                <div className="d-flex justify-content-center mt-2">
                  <Link to="/signin" className="text-decoration-none">Have account? Sign In</Link>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  OR
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <Link to="/recovery" className="text-decoration-none">forgot password?</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpPage;
