import { FC } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import UserInfo from "./User.view"
import { useListUserQuery } from "../../../store/api/oncall.user.api";
import Loader from "../OnCallIncidentList/Loader";
import { Membership } from "../../../store/features/workspace.slice";
import Suspense from "../../Suspense";
import { AccountIntegrations } from "../../../store/features/integration.slice";
import { Account } from "../../../store/features/account.slice";

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
  account: Account;
  memberships: Membership[];
  integrations: AccountIntegrations;
}

export interface IProps {
  user: string
}

type Props = StateProps & IProps;

const UserContainer: FC<Props> = (props) => {
  const query = useListUserQuery({ workspace: props.workspace });
  return (
    <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <UserInfo {...props} integrations={props.integrations} readOnly={props.account?.username !== props.user}/>
    </Suspense>
  );
};

const mapStateToProps = function (state: RootState, ownProps: IProps): StateProps {
  const workspace = state.workspaceState?.current || "";
  const memberships = state.workspaceState.memberships[workspace] || [];
  const integrations = state.integrationState.accountIntegrations || {};
  const account = state.accountState?.account || {} as Account;
  return { ...ownProps, workspace, memberships, integrations, account }
}

export default connect(mapStateToProps)(UserContainer);
