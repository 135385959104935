import { FC } from "react";
import { useAppSelector } from "../../app/hooks";

type IProps = {
  workspace: string
  incident: string
};

const OnCallIncidentInfo: FC<IProps> = ({ workspace, incident }) => {
  const incidentState = useAppSelector((state) => state.onCallIncidentState);

  const data = incidentState.incidents[incident]

  if (!data) return <h1>Can not load data</h1>

  return (
    <div>
      <div>
        Incident Info
      </div>
      <div>
        {data.title}
      </div>
    </div>
  );
};

export default OnCallIncidentInfo;
