import React, { FC, useEffect, useState } from "react";
import Suspense from "./../../Suspense";
import { useFetchEscalationPolicyQuery, useUpdateEscalationPolicyMutation } from "../../../store/api/oncall.team.api";
import Loader from "../OnCallAlertList/Loader";
import { useAppSelector } from "../../../app/hooks";
import { conditionOptions, typeFilter } from "../../forms/FormEscalationPolicy/constants";
import { Modal, Tooltip } from "../../index";
import { FormEscalationPolicy } from "../../forms";
import { toast } from "react-toastify";

type IProps = {
  workspace: string
  team: string
};

const OnCallTeamEscalationPolicy: FC<IProps> = ({ workspace, team }) => {
  const escalationPolicyQuery = useFetchEscalationPolicyQuery({ workspace: workspace, team: team });
  const [update, { data, isError, error, isSuccess }] = useUpdateEscalationPolicyMutation();
  const teamState = useAppSelector((state) => state.onCallTeamState);

  const [stateValue, setStateValue] = useState(
    teamState.escalation_policies[teamState.matchTeamSlugs[team]]?.rules || []
  )

  useEffect(() => {
    setStateValue(teamState.escalation_policies[teamState.matchTeamSlugs[team]]?.rules || [])
  }, [teamState, setStateValue, team])

  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("escalation-policy-update-modal")()
      toast.success("Update escalation policy successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess, data]);

  const onPause = (index: number) => {
    const newState = [...stateValue.slice(0, index), {
      ...stateValue[index],
      enabled: false
    }, ...stateValue.slice(index + 1)];
    update({ workspace, team, data: { rules: newState } });
  }

  const onUnpause = (index: number) => {
    const newState = [...stateValue.slice(0, index), {
      ...stateValue[index],
      enabled: true
    }, ...stateValue.slice(index + 1)];
    update({ workspace, team, data: { rules: newState } });
  }

  const addHandler = (data: any) => {
    const newState = [...stateValue];
    data.enabled = true
    newState.push(data);
    update({ workspace, team, data: { rules: newState } });
  }

  const submitHandler = (index: number, data: any) => {
    const newState = [...stateValue.slice(0, index), data, ...stateValue.slice(index + 1)];
    update({ workspace, team, data: { rules: newState } });
  }

  const onRemove = (index: number) => {
    const newState = [...stateValue.slice(0, index), ...stateValue.slice(index + 1)];
    update({ workspace, team, data: { rules: newState } });
  }

  return (
    <Suspense query={escalationPolicyQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <div className="mb-2">
        <div className="d-flex mb-2">
          <div className="flex-grow-1">
            <h5>Escalation Policies</h5>
          </div>
          <div>
            <Modal id="escalation-policy-update-modal" title="Create a new escalation policy"
              className="modal-dialog-centered modal-lg"
              btnOpenClassName="btn btn-sm btn-outline-primary"
              btnOpenName={"Create Escalation Policy"}
              hideFooter={true}>
              <FormEscalationPolicy workspace={workspace}
                team={teamState.matchTeamSlugs[team]}
                onSubmit={addHandler}
                onCancel={Modal.closeModal("escalation-policy-update-modal")}/>
            </Modal>
          </div>
        </div>
        <div>
          {
            stateValue.map((rule, rule_index) => {
              return (
                <div key={rule_index} className="card mb-2">

                  <div className="card-header d-flex align-items-center">
                    <div className="flex-grow-1">
                      <strong>{rule.name}</strong>
                    </div>
                    <div className="flex-shrink-1 px-1">
                      <Modal id="escalation-policy-update-modal" title="Escalation policy"
                        className="modal-dialog-centered modal-lg"
                        btnOpenClassName="btn btn-sm btn-outline-primary mx-2"
                        btnOpenName={"change rules"}
                        hideFooter={true}>
                        <FormEscalationPolicy workspace={workspace}
                          team={teamState.matchTeamSlugs[team]}
                          data={rule}
                          onSubmit={(data) => submitHandler(rule_index, data)}
                          onCancel={Modal.closeModal("escalation-policy-update-modal")}/>
                      </Modal>
                    </div>
                    <div className="flex-shrink-1 px-1">
                      {rule.enabled && (
                        <Tooltip text="pause escalation policy rule" children={
                          <i className="fa fa-pause cursor-pointer mx-2 text-primary-emphasis"
                            onClick={() => onPause(rule_index)}/>
                        }/>
                      )}
                      {!rule.enabled && (
                        <Tooltip text="pause escalation policy rule" children={
                          <i className="fa fa-play cursor-pointer mx-2 text-primary-emphasis"
                            onClick={() => onUnpause(rule_index)}/>
                        }/>
                      )}
                    </div>
                    <div className="flex-shrink-1 px-1">
                      <Tooltip text="delete escalation policy rule" children={
                        <i className="far fa-trash-alt cursor-pointer text-primary-emphasis"
                          onClick={() => onRemove(rule_index)}/>
                      }/>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 border-end">
                        <div className="list-group list-group-flush">
                          {!(rule.steps || []).length && <div>No steps yet</div>}
                          {(rule.steps || []).map((step, step_index) => (
                            <div key={step_index} className="list-group-item">
                              {step_index + 1}. {step.type}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="pb-2">
                          <strong>{typeFilter[rule.filter?.type]}</strong>
                        </div>
                        <div>
                          {(rule.filter?.conditions || []).map((condition, condition_index) => (
                            <div key={condition_index} className="list-group-item">
                              <span className="me-1">{conditionOptions[condition.field]}</span>
                              <span className="me-1">&#x2192;</span>
                              <span className="me-1">{condition.not ? "not" : "is"}</span>
                              <span className="me-1">{Object.keys(condition.operation)}</span>
                              <span>{Object.values(condition.operation)}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </Suspense>
  );
};

export default OnCallTeamEscalationPolicy;
