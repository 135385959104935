import { FormOnCallAlertPayload } from "../../../forms";

export interface PayloadItem {
  name: string;
  component: any;
}

export const PayloadElems: { [id: string]: PayloadItem } = {
  "alertmanager": {
    name: "AlertManager",
    component: FormOnCallAlertPayload
  }
}
