import { primaryKey } from "@mswjs/data";
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary";
import { Membership } from "../../store/features/workspace.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}
interface MockMembership extends Membership {
  id: string;
}

export const MembershipModel = defineModel<MockMembership>(() => ({
  id: primaryKey(String),
  account: String,
  username: String,
  email: String
}))
