import React, { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Integration } from "../../../store/features/oncall.integration.slice";

type IProps = {
  workspace: string
  infrastructure: string
  items: Integration[]
};

const InfrastructureIntegrationsList: FC<IProps> = ({ workspace, items, infrastructure }) => {
  if (items.length < 1) return null
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Status</th>
            <th scope="col">Name</th>
            <th scope="col">Service</th>
            <th scope="col">CreatedAt</th>
          </tr>
        </thead>
        <tbody>
          {
            (items || []).map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item.active
                      ? <i className="fa-solid fa-circle text-success"/>
                      : <i className="fa-solid fa-circle-exclamation text-warning"/>
                    }
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="px-2">
                        <img src={`/images/ico/oncall/${item.type}.svg`} alt={item.name} height={24}/>
                      </div>
                      <div>
                        {/*<Link*/}
                        {/*
                        to={`/workspace/${workspace}/-/infrastructure/${infrastructure}/integration/${item.id}`}>*/}
                        {/*  {item.name}*/}
                        {/*</Link>*/}
                        <Link to={`/workspace/${workspace}/-/oncall/integrations/${item.id}`}>{item.name}</Link>
                      </div>
                    </div>
                  </td>
                  <td>{item.type}</td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default InfrastructureIntegrationsList;
