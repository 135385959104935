import { rest } from "msw";
import { db } from "../db";
import { WorkspaceMembershipResponse } from "../../store/api/types/workspace";

const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const getMembershipList = rest.get("/v1/workspace/:slugOrID/membership", async (req, res, ctx) => {
  const list = db.membership.getAll()
  return res(
    ctx.json({ items: list }),
  );
});

export const getMembershipInfo = rest.get<WorkspaceMembershipResponse>("/v1/workspace/:slugOrID/membership/:member",
  async (req, res, ctx) => {
    const { slugOrID } = req.params
    const filed = regexExp.test(slugOrID as string) ? "id" : "slug"
    const workspace = db.membership.findFirst({
      where: {
        [filed]: {
          equals: slugOrID
        },
      }
    })
    return res(
      ctx.json(workspace),
    );
  });
