import React, { FC } from "react";
import moment from "moment/moment";
import { Modal } from "../../index";
import { InfrastructureChannel } from "../../../store/features/infrastructure.slice";
import { FormDelete } from "../index";
import { OnChangeValue } from "react-select";
import FormChannelRules from "./FormChannelRules";

type IProps = {
  workspace: string
  items: InfrastructureChannel[]
  onRemove?: (id: string) => void
  onUpdate?: (val: any) => void
};

function channelType(item: string) {
  const icon = item?.toLowerCase();
  switch (icon) {
    case "slack":
      return "fa-slack";
    case "discord":
      return "fa-discord";
    case "telegram":
      return "fa-telegram";
    default:
      return "fa-font-awesome";
  }
}

const Index: FC<IProps> = ({ items, onRemove, workspace, onUpdate }) => {
  function changeFilterHandler(val: OnChangeValue<any, any>) {
    onUpdate && onUpdate(val)
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
            <th scope="col">Rules</th>
            <th scope="col"/>
          </tr>
        </thead>
        <tbody>
          { items &&
            items.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <i className={`fa-brands ${channelType(item.provider)}`}/>&nbsp;{item.name}
                    </div>
                  </td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                  <td>
                    <Modal id="channel-rule-modal" title="Channel rules"
                      btnOpenClassName="btn btn-sm btn-outline-primary"
                      btnOpenName="change rules" className="modal-dialog-centered integration-rules"
                      hideFooter={true}>
                      <FormChannelRules
                        data={item} workspace={workspace}
                        onSubmit={changeFilterHandler}
                        onCancel={Modal.closeModal("channel-rule-modal")} />
                    </Modal>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Modal id="delete-channel-modal" title="Delete channel"
                        btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0 mx-2"
                        btnOpenName=" " className="modal-dialog-centered"
                        hideFooter={true}>
                        <FormDelete name={item.name}
                          onSubmit={() => onRemove && onRemove(item.id)}
                          onCancel={Modal.closeModal("delete-channel-modal")}/>
                      </Modal>
                    </div>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default Index;
