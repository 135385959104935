import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type WorkspaceMemberships = {
  workspace: string
  memberships: Membership[],
}

export type Membership = {
  account: string
  username: string
  email: string
}

export type Workspace = {
  id: string;
  slug: string;
  name: string;
  description?: string;
  created_at?: number;
  updated_at?: number;
}

interface IWorkspaceState {
  error: string | null;
  loading: boolean;
  current: string | null;
  workspaces: { [id: string]: Workspace };
  memberships: { [id: string]: Membership[] };
}

const initialState: IWorkspaceState = {
  error: null,
  loading: true,
  current: null,
  workspaces: {},
  memberships: {},
};

export const workspaceSlice = createSlice({
  initialState,
  name: "workspace",
  reducers: {
    setCurrentWorkspace: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
      state.loading = false
    },
    setWorkspaceList: (state, action: PayloadAction<Workspace[]>) => {
      const newState = state
      newState.workspaces = {}
      for (let key in action.payload) {
        newState.workspaces[action.payload[key].slug] = action.payload[key]
      }
      state.loading = false
      return newState
    },
    setWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.workspaces[action.payload.slug] = action.payload;
      state.loading = false
    },
    removeWorkspace: (state, action: PayloadAction<string>) => {
      delete state.workspaces[action.payload];
    },
    setMemberships: (state, action: PayloadAction<WorkspaceMemberships>) => {
      state.memberships[action.payload.workspace] = action.payload.memberships
    },
  },
});

const persistConfig = {
  key: "workspace",
  storage: storage,
  whitelist: ["current"],
};

// Extract the action creators object and the reducer
const { reducer, actions } = workspaceSlice;
// Extract and export each action creator by name
export const { setWorkspaceList, setWorkspace, setCurrentWorkspace, removeWorkspace, setMemberships } = actions;
// Export the reducer, either as a default or named export
export default persistReducer(persistConfig, reducer);

