import React, { FC, useState } from "react";
import Select, { OnChangeValue } from "react-select";
import AlertPrioritySelect from "./AlertPrioritySelect";
import PatternSelect from "./PatternSelect";
import IntegrationSelect from "./IntegrationSelect";
import { Filter } from "./types"

import "./styles.scss"

interface IProps {
  item: Filter
  workspace: string
  options: conditionOptions
  onChange: (filter: Filter) => void
}

interface typeOptions {
  value: string
  label: string
}

interface conditionOptions {
  field?: typeOptions[]
  not?: { value: boolean, label: string }[]
  operation?: {
    [field: string]: typeOptions[]
  }
  priorityValue?: typeOptions[]
}

const typeFilter: typeOptions[] = [
  { value: "match-all", label: "Match all" },
  { value: "match-any-condition", label: "Match any condition" },
  { value: "match-all-conditions", label: "Match all conditions" },
];

const defaultConditionOptions = [
  { value: "alert:priority", label: "Alert priority" },
  { value: "pattern", label: "Pattern" },
  { value: "integration:name", label: "Integration name" },
]

const FormFilterRules: FC<IProps> = ({ item, workspace, onChange, options }) => {
  const [typeValue, setTypeValue] = useState(item.type || "match-all");
  const [conditions, setConditions] = useState(item.conditions || [])

  const onStepChangeHandler = (selectedOption: any) => {
    setTypeValue(state => {
      state = selectedOption.value
      onChange({ type: state, conditions })
      return state
    })
  }

  const onConditionAddHandler = () => {
    setConditions(state => {
      onChange({ type: typeValue, conditions: [...state, { field: "", not: false, operation: { any: [] } }] })
      return [...state, { field: "", not: false, operation: { any: [] } }]
    })
  };
  const onStepConditionFieldHandler = (index: number, val: OnChangeValue<any, any>) => {
    setConditions(state => {
      onChange({
        type: typeValue, conditions: [...state.slice(0, index), {
          ...state[index],
          field: val.value,
          not: false,
          operation: { any: [] }
        }, ...state.slice(index + 1)]
      })
      return [...state.slice(0, index), {
        ...state[index],
        field: val.value,
        not: false,
        operation: { any: [] }
      }, ...state.slice(index + 1)]
    })
  }
  const priorityChangeHandler = (index: number, data: { not: boolean, operation: string, value: string }) => {
    setConditions(state => {
      const cond = [...state.slice(0, index), {
        ...state[index],
        not: data.not,
        operation: { [data.operation]: data.value }
      }, ...state.slice(index + 1)]
      onChange({ type: typeValue, conditions: cond })
      return cond
    })
  }
  const IntegrationChangeHandler = (index: number, data: {
    not: boolean,
    operation: string,
    value: string | string[]
  }) => {
    setConditions(state => {
      const cond = [...state.slice(0, index), {
        ...state[index],
        not: data.not,
        operation: { [data.operation]: data.value }
      }, ...state.slice(index + 1)]
      onChange({ type: typeValue, conditions: cond })
      return cond
    })
  }
  const onStepRemoveHandler = (index: number) => {
    setConditions(state => {
      return [...state.slice(0, index), ...state.slice(index + 1)]
    })
  };

  const selectedOption = typeFilter.find(val => val.value === typeValue)

  return (
    <div>
      <div className="flex-grow-1 my-1">
        <Select
          defaultValue={selectedOption}
          onChange={onStepChangeHandler}
          options={typeFilter}/>
      </div>

      <div className="flex-grow-1 my-1">
        {typeValue !== "match-all" && conditions.map((elem, index) => {
          const selectedField = defaultConditionOptions.find(val => val.value === elem.field);
          return (
            <div className="row mt-2 align-items-center justify-content-between d-flex flex-nowrap" key={index}>
              <Select
                className="col-sm-3"
                onChange={val => onStepConditionFieldHandler(index, val)}
                defaultValue={selectedField}
                options={options.field}/>
              {elem.field === "alert:priority" && (
                <AlertPrioritySelect values={elem}
                  onChange={(val) => priorityChangeHandler(index, val)}/>
              )}
              {elem.field === "pattern" && (
                <PatternSelect values={elem}
                  onChange={(val) => priorityChangeHandler(index, val)}/>
              )}
              {elem.field === "integration:name" && (
                <IntegrationSelect values={elem} workspace={workspace}
                  onChange={(val) => IntegrationChangeHandler(index, val)}/>
              )}
              <div className="col-sm-1 d-flex justify-content-end cursor-pointer"
                onClick={() => onStepRemoveHandler(index)}>
                <i className="far fa-trash-alt"/>
              </div>
            </div>
          )
        })
        }
      </div>

      {typeValue !== "match-all" &&
        <span className="text-secondary cursor-pointer">
          <i className="fa fa-plus"/>
          <span className="text-primary-hover ms-1" onClick={onConditionAddHandler}>Add condition</span>
        </span>
      }
    </div>
  );
};

export default FormFilterRules;
