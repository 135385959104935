import { useEffect, useRef } from "react";
import { useStateCallback } from "../app/hooks"
import { Modal } from "bootstrap"

export interface IProps {
  title: string;
  children: JSX.Element | JSX.Element[]
  id: string;
  className?: string;
  btnOpenName?: string;
  btnOpenClassName?: string;
  btnApplyName?: string;
  onApply?: () => boolean | Promise<any>;
  btnCloseName?: string;
  onClose?: () => void;
  afterOpen?: () => void
  beforeOpen?: () => void
  hideFooter?: boolean
}

const ModalDialog = (props: IProps) => {

  const [show, setShow] = useStateCallback(false)
  const modalRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);

  const closeHandle = () => {
    setShow(false);
    props.onClose && props.onClose()
  };

  const applyHandle = () => {
    if (props.onApply) {
      const resultFn = props.onApply()
      if (typeof resultFn === "boolean") {
        resultFn && closeRef.current && closeRef.current.click()
      } else {
        resultFn.then(() => {
          closeRef.current && closeRef.current.click()
        })
      }
    }
  };

  const showModalHandler = () => {
    props.beforeOpen && props.beforeOpen()

    setShow(true, () => {
      const elem = document.getElementById(`${props.id || "exampleModal"}`);
      if (elem) {
        const modal = new Modal(elem)
        if (modal) {
          modal.show();
          const handler = () => {
            elem.removeEventListener("hidden.bs.modal", handler)
            setShow(false)
          }
          elem.addEventListener("hidden.bs.modal", handler)

          props.afterOpen && props.afterOpen()
        }
      }
    });
  }

  return (
    <div>
      <button type="button" className={props.btnOpenClassName || "btn btn-primary"}
        onClick={showModalHandler}>
        {props.btnOpenName || "Open"}
      </button>

      {show && (
        <div className="modal fade" id={props.id || "exampleModal"} tabIndex={-1}
          aria-labelledby={`${props.id || "exampleModal"}`}
          aria-hidden="true" ref={modalRef}>
          <div className={`modal-dialog ${props.className}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id={`${props.id || "exampleModal"}`}>
                  {props.title}
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {props.children}
              </div>

              {!props.hideFooter && (
                <div className="modal-footer">
                  {
                    props.onApply ? (
                      <div className="d-flex">
                        <div className="me-2">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            ref={closeRef}
                            onClick={closeHandle}>
                              Close
                          </button>
                        </div>
                        <div>
                          <button type="button" className="btn btn-primary"
                            onClick={applyHandle}>
                            {props.btnApplyName || "Save"}
                          </button>
                        </div>
                      </div>
                    )
                      : (
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                          ref={closeRef}
                          onClick={closeHandle}>
                          {props.btnCloseName || "Close"}
                        </button>
                      )
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ModalDialog.closeModal = (id: string) => {
  return () => {
    const elem = document.getElementById(id)
    if (elem) {
      const modal = Modal.getInstance(elem)
      modal && modal.hide();
    }
  }
}

export default ModalDialog;
