import { primaryKey } from "@mswjs/data";
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary";
import { Workspace } from "../../store/features/workspace.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export const WorkspaceModel = defineModel<Workspace>(() => ({
  id: primaryKey(String),
  slug: String,
  name: String,
  description: String,
  created_at: Number,
  updated_at: Number,
}))
