import React, { FC } from "react";
import moment from "moment/moment";
import { IntegrationTemplate } from "../../../store/features/oncall.integration.slice";
import { Modal } from "../../index";
import { FormDelete } from "../../forms";
import FormIntegrationTemplate from "./FormIntegrationTemplate";

type IProps = {
  items: IntegrationTemplate[]
  onRemove: (template: string) => void
  onUpdate: (data: Data) => void
};

interface Data {
  name: string
  payload: string
}

const OnCallTemplateList: FC<IProps> = ({ items, onRemove, onUpdate }) => {
  function removeTemplateHandler(item: IntegrationTemplate) {
    onRemove(item.id)
  }

  function updateTemplateHandler(id: string, item: Data) {
    const data = {
      name: item.name,
      payload: item.payload,
      id: id
    }
    onUpdate(data)
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {
            (items || []).map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item.name}
                  </td>
                  <td>{moment.unix(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Modal
                        id="integration-template-modal" title="Template info"
                        btnOpenClassName="fa fa-pen cursor-pointer bg-transparent border-0 mx-2"
                        btnOpenName=" " className="modal-dialog-centered integration-rules"
                        hideFooter={true}>
                        <FormIntegrationTemplate
                          button="Update"
                          data={{ name: item.name, template: item.payload }}
                          onChange={data => updateTemplateHandler(item.id, data)} />
                      </Modal>
                      <Modal id="delete-template-modal" title="Delete template"
                        btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0 mx-2"
                        btnOpenName=" " className="modal-dialog-centered"
                        hideFooter={true}>
                        <FormDelete name={item.name}
                          onSubmit={() => removeTemplateHandler(item)}
                          onCancel={Modal.closeModal("delete-template-modal")}/>
                      </Modal>
                    </div>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default OnCallTemplateList;
