import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { OnCallIntegrationList } from "./../../components/oncall"
import { useCreateIntegrationMutation, useListProviderQuery } from "../../store/api/oncall.integration.api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../components";

const OnCallIntegrationListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: listProvider } = useListProviderQuery();
  const [createIntegration, { isError, error, isSuccess, data }] = useCreateIntegrationMutation();
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      if (isSuccess) {
        toast.success("You successfully create", {
          position: "bottom-right",
        });
        if (data) {
          navigate(`/workspace/${workspace}/-/oncall/integrations/${data.id}`)
        }
      }
      if (isError) {
        setSubmitted(false);
        if (Array.isArray((error as any)?.data?.error)) {
          (error as any).data.error.forEach((el: any) =>
            toast.error(el.message, {
              position: "bottom-right",
            })
          );
        } else {
          toast.error((error as any)?.data?.message, {
            position: "bottom-right",
          });
        }
      }
    }
  }, [navigate, error, isSubmitted, isError, isSuccess, from, data, workspace]);

  if (!workspace) return <Navigate to="/" replace/>

  const onCreateHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    if (isSubmitted) return
    setSubmitted(true)
    event.preventDefault();
    const elem: HTMLLIElement = event.currentTarget;
    const id = elem.dataset?.id || ""
    const name = elem.dataset?.name || ""
    createIntegration({ workspace: workspace, data: { name: name, provider: id } })
  };

  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "integrations", slug: "integrations" }]}/>
      </div>
      <div className="d-flex mb-4">
        <div className="flex-grow-1">
          <h2>Integration List</h2>
        </div>
        <div>

          <div className="dropdown">
            <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false" data-bs-auto-close="outside">
              Add Integration
            </button>
            <div className="dropdown-menu dropdown-menu-end p-1">
              <ul className="list-group list-group-flush">
                {(listProvider ?? []).map((item, index) => (
                  <li key={index} className="list-group-item cursor-pointer" data-id={item.id} data-name={item.name}
                    onClick={onCreateHandler}>
                    <div className="d-flex">
                      <div className="p-2">
                        <img src={`/images/ico/oncall/${item.type}.svg`} alt={item.name} height={36}/>
                      </div>
                      <div className="align-self-center">{item.name}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div>
        <OnCallIntegrationList/>
      </div>
    </div>
  );
};

export default OnCallIntegrationListPage;
