import React, { FC, useEffect } from "react";
import { Team } from "../../../store/features/oncall.team.slice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteTeamMutation } from "../../../store/api/oncall.team.api";
import { Modal } from "../../index";
import { FormDelete } from "../../forms";

type IProps = {
  workspace: string;
  items: { [id: string]: Team };
};

const TeamsList: FC<IProps> = ({ workspace, items }) => {
  const [deleteTeam, { isError, error, isSuccess }] = useDeleteTeamMutation()

  const removeTeamHandler = (data: Team) => {
    deleteTeam({ workspace: workspace, team: data.slug })
    Modal.closeModal("delete-team-modal")()
  }

  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("delete-team-modal")()
      toast.success("Delete team successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col"/>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(items).map((key, index) => {
              const item = items[key]
              return (
                <tr key={index}>
                  <td>
                    <Link to={`/workspace/${workspace}/-/oncall/teams/${item.slug}`}>
                      {item.name}
                    </Link>
                  </td>
                  <td>{item.description}</td>
                  <td>
                    <Modal id="delete-team-modal" title="Delete team"
                      btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0 mx-2"
                      btnOpenName=" " className="modal-dialog-centered"
                      hideFooter={true}>
                      <FormDelete name={item.name}
                        onSubmit={() => removeTeamHandler(item)}
                        onCancel={Modal.closeModal("delete-team-modal")}/>
                    </Modal>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default TeamsList;
