import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChangePasswordMutation } from "../../store/api/auth.api";

const FormSchema = object({
  password: string()
    .min(1, "Password is required")
    .min(6, "Password must be more than 6 characters")
    .max(32, "Password must be less than 32 characters"),
  confirm: string()
    .min(1, "Confirm password is required")
    .min(6, "Confirm password must be more than 6 characters")
    .max(32, "Confirm password must be less than 32 characters")
}).refine((data) => data.password === data.confirm, {
  message: "Passwords don't match",
  path: ["confirm"],
});

export interface IProps {
  code: string
}

export type FormData = TypeOf<typeof FormSchema>;

const FormAccountChangePassword: FC<IProps> = ({ code }) => {
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const [changePassword, { isError, error, isSuccess }] = useChangePasswordMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("Password has been successfully changed", {
        position: "bottom-right",
      });
      navigate(from);
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [navigate, error, isError, isSuccess, from]);

  useEffect(() => {
    if (!isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    changePassword({ code: code, data: values });
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="form-floating mb-4">
            <input type="password" id="passwordInput" className="form-control" placeholder="Enter password"
              {...register("password")}/>
            <label className="form-label" htmlFor="passwordInput">
              {
                errors.password?.message
                  ? <small className="text-danger">{errors.password?.message}</small>
                  : <small className="text-secondary">Enter password</small>
              }
            </label>
          </div>

          <div className="form-floating mb-4">
            <input type="password" id="confirmInput" className="form-control" placeholder="Enter confirm"
              {...register("confirm")}/>
            <label className="form-label" htmlFor="confirmInput">
              {
                errors.confirm?.message
                  ? <small className="text-danger">{errors.confirm?.message}</small>
                  : <small className="text-secondary">Enter confirm</small>
              }
            </label>
          </div>

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">Change password</button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormAccountChangePassword;
