import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  ChatOpsCreateRequest,
  ChatOpsFetchRequest,
  ChatOpsListRequest,
  ChatOpsRemoveRequest,
  ChatOpsResponse
} from "./types/oncall"
import { removeChatOps, setChatOps, setChatOpsList } from "../features/oncall.chat_ops.slice";

const transformResponse = (item: ChatOpsResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}

export const onCallChatOpsApi = createApi({
  reducerPath: "onCallChatOpsApi",
  baseQuery: customFetchBase,
  tagTypes: ["OnCallChats"],
  endpoints: (builder) => ({
    listChatOps: builder.query<ChatOpsResponse[], ChatOpsListRequest>({
      query(req: ChatOpsListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/chat`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: ChatOpsResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "OnCallChats" as const, id })),
            { type: "OnCallChats", id: "LIST" },
          ]
          : [{ type: "OnCallChats", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setChatOpsList(data));
        } catch (error) {
        }
      },
    }),
    fetchChatOps: builder.query<ChatOpsResponse, ChatOpsFetchRequest>({
      query(req: ChatOpsFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/chat/${req.chat}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setChatOps(data));
        } catch (error) {
        }
      },
    }),
    createChatOps: builder.mutation<ChatOpsResponse, ChatOpsCreateRequest>({
      query(req: ChatOpsCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/chat`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setChatOps(data));
        } catch (error) {
        }
      },
    }),
    deleteChatOps: builder.mutation<void, ChatOpsRemoveRequest>({
      query(req: ChatOpsRemoveRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/chat/${req.chat}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeChatOps(args.chat));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListChatOpsQuery,
  useFetchChatOpsQuery,
  useCreateChatOpsMutation,
  useDeleteChatOpsMutation,
} = onCallChatOpsApi;

