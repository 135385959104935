import { Breadcrumbs, Modal } from "../../components";
import { OnCallTeamList } from "../../components/oncall/OnCallTeamList";
import React from "react";
import { useParams } from "react-router-dom";
import FormOnCallTeamCreate from "../../components/forms/FormOnCallTeamCreate";

const OnCallTeamListPage = () => {
  let { workspace } = useParams();
  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "teams", slug: "teams" }]}/>
      </div>
      <div className="mb-4 d-flex align-items-center">
        <h2 className="m-0">Team List</h2>
        <Modal id="create-team-modal" title="Team create"
          btnOpenClassName="btn btn-sm btn-primary mx-3"
          btnOpenName="Team create" className="modal-dialog-centered"
          hideFooter={true}>
          <FormOnCallTeamCreate workspace={workspace ?? ""} onSubmit={Modal.closeModal("create-team-modal")}/>
        </Modal>
      </div>
      <div>
        <OnCallTeamList/>
      </div>
    </div>
  );
};

export default OnCallTeamListPage;
