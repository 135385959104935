import React, { FC, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, string, TypeOf } from "zod";
import { useConnectPhoneMutation, useLazyVerifyPhoneQuery } from "../../../store/api/integration.api";
import { CountdownTimer } from "./../../../components";
import { toast } from "react-toastify";

import "./style.scss"

const FormSchema = object({
  phone: string()
    .min(1, "Phone is required"),
  confirm: string()
    .min(1, "Code is required")
    .optional()
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string
  account: string
  phone: string
  onConnect?: () => void
}

const FormPhoneConnect: FC<IProps> = ({ workspace, account, phone, onConnect }) => {

  const [connectPhone, { data, isError, error, isSuccess }] = useConnectPhoneMutation();
  const [verifyPhone, result, lastPromiseInfo] = useLazyVerifyPhoneQuery()

  const [isCodeSubmitted, setIsCodeSubmitted] = useState<boolean>(false)
  const [allowResend, setAllowResend] = useState<boolean>(true)

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    reset,
    resetField,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      setAllowResend(false)
      setIsCodeSubmitted(true)
      toast.success("Send verify code successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess, data]);

  useEffect(() => {
    setValue("phone", phone || "")
  }, [phone, setValue])

  useEffect(() => {
    const v = getValues()
    if (isCodeSubmitted && (lastPromiseInfo && lastPromiseInfo?.lastArg?.code === v.confirm)) {
      if (result.isSuccess) {
        reset()
        setAllowResend(true)
        setIsCodeSubmitted(false)
        onConnect && onConnect();
      }
      if (result.isError) {
        toast.error("invalid verification code", {
          position: "bottom-right",
        });
      }
    }
  }, [getValues, isCodeSubmitted, lastPromiseInfo, onConnect, reset, result])

  const setAllowResendHandler = () => {
    setAllowResend(true)
  }

  const resendCodeHandler = () => {
    setAllowResend(false)
    const v = getValues()
    if (v) connectPhone({ workspace: workspace, account: account, data: { phone: v.phone } });
  }

  const changePhoneHandler = () => {
    resetField("confirm")
    setAllowResend(true)
    setIsCodeSubmitted(false)
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    if (!isCodeSubmitted) {
      connectPhone({ workspace: workspace, account: account, data: { phone: values.phone } });
    } else {
      values.confirm && verifyPhone({
        workspace: workspace,
        account: account,
        integration: data?.id || "",
        code: values.confirm
      })
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-floating mb-4">
            <input type="text" id="phoneInput" className="form-control" placeholder="Enter phone"
              disabled={isCodeSubmitted}
              {...register("phone")}/>
            <label className="form-label" htmlFor="phoneInput">
              {errors.phone?.message
                ? <small className="text-danger">{errors.phone?.message}</small>
                : <small className="text-secondary">Enter phone</small>
              }
            </label>
            <div className="text-end">
              {isCodeSubmitted && (
                <button type="button" className="btn btn-link text-decoration-none p-0"
                  onClick={changePhoneHandler}>
                  change phone
                </button>
              )}
            </div>
          </div>

          {(isCodeSubmitted) && (
            <div className="form-floating mb-4">
              <input type="text" id="confirmInput" className="form-control" placeholder="Enter confirm"
                {...register("confirm")}/>
              <label className="form-label" htmlFor="confirmInput">
                {errors.confirm?.message
                  ? <small className="text-danger">{errors.confirm?.message}</small>
                  : <small className="text-secondary">Enter confirm</small>
                }
              </label>
              <div className="text-end">
                <button type="button" className="btn btn-link text-decoration-none p-0"
                  disabled={!allowResend}
                  onClick={resendCodeHandler}>
                  resend code {!allowResend && <CountdownTimer value={"00:00:10"} onStop={setAllowResendHandler}/>}
                </button>
              </div>
            </div>
          )}

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">
              {(!isCodeSubmitted)
                ? <span>Connect phone</span>
                : <span>Verify phone</span>
              }
            </button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormPhoneConnect;
