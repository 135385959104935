import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../store/api/auth.api";
import { accountApi } from "../store/api/account.api";
import { workspaceApi } from "../store/api/workspace.api";
import { onCallAlertApi } from "../store/api/oncall.alert.api";
import { onCallIncidentApi } from "../store/api/oncall.incident.api";
import { onCallIntegrationApi } from "../store/api/oncall.integration.api";
import { onCallChatOpsApi } from "../store/api/oncall.chat_ops.api";
import { onCallUserApi } from "../store/api/oncall.user.api";
import { onCallTeamApi } from "../store/api/oncall.team.api";
import { integrationApi } from "../store/api/integration.api";
import { infrastructureApi } from "../store/api/infrastrucrture.api";
import sessionReducer from "../store/features/session.slice";
import accountReducer from "../store/features/account.slice";
import workspaceReducer from "../store/features/workspace.slice";
import onCallAlertReducer from "../store/features/oncall.alert.slice";
import onCallIncidentReducer from "../store/features/oncall.incident.slice";
import onCallIntegrationReducer from "../store/features/oncall.integration.slice";
import integrationReducer from "../store/features/integration.slice";
import onCallChatOpsReducer from "../store/features/oncall.chat_ops.slice";
import onCallUserReducer from "../store/features/oncall.user.slice";
import onCallTeamReducer from "../store/features/oncall.team.slice";
import infrastructureReducer from "../store/features/infrastructure.slice";

import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE, } from "redux-persist"


export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [workspaceApi.reducerPath]: workspaceApi.reducer,
    [onCallAlertApi.reducerPath]: onCallAlertApi.reducer,
    [onCallIncidentApi.reducerPath]: onCallIncidentApi.reducer,
    [onCallIntegrationApi.reducerPath]: onCallIntegrationApi.reducer,
    [onCallChatOpsApi.reducerPath]: onCallChatOpsApi.reducer,
    [onCallUserApi.reducerPath]: onCallUserApi.reducer,
    [onCallTeamApi.reducerPath]: onCallTeamApi.reducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
    [infrastructureApi.reducerPath]: infrastructureApi.reducer,
    accountState: accountReducer,
    sessionState: sessionReducer,
    workspaceState: workspaceReducer,
    onCallAlertState: onCallAlertReducer,
    onCallIncidentState: onCallIncidentReducer,
    onCallIntegrationState: onCallIntegrationReducer,
    onCallChatOpsState: onCallChatOpsReducer,
    onCallUserState: onCallUserReducer,
    integrationState: integrationReducer,
    onCallTeamState: onCallTeamReducer,
    infrastructureState: infrastructureReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      accountApi.middleware,
      workspaceApi.middleware,
      workspaceApi.middleware,
      onCallAlertApi.middleware,
      onCallIncidentApi.middleware,
      onCallIntegrationApi.middleware,
      onCallChatOpsApi.middleware,
      onCallUserApi.middleware,
      onCallTeamApi.middleware,
      integrationApi.middleware,
      infrastructureApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store)

const STORE = { store, persistor }

export default STORE
