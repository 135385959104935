import { FC, useEffect, useRef } from "react";
import { useInfoTelegramBotQuery, useConnectTelegramMutation } from "../../store/api/integration.api";
import { CopyBlock, paraisoLight } from "react-code-blocks";

interface IProps {
  workspace: string;
}

const FormUserTelegramConnect: FC<IProps> = ({ workspace }) => {
  const [connectTelegram, { data }] = useConnectTelegramMutation()
  const { data: botInfo } = useInfoTelegramBotQuery({ workspace: workspace })
  const firstRender = useRef<boolean>(false)

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      connectTelegram({ workspace: workspace })
    }
  })

  return (
    <div>
      <p>
        1. Go to <a
          href={`https://t.me/${botInfo?.username}`}
          className="text-primary" target="_blank" rel="noopener noreferrer">@{botInfo?.name}</a>
      </p>
      <p>2. Send this verification code to the bot and wait for the confirmation message:</p>
      <div className="mb-2">
        {
          data?.code && (
            <CopyBlock
              text={data?.code}
              language={"text"}
              showLineNumbers={false}
              startingLineNumber={1}
              theme={paraisoLight}
              codeBlock
            />
          )
        }
      </div>
      <p>3. Refresh the page and start to manage alerts in your personal Telegram.</p>
    </div>
  );
};

export default FormUserTelegramConnect;
