import React, { ChangeEvent, FC, useState } from "react";
import { TeamScheduleRotationRestrictions } from "../../../store/features/oncall.team.slice";
import FormRestrictionDay from "./FormRestrictionDay";
import FormRestrictionWeek from "./FormRestrictionWeek";

interface Time_restriction {
  type: string
  restrictions: TeamScheduleRotationRestrictions[]
}
export interface IProps {
  data: Time_restriction
  onChange: (time_restrictions: {type: string, restrictions: TeamScheduleRotationRestrictions[]}) => void
}

interface RestrictionState {
  [type: string]: TeamScheduleRotationRestrictions[]
}

const FormScheduleRestrict: FC<IProps> = ({ data, onChange }) => {
  const [restrictOn, setRestrictOn] = useState(!!data?.restrictions || false);
  const [restrictions, setRestrictions] = useState<RestrictionState>({ [data?.type]: data?.restrictions });

  function RestrictType(e: ChangeEvent<HTMLInputElement>) {
    const state = { type: e.target.value, restrictions: restrictions[e.target.value] || [] }
    onChange(state)
  }

  const changeRestrict = (e: ChangeEvent<HTMLInputElement>) => {
    setRestrictOn(e.target.checked)
    if (!restrictOn) {
      const state = { type: "time-of-day", restrictions: restrictions["time-of-day"] }
      onChange(state)
      return state
    }
    if (restrictOn) {
      const state = {} as Time_restriction
      onChange(state)
      return state
    }
  }

  const changeRestrictionsDay = (val: TeamScheduleRotationRestrictions[]) => {
    setRestrictions(state => {
      state["time-of-day"] = val
      onChange({ type: "time-of-day", restrictions: val })
      return state
    })
  }
  const changeRestrictionsWeek = (val: TeamScheduleRotationRestrictions[]) => {
    setRestrictions(state => {
      state["weekday-and-time-of-day"] = val
      onChange({ type: "weekday-and-time-of-day", restrictions: val })
      return state
    })
  }

  return (
    <div>
      <div className="form-check form-switch mb-2">
        <input
          checked={restrictOn} onChange={changeRestrict}
          className="form-check-input" type="checkbox" role="switch" id="restrictInput"
        />
        <label className="form-label mb-0" htmlFor="restrictInput">Restricts on time interval</label>
      </div>
      {restrictOn &&
        <div className="card">
          <div className="card-header d-flex">
            <div className="form-check">
              <input
                checked={data?.type === "time-of-day"}
                onChange={RestrictType}
                value="time-of-day"
                className="form-check-input" type="radio" name="restrict-type-picker" id="time-of-day"/>
              <label className="form-check-label" htmlFor="time-of-day">
                Time of day
              </label>
            </div>
            <div className="form-check mx-3">
              <input
                checked={data?.type === "weekday-and-time-of-day"}
                onChange={RestrictType}
                value="weekday-and-time-of-day"
                className="form-check-input" type="radio" name="restrict-type-picker" id="weekday-and-time-of-day"/>
              <label className="form-check-label" htmlFor="weekday-and-time-of-day">
                Weekday and time of day
              </label>
            </div>
          </div>
          <div className="card-body">
            {data?.type === "time-of-day" &&
              <FormRestrictionDay restrictions={restrictions["time-of-day"] || []} onChange={changeRestrictionsDay} />
            }
            {data?.type === "weekday-and-time-of-day" &&
              <FormRestrictionWeek
                restrictions={restrictions["weekday-and-time-of-day"] || []} onChange={changeRestrictionsWeek} />
            }
          </div>
        </div>
      }

    </div>
  );
};

export default FormScheduleRestrict;
