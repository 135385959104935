import React, { useEffect, useRef, useState } from "react";
import SidebarContent from "./SidebarContent";

const SidebarOver = () => {
  const [hide, setHide] = useState(true);
  const sideNavRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Add event listener to the document object
    const toggle = document.getElementById("toggle-sidebar-over")

    if (!hide) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      if (toggle) toggle.addEventListener("click", handleToggle)
    }

    // Remove event listener when the component unmounts
    return () => {
      if (!hide) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      if(toggle) toggle.removeEventListener("click", handleToggle)
    };
  });
  function handleClickOutside(event: Event) {
    const target = event.target as Element;
    if (sideNavRef.current && !sideNavRef.current.contains(target)) {
      setHide(true)
    }
  }
  const handleToggle = () => setHide(!hide);

  return (
    <>
      <div className={`sidebar sidebar-over d-flex flex-column flex-shrink-0 text-bg-dark mh-100 ${hide ? "hide" : ""}`}
        ref={sideNavRef}>

        <div className="mb-auto" onClick={handleToggle}>
          <SidebarContent/>
        </div>

        <ul className="nav nav-pills flex-column mb-1">
          <li className="nav-item d-flex">
            <button className="nav-link text-white cursor-pointer w-100" onClick={handleToggle}>
              <div className="text-start d-flex text-nowrap">
                <div className="text-center"><i className="fa fa-arrow-left"/></div>
                <span className="ms-2">&nbsp;Close sidebar</span>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};
export default SidebarOver;