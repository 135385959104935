import { ChangeEvent, FC, FocusEvent, useEffect } from "react";
import { useForm } from "react-hook-form";

import "./style.scss"

export interface IProps {
  value: string
  onChange: (s: string) => void
  className?: string
}

interface FormInputs {
  h: string
  m: string
}

const TimePicker: FC<IProps> = ({ className, value, onChange }) => {

  const { register, setValue, getValues } = useForm<FormInputs>();

  useEffect(() => {
    if (value) {
      const v = parseTime(value)
      setValue("h", v?.h || "00");
      setValue("m", v?.m || "00");
    }
  }, [setValue, value]);

  const handleChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.name === "h") setValue("h", e.target.value)
    if (e.target.name === "m") setValue("m", e.target.value)
  }

  const handleFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.name === "h") setValue("h", "")
    if (e.target.name === "m") setValue("m", "")
  }

  const handleBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    let val = parseInt(e.target.value, 10)

    if (e.target.name === "h") {
      let h = "00"
      if (val && val >= 0) {
        if (val >= 24) val %= 24
        h = (val < 10) ? "0" + val : val.toString()
      }
      setValue("h", h)
      onChangeEvent()
      return;
    }

    if (e.target.name === "m") {
      let m = "00"
      if (val && val >= 0) {
        if (val >= 60) val %= 60
        m = (val < 10) ? "0" + val : val.toString()
      }
      setValue("m", m)
      onChangeEvent()
      return;
    }
  };

  function onChangeEvent() {
    const values = getValues()
    let s = ""
    if (values.h) s += `${values.h}h`
    if (values.m) s += `${values.m}m`
    if (!s) s = "00h00m"
    onChange(s)
  }

  return (
    <div className={`timepicker ${className}`}>
      <input type="text" className="hh" min="0" max="23" maxLength={2} placeholder="hh"
        {...register("h")}
        onChange={handleChangeEvent}
        onFocus={handleFocusEvent}
        onBlur={handleBlurEvent}
      />:
      <input type="text" className="mm" min="0" max="59" maxLength={2} placeholder="mm"
        {...register("m")}
        onChange={handleChangeEvent}
        onFocus={handleFocusEvent}
        onBlur={handleBlurEvent}
      />
    </div>
  );
};

// Parse formats:
// ' 01:02:05 '
// '01:02:05'
// '01:02'
// '1h10m'
// '2h'
// '5m'
// '5 h 10 m'
// '5 h'
// '5 m'
function parseTime(str: string): { h: string, m: string } {
  str = str.trim()

  if (str.indexOf(":") > -1) {
    const time = str.match(/(\d+)(?::(\d\d))?(?::(\d\d))?\s*(p?)/i);
    if (!time) {
      return { h: "00", m: "00" };
    }
    let hours = parseInt(time[1], 10);
    if (hours === 12 && !time[4]) {
      hours = 12;
    } else {
      hours += (hours < 12 && time[4]) ? 12 : 0;
    }
    let h = hours.toString()
    let m = time && time.length >= 2 ? time[2] : "00"

    if (h.length === 1) h = "0" + h
    if (m.length === 1) m = "0" + m

    return { h, m }
  }

  const hours = str.match(/(\d+)(\s*)?h/);
  const minutes = str.match(/(\d+)(\s*)?m/);

  let h = (hours && hours.length >= 1) ? hours[1] : "00"
  let m = (minutes && minutes.length >= 1) ? minutes[1] : "00"

  if (h.length === 1) h = "0" + h
  if (m.length === 1) m = "0" + m

  return { h, m }
}

export default TimePicker;
