import { ChangeEvent, FC, useEffect, useState } from "react";
import { OnChangeValue } from "react-select";
import { FormFilterRules } from "../index";
import { InfrastructureChannel, InfrastructureChannelRulesFilter } from "../../../store/features/infrastructure.slice";

export interface IProps {
  workspace: string
  data?: InfrastructureChannel
  onSubmit: (data: any) => void
  onCancel?: () => void
}
const conditionOptions = {
  field: [
    { value: "alert:priority", label: "Alert priority" },
    { value: "pattern", label: "Pattern" },
    { value: "integration:name", label: "Integration name" },
  ],
  not: [{ value: true, label: "NOT" }, { value: false, label: "IS" }],
  operation: {
    "alert:priority": [{ value: "equals", label: "Equals" }],
    "pattern": [{ value: "matches", label: "Matches" }],
    "integration:name": [{ value: "equals", label: "Equals" }, { value: "any", label: "Any" }, {
      value: "contains",
      label: "Contains"
    }],
  },
  priorityValue: [{ value: "critical", label: "Critical" }, { value: "warning", label: "Warning" }, {
    value: "info",
    label: "Info"
  }, { value: "none", label: "None" }],
}

export interface FormData {
  name: string
  filter: InfrastructureChannelRulesFilter
}

const FornChannelRules: FC<IProps> = ({ data, workspace, onSubmit, onCancel }) => {
  const [stateValue, setStateValue] = useState<FormData>(definePropertyIfNotExists(data))

  useEffect(() => {
    setStateValue(definePropertyIfNotExists(data))
  }, [data, setStateValue])

  const changeNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setStateValue(state => ({ ...state, name: e.target.value }))
  }

  function changeFilterHandler(val: OnChangeValue<any, any>) {
    setStateValue(state => ({ ...state, filter: val }))
  }

  const cancelHandler = () => {
    onCancel && onCancel()
  }

  const applyHandler = () => {
    onSubmit(stateValue)
  }

  return (
    <div className="form-escalation-policy">
      <div className="mb-2">
        <label htmlFor="nameInput" className="form-label m-0">Enter name</label>
        <input name="name" type="text" className="form-control my-2"
          placeholder="Enter Name"
          value={stateValue.name}
          onChange={changeNameHandler}/>
      </div>

      <div className="mb-2">
        <label htmlFor="filterForm" className="form-label m-0">Filters</label>
        <FormFilterRules workspace={workspace}
          item={stateValue?.filter || {}}
          options={conditionOptions}
          onChange={changeFilterHandler}/>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <button className="btn btn-danger" onClick={cancelHandler}>Cancel</button>
        <button className="btn btn-primary" onClick={applyHandler} disabled={stateValue.name.length < 1}>Apply</button>
      </div>
    </div>
  );
};

function definePropertyIfNotExists(data: any) {
  let newData = data ? { ...data } : {}
  if (!newData.name) newData.name = "";
  if (!newData.filter) newData.filter = { type: "match-all", conditions: [] };
  return newData;
}

export default FornChannelRules;
