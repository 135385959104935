import React, { useEffect, useState, useRef } from "react";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  const sideNavRef = useRef<HTMLInputElement>(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mini, setMini] = useState(windowWidth < 1200);
  useEffect(() => {
    const handleWindowResize = () => {
      if (windowWidth !== window.innerWidth) {
        setMini(windowWidth < 1200)
      }

      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  useEffect(() => {
    // Add event listener to the document object
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  function handleClickOutside(event: Event) {
    const target = event.target as Element;
    if (windowWidth > 1200) return;
    if (sideNavRef.current && !sideNavRef.current.contains(target)) {
      setMini(windowWidth < 1200)
    }
  }

  const handleToggle = () => setMini(!mini);

  return (
    <>
      <div
        className={`sidebar d-flex flex-column flex-shrink-0 text-bg-dark mh-100 ${mini ? "mini" : ""}`}
        ref={sideNavRef}>

        <SidebarContent/>

        <div>
          <ul className="nav nav-pills flex-column mb-1">
            <li className="nav-item d-flex">
              <button className="nav-link text-white cursor-pointer w-100" onClick={handleToggle}>
                <div className="text-start d-flex text-nowrap">
                  <div className="text-center toggle-icon"><i className="fa fa-arrow-left"/></div>
                  <span className="ms-2">&nbsp;Collapse sidebar</span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
