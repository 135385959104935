import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  IntegrationFetchRequest,
  IntegrationListRequest,
  IntegrationCreateRequest,
  IntegrationUpdateRequest,
  IntegrationResponse,
  ProviderResponse,
  IntegrationChatRequest,
  IntegrationWatchdogResponse,
  IntegrationWatchdogRequest,
  IntegrationChatCreateRequest,
  IntegrationChatRemoveRequest,
  IntegrationChatResponse,
  IntegrationResponderRequest,
  IntegrationRulesUpdateRequest,
  IntegrationTemplateListRequest,
  IntegrationTemplateResponse,
  CreateIntegrationTemplateRequest,
  RemoveIntegrationTemplateRequest,
  UpdateIntegrationTemplateRequest
} from "./types/oncall"
import {
  setIntegration,
  setIntegrationChatList,
  setIntegrationList,
  setIntegrationChat,
  removeIntegrationChat,
  updateIntegrationChat,
  setIntegrationTemplateList,
  setIntegrationTemplate,
  removeIntegrationTemplate,
  updateIntegrationTemplate
} from "../features/oncall.integration.slice";

const transformResponse = (item: IntegrationResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformResponseChats = (item: IntegrationChatResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformResponseWatchdog = (item: IntegrationWatchdogResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}

export const onCallIntegrationApi = createApi({
  reducerPath: "onCallIntegrationApi",
  baseQuery: customFetchBase,
  tagTypes: ["OnCallIntegrations"],
  endpoints: (builder) => ({
    listIntegration: builder.query<IntegrationResponse[], IntegrationListRequest>({
      query(req: IntegrationListRequest) {
        const uri =  req.infrastructure
          ? `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/integration`
          : `v1/workspace/${req.workspace}/oncall/integration`
        return {
          url: uri,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: IntegrationResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "OnCallIntegrations" as const, id })),
            { type: "OnCallIntegrations", id: "LIST" },
          ]
          : [{ type: "OnCallIntegrations", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegrationList(data));
        } catch (error) {
        }
      },
    }),
    fetchIntegration: builder.query<IntegrationResponse, IntegrationFetchRequest>({
      query(req: IntegrationFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}`,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegration(data));
        } catch (error) {
        }
      },
    }),
    createIntegration: builder.mutation<IntegrationResponse, IntegrationCreateRequest>({
      query(req: IntegrationCreateRequest) {
        const uri =  req.infrastructure
          ? `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/integration`
          : `v1/workspace/${req.workspace}/oncall/integration`
        return {
          url: uri,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegration(data));
        } catch (error) {
        }
      },
    }),
    updateIntegration: builder.mutation<IntegrationResponse, IntegrationUpdateRequest>({
      query(req: IntegrationUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegration(data));
        } catch (error) {
        }
      },
    }),
    listProvider: builder.query<ProviderResponse[], void>({
      query() {
        return {
          url: "v1/oncall/provider",
          credentials: "include",
        };
      },
      transformResponse: (response: { items: ProviderResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
    }),
    respondIntegration: builder.mutation<IntegrationResponse, IntegrationResponderRequest>({
      query(req: IntegrationResponderRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/responder`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegration(data));
        } catch (error) {
        }
      },
    }),
    fetchIntegrationChatList: builder.query<IntegrationChatResponse[], IntegrationChatRequest>({
      query(req: IntegrationChatRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/chat`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: IntegrationChatResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponseChats)
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegrationChatList({ integration: args.integration, chats: data }));
        } catch (error) {
        }
      },
    }),
    createIntegrationChat: builder.mutation<IntegrationChatResponse, IntegrationChatCreateRequest>({
      query(req: IntegrationChatCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/chat`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponseChats,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegrationChat( { integration: args.integration, chat: data }));
        } catch (error) {
        }
      },
    }),
    deleteIntegrationChat: builder.mutation<void, IntegrationChatRemoveRequest>({
      query(req: IntegrationChatRemoveRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/chat/${req.chat}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeIntegrationChat({ integration: args.integration ,chat: args.chat }));
        } catch (error) {
        }
      },
    }),
    updateIntegrationRules: builder.mutation<IntegrationChatResponse, IntegrationRulesUpdateRequest>({
      query(req: IntegrationRulesUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/chat/${req.chat}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateIntegrationChat({ integration: args.integration, chat: data }));
        } catch (error) {
        }
      },
    }),
    fetchIntegrationWatchdog: builder.query<IntegrationWatchdogResponse, IntegrationWatchdogRequest>({
      query(req: IntegrationWatchdogRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/watchdog`,
          credentials: "include",
        };
      },
      transformResponse: transformResponseWatchdog,
    }),
    updateIntegrationWatchdog: builder.mutation<IntegrationWatchdogResponse, IntegrationWatchdogRequest>({
      query(req: IntegrationWatchdogRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/watchdog`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
    }),
    createIntegrationTemplate: builder.mutation<IntegrationTemplateResponse, CreateIntegrationTemplateRequest>({
      query(req: CreateIntegrationTemplateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/template`,
          method: "POST",
          body: { ...req.data, payload: window.btoa(req.data.payload) },
          credentials: "include",
        };
      },
      transformResponse: (item: IntegrationTemplateResponse) => {
        return { ...item, payload: window.atob(item.payload) }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegrationTemplate(data));
        } catch (error) {
        }
      },
    }),
    listIntegrationTemplate: builder.query<IntegrationTemplateResponse[], IntegrationTemplateListRequest>({
      query(req: IntegrationTemplateListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/template`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: IntegrationTemplateResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(item => {
          return { ...item, payload: window.atob(item.payload) }
        })
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIntegrationTemplateList(data));
        } catch (error) {
        }
      },
    }),
    updateIntegrationTemplateInfo: builder.mutation<IntegrationTemplateResponse, UpdateIntegrationTemplateRequest>({
      query(req: UpdateIntegrationTemplateRequest) {
        return {
          url:
            `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/template/${req.template}`,
          method: "PUT",
          body: { ...req.data, payload: window.btoa(req.data.payload) },
          credentials: "include",
        };
      },
      transformResponse: (item: IntegrationTemplateResponse) => {
        return { ...item, payload: window.atob(item.payload) }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateIntegrationTemplate(data));
        } catch (error) {
        }
      },
    }),
    deleteIntegrationTemplate: builder.mutation<void, RemoveIntegrationTemplateRequest>({
      query(req: RemoveIntegrationTemplateRequest) {
        return {
          url:
            `v1/workspace/${req.workspace}/oncall/integration/${req.integration}/template/${req.template}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeIntegrationTemplate( { integration: args.integration, template: args.template }));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListIntegrationQuery,
  useFetchIntegrationQuery,
  useCreateIntegrationMutation,
  useUpdateIntegrationMutation,
  useListProviderQuery,
  useRespondIntegrationMutation,
  useFetchIntegrationChatListQuery,
  useCreateIntegrationChatMutation,
  useDeleteIntegrationChatMutation,
  useFetchIntegrationWatchdogQuery,
  useUpdateIntegrationWatchdogMutation,
  useUpdateIntegrationRulesMutation,
  useLazyListIntegrationQuery,
  useListIntegrationTemplateQuery,
  useCreateIntegrationTemplateMutation,
  useDeleteIntegrationTemplateMutation,
  useUpdateIntegrationTemplateInfoMutation
} = onCallIntegrationApi;

