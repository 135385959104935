import { FC } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";

export interface IQuery {
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  error?: SerializedError | FetchBaseQueryError | undefined
}

export interface IProps {
  query: IQuery
  children: JSX.Element | JSX.Element[]
  loader: JSX.Element
  fallback: JSX.Element
}

const Suspense: FC<IProps> = ({ query, children, loader, fallback }) => {
  const navigate = useNavigate();

  if (query.isLoading) return loader;
  if (query.isError) {
    if (query.error && "status" in query.error) {
      if ((query.error as FetchBaseQueryError).status === 404) {
        navigate("/404")
        return null
      }
    }
    return fallback
  }

  return (
    <div>
      {children}
    </div>
  );
}

export default Suspense;
