import { OnCallIncidentList } from "./../../components/oncall"
import { Breadcrumbs } from "../../components";
import React from "react";

const OnCallIncidentListPage = () => {
  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "incidents", slug: "incidents" }]}/>
      </div>
      <div className="mb-4">
        <h2>Incident List</h2>
      </div>
      <div>
        <OnCallIncidentList/>
      </div>
    </div>
  );
};

export default OnCallIncidentListPage;
