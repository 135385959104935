import { FC } from "react";
import { FormProvider, SubmitHandler, useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { object, string, TypeOf } from "zod";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useLazySearchChatListQuery } from "../../store/api/integration.api";
import { WorkspaceIntegrations } from "../../store/features/integration.slice";

interface SelectOption {
  label: string;
  value: string;
}

const FormSchema = object({
  name: string()
    .min(1, "Name is required"),
  service: string()
    .min(1, "Service is required"),
  chat: string()
    .min(1, "Chat is required"),
});

export type FormData = TypeOf<typeof FormSchema>;

interface ChannelData {
  name: string
  service: string
  chat: string
}

export interface IProps {
  integrations: WorkspaceIntegrations
  workspace: string
  onSubmit?: (data: ChannelData) => void;
  onCancel?: () => void
}

const FormChannelConnect: FC<IProps> = ({ integrations, workspace, onSubmit, onCancel }) => {
  const [searchQuery, result] = useLazySearchChatListQuery()

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    resetField,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const {
    field: {
      value: integrationValue,
      onChange: integrationOnChange,
      ...restLangIntegrationField
    }
  } = useController({ name: "service", control });

  const { field: { value: chatValue, onChange: chatOnChange, ...restLangChatField } } = useController({
    name: "chat",
    control
  });

  const providerOptions = Object.keys(integrations).map((key) => {
    const item = integrations[key]
    return { value: item.id, label: key }
  });

  const changeServiceHandler = (option: any) => {
    resetField("chat");
    integrationOnChange((option as SelectOption).value)
  }

  const changeChatHandler = (option: any) => {
    chatOnChange((option as SelectOption).value)
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    onSubmit && onSubmit(values)
  };

  const chatPromiseOptions = (inputValue: string) => {
    const provider = Object.keys(integrations)
      .find(key => integrations[key].id === getValues("service"))

    return new Promise<SelectOption[]>((resolve) => {
      if (!getValues("service")) {
        return resolve([])
      }
      searchQuery({
        workspace: workspace,
        provider: provider || "",
        service: getValues("service"),
        filter: { search: inputValue || "", limit: 25 }
      })
        .then((v) => {
          if (v?.error) return resolve([])
          resolve((v?.data || []).map(item => ({ value: item.id, label: item.name })));
        })
    });
  };

  const chatOptions = (result?.data || []).map(item => ({ value: item.id, label: item.name }));

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="form-floating mb-4">
            <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}/>
            <label className="form-label" htmlFor="nameInput">
              {errors.name?.message
                ? <small className="text-danger">{errors.name?.message}</small>
                : <small className="text-secondary">Enter name</small>
              }
            </label>
          </div>

          <div className="form-floating mb-4">
            <Select
              className="select-input"
              placeholder="Choose service"
              options={providerOptions}
              value={integrationValue ? providerOptions.find(x => x.value === integrationValue) : integrationValue}
              onChange={changeServiceHandler}
              {...restLangIntegrationField}
            />
            {errors.service && <small className="text-danger">{errors.service?.message}</small>}
          </div>

          <div className="form-floating mb-4">
            <AsyncSelect
              key={"chat_" + (getValues("service") || "")}
              className="select-input"
              placeholder="Choose chat"
              defaultOptions
              loadOptions={chatPromiseOptions}
              value={chatValue ? chatOptions.find(x => x.value === chatValue) : chatValue}
              onChange={changeChatHandler}
              {...restLangChatField}
            />
            {errors.chat && <small className="text-danger">{errors.chat?.message}</small>}
          </div>
          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-sm btn-primary">Create</button>
            <button type="reset" className="btn btn-sm btn-outline-secondary" onClick={() => onCancel && onCancel()}>
              Cancel
            </button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormChannelConnect;
