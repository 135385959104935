import {
  FormIntegrationBitbucketConnect,
  FormIntegrationDiscordConnect,
  FormIntegrationGithubConnect,
  FormIntegrationGitlabConnect,
  FormIntegrationSlackConnect,
  FormIntegrationTelegramConnect
} from "../../forms";

export interface IntegrationItem {
  id?: string;
  name: string;
  provider: string;
  icon: string;
  connected: boolean,
  component: any;
}

export const IntegrationElems: IntegrationItem[] = [
  {
    name: "Slack",
    provider: "slack",
    icon: "fa-brands fa-slack",
    connected: false,
    component: FormIntegrationSlackConnect
  },
  {
    name: "Discord",
    provider: "discord",
    icon: "fa-brands fa-discord",
    connected: false,
    component: FormIntegrationDiscordConnect,
  },
  {
    name: "Telegram",
    provider: "telegram",
    icon: "fa-brands fa-telegram",
    connected: false,
    component: FormIntegrationTelegramConnect,
  },
  {
    name: "GitHub",
    provider: "github",
    icon: "fa-brands fa-github",
    connected: false,
    component: FormIntegrationGithubConnect,
  },
  {
    name: "GitLab",
    provider: "gitlab",
    icon: "fa-brands fa-gitlab",
    connected: false,
    component: FormIntegrationGitlabConnect,
  },
  {
    name: "BitBucket",
    provider: "bitbucket",
    icon: "fa-brands fa-bitbucket",
    connected: false,
    component: FormIntegrationBitbucketConnect,
  },
];
