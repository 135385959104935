import { Outlet } from "react-router-dom";
import Header from "./navigation/Header";

const AccountLayout = () => {
  return (
    <div className="vh-100">
      <div className="header-content d-inline-block">
        <Header/>
      </div>
      <div className="container-fluid px-4 container-info">
        <Outlet/>
      </div>
    </div>
  );
};

export default AccountLayout;
