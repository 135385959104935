import { FC } from "react";
import Tooltip from "../Tooltip";

type Labels = Record<string, string>

interface Alert {
  annotations?: Labels
  endsAt?: string
  fingerprint?: string
  generatorURL?: string
  labels?: Labels
  startsAt?: string
  status: string
}

type AlertItem = {
  status: string,
  labels: Labels
}

interface IProps {
  payload?: {
    alerts?: Alert[],
    commonAnnotations?: { string: string },
    commonLabels?: Labels,
    externalURL?: string
    groupKey?: string
    groupLabels?: { string: string },
    receiver?: string
    status?: string
    truncatedAlerts?: number
    version?: string
  }
}

const statuses: {[key: string]: string} = {
  "firing": "bg-danger",
  "resolved": "bg-success",
}

const OnCallAlertManagerPayload: FC<IProps> = ({ payload }) => {
  const backgrounds = ["bg-danger", "bg-primary", "bg-secondary", "bg-success", "bg-warning"];
  let random: number = -1

  const randomBackground = () => {
    random = (random === backgrounds.length - 1) ? 0 : random + 1
    return backgrounds[random]
  }

  function alertStatusType(status: string) {
    return statuses[status.toLowerCase()] ?? "bg-secondary"
  }

  function filterLabels(commonLabels: Labels, items: Labels) {
    let labels: Record<string, string> = {};
    Object.keys(items).forEach((key) => {
      if (Object.hasOwn(commonLabels, key)) return null
      labels[key] = items[key];
    });
    return labels
  }

  let existsLabels = false
  const alerts: AlertItem[] = (payload?.alerts || []).map((item) => {
    const labels = filterLabels(payload?.commonLabels ?? {}, item?.labels ?? {})
    if (!Object.keys(labels).length) return { status: item.status, labels: {} };
    existsLabels = true;
    return { status: item.status, labels: labels }
  })

  if (!existsLabels) return <div>&mdash;</div>;

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col" className="col-1 p-1 text-center">Status</th>
            <th scope="col" className="col-11 p-1 text-center">Instances</th>
          </tr>
        </thead>
        <tbody>
          {alerts.map((item, index) => {
            return (
              <tr key={index} className="row-fluid">
                <td className="p-1">
                  <Tooltip placement="top" text={`status: ${item.status}`} children={
                    <div className="d-flex justify-content-center align-items-center pt-2">
                      <div className={`alert-status ${alertStatusType(item.status)}`}/>
                    </div>
                  }></Tooltip>
                </td>
                <td className="p-1">
                  {Object.keys(item.labels).map(key => (
                    <span title={`${key}:${item.labels[key]}`} key={key}
                      className={`alert-badge badge me-2 ${randomBackground()}`}>
                      {key}:{item.labels[key]}
                    </span>
                  ))}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OnCallAlertManagerPayload;