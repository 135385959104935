import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateIntegrationMutation } from "../../store/api/oncall.integration.api";
import { useAppSelector } from "../../app/hooks";

const FormSchema = object({
  name: string()
    .min(1, "Name can not be empty"),
  description: string()
    .max(1024, "Email is not valid")
    .optional(),
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string
  integration: string
}

const FormOnCallIntegrationInfo: FC<IProps> = ({ workspace, integration }) => {
  const integrationState = useAppSelector((state) => state.onCallIntegrationState);
  const editIntegration = integrationState.integrations[integration]
  const [updateIntegration, { isError, error, isSuccess }] = useUpdateIntegrationMutation();

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("name", editIntegration?.name || "")
    setValue("description", editIntegration?.description || "")
  }, [editIntegration, setValue])

  useEffect(() => {
    if (isSuccess) {
      toast.success("Update successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    updateIntegration({
      workspace,
      integration,
      data: values
    });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="mb-2">
            <label className="form-label" htmlFor="nameInput">Name</label>
            <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}/>
            {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="descriptionInput">Description</label>
            <textarea id="descriptionInput" className="form-control" placeholder="Enter description" rows={4}
              {...register("description")}/>
            {errors.description?.message && <small className="text-danger">{errors.description?.message}</small>}
          </div>

          <div className="d-flex">
            <button type="submit" className="btn btn-primary">Apply</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormOnCallIntegrationInfo;
