import { FC, useEffect, useRef, useState } from "react"

export interface IProps {
  // hh:mm:ss
  value: string
  onStop?: () => void
  className?: string
}

const CountdownTimer: FC<IProps> = ({ value, onStop, className }) => {

  const idRef = useRef<number | null>(null);
  const [timer, setTimer] = useState(parseTime(value));
  const [timerString, setTimerString] = useState(value);

  const clear = () => {
    if (idRef.current) {
      window.clearInterval(idRef.current);
    }
  };

  useEffect(() => {
    idRef.current = window.setInterval(() => {
      setTimer((time) => {
        const v = time - 1
        setTimerString(toHHMMSS(v))
        return v
      });
    }, 1000);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
      onStop && onStop()
    }
  }, [onStop, timer]);

  useEffect(() => {
    setTimer(parseTime(value))
    setTimerString(value)
  }, [value]);

  return (
    <span className={className}>{timerString}</span>
  )
}

function parseTime(v: string) {
  const a = v.split(":");
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}

function toHHMMSS(v: number) {
  const hours = Math.floor(v / 3600);
  const minutes = Math.floor((v - (hours * 3600)) / 60);
  const seconds = v - (hours * 3600) - (minutes * 60);
  const hh = (hours < 10) ? "0" + hours : hours
  const mm = (minutes < 10) ? "0" + minutes : minutes
  const ss = (seconds < 10) ? "0" + seconds : seconds
  return `${hh}:${mm}:${ss}`;
}

export default CountdownTimer;
