import React, { FC } from "react";
import { useAppSelector } from "../../app/hooks";
import moment from "moment/moment";
import {
  useAckAlertMutation,
  useAssignResponderMutation,
  useRemoveResponderMutation,
  useUnAckAlertMutation,
  useUpdateAlertMutation
} from "../../store/api/oncall.alert.api";
import AlertEventsContainer from "./OnCallAlertList/AlertEvents.container";
import AlertPayload from "./OnCallAlertList/AlertPayload";
import { Membership } from "../../store/features/workspace.slice";
import { FormOnCallAlertStatusChange } from "../forms";
import FormResponders, { Responder } from "../forms/FormResponders";
import Suspense from "../Suspense";
import { useListTeamQuery } from "../../store/api/oncall.team.api";
import Loader from "./OnCallIntegrationList/Loader";

type IProps = {
  workspace: string
  alert: string
};

const OnCallAlertInfo: FC<IProps> = ({ workspace, alert }) => {
  const alertState = useAppSelector((state) => state.onCallAlertState);
  const integrationState = useAppSelector((state) => state.onCallIntegrationState)?.integrations;
  const membershipState = useAppSelector((state) => state.workspaceState)?.memberships[workspace];
  const teamListQuery = useListTeamQuery({ workspace: workspace });
  const teams = useAppSelector((state) => state.onCallTeamState).teams;
  const [updateAlert] = useUpdateAlertMutation();
  const [assignResponder] = useAssignResponderMutation();
  const [removeResponder] = useRemoveResponderMutation();
  const [ackAlert] = useAckAlertMutation();
  const [unAckAlert] = useUnAckAlertMutation();

  function assignResponderHandler(responder: Responder) {
    assignResponder({ workspace, alert, data: { responder: responder.id, kind: responder.kind } })
  }

  function removeResponderHandler(responder: Responder) {
    removeResponder({ workspace, alert, responder: responder.id })
  }

  function alertStatusType(item: string) {
    if (item) {
      const status = item.toLowerCase();
      switch (status) {
        case "resolved":
          return "bg-success"
        default:
          return "bg-danger"
      }
    }
  }

  function alertPriorityType(item: string) {
    if (item) {
      const priority = item.toLowerCase();
      switch (priority) {
        case "warning":
          return "text-warning"
        case "critical":
          return "text-danger"
        case "info":
          return "text-info"
        default:
          return "text-secondary"
      }
    }
  }

  const backgrounds = ["bg-danger", "bg-primary", "bg-secondary", "bg-success", "bg-info", "bg-warning"];
  let random: number = -1
  const randomBackground = () => {
    if (random === backgrounds.length - 1) {
      random = -1
    }
    random++
    return backgrounds[random]
  }

  const updateAlertHandler = (alert: string, status: string) => {
    const updates = { workspace, alert }
    switch (status) {
      case "resolved":
        updateAlert(updates);
        break;
      case "acknowledged":
        ackAlert(updates)
        break;
      case "unack":
        unAckAlert(updates);
        break;
    }
  };

  const options: Responder[] = []
  const responders: Responder[] = []

  const data = alertState.alerts[alert]
  if (!data) return <h1>Can not load data</h1>

  const mbsp = {} as { [key: string]: Membership }
  membershipState?.forEach(m => {
    options.push({ id: m.account, email: m.email, name: m.username, kind: "user" })
    mbsp[m.account] = m
  })
  Object.keys(teams).forEach(key => {
    options.push({ id: teams[key].id, name: teams[key].name, kind: "team" })
  })
  data?.responders?.forEach(r => {
    if (r.kind === "user" && mbsp[r.id])
      responders.push({ id: r.id, email: mbsp[r.id].email, name: mbsp[r.id].username, kind: "user" })
    if (r.kind === "team" && teams[r.id])
      responders.push({ id: r.id, name: teams[r.id].name, kind: "team" })
  })

  return (
    <div>
      <div className="sticky-top bg-white m-0 w-100 p-0">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="text-truncate d-flex align-items-center fs-5">
            <p className="mb-0" title={data?.title}>Name: {data.title}</p>
          </div>
        </div>
        <nav className="mt-2">
          <div className="nav nav-tabs m-0 p-0" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-description-tab" data-bs-toggle="tab"
              data-bs-target="#nav-description"
              type="button" role="tab" aria-controls="nav-description" aria-selected="true">Description
            </button>
            <button className="nav-link" id="nav-timeline-tab" data-bs-toggle="tab" data-bs-target="#nav-timeline"
              type="button" role="tab" aria-controls="nav-timeline" aria-selected="false">TimeLine
            </button>
            <button className="nav-link" id="nav-payload-tab" data-bs-toggle="tab" data-bs-target="#nav-payload"
              type="button" role="tab" aria-controls="nav-payload" aria-selected="false">Payload
            </button>
          </div>
        </nav>
      </div>
      <div className="tab-content mt-2" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-description" role="tabpanel"
          aria-labelledby="nav-description-tab">
          <div className="row-fluid d-flex flex-wrap text-secondary align-items-center py-2">
            <div className="col-12 col-md-2">Number</div>
            <div className={"col-12 col-md-9"}>
              <span className={"text-uppercase text-secondary mb-0"}>{data?.number}</span>
            </div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary align-items-center py-2">
            <div className="col-12 col-md-2">Elapsed time</div>
            <div className={"col-12 col-md-9"}>
              <span className="text-secondary mb-0">
                {moment.duration(
                  moment(data.closed_at ? data.closed_at : moment()).diff(moment(data?.created_at))).humanize()
                }
              </span>
            </div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary align-items-center py-2">
            <div className="col-12 col-md-2">Updated at</div>
            <div className={"col-12 col-md-9"}>
              <span className="text-secondary mb-0">
                {moment.duration(moment(data.updated_at ? data.updated_at : "").diff(moment())).humanize() + " ago"}
              </span>
            </div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary align-items-center py-2">
            <p className="col-12 col-md-2 mb-0">Responders</p>
            <div className="col-12 col-md-9">
              <div className="incident-responder d-flex align-items-center">
                <Suspense query={teamListQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
                  <FormResponders responders={responders} options={options}
                    onSelect={assignResponderHandler}
                    onRemove={removeResponderHandler}/>
                </Suspense>
              </div>
            </div>
          </div>
          <div className="row-fluid flex-wrap d-flex text-secondary align-items-center py-2">
            <div className="col-12 col-md-2 mb-0">Priority</div>
            <div className={"col-12 col-md-9 d-flex align-items-center"}>
              <span className={"text-uppercase text-secondary mb-0"}><i
                className={alertPriorityType(data?.priority) + " fas fa-flag me-2"}/>{data?.priority}</span>
            </div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary align-items-center py-2">
            <div className="col-12 col-md-2 mb-0">Status</div>
            <div className="col-12 col-md-9 d-flex align-items-center">
              <div className="btn-group">
                <div className={`${alertStatusType(data.status)} rounded text-center cursor-pointer px-2 btn btn-sm`}
                  data-bs-toggle="dropdown" aria-expanded="false"
                  data-bs-offset="30,-20">
                  <span className="text-white me-2">{data.status}</span>
                  <FormOnCallAlertStatusChange item={data} onHandler={updateAlertHandler}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <p className="col-12 col-md-2 mb-0">Count</p>
            <p className="col-12 col-md-9 mb-0">{data.count}</p>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <p className="col-12 col-md-2 mb-0">Integration</p>
            <p className="col-12 col-md-9 mb-0">
              <span>{integrationState[data.integration]?.name}</span>
            </p>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 col-md-2 mb-0">Service</div>
            <div className="col-12 col-md-9 mb-0">{data.service}</div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 col-md-2 mb-0">Created at</div>
            <div className="col-12 col-md-9 mb-0">{moment(data.created_at).format("MMM DD, YYYY HH:mm a")}</div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 col-md-2 mb-0">Description</div>
            <div className="col-12 col-md-9 mb-0">{data.description}</div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 col-md-2 mb-0">Extra properties</div>
            <div className="col-12 col-md-9 mb-0">
              {data.payload?.commonLabels &&
                <span>
                  {Object.keys(data.payload?.commonLabels).map((key) => {
                    if (key === "prometheus") return null
                    const commonLabels = data.payload?.commonLabels ?? {} as Record<string, string>;
                    return <span key={key}
                      className={"badge me-2 text-truncate " + randomBackground()}>{key} : {commonLabels[key]}</span>
                  })}
                </span>
              }
            </div>
          </div>
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 col-md-2 mb-0">Matching instances</div>
            <div className="col-12 col-md-9 mb-0">
              {data.service && <AlertPayload service={data.service} payload={data.payload}/>}
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-timeline" role="tabpanel" aria-labelledby="nav-timeline-tab">
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 mb-0">
              <AlertEventsContainer workspace={workspace} integration={data.integration} alert={data.id}/>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="nav-payload" role="tabpanel" aria-labelledby="nav-payload-tab">
          <div className="row-fluid d-flex flex-wrap text-secondary py-2">
            <div className="col-12 mb-0 p-2 bg-light rounded">
              <code className="text-dark">
                <pre>{JSON.stringify(data.payload, null, 2)}</pre>
              </code>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnCallAlertInfo;
