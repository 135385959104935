import { OnCallUserList } from "../../components/oncall";
import { FormOnCallUserAdd } from "../../components/forms";
import React from "react";
import { Breadcrumbs } from "../../components";

const OnCallUserListPage = () => {
  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "users", slug: "users" }]}/>
      </div>
      <div className="mb-4">
        <h2>User List</h2>
      </div>
      <div className="col-4 mb-3">
        <FormOnCallUserAdd/>
      </div>
      <div>
        <OnCallUserList/>
      </div>
    </div>
  );
};

export default OnCallUserListPage;
