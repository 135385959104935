import React from "react";
import PagePreloader from "../PagePreloader";
import { useListWorkspaceQuery } from "../../store/api/workspace.api";
import { Navigate, useLocation } from "react-router-dom";

type IWorkspaceMiddleware = {
  children: React.ReactElement;
};

const WorkspaceMiddleware: React.FC<IWorkspaceMiddleware> = ({ children }) => {
  const location = useLocation()

  const { isLoading, isSuccess, data } = useListWorkspaceQuery();
  if (isLoading) {
    return <PagePreloader/>;
  }

  if (isSuccess && (!data || !data.length) && location.pathname !== "/onboarding") {
    return <Navigate to={"/onboarding"}/>;
  }

  return children;
};

export default WorkspaceMiddleware;
