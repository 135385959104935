import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type { IncidentFetchRequest, IncidentListRequest, IncidentResponse } from "./types/oncall"
import { setIncident, setIncidentList } from "../features/oncall.incident.slice";

const transformResponse = (item: IncidentResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}

export const onCallIncidentApi = createApi({
  reducerPath: "onCallIncidentApi",
  baseQuery: customFetchBase,
  tagTypes: ["OnCallIncidents"],
  endpoints: (builder) => ({
    listIncident: builder.query<IncidentResponse[], IncidentListRequest>({
      query(req: IncidentListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/incident`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: IncidentResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "OnCallIncidents" as const, id })),
            { type: "OnCallIncidents", id: "LIST" },
          ]
          : [{ type: "OnCallIncidents", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIncidentList(data));
        } catch (error) {
        }
      },
    }),
    fetchIncident: builder.query<IncidentResponse, IncidentFetchRequest>({
      query(req: IncidentFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/incident/${req.incident}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setIncident(data));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListIncidentQuery,
  useFetchIncidentQuery,
} = onCallIncidentApi;

