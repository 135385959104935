import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../app/store";
import Loader from "../OnCallIntegrationList/Loader";
import {
  useListIntegrationTemplateQuery,
  useCreateIntegrationTemplateMutation,
  useDeleteIntegrationTemplateMutation,
  useUpdateIntegrationTemplateInfoMutation,
} from "../../../store/api/oncall.integration.api";
import { useAppSelector } from "../../../app/hooks";
import Suspense from "../../Suspense";
import OnCallTemplateList from "./OnCallTemplateList";
import { Modal } from "../../index";
import FormIntegrationTemplate from "./FormIntegrationTemplate";
import { toast } from "react-toastify";

// props from connect mapStateToProps
interface StateProps {
  loading: boolean;
  workspace: string;
}
interface IProps {
  integration: string
}

type Props = StateProps & IProps;

interface Data {
  name: string
  payload: string
  id?: string
}

const OnCallTemplateListContainer = (props: Props) => {
  const query = useListIntegrationTemplateQuery({ workspace: props.workspace, integration: props.integration });
  const templatesState = useAppSelector((state) => state.onCallIntegrationState)?.templates[props.integration];
  const [createTemplate, createResult] = useCreateIntegrationTemplateMutation()
  const [removeTemplate, removeResult] = useDeleteIntegrationTemplateMutation()
  const [updateTemplate, updateResult] = useUpdateIntegrationTemplateInfoMutation()
  const onCreateHandler = (data: Data) => {
    Modal.closeModal("create-template-modal")()
    createTemplate({ workspace: props.workspace, integration: props.integration, data })
  }
  const onRemoveHandler = (template: string) => {
    Modal.closeModal("delete-template-modal")()
    removeTemplate({ workspace: props.workspace, integration: props.integration, template: template })
  }

  const onUpdateHandler = (template: Data) => {
    Modal.closeModal("integration-template-modal")()
    const data: Data = {
      name: template.name,
      payload: template.payload
    }
    updateTemplate({
      workspace: props.workspace,
      integration: props.integration,
      template: template?.id ?? "",
      data: data,
    })
  }
  useEffect(() => {
    if (updateResult.isSuccess) {
      Modal.closeModal("integration-template-modal")()
      toast.success("Update successfully", {
        position: "bottom-right",
      });
    }
    if (updateResult.isError) {
      if (Array.isArray((updateResult.error as any)?.data?.error)) {
        (updateResult.error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((updateResult.error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [updateResult]);


  useEffect(() => {
    if (removeResult.isSuccess) {
      Modal.closeModal("delete-template-modal")()
      toast.success("Delete successfully", {
        position: "bottom-right",
      });
    }
    if (removeResult.isError) {
      if (Array.isArray((removeResult.error as any)?.data?.error)) {
        (removeResult.error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((removeResult.error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [removeResult]);

  useEffect(() => {
    if (createResult.isSuccess) {
      Modal.closeModal("create-template-modal")()
      toast.success("Create successfully", {
        position: "bottom-right",
      });
    }
    if (createResult.isError) {
      if (Array.isArray((createResult.error as any)?.data?.error)) {
        (createResult.error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((createResult.error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [createResult]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <strong>Template list</strong>
        <Modal id="create-template-modal" title="Create template"
          btnOpenClassName="cursor-pointer btn btn-sm btn-primary mx-2"
          btnOpenName="Create template" className="modal-dialog-centered integration-rules"
          hideFooter={true}>
          <FormIntegrationTemplate
            data={{ name: "", template: "" }}
            onChange={(data) => onCreateHandler(data)} />
        </Modal>
      </div>
      <Suspense query={query} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
        <OnCallTemplateList
          {...props} items={templatesState}
          onRemove={(template) => onRemoveHandler(template)}
          onUpdate={(template) => onUpdateHandler(template)} />
      </Suspense>
    </div>
  );
};

const mapStateToProps = function (state: RootState): StateProps {
  return {
    loading: state.workspaceState.loading,
    workspace: state.workspaceState?.current || "",
  }
}

export default connect(mapStateToProps)(OnCallTemplateListContainer);
