import React, { FC } from "react";
import { Membership } from "../../store/features/workspace.slice";
import { Team } from "../../store/features/oncall.team.slice";
import { Gravatar } from "../Gravatar";

type Props = {
  kind: string,
  responder: Membership | Team
  users: Membership[];
  teams: Team[];
  onHandler: (type: string, item: string) => void
  onRemove?: () => void
};

const FormOnCallResponderAssign: FC<Props> =
  ({ kind, users, teams, responder, onHandler, onRemove }) => {
    const onChangeHandler = (type: string, item: string) => {
      onHandler(type, item)
    }
    const onRemoveHandler = () => {
      onRemove && onRemove()
    }

    return (
      <div className="dropdown">
        <div className="text-decoration-none" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {(!responder || !kind)
          ? (
            <span className="text-secondary border rounded-circle p-2">
              <i className="fa fa-user-plus"/>
            </span>
          )
          : (
            <div>
              {(kind === "user") && (
                <span className="d-flex align-items-center">
                  <Gravatar email={(responder as Membership)?.email || ""} size={30}
                    imageClassName="rounded-circle"/>&nbsp;
                  <div className="ms-3" onClick={onRemoveHandler}>
                    <i className="fa fa-times"/>
                  </div>
                  <span className="text-truncate">{(responder as Membership)?.username}</span>
                </span>
              )}
              {(kind === "team") && (
                <span className="d-flex align-items-center">
                  <i className="fa fa-users pe-1"/>
                  <span className="text-truncate">{(responder as Team)?.name}</span>
                  <div className="ms-3" onClick={onRemoveHandler}>
                    <i className="fa fa-times"/>
                  </div>
                </span>
              )}
            </div>
          )}
        </div>

        <ul className="dropdown-menu">
          <small className="p-3 text-secondary">Users</small>
          {users.map((user, index) => (
            <li key={index} className="dropdown-item cursor-pointer p-2"
              onClick={() => onChangeHandler("user", user.account)}>
              <Gravatar email={user.email || ""} size={30} imageClassName="rounded-circle"/>
              <span className="ms-1 text-truncate">{user.username || " Responder"}</span>
            </li>
          ))}

          <small className="p-3 text-secondary">Teams</small>
          {teams.map((team, index) => (
            <li key={index} className="dropdown-item cursor-pointer p-2"
              onClick={() => onChangeHandler("team", team.id)}>
              <i className="fa fa-users pe-1"/>
              <span className="ms-1 text-truncate">{team.name || " Responder"}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default FormOnCallResponderAssign;
