import React, { useEffect } from "react";
import { Breadcrumbs, Modal } from "../../components";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { Infrastructure } from "../../store/features/infrastructure.slice";
import {
  useCreateInfrastructureChannelMutation,
  useFetchInfrastructureQuery,
  useRespondInfrastructureMutation,
  useUpdateInfrastructureInfoMutation
} from "../../store/api/infrastrucrture.api";
import Suspense from "../../components/Suspense";
import Loader from "../../components/oncall/OnCallAlertList/Loader";
import InfrastructureInfo from "../../components/workspace/WorkspaceInfrastructure/InfrastructureInfo";
import { toast } from "react-toastify";
import { FormOnCallResponderAssign } from "../../components/forms";
import { Membership } from "../../store/features/workspace.slice";
import { useListUserQuery } from "../../store/api/oncall.user.api";
import { selectIntegrationByFilter, selectResponderByKindAndID } from "../../app/selectors";
import { useListTeamQuery } from "../../store/api/oncall.team.api";
import InfrastructureIntegrationList
  from "../../components/workspace/WorkspaceInfrastructure/InfrastructureIntegrationList";
import {
  useCreateIntegrationMutation,
  useListIntegrationQuery,
  useListProviderQuery
} from "../../store/api/oncall.integration.api";
import { useListForWorkspaceQuery } from "../../store/api/integration.api";
import FormChannelConnect from "../../components/forms/FormChannelConnect";
import FormChannelsContainer from "../../components/forms/FormInfrastructureChannels/FormChannels.container";

interface ChannelData {
  name: string
  service: string
  chat: string
}

const WorkspaceInfrastructureInfoPage = () => {
  const { infrastructure } = useParams();
  let [tabParams, setTabParams] = useSearchParams();

  //Infrastructure
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current
  const queryInfrastructure =
    useFetchInfrastructureQuery({ workspace: workspace ?? "", infrastructure: infrastructure ?? "" })
  const [updateInfrastructure, { isError, isSuccess, error }]
    = useUpdateInfrastructureInfoMutation()
  const matchWorkspace = workspaceState.workspaces[workspace ?? ""]?.id
  const infrastructureState =
    useAppSelector((state) => state.infrastructureState)?.infrastructures[matchWorkspace ?? ""];
  let inf = {} as Infrastructure;
  if (infrastructureState) inf = infrastructureState.find(item => item.slug === infrastructure || "") as Infrastructure;

  // Responder
  const [respondInfrastructure] = useRespondInfrastructureMutation();
  const userQuery = useListUserQuery({ workspace: workspace ?? "" });
  const userState = useAppSelector((state) => state.workspaceState)?.memberships[workspace ?? ""];
  const responder = useAppSelector((state) => selectResponderByKindAndID(state, {
    workspace: workspace ?? "",
    kind: inf?.responder?.kind || "",
    id: inf?.responder?.id || ""
  }))
  const users = useAppSelector((state) => state.onCallUserState).users;
  const teamListQuery = useListTeamQuery({ workspace: workspace ?? "" });
  const teams = useAppSelector((state) => state.onCallTeamState).teams;

  //Integrations
  const [createIntegration, createIntegrationResp] = useCreateIntegrationMutation();
  const integrationsQuery =
    useListIntegrationQuery({ workspace: workspace ?? "", infrastructure: inf?.id });
  const integrationState = useAppSelector((state) =>
    selectIntegrationByFilter(state, { infrastructure: inf?.id })) || [];
  const { data: listProvider } = useListProviderQuery();

  //Channels
  const chatsQuery = useListForWorkspaceQuery({ workspace: workspace ?? "" })
  const workspaceIntegrationState = useAppSelector((state) => state.integrationState).workspaceIntegrations;
  const [channelCreate, channelCreateResp] = useCreateInfrastructureChannelMutation()
  const onChannelCreateHandler = (data: ChannelData) => {
    channelCreate({ workspace: workspace ?? "", infrastructure: inf?.id, data })
  }
  useEffect(() => {
    if (channelCreateResp.isSuccess) {
      toast.success("Create channel successfully", {
        position: "bottom-right",
      });
    }
    if (channelCreateResp.isError) {
      if (Array.isArray((channelCreateResp.error as any)?.data?.error)) {
        (channelCreateResp.error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((channelCreateResp.error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [channelCreateResp]);

  const onCreateHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    const elem: HTMLLIElement = event.currentTarget;
    const id = elem.dataset?.id || ""
    const name = elem.dataset?.name || ""
    createIntegration({ workspace: workspace ?? "", infrastructure: inf?.id, data: { name: name, provider: id } })
  };

  let userMap: Membership[] = []
  Object.keys(users).map(value => {
    if (!users) return null
    else userState.forEach(elem => {
      if (users[value].account === elem.account) {
        userMap.push(elem)
      }
    })
    return userMap
  })

  function onUpdateHandler (data: {name: string, description?: string}) {
    updateInfrastructure({ workspace: workspace ?? "", infrastructure: infrastructure ?? "", data: data })
  }
  useEffect(() => {
    if (isSuccess) {
      toast.success("Update infrastructure successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    if (createIntegrationResp.isSuccess) {
      toast.success("Update integration successfully", {
        position: "bottom-right",
      });
    }
    if (createIntegrationResp.isError) {
      if (Array.isArray((createIntegrationResp.error as any)?.data?.error)) {
        (createIntegrationResp.error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((createIntegrationResp.error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [createIntegrationResp]);
  useEffect(() => {
    const handlerParams = () => {
      if (!tabParams.get("t")) {
        setTabParams("t=gen")
      }
    };
    handlerParams()
  });
  function tabHandler(param: string) {
    setTabParams("t=" + param)
  }
  function assignResponder(type: string, item: string) {
    respondInfrastructure({
      workspace: workspace ?? "", infrastructure: inf?.id ?? "", data: { kind: type, id: item }
    })
  }
  function removeResponder() {
    respondInfrastructure({
      workspace: workspace ?? "", infrastructure: inf?.id ?? "", data: null
    })
  }

  return (
    <section>
      <div>
        <Breadcrumbs
          entities={[{ name: "infrastructures", slug: "-/infrastructures" }, { name: inf?.slug || "" }]}/>
      </div>
      <div className="mb-4">
        <h2>{inf?.name} info</h2>
      </div>
      <nav className="sticky-top bg-white my-2">
        <div className="nav nav-tabs m-0 p-0 d-flex justify-content-between" id="nav-tab" role="tablist">
          <div className="d-flex">
            <button
              className={tabParams.get("t") === "gen" ? "active nav-link" : "nav-link"}
              id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab"
              aria-controls="nav-general" aria-selected="false"
              onClick={() => tabHandler("gen")}>
                General
            </button>
            <button
              className={tabParams.get("t") === "notify" ? "active nav-link" : "nav-link"}
              id="nav-notify-tab" data-bs-toggle="tab" data-bs-target="#nav-notify" type="button" role="tab"
              aria-controls="nav-notify" aria-selected="false"
              onClick={() => tabHandler("notify")}>
                Notifications
            </button>
            <button
              className={tabParams.get("t") === "alerts" ? "active nav-link" : "nav-link"}
              id="nav-alerts-tab" data-bs-toggle="tab" data-bs-target="#nav-alerts" type="button" role="tab"
              aria-controls="nav-alerts" aria-selected="false"
              onClick={() => tabHandler("alerts")}>
                Alerts
            </button>
          </div>
          <div className="d-flex">
            <button
              className={tabParams.get("t") === "settings" ? "active nav-link" : "nav-link"}
              id="nav-settings-tab" data-bs-toggle="tab" data-bs-target="#nav-settings" type="button" role="tab"
              aria-controls="nav-settings" aria-selected="true"
              onClick={() => tabHandler("settings")}>
              <i className="fas fa-gears" />
            </button>
          </div>
        </div>
      </nav>
      <div className="tab-content mt-2" id="nav-tabContent">
        {tabParams.get("t") === "gen" && (
          <div
            className={tabParams.get("t") === "gen" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-general" role="tabpanel"
            aria-labelledby="nav-general-tab">
            <div className="row d-flex justify-content-center">
              General Info
            </div>
          </div>
        )}
        {tabParams.get("t") === "notify" && (
          <div
            className={tabParams.get("t") === "notify" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-notify" role="tabpanel" aria-labelledby="nav-notify-tab">
            <div className="row-fluid d-flex flex-wrap py-2">
              <div className="mb-4 col-12">
                <div className="d-flex align-items-center mb-2">
                  <h4 className="m-0">Infrastructure channels:</h4>
                  <div>
                    <Modal id="create-channel-modal" title="Add new channel"
                      btnOpenName="Add channel" className="modal-dialog-centered"
                      btnOpenClassName="btn btn-sm btn-primary ms-3"
                      hideFooter={true}>
                      <Suspense query={chatsQuery} loader={<Loader/>}
                        fallback={<div>Failed integration loading</div>}>
                        <FormChannelConnect
                          onSubmit={(data: ChannelData) => onChannelCreateHandler(data)}
                          onCancel={Modal.closeModal("create-channel-modal")}
                          integrations={workspaceIntegrationState}
                          workspace={workspace ?? ""} />
                      </Suspense>
                    </Modal>
                  </div>
                </div>
                <div>
                  <FormChannelsContainer workspace={workspace ?? ""} infrastructure={inf?.id ?? ""} />
                </div>
              </div>
            </div>
          </div>
        )}
        {tabParams.get("t") === "alerts" && (
          <div
            className={tabParams.get("t") === "alerts" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-alerts" role="tabpanel" aria-labelledby="nav-alerts-tab">
            <div className="row d-flex justify-content-center">
              Alerts
            </div>
          </div>
        )}
        {tabParams.get("t") === "settings" && (
          <div
            className={tabParams.get("t") === "settings" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-settings" role="tabpanel"
            aria-labelledby="nav-options-tab">
            <div className="row d-flex justify-content-center">
              <div className="col-12 mb-4">
                <div className="col-md-6">
                  <Suspense query={queryInfrastructure} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
                    <InfrastructureInfo infrastructure={inf} onSubmit={onUpdateHandler} />
                  </Suspense>
                </div>
              </div>
              <div className="mb-4">
                <div className="pb-2">
                  <h4>Respond user or team</h4>
                </div>
                <div className="row-fluid d-flex mb-2 align-items-center">
                  <div className="col">Responder:</div>
                  <div className="ms-2 col-11">
                    <Suspense query={userQuery} loader={<Loader/>} fallback={<div>Failed users loading</div>}>
                      <Suspense query={teamListQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
                        <FormOnCallResponderAssign
                          kind={inf?.responder?.kind || ""}
                          users={userMap}
                          teams={Object.values(teams)}
                          responder={responder as Membership}
                          onHandler={assignResponder}
                          onRemove={removeResponder}
                        />
                      </Suspense>
                    </Suspense>
                  </div>
                </div>
              </div>
              <div className="row-fluid d-flex py-2">
                <div className="col-12 mb-0">
                  <div className="mb-4 d-flex align-items-center">
                    <h4>Integration list</h4>
                    <div className="dropdown ms-3">
                      <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                        data-bs-display="static"
                        aria-expanded="false" data-bs-auto-close="outside">
                      Add Integration
                      </button>
                      <div className="dropdown-menu dropdown-menu-end p-1">
                        <ul className="list-group list-group-flush">
                          {(listProvider ?? []).map((item, index) => (
                            <li
                              key={index} className="list-group-item cursor-pointer"
                              data-id={item.id} data-name={item.name}
                              onClick={onCreateHandler}>
                              <div className="d-flex">
                                <div className="p-2">
                                  <img src={`/images/ico/oncall/${item.type}.svg`} alt={item.name} height={36}/>
                                </div>
                                <div className="align-self-center">{item.name}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <Suspense query={integrationsQuery} loader={<Loader/>} fallback=
                    {<div>Failed integration list loading <hr/></div>}
                  >
                    <InfrastructureIntegrationList
                      workspace={workspace ?? ""} infrastructure={inf?.id} items={integrationState} />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </section>
  );
};

export default WorkspaceInfrastructureInfoPage;
