import React, { FC, useEffect, useState } from "react";
import { NotificationPolicy, NotificationPolicyRules } from "../../../store/features/oncall.user.slice";
import { Modal, Tooltip } from "../../index";

import "./style.scss"
import { FormOnCallUserNotificationRules } from "../../forms";
import { useUpdateNotificationPolicyMutation } from "../../../store/api/oncall.user.api";
import { toast } from "react-toastify";

const steps: { [id: string]: string } = {
  notify_slack: "Notify by slack",
  notify_telegram: "Notify by telegram",
  notify_discord: "Notify by discord",
  notify_phone: "Notify by phone call",
  notify_email: "Notify by email",
  notify_push: "Notify by push",
};
const typeFilter: { [id: string]: string } = {
  "match-all": "Match all",
  "match-any-condition": "Match any condition",
  "match-all-conditions": "Match all conditions",
};
const conditionOptions: { [id: string]: string } = {
  "alert:priority": "Alert priority",
  "pattern": "Pattern",
  "integration:name": "Integration name",
};

export interface IProps {
  notification: NotificationPolicy
  workspace: string
  account: string
  user: string
}

const OnCallUserNotificationPolicyInfo: FC<IProps> = ({ notification, workspace, account, user }) => {
  const [updateNotificationPolicy, { isError, error, isSuccess }] = useUpdateNotificationPolicyMutation();

  const [notificationValue, setNotificationValue] = useState(notification?.rules || [])
  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("notification-rule-modal")()
      toast.success("Successfully update", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  const onRuleChange = (index: number, data: NotificationPolicyRules) => {
    setNotificationValue(state => {
      const update = [...state.slice(0, index), {
        ...state[index],
        name: data.name,
        filter: data.filter,
        steps: data.steps
      }, ...state.slice(index + 1)]
      onSubmitHandler(update)
      return update
    })
  }
  const onAddHandler = (data: NotificationPolicyRules) => {
    setNotificationValue(state => {
      const update = [...state, { name: data.name, filter: data.filter, steps: data.steps }]
      onSubmitHandler(update)
      return update
    })
  };

  const onRemoveHandler = (index: number) => {
    setNotificationValue(state => {
      const update = [...state.slice(0, index), ...state.slice(index + 1)]
      onSubmitHandler(update)
      return update
    })
  };
  const onSubmitHandler = (updates: NotificationPolicyRules[]) => {
    updateNotificationPolicy({
      workspace: workspace,
      account: user,
      data: { rules: updates },
    });
  };

  if (!notification) return (
    <div className="alert alert-primary" role="alert">
      <i className="fa-solid fa-circle-info"/>&nbsp;Notification rules are not configured by this user
    </div>
  )

  return (
    <div>
      {account === user &&
        <div className="d-flex justify-content-end">
          <Modal id="notification-rule-modal" title="Notification create"
            btnOpenClassName="btn btn-sm btn-outline-primary mx-2"
            btnOpenName={"add notification"} className="modal-dialog-centered integration-rules"
            hideFooter={true}>
            <FormOnCallUserNotificationRules rules={{} as NotificationPolicyRules} workspace={workspace}
              updateHandler={onAddHandler}/>
          </Modal>
        </div>
      }
      {(notificationValue || []).map((rule, index) => {
        return (
          <div key={index} className="card my-2">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h6 className="m-0">{rule.name}</h6>
              {account === user &&
                <div className="d-flex align-items-center">
                  <Modal id="notification-rule-modal" title="Notification rules"
                    btnOpenClassName="btn btn-sm btn-outline-primary mx-2"
                    btnOpenName={"change rules"} className="modal-dialog-centered integration-rules"
                    hideFooter={true}>
                    <FormOnCallUserNotificationRules rules={rule} workspace={workspace}
                      updateHandler={(val) => onRuleChange(index, val)}/>
                  </Modal>
                  <Tooltip placement="top" text="delete notification" children={
                    <i className="far fa-trash-alt cursor-pointer mx-2 text-primary-emphasis"
                      onClick={() => onRemoveHandler(index)}/>
                  }></Tooltip>
                </div>
              }
            </div>
            <div className="row">
              <div className="col-6">
                {(rule.steps || []).map((step, idx) => {
                  return (
                    <div key={idx}
                      className={"d-flex align-items-center list-group-item p-2"}>
                      <div className="flex-grow-1 me-5 d-flex">
                        <div>
                          {step.enabled
                            ? (
                              <Tooltip text="active">
                                <i className="fa-regular fa-circle-check text-success">&nbsp;</i>
                              </Tooltip>
                            )
                            : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          }
                        </div>
                        <div>{steps[step.type]}</div>
                      </div>
                      <div className="mx-2">after</div>
                      <div>
                        <span>{step.delay}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="col-6 p-2 border-start">
                <span>{typeFilter[rule?.filter?.type]}</span>
                {(rule?.filter?.conditions || []).map((condition, id) => {
                  return (
                    <div key={id} className="d-flex flex-wrap">
                      <span> {conditionOptions[condition.field]} &#x2192;</span>
                      <span className="mx-2">{condition.not ? "NOT" : "IS"}</span>
                      <span>{Object.keys(condition.operation)}</span>
                      <span className="mx-2">{Object.values(condition.operation)}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default OnCallUserNotificationPolicyInfo;
