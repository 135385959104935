import React, { FC, useEffect } from "react";
import Modal from "../Modal";
import Suspense from "../Suspense";
import {
  useListTeamScheduleOverrideQuery,
  useDeleteTeamScheduleOverrideMutation
} from "../../store/api/oncall.team.api";
import Loader from "./OnCallAlertList/Loader";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";
import { FormScheduleOverride, FormDelete } from "../forms";
import { TeamScheduleOverride } from "../../store/features/oncall.team.slice";
import { toast } from "react-toastify";

type IProps = {
  workspace: string
  team: string
  schedule: string
};

const OnCallTeamScheduleOverrideList: FC<IProps> = ({ workspace, team, schedule }) => {
  const scheduleOverrideListQuery = useListTeamScheduleOverrideQuery(
    { workspace: workspace, team: team, schedule: schedule }
  );
  const overridesState = useAppSelector((state) => state.onCallTeamState)?.team_schedule_overrides[schedule] || []

  const [deleteTeamRotation, { isError, error, isSuccess }] = useDeleteTeamScheduleOverrideMutation()

  const removeTeamRotationHandler = (data: TeamScheduleOverride) => {
    deleteTeamRotation({ workspace: workspace, team: team, schedule: schedule, override: data.id })
    Modal.closeModal("delete-team-override-modal")()
  }

  useEffect(() => {
    if (isSuccess) {
      Modal.closeModal("delete-team-override-modal")()
      toast.success("Delete schedule override successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  return (
    <div className="table-responsive">
      <Suspense query={scheduleOverrideListQuery} loader={<Loader/>} fallback={<div>Failed rotation loading</div>}>
        <div className="d-flex justify-content-between align-items-center m-1">
          <h6>
            Overrides
          </h6>
          <Modal
            id="override-team-schedule-modal" title="Override create"
            btnOpenClassName="btn btn-sm btn-outline-primary cursor-pointer mx-2"
            btnOpenName="override create" className="modal-dialog-centered integration-rules"
            hideFooter={true}>
            <FormScheduleOverride
              workspace={workspace}
              onSubmit={Modal.closeModal("override-team-schedule-modal")}
              onCancel={Modal.closeModal("override-team-schedule-modal")}
              team={team} schedule={schedule} override={{} as TeamScheduleOverride} method={"create"} />
          </Modal>
        </div>
        <table className="table">
          <thead className="table-light">
            <tr>
              <th scope="col">Override name</th>
              <th scope="col">CreatedAt</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(overridesState).map((key, index) => {
                const item = overridesState[index]
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <span>Override {index + 1}</span>
                      </div>
                    </td>
                    <td>{moment.unix(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                    <td className="d-flex justify-content-end">
                      <Modal
                        id="delete-team-override-modal" title="Delete override"
                        btnOpenClassName="far fa-trash-alt cursor-pointer bg-transparent border-0 mx-2"
                        btnOpenName=" " className="modal-dialog-centered"
                        hideFooter={true}>
                        <FormDelete
                          name={"confirm"}
                          onSubmit={() => removeTeamRotationHandler(item)}
                          onCancel={Modal.closeModal("delete-team-override-modal")} />
                      </Modal>
                      <Modal
                        id="override-team-schedule-modal" title="Override info"
                        btnOpenClassName="fa fa-pen cursor-pointer bg-transparent border-0 mx-2"
                        btnOpenName=" " className="modal-dialog-centered integration-rules"
                        hideFooter={true}>
                        <FormScheduleOverride
                          workspace={workspace}
                          onSubmit={Modal.closeModal("override-team-schedule-modal")}
                          onCancel={Modal.closeModal("override-team-schedule-modal")}
                          team={team} schedule={schedule} override={item} method={"update"} />
                      </Modal>
                    </td>
                  </tr>
                )
              }
              )
            }
          </tbody>
        </table>
      </Suspense>
    </div>
  );
};

export default OnCallTeamScheduleOverrideList;
