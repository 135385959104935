import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react"

import "bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./assets/theme/main.scss";

const container = document.getElementById("root")!;
const root = createRoot(container);

let appReady = Promise.resolve();

// Enable API mocking only in mocks
if (process.env.REACT_APP_ENV === "mocks") {
  const { worker } = require("./mocks");
  appReady = worker.start({
    onUnhandledRequest: "bypass",
    serviceWorker: {
      /**
       * Use a custom Service Worker script URL to resolve
       * the mock worker served by Codesandbox.
       * @note You DO NOT need this in your application.
       * @see https://mswjs.io/docs/api/setup-worker/start#serviceworker
       */
      url: "/mockServiceWorker.js"
    }
  });
  worker.printHandlers();
}

appReady.then(() => {
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </PersistGate>
    </Provider>
    // </React.StrictMode>
  );
});
