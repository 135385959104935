import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { accountApi } from "./account.api";
import { setToken, Session } from "../features/session.slice";
import { setAccount, Account } from "../features/account.slice";
import type {
  SessionResponse,
  SignInData,
  SignUpData,
  UpdatePasswordRequest,
  RecoveryPasswordRequest,
  ChangePasswordRequest
} from "./types/auth"

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    loginAccount: builder.mutation<SessionResponse, SignInData>({
      query(data) {
        return {
          url: "v1/session",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken({ token: data.access_token } as Session));
          dispatch(setAccount({ id: data.id, username: data.username, email: data.email } as Account));
          await dispatch(accountApi.endpoints.getMe.initiate());
        } catch (error) {
        }
      },
    }),
    createAccount: builder.mutation<SessionResponse, SignUpData>({
      query(data) {
        return {
          url: "v1/account",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken({ token: data.access_token } as Session));
          dispatch(setAccount({ id: data.id, username: data.username, email: data.email } as Account));
          await dispatch(accountApi.endpoints.getMe.initiate());
        } catch (error) {
        }
      },
    }),
    updatePassword: builder.mutation<SessionResponse, UpdatePasswordRequest>({
      query(data) {
        return {
          url: "v1/account/password",
          method: "PUT",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken({ token: data.access_token } as Session));
          dispatch(setAccount({ id: data.id, username: data.username, email: data.email } as Account));
          await dispatch(accountApi.endpoints.getMe.initiate());
        } catch (error) {
        }
      },
    }),
    recoveryPassword: builder.mutation<SessionResponse, RecoveryPasswordRequest>({
      query(data) {
        return {
          url: "v1/password/recovery",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
    }),
    changePassword: builder.mutation<SessionResponse, ChangePasswordRequest>({
      query(req) {
        return {
          url: `v1/password/recovery/${req.code}`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken({ token: data.access_token } as Session));
          dispatch(setAccount({ id: data.id, username: data.username, email: data.email } as Account));
          await dispatch(accountApi.endpoints.getMe.initiate());
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useLoginAccountMutation,
  useCreateAccountMutation,
  useUpdatePasswordMutation,
  useRecoveryPasswordMutation,
  useChangePasswordMutation
} = authApi;
