import React, { FC, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { object, string, TypeOf } from "zod";
import { useConnectEmailMutation, useLazyVerifyEmailQuery } from "../../../store/api/integration.api";
import { CountdownTimer } from "./../../../components";
import { toast } from "react-toastify";

import "./style.scss"

const FormSchema = object({
  email: string()
    .email("Email is not correct")
    .min(1, "Email is required"),
  confirm: string()
    .min(1, "Code is required")
    .optional()
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string
  account: string
  email: string
  onConnect?: () => void
}

const FormEmailConnect: FC<IProps> = ({ workspace, account, email, onConnect }) => {

  const [connectEmail, { data, isError, error, isSuccess }] = useConnectEmailMutation();
  const [verifyEmail, result, lastPromiseInfo] = useLazyVerifyEmailQuery()

  const [isCodeSubmitted, setIsCodeSubmitted] = useState<boolean>(false)
  const [allowResend, setAllowResend] = useState<boolean>(true)

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    reset,
    resetField,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      setAllowResend(false)
      setIsCodeSubmitted(true)
      toast.success("Send verify code successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess, data]);

  useEffect(() => {
    setValue("email", email || "")
  }, [email, setValue])

  useEffect(() => {
    const v = getValues()
    if (isCodeSubmitted && (lastPromiseInfo && lastPromiseInfo?.lastArg?.code === v.confirm)) {
      if (result.isSuccess) {
        reset()
        setAllowResend(true)
        setIsCodeSubmitted(false)
        onConnect && onConnect();
      }
      if (result.isError) {
        toast.error("invalid verification code", {
          position: "bottom-right",
        });
      }
    }
  }, [getValues, isCodeSubmitted, lastPromiseInfo, onConnect, reset, result])

  const setAllowResendHandler = () => {
    setAllowResend(true)
  }

  const resendCodeHandler = () => {
    setAllowResend(false)
    const v = getValues()
    if (v) connectEmail({ workspace: workspace, account: account, data: { email: v.email } });
  }

  const changeEmailHandler = () => {
    resetField("confirm")
    setAllowResend(true)
    setIsCodeSubmitted(false)
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    if (!isCodeSubmitted) {
      connectEmail({ workspace: workspace, account: account, data: { email: values.email } });
    } else {
      values.confirm && verifyEmail({
        workspace: workspace,
        account: account,
        integration: data?.id || "",
        code: values.confirm
      })
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-floating mb-4">
            <input type="text" id="emailInput" className="form-control" placeholder="Enter email"
              disabled={isCodeSubmitted}
              {...register("email")}/>
            <label className="form-label" htmlFor="emailInput">
              {errors.email?.message
                ? <small className="text-danger">{errors.email?.message}</small>
                : <small className="text-secondary">Enter email</small>
              }
            </label>
            <div className="text-end">
              {isCodeSubmitted && (
                <button type="button" className="btn btn-link text-decoration-none p-0"
                  onClick={changeEmailHandler}>
                  change email
                </button>
              )}
            </div>
          </div>

          {(isCodeSubmitted) && (
            <div className="form-floating mb-4">
              <input type="text" id="confirmInput" className="form-control" placeholder="Enter confirm"
                {...register("confirm")}/>
              <label className="form-label" htmlFor="confirmInput">
                {errors.confirm?.message
                  ? <small className="text-danger">{errors.confirm?.message}</small>
                  : <small className="text-secondary">Enter confirm</small>
                }
              </label>
              <div className="text-end">
                <button type="button" className="btn btn-link text-decoration-none p-0"
                  disabled={!allowResend}
                  onClick={resendCodeHandler}>
                  resend code {!allowResend && <CountdownTimer value={"00:00:10"} onStop={setAllowResendHandler}/>}
                </button>
              </div>
            </div>
          )}

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">
              {(!isCodeSubmitted)
                ? <span>Connect email</span>
                : <span>Verify email</span>
              }
            </button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormEmailConnect;
