import md5 from "md5";
import React from "react";

interface Props {
  hash?: string,
  className?: string,
  imageClassName?: string,
  email: string,
  alt?: string,
  size?: number,
  rating?: string

}

export const Gravatar = (props: Props) => {
  let hash: string = "";
  const base = "https://www.gravatar.com/avatar/";
  const size = props?.size || 50
  const rating = props?.rating || "g"
  const query = {
    s: size,
    r: rating,
    d: "retro",
  };
  const retinaQuery = {
    s: size * 2,
    r: rating,
    d: "retro",
  };
  if (!props.hash) {
    const formattedEmail = (props.email).trim().toLowerCase();
    hash = md5(formattedEmail);
  }

  const src = `${base}${hash}?${query}`;
  const retinaSrc = `${base}${hash}?${retinaQuery}`;


  return (
    <span className={props.className}>
      <img alt={props.alt} className={props.imageClassName || "avatar"} width={props.size} height={props.size}
        src={src || retinaSrc}/>
    </span>
  );
};
