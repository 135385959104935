import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  useCreateScheduleOverrideMutation,
  useUpdateTeamScheduleOverrideInfoMutation,
} from "../../../store/api/oncall.team.api";
import moment from "moment"
import { TeamScheduleOverride, TeamScheduleRotation } from "../../../store/features/oncall.team.slice";
import { Membership } from "../../../store/features/workspace.slice";
import { useAppSelector } from "../../../app/hooks";
import { toast } from "react-toastify";
import Select, { OnChangeValue } from "react-select";
import { TeamScheduleOverrideUpdateRequest } from "../../../store/api/types/oncall";
import { array, number, object, string, TypeOf } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";

const FormSchema = object({
  user: string(),
  rotations: array(string()),
  start_on: number(),
  end_on: number()
}).refine(val => val.end_on > val.start_on);

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string
  team: string
  schedule: string
  override: TeamScheduleOverride
  onSubmit?: () => void
  onCancel?: () => void
  method: string
}

interface Rotation {
  label: string;
  value: string;
}

const Index: FC<IProps> = ({ onSubmit, onCancel, workspace, team, schedule, override, method }) => {
  const [overrideState, setOverrideState] = useState<TeamScheduleOverrideUpdateRequest>(override);
  const userState = useAppSelector((state) => state.workspaceState)?.memberships[workspace];
  const rotationsState = useAppSelector((state) => state.onCallTeamState)?.team_schedule_rotations[schedule] || []
  const users = useAppSelector((state) => state.onCallUserState).users;

  const [updateOverride, { isError, error, isSuccess }] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    method === "create" ? useCreateScheduleOverrideMutation() : useUpdateTeamScheduleOverrideInfoMutation();

  useEffect(() => {
    setOverrideState(override || {} as TeamScheduleOverride)
  }, [setOverrideState, override] );
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    values: {
      user: overrideState.user,
      rotations: overrideState.rotations,
      start_on: overrideState.start_date,
      end_on: overrideState.end_date
    }
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      onSubmit && onSubmit()
      refSuccess.current = true;
      toast.success("update team schedule successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [ error, isError, isSuccess, onSubmit ]);

  let userMap: Membership[] = []
  Object.keys(users).map(value => {
    if (!users) return null
    else userState.forEach(elem => {
      if (users[value].account === elem.account) {
        userMap.push(elem)
      }
    })
    return userMap
  })

  const handleChangeStartDate = (e: ChangeEvent<HTMLInputElement>) => {
    setOverrideState(state => ({ ...state, start_date: moment(e.target.value).unix() }))
  }
  const handleChangeEndDate = (e: ChangeEvent<HTMLInputElement>) => {
    setOverrideState(state => ({ ...state, end_date: moment(e.target.value).unix() }))
  }

  const handleChangeUser = (val: OnChangeValue<any, any>) => {
    setOverrideState(state => ({ ...state, user: val.value }))
  }
  const handleChangeRotations = (val: OnChangeValue<any, any>) => {
    const value = val.map((item: Rotation) => item.value)
    setOverrideState(state => ({ ...state, rotations: value }))
  }

  const excludeFromValue = (responder: string, options: Membership[]) =>  {
    return options.filter(item => item.account === overrideState.user)
      .map(item => ({ value: item.account, label: item.username }))
  }

  const excludeFromRotations = (rotations: string[], options: TeamScheduleRotation[]): Rotation[] =>  {
    const items: Rotation[] = [];
    options.map(item =>
      (rotations || []).filter(elem => elem === item.id ? items.push({ value: item.id, label: item.name }) : null))
    return items
  }
  const excludeFromRotationOptions = (rotations: string[], options: TeamScheduleRotation[]): Rotation[] =>  {
    return options.map(item => {
      return { value: item.id, label: item.name }
    })
  }

  const onSubmitHandler = () => {
    updateOverride(
      { workspace: workspace, team: team, schedule: schedule, data: overrideState, override: override?.id }
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="mb-2 col-12 col-lg-6">
          <label className="form-label mb-0">User</label>
          <Select
            isMulti={false}
            value={excludeFromValue(overrideState.user, userMap)}
            onChange={handleChangeUser}
            options={userMap
              .filter(item => item.account !== overrideState.user)
              .map(item => ({ value: item.account, label: item.username }))}
          />
          {errors.user?.message && <small className="text-danger">{errors.user?.message}</small>}
        </div>
        <div className="mb-2 col-12">
          <label className="form-label mb-0">Rotations</label>
          <Select
            isMulti={true}
            value={excludeFromRotations(overrideState.rotations, rotationsState)}
            onChange={handleChangeRotations}
            options={excludeFromRotationOptions(overrideState.rotations, rotationsState)}
          />
          {errors.rotations?.message && <small className="text-danger">{errors.rotations?.message}</small>}
        </div>
        <div className="mb-2 col-12 col-lg-6">
          <label className="form-label mb-0" htmlFor="startDateInput">Start on</label>
          <input
            type="datetime-local"
            defaultValue={moment.unix(overrideState?.start_date).format("YYYY-MM-DD[T]HH:mm")}
            id="startDateInput" className="form-control" onChange={handleChangeStartDate}/>
          {errors.start_on?.message && <small className="text-danger">{errors.start_on?.message}</small>}
        </div>
        <div className="mb-2 col-12 col-lg-6">
          <label className="form-label mb-0" htmlFor="endtDateInput">End on</label>
          <input
            type="datetime-local"
            defaultValue={moment.unix(overrideState?.end_date).format("YYYY-MM-DD[T]HH:mm")}
            id="endtDateInput" className="form-control" onChange={handleChangeEndDate}/>
          {errors.end_on?.message && <small className="text-danger">{errors.end_on?.message}</small>}
          {(overrideState.end_date < overrideState.start_date) &&
            <small className="text-danger">End on must be better start on</small>}
        </div>

        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-primary">Apply</button>
          <button className="btn btn-danger" onClick={() => {onCancel && onCancel()}}>
          Cancel
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Index;
