import { Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { OnCallIncidentInfo } from "./../../components/oncall";
import { Breadcrumbs } from "../../components";
import React from "react";

const OnCallIncidentInfoPage = () => {
  const { incident } = useParams();
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current
  const incidentState = useAppSelector((state) => state.onCallIncidentState);


  if (!workspace || !incident) return <Navigate to="/" replace/>
  const data = incidentState.incidents[incident]

  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "incidents", slug: "incidents" }, { name: data.title }]}/>
      </div>
      <div className="mb-4">
        <h2>Incident Info</h2>
      </div>
      <OnCallIncidentInfo workspace={workspace} incident={incident}/>
    </div>
  );
};

export default OnCallIncidentInfoPage;
