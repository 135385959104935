import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  NotificationPolicyRequest,
  NotificationPolicyResponse,
  NotificationPolicyUpdateRequest,
  UserCreateRequest,
  UserFetchRequest,
  UserListRequest,
  UserRemoveRequest,
  UserResponse,
} from "./types/oncall"
import {
  attachUserList,
  removeUser,
  setNotificationPolicy,
  setUser,
  setUserList,
} from "../features/oncall.user.slice";

const transformResponse = (item: UserResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformNotificationResponse = (item: NotificationPolicyResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}

export const onCallUserApi = createApi({
  reducerPath: "onCallUserApi",
  baseQuery: customFetchBase,
  tagTypes: ["OnCallUsers"],
  endpoints: (builder) => ({
    listUser: builder.query<UserResponse[], UserListRequest>({
      query(req: UserListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/user`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: UserResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "OnCallUsers" as const, id })),
            { type: "OnCallUsers", id: "LIST" },
          ]
          : [{ type: "OnCallUsers", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserList(data));
        } catch (error) {
        }
      },
    }),
    fetchUser: builder.query<UserResponse, UserFetchRequest>({
      query(req: UserFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/user/${req.account}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
        }
      },
    }),
    createUsers: builder.mutation<UserResponse[], UserCreateRequest>({
      query(req: UserCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/users`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: UserResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(attachUserList(data));
        } catch (error) {
        }
      },
    }),
    deleteUser: builder.mutation<void, UserRemoveRequest>({
      query(req: UserRemoveRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/user/${req.account}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeUser(args.account));
        } catch (error) {
        }
      },
    }),
    notificationPolicy: builder.query<NotificationPolicyResponse, NotificationPolicyRequest>({
      query(req: NotificationPolicyRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/user/${req.account}/notification_policy`,
          credentials: "include",
        };
      },
      transformResponse: transformNotificationResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setNotificationPolicy({ account: args.account, policy: data }));
        } catch (error) {
        }
      },
    }),
    updateNotificationPolicy: builder.mutation<NotificationPolicyResponse, NotificationPolicyUpdateRequest>({
      query(req: NotificationPolicyUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/notification_policy`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformNotificationResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setNotificationPolicy({ account: args.account, policy: data }));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListUserQuery,
  useFetchUserQuery,
  useCreateUsersMutation,
  useDeleteUserMutation,
  useNotificationPolicyQuery,
  useUpdateNotificationPolicyMutation,
} = onCallUserApi;

