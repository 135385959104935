import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  useCreateScheduleRotationMutation,
  useListTeamQuery,
  useUpdateTeamScheduleRotationInfoMutation,
} from "../../../store/api/oncall.team.api";
import moment from "moment"
import Select, { OnChangeValue } from "react-select";
import FormScheduleRestrict from "./FormScheduleRestrict";
import { TeamScheduleRotation, TeamScheduleRotationRestrictions } from "../../../store/features/oncall.team.slice";
import { TeamRotationRequest } from "../../../store/api/types/oncall";
import FormOnCallScheduleParticipants from "./FormOnCallScheduleParticipants";
import { Suspense } from "../../index";
import Loader from "../../oncall/OnCallIncidentList/Loader";
import { useListUserQuery } from "../../../store/api/oncall.user.api";
import { Membership } from "../../../store/features/workspace.slice";
import { useAppSelector } from "../../../app/hooks";
import { toast } from "react-toastify";
import { array, number, object, string, TypeOf } from "zod";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";

const FormSchema = object({
  name: string()
    .min(3, "Name can not be empty")
    .transform(val => nameFormat(val).replace(/ +/g, " ")),
  participants: array(string()).length(1, "Participants is required"),
  rotation_type: string().min(1, "Type is required"),
  start_on: number().min(1, "Start on required"),
  end_on: number().optional(),
  restriction_type: string().min(1, "Restriction is required").optional(),
  restrictions_week: array(object({
    start_day: string().min(1 ,"Start day required"),
    start_hour: number(),
    start_minute: number(),
    end_day: string().min(1 ,"End day required"),
    end_hour: number(),
    end_minute: number()
  })).optional(),
  restrictions_day: array(object({
    start_day: string().optional(),
    start_hour: number(),
    start_minute: number(),
    end_day: string().optional(),
    end_hour: number(),
    end_minute: number()
  })).optional(),
}).refine(val => val.end_on && val.end_on > val.start_on);

export type FormData = TypeOf<typeof FormSchema>;

const nameFormat = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9_\s]+/g, "")
}

export interface IProps {
  workspace: string
  team: string
  schedule: string
  rotation: TeamScheduleRotation
  onSubmit?: () => void
  onCancel?: () => void
  method: string
}
const typeOptions = [
  {
    label: "Daily",
    value: "daily"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Hourly",
    value: "hourly"
  }
]

const Index: FC<IProps> = ({ onSubmit, onCancel, workspace, team, schedule, rotation  , method }) => {
  const [endOn, setEndOn] = useState(!!rotation?.end_date || false)
  const [rotationState, setRotationState] = useState(rotation);
  const userQuery = useListUserQuery({ workspace: workspace ?? "" });
  const teamListQuery = useListTeamQuery({ workspace: workspace });
  const userState = useAppSelector((state) => state.workspaceState)?.memberships[workspace];

  const users = useAppSelector((state) => state.onCallUserState).users;
  const teams = useAppSelector((state) => state.onCallTeamState).teams;

  const [updateRotation, { isError, error, isSuccess }] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    method === "create" ? useCreateScheduleRotationMutation() : useUpdateTeamScheduleRotationInfoMutation();

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    values: {
      name: rotationState?.name,
      participants: rotationState?.participants?.map(item => item.id),
      rotation_type: rotationState?.type,
      start_on: rotationState?.start_date,
      end_on: rotationState?.end_date,
      restriction_type: rotationState.time_restriction?.type,
    }
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setRotationState(rotation || {} as TeamRotationRequest)
  }, [setRotationState, rotation] );

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      onSubmit && onSubmit()
      refSuccess.current = true;
      toast.success("update team schedule successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [ error, isError, isSuccess, onSubmit ]);

  let userMap: Membership[] = []
  Object.keys(users).map(value => {
    if (!users) return null
    else userState.forEach(elem => {
      if (users[value].account === elem.account) {
        userMap.push(elem)
      }
    })
    return userMap
  })

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = nameFormat(e.target.value)
    setRotationState(state => ({ ...state, name: e.target.value }))
  }
  const changeEnd = (e: ChangeEvent<HTMLInputElement>) => {
    setEndOn(e.target.checked)
    setRotationState(state => ({ ...state, end_date: 0 }))
  }
  const handleChangeStartDate = (e: ChangeEvent<HTMLInputElement>) => {
    setRotationState(state => ({ ...state, start_date: moment(e.target.value).unix() }))
  }
  const handleChangeEndDate = (e: ChangeEvent<HTMLInputElement>) => {
    setRotationState(state => ({ ...state, end_date: moment(e.target.value).unix() }))
  }

  const onTypeChangeHandler = (selectedOption: any) => {
    setRotationState(state => ({ ...state, type: selectedOption.value }))
  }

  const changeRestrictionHandler = (
    time_restriction: {type: string, restrictions: TeamScheduleRotationRestrictions[]}) => {
    setRotationState(state => ({ ...state, time_restriction: time_restriction }))
  }

  const changeParticipantsHandler = (val: OnChangeValue<any, any>) => {
    setRotationState(state => ({ ...state, participants: val }))
  }
  const onSubmitHandler = () => {
    onSubmit && onSubmit()
    updateRotation(
      { workspace: workspace, team: team, schedule: schedule, data: rotationState, id: rotation?.id || "" }
    )
  };

  const defaultType = typeOptions?.find(item => item.value === rotationState.type)
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="mb-2 col-12">
          <label className="form-label" htmlFor="nameScheduleInput">Name</label>
          <input
            type="text" id="nameScheduleInput" className="form-control" placeholder="Enter name"
            defaultValue={rotationState?.name}
            onChange={handleChangeName}/>
          {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
        </div>
        <div className="mb-2 col-12">
          <label className="form-label">Participants</label>
          <Suspense query={userQuery} loader={<Loader/>} fallback={<div>Failed users loading</div>}>
            <Suspense query={teamListQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
              <FormOnCallScheduleParticipants
                participants={rotationState.participants || []}
                users={userMap} teams={teams}
                onHandler={changeParticipantsHandler} />
            </Suspense>
          </Suspense>
          {errors.participants?.message && <small className="text-danger">{errors.participants?.message}</small>}
        </div>
        <div className="flex-grow-1 my-2 col-12">
          <label className="form-label">Rotation type</label>
          <Select
            defaultValue={defaultType}
            onChange={onTypeChangeHandler}
            options={typeOptions}/>
          {errors.rotation_type?.message && <small className="text-danger">{errors.rotation_type?.message}</small>}
        </div>
        <div className="mb-2 col-12 col-lg-6">
          <label className="form-label mb-0" htmlFor="startDateInput">Start on</label>
          <input
            type="datetime-local"
            defaultValue={moment.unix(rotationState?.start_date).format("YYYY-MM-DD[T]HH:mm")}
            id="startDateInput" className="form-control" onChange={handleChangeStartDate} />
          {errors.start_on?.message && <small className="text-danger">{errors.start_on?.message}</small>}
        </div>
        <div className="mb-2 col-12 col-lg-6">
          <div className="form-check form-switch mb-0">
            <input
              checked={endOn} onChange={changeEnd}
              className="form-check-input" type="checkbox" role="switch" id="endDateInput"
            />
            <label className="form-label mb-0" htmlFor="endDateInput">Ends on</label>
          </div>
          <div className="">
            {endOn &&
          <input
            type="datetime-local"
            defaultValue={rotationState.end_date
              ? moment.unix(rotationState?.end_date).format("YYYY-MM-DD[T]HH:mm")
              : undefined}
            className="form-control" onChange={handleChangeEndDate}/>
            }
            {errors.end_on?.message && <small className="text-danger">{errors.end_on?.message}</small>}
            {(rotationState.end_date < rotationState.start_date) &&
              <small className="text-danger">End on must be better start on</small>}
          </div>
        </div>

        <div className="my-2 col-12">
          <FormScheduleRestrict data={rotationState?.time_restriction} onChange={changeRestrictionHandler} />
          {
            errors.restriction_type?.message &&
            <small className="text-danger">{errors.restriction_type?.message}</small>
          }
        </div>

        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-primary">
        Apply
          </button>
          <button className="btn btn-danger" onClick={() => {onCancel && onCancel()}}>
        Cancel
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Index;
