import {
  ackAlertInfo,
  createTeamSchedule,
  getAlertEvents,
  getAlertInfo,
  getAlertList,
  getIncidentInfo,
  getIncidentList,
  getIntegrationList,
  getTeamEscalation,
  getTeamInfo,
  getTeamList,
  getTeamMember,
  getTeamScheduleList,
  getTeamUser,
  putAlertInfo,
  putIncidentInfo,
  unAckAlertInfo,
  deleteTeamSchedule,
  putScheduleInfo,
  getTeamScheduleRotationList,
  getTeamScheduleOverrideList,
  getIntegrationInfo,
  getIntegrationProvider,
  getIntegrationChat,
  getIntegrationWatchdog,
  getIntegrationTemplate,
  putIntegrationTemplate,
  postIntegrationTemplate, deleteIntegrationTemplate
} from "./oncall";
import { getAccountHandler, getAuthHandler, postAccountHandler } from "./auth";
import {
  getWorkspaceList,
  getWorkspaceInfo,
  postWorkspaceCreate,
  putWorkspaceInfo, deleteWorkspaceInfo,
} from "./workspace";
import { getMembershipList } from "./membership";
import {
  getInfrastructureChannelList,
  getInfrastructureInfo,
  getInfrastructureIntegrationList,
  getInfrastructureList,
  postInfrastructureCreate
} from "./infrastructure";

export const handlers = [
  getAuthHandler,
  getAccountHandler,
  postAccountHandler,
  getWorkspaceList,
  getWorkspaceInfo,
  postWorkspaceCreate,
  putWorkspaceInfo,
  getMembershipList,
  deleteWorkspaceInfo,
  getAlertList,
  getAlertInfo,
  putAlertInfo,
  ackAlertInfo,
  unAckAlertInfo,
  getAlertEvents,
  getIncidentList,
  getIncidentInfo,
  putIncidentInfo,
  getIntegrationList,
  getIntegrationInfo,
  getIntegrationProvider,
  getIntegrationChat,
  getIntegrationWatchdog,
  getIntegrationTemplate,
  putIntegrationTemplate,
  postIntegrationTemplate,
  deleteIntegrationTemplate,
  getTeamList,
  getTeamInfo,
  getTeamMember,
  getTeamUser,
  getTeamEscalation,
  getTeamScheduleList,
  createTeamSchedule,
  deleteTeamSchedule,
  putScheduleInfo,
  getTeamScheduleRotationList,
  getTeamScheduleOverrideList,
  getInfrastructureList,
  postInfrastructureCreate,
  getInfrastructureInfo,
  getInfrastructureIntegrationList,
  getInfrastructureChannelList
];