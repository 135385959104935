import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Responder = {
  id: string
  kind: string
  acknowledge?: boolean
}
export type Alert = {
  id: string
  number: number
  title: string
  priority: string
  status: string
  description?: string
  workspace: string
  account: string
  integration: string
  count?: number
  service?: string
  responders?: Responder[]
  source?: string
  payload?: any
  started_at?: number
  created_at: number
  updated_at?: number
  closed_at?: number
}

interface IOnCallAlertState {
  error: string | null;
  loading: boolean;
  last_updated: number | null
  alerts: { [id: string]: Alert };
  events: { [id: string]: AlertEvent[] };
}

const initialState: IOnCallAlertState = {
  error: null,
  loading: true,
  last_updated: null,
  alerts: {},
  events: {},
};
export type AlertEventData = {
  count?: number
  user?: string
  prev_status?: string
  current_status?: string
  escalation_policy?: string
  chat?: string
  target?: string
  responder?: string
  kind?: string
}

export type AlertEvent = {
  id: string
  type: string
  alert_id: string
  data: AlertEventData
  created_at: number
}
export type AlertEvents = {
  alert: string
  events: AlertEvent[],
}

export const onCallAlertSlice = createSlice({
  initialState,
  name: "onCallAlert",
  reducers: {
    setAlertList: (state, action: PayloadAction<Alert[]>) => {
      for (let key in action.payload) {
        state.alerts[action.payload[key].id] = action.payload[key]
      }
      state.loading = false
      state.last_updated = Math.floor(Date.now() / 1000)
      return state
    },
    setAlert: (state, action: PayloadAction<Alert>) => {
      state.alerts[action.payload.id] = action.payload;
      state.loading = false
    },
    setAlertEventList: (state, action: PayloadAction<AlertEvents>) => {
      state.events[action.payload.alert] = action.payload.events
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = onCallAlertSlice;
// Extract and export each action creator by name
export const { setAlertList, setAlert, setAlertEventList } = actions;
// Export the reducer, either as a default or named export
export default reducer;

