import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { useRecoveryPasswordMutation } from "../../store/api/auth.api";

const FormSchema = object({
  login: string()
    .min(1, "Login is required"),
});

export interface IProps {

}

export type FormData = TypeOf<typeof FormSchema>;

const FormAccountRecoveryPassword: FC<IProps> = () => {
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const [recoveryPassword, { isError, error, isSuccess }] = useRecoveryPasswordMutation();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("Recovery email sent", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  useEffect(() => {
    if (!isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    recoveryPassword(values);
  };

  if (isSuccess) {
    return (
      <div className="alert alert-info text-center" role="alert">
        A message has been sent to your mail.
      </div>
    )
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="form-floating mb-4">
            <input type="text" id="loginInput" className="form-control" placeholder="Enter username or email"
              {...register("login")}/>
            <label className="form-label" htmlFor="loginInput">
              {errors.login?.message
                ? <small className="text-danger">{errors.login?.message}</small>
                : <small className="text-secondary">Enter username or email</small>
              }
            </label>
          </div>

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">Send recovery email</button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormAccountRecoveryPassword;
