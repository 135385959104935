import { useParams ,useSearchParams } from "react-router-dom";
import {
  useDeleteIntegrationChatMutation,
  useFetchIntegrationChatListQuery,
  useFetchIntegrationQuery,
  useFetchIntegrationWatchdogQuery,
  useRespondIntegrationMutation,
  useUpdateIntegrationWatchdogMutation
} from "../../store/api/oncall.integration.api";
import OnCallIntegrationInfo from "../../components/oncall/OnCallIntegrationInfo";
import {
  FormOnCallIntegrationAddChat,
  FormOnCallIntegrationChats,
  FormOnCallIntegrationInfo,
  FormOnCallIntegrationWatchdog,
  FormOnCallResponderAssign
} from "../../components/forms";
import { useAppSelector } from "../../app/hooks";
import { Breadcrumbs, Modal, Suspense } from "../../components";
import { useListUserQuery } from "../../store/api/oncall.user.api";
import Loader from "../../components/oncall/OnCallIncidentList/Loader";
import { selectResponderByKindAndID } from "../../app/selectors";
import { Membership } from "../../store/features/workspace.slice";
import React, { useEffect } from "react";
import { useListForWorkspaceQuery } from "../../store/api/integration.api";
import { toast } from "react-toastify";
import AlertsContainer from "../../components/oncall/OnCallAlertList/Alerts.container";
import { useListTeamQuery } from "../../store/api/oncall.team.api";
import OnCallTemplateListContainer from "../../components/oncall/OnCallIntegrationInfo/OnCallTemplateList.container";

const OnCallIntegrationInfoPage = () => {
  const { integration } = useParams();
  let [tabParams, setTabParams] = useSearchParams()

  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current ?? ""
  const integrationQuery = useFetchIntegrationQuery({ workspace: workspace ?? "", integration: integration ?? "" });
  const integrationState = useAppSelector((state) => state.onCallIntegrationState);
  const data = integrationState.integrations[integration ?? ""]

  const userQuery = useListUserQuery({ workspace: workspace ?? "" });
  const userState = useAppSelector((state) => state.workspaceState)?.memberships[workspace];
  const responder = useAppSelector((state) => selectResponderByKindAndID(state, {
    workspace: workspace,
    kind: data?.responder?.kind || "",
    id: data?.responder?.id || ""
  }))

  const users = useAppSelector((state) => state.onCallUserState).users;
  const teamListQuery = useListTeamQuery({ workspace: workspace });
  const teams = useAppSelector((state) => state.onCallTeamState).teams;

  const [respondIntegration] = useRespondIntegrationMutation();

  const chatsIntegrationsQuery = useListForWorkspaceQuery({ workspace: workspace })
  const chatsQuery = useFetchIntegrationChatListQuery({ workspace: workspace, integration: integration ?? "" });
  const chatState = useAppSelector((state) => state.onCallIntegrationState)?.chats[integration ?? ""];
  const [deleteChat] = useDeleteIntegrationChatMutation();

  const watchdogQuery = useFetchIntegrationWatchdogQuery({
    workspace: workspace ?? "",
    integration: integration ?? ""
  });
  const [watchdogIntegration, { isError, error, isSuccess }] = useUpdateIntegrationWatchdogMutation()
  useEffect(() => {
    if (isSuccess) {
      toast.success("watchdog rules change", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);
  useEffect(() => {
    const handlerParams = () => {
      if (!tabParams.get("t")) {
        setTabParams("t=gen")
      }
    };
    handlerParams()
  });

  let userMap: Membership[] = []
  Object.keys(users).map(value => {
    if (!users) return null
    else userState.forEach(elem => {
      if (users[value].account === elem.account) {
        userMap.push(elem)
      }
    })
    return userMap
  })

  function assignResponder(type: string, item: string) {
    respondIntegration({
      workspace, integration: integration ?? "", data: { kind: type, id: item }
    });
  }
  function removeResponder() {
    respondIntegration({
      workspace, integration: integration ?? "", data: null
    });
  }

  function watchdogHandler(data: { interval: string, enabled: boolean }) {
    watchdogIntegration({
      workspace, integration: integration ?? "", data
    });
  }

  const removeChatHandler = (id: string) => {
    deleteChat({ workspace: workspace, chat: id, integration: integration ?? "" })
  }

  function tabHandler(param: string) {
    setTabParams("t=" + param)
  }

  return (
    <Suspense query={integrationQuery} loader={<div>Loading</div>} fallback={<div>Failed data loading</div>}>
      <div>
        <Breadcrumbs component={"oncall"}
          entities={[{ name: "integrations", slug: "integrations" }, { name: data?.name }]}/>
      </div>

      <div className="mb-4">
        <h2>Integration Info</h2>
      </div>

      <nav className="sticky-top bg-white my-2">
        <div className="nav nav-tabs m-0 p-0" id="nav-tab" role="tablist">
          <button
            className={tabParams.get("t") === "gen" ? "active nav-link" : "nav-link"}
            id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab"
            aria-controls="nav-general" aria-selected="true"
            onClick={() => tabHandler("gen")}>
            General
          </button>
          <button
            className={tabParams.get("t") === "alerts" ? "active nav-link" : "nav-link"}
            id="nav-alerts-tab" data-bs-toggle="tab" data-bs-target="#nav-alerts" type="button" role="tab"
            aria-controls="nav-alerts" aria-selected="false"
            onClick={() => tabHandler("alerts")}>
            Alerts
          </button>
          <button
            className={tabParams.get("t") === "templates" ? "active nav-link" : "nav-link"}
            id="nav-templates-tab" data-bs-toggle="tab" data-bs-target="#nav-templates" type="button" role="tab"
            aria-controls="nav-templates" aria-selected="false"
            onClick={() => tabHandler("templates")}>
            Templates
          </button>
        </div>
      </nav>

      <div className="tab-content mt-2" id="nav-tabContent">
        {tabParams.get("t") === "gen" && (
          <div
            className={tabParams.get("t") === "gen" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-general" role="tabpanel"
            aria-labelledby="nav-general-tab">
            <div className="row justify-content-lg-center justify-content-center">
              <div className="col-lg-10 col-12">
                <div className="mb-4">
                  <div className="pb-2">
                    <h4>General Info</h4>
                  </div>
                  <div className="col-8">
                    <FormOnCallIntegrationInfo workspace={workspace} integration={integration ?? ""}/>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="pb-2">
                    <h4>Watchdog settings</h4>
                  </div>
                  <Suspense query={watchdogQuery} loader={<div>Loading</div>} fallback={<div>Failed data loading</div>}>
                    <FormOnCallIntegrationWatchdog data={watchdogQuery.data ?? { interval: "", enabled: false }}
                      onHandler={watchdogHandler}/>
                  </Suspense>
                </div>

                <div className="mb-4">
                  <div className="pb-2">
                    <h4>Respond user or team</h4>
                  </div>
                  <div className="row-fluid d-flex mb-2 align-items-center">
                    <div className="col">Responder:</div>
                    <div className="ms-2 col-11">
                      <Suspense query={userQuery} loader={<Loader/>} fallback={<div>Failed users loading</div>}>
                        <Suspense query={teamListQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
                          <FormOnCallResponderAssign
                            kind={data?.responder?.kind || ""}
                            users={userMap}
                            teams={Object.values(teams)}
                            responder={responder as Membership}
                            onHandler={assignResponder}
                            onRemove={removeResponder}
                          />
                        </Suspense>
                      </Suspense>
                    </div>
                  </div>
                </div>

                <div className="mb-4 col-lg-10 col-12">
                  <div className="pb-2">
                    <h4>How to connect?</h4>
                  </div>
                  <OnCallIntegrationInfo integration={integration ?? ""}/>
                </div>
                <div className="mb-4 col-lg-10 col-12">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h4 className="m-0">Integration chats:</h4>
                    <div>
                      <Modal id="create-chat-modal" title="Add new chat"
                        btnOpenName="Add Chat" className="modal-dialog-centered"
                        hideFooter={true}>
                        <Suspense query={chatsIntegrationsQuery} loader={<Loader/>}
                          fallback={<div>Failed integration loading</div>}>
                          <FormOnCallIntegrationAddChat integration={integration ?? ""} workspace={workspace}
                            onSubmit={Modal.closeModal("create-chat-modal")}/>
                        </Suspense>
                      </Modal>
                    </div>
                  </div>
                  <div>
                    <Suspense query={chatsQuery} loader={<Loader/>} fallback={<div>Failed chats loading</div>}>
                      <FormOnCallIntegrationChats items={chatState} onRemove={removeChatHandler} workspace={workspace}
                        integration={integration ?? ""}/>
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {tabParams.get("t") === "alerts" && (
          <div
            className={tabParams.get("t") === "alerts" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-alerts" role="tabpanel" aria-labelledby="nav-alerts-tab">
            <div className="row-fluid d-flex flex-wrap py-2">
              <div className="col-12 mb-0">
                <AlertsContainer fetch={true} component={"all"} limit={100} integration={integration ?? ""}/>
              </div>
            </div>
          </div>
        )}

        {tabParams.get("t") === "templates" && (
          <div
            className={tabParams.get("t") === "templates" ? "tab-pane fade show active" : "fade tab-pane"}
            id="nav-templates" role="tabpanel" aria-labelledby="nav-templates-tab">
            <div className="row-fluid d-flex flex-wrap py-2">
              <div className="col-12 mb-0">
                <OnCallTemplateListContainer integration={integration ?? ""} />
              </div>
            </div>
          </div>
        )}

      </div>
    </Suspense>
  );
};

export default OnCallIntegrationInfoPage;
