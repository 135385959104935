import React, { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Alert } from "../../../store/features/oncall.alert.slice";
import { typeTimeStamp, useAppSelector } from "../../../app/hooks";
import {
  useUpdateAlertMutation,
  useAckAlertMutation,
  useUnAckAlertMutation, useAssignResponderMutation, useRemoveResponderMutation
} from "../../../store/api/oncall.alert.api";
import Tooltip from "../../Tooltip";
import { FormOnCallAlertStatusChange } from "../../forms";
import { Membership } from "../../../store/features/workspace.slice";
import FormResponders, { Responder } from "../../forms/FormResponders";


type IProps = {
  workspace: string
  items: { [id: string]: Alert }
};

const AlertsListAll: FC<IProps> = ({ workspace, items }) => {
  const membershipState = useAppSelector((state) => state.workspaceState)?.memberships[workspace];
  const teams = useAppSelector((state) => state.onCallTeamState).teams;
  const [updateAlert] = useUpdateAlertMutation();
  const [ackAlert] = useAckAlertMutation();
  const [unAckAlert] = useUnAckAlertMutation();
  const [assignResponder] = useAssignResponderMutation();
  const [removeResponder] = useRemoveResponderMutation();

  function assignResponderHandler(alert: string, responder: Responder) {
    assignResponder({ workspace, alert, data: { responder: responder.id, kind: responder.kind } })
  }

  function removeResponderHandler(alert: string, responder: Responder) {
    removeResponder({ workspace, alert, responder: responder.id })
  }

  function alertStatusType(item: string) {
    if (item) {
      const status = item.toLowerCase();
      switch (status) {
        case "resolved":
          return "bg-success"
        default:
          return "bg-danger"
      }
    }
  }
  function alertPriorityType(item: string) {
    if(item) {
      const priority = item.toLowerCase();
      switch (priority) {
        case "warning":
          return "text-warning"
        case "critical":
          return "text-danger"
        case "info":
          return "text-info"
        default:
          return "text-secondary"
      }
    }
  }

  const updateAlertHandler = (alert: string, status: string) => {
    const updates = { workspace, alert }
    switch (status) {
      case "resolved":
        updateAlert(updates);
        break;
      case "acknowledged":
        ackAlert(updates)
        break;
      case "unack":
        unAckAlert(updates);
        break;
    }
  };

  const options: Responder[] = []

  const mbsp = {} as { [key: string]: Membership }
  membershipState?.forEach(m => {
    options.push({ id: m.account, email: m.email, name: m.username, kind: "user" })
    mbsp[m.account] = m
  })
  Object.keys(teams).forEach(key => {
    options.push({ id: teams[key].id, name: teams[key].name, kind: "team" })
  })

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col" className="alert-name">Name</th>
            <th scope="col" className="alert-responder">Responders</th>
            <th scope="col" className="alert-created">CreatedAt</th>
            <th scope="col" className="alert-status-change"/>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(items).map((key, index) => {
              const item = items[key]
              const responders: Responder[] = []
              item?.responders?.forEach(r => {
                if (r.kind === "user" && mbsp[r.id])
                  responders.push({ id: r.id, email: mbsp[r.id].email, name: mbsp[r.id].username, kind: "user" })
                if (r.kind === "team" && teams[r.id])
                  responders.push({ id: r.id, name: teams[r.id].name, kind: "team" })
              })
              return (
                <tr key={index} className="table-hover">
                  <td className="text-truncate alert-name">
                    <div className="d-flex align-items-center">
                      <div className="alert-number">
                        #{item.number}
                      </div>
                      <div className="alert-count mx-2">
                        {item.count}
                      </div>
                      <Tooltip placement="top" text={"status: " + item.status} children={
                        <div className="me-2">
                          <div className={`${alertStatusType(item.status)} alert-status`}/>
                        </div>
                      }/>
                      <Tooltip placement="top" text={`priority: ${item?.priority ? item?.priority : "none"}`} children={
                        <span className="text-capitalize text-secondary alert-priority">
                          <i className={`${alertPriorityType(item.priority)} fas fa-flag me-2`}/>
                        </span>
                      }/>
                      <div title={item.title}>
                        <Link to={`/workspace/${workspace}/-/oncall/alerts/${item.id}`}>{item.title}</Link>
                      </div>
                    </div>
                  </td>

                  <td className="alert-responder">
                    <FormResponders responders={responders} options={options}
                      onSelect={(responder) => assignResponderHandler(item.id, responder)}
                      onRemove={(responder) => removeResponderHandler(item.id, responder)}/>
                  </td>

                  <td className="alert-created">
                    <div className="text-secondary timeline-container text-end">
                      <Tooltip placement="top" text={moment(item?.created_at).format("MMM DD, YYYY HH:mm a")} children={
                        <span className={"w-100"}>{typeTimeStamp(item?.created_at)}</span>
                      }></Tooltip>
                    </div>
                  </td>

                  <td className="cursor-pointer">
                    <FormOnCallAlertStatusChange item={item} onHandler={updateAlertHandler}/>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default AlertsListAll;
