import { factory } from "@mswjs/data";
import { AccountModel } from "./models/AccountModel";
import { WorkspaceModel } from "./models/WorkspaceModel";
import { AlertEventsModel, AlertModel } from "./models/AlertModel";
import { IncidentModel } from "./models/IncidentModel";
import { faker } from "@faker-js/faker";
import { MembershipModel } from "./models/MembershipModel";
import {
  IntegrationChatModel,
  IntegrationModel,
  IntegrationProviderModel, IntegrationTemplateModel,
  IntegrationWatchdogModel
} from "./models/InterationModel";
import { TeamModel, TeamSchedule, TeamScheduleOverride, TeamScheduleRotation } from "./models/TeamModel";
import { InfrastructureModel } from "./models/InfrastructureModel";

export const db = factory({
  account: AccountModel,
  workspace: WorkspaceModel,
  membership: MembershipModel,
  integration: IntegrationModel,
  integration_provider: IntegrationProviderModel,
  integration_chat: IntegrationChatModel,
  integration_watchdog: IntegrationWatchdogModel,
  integration_template: IntegrationTemplateModel,
  alert: AlertModel,
  alertEvents: AlertEventsModel,
  incident: IncidentModel,
  team: TeamModel,
  teamSchedule: TeamSchedule,
  teamScheduleRotation: TeamScheduleRotation,
  teamScheduleOverride: TeamScheduleOverride,
  infrastructure: InfrastructureModel,
});

// Define account
db.account.create({
  id: "c782b831-3654-4249-a139-80591596033e",
  username: "demo",
  password: "123456",
  email: "demo@mail.ru",
  active: true,
  created_at: 1524379940,
  updated_at: 1524379940,
});
// Define workspace
db.workspace.create({
  id: "a1dd4097-38a3-4364-bb69-f4b0f1db030b",
  slug: "demo",
  name: "demo",
  description: "demo workspace",
  created_at: 1524379940,
  updated_at: 1524379940,
});
// Define member
db.membership.create({
  id: "b184d37a-0c64-438a-bc23-e34a2144dfe4",
  account: "c782b831-3654-4249-a139-80591596033e",
  username: "demo",
  email: "anekratia@gmail.com"
});
// Define integration
db.integration.create({
  id: "78605d0b-93c2-4f51-93e0-cb5fcdf586ae",
  name: "demo-integration",
  type: "lol ",
  infrastructure: "9a17bdef-9229-43e2-9458-e8f2198288c6",
  hash: "1+1",
  description: "desc",
  created_at: 1524379940,
  updated_at: 1524379940,
  responder: {},
  spec: {},
});
db.integration_watchdog.create({
  id: "b0111876-0c27-11ee-be56-0242ac120002",
  integration: "78605d0b-93c2-4f51-93e0-cb5fcdf586ae",
  enabled: false,
  interval: "123",
  created_at: 1524379940,
  updated_at: 1524379940,
});
db.integration_chat.create({
  id: "9c938838-0c27-11ee-be56-0242ac120002",
  name: "123",
  integration: "78605d0b-93c2-4f51-93e0-cb5fcdf586ae",
  provider: "123",
  chat: "123",
  filter: Object,
  created_at: 1524379940,
  updated_at: 1524379940
});
db.integration_provider.create({
  id: "94f232d9-496c-49f5-b2ae-e380ad6c4df8",
  name: "lll",
  type: "123",
  description: "123",
});
db.integration_template.create({
  id: "3c92ec92-0c29-11ee-be56-0242ac120002",
  name: "lll",
  payload: "QWxlcnQ6Clt7eyAuU3RhdHVzIH19L3t7IC5Qcmlvcml0eSB9fV1be3sgLkludGVncmF0aW9uIH19XSB7eyAuTmFtZSB9fQo8Yj5E" +
    "ZXNjOjwvYj4ge3sgLkRlc2NyaXB0aW9uIH19Cgp7eyByYW5nZSAkYWxlcnQgOj0gLkFsZXJ0cyB9fQogIFt7eyBpbmRleCAkYWxlcnQu" +
    "TGFiZWxzLkluc3RhbmNlICJpbnN0YW5jZSIgfX1dOiB7eyAkYWxlcnQuQW5ub3RhdGlvbnMuU3VtbWFyeSB9fQp7e2VuZH19Cgp7" +
    "eyAuU2VydmljZSB9fQ==",
  integration: "78605d0b-93c2-4f51-93e0-cb5fcdf586ae",
  created_at: 1524379940,
  updated_at: 1524379940
});
// Define alert
db.alert.create({
  id: "a25fac9b-53db-4132-934c-06c406746969",
  description: "Alert Description",
  title: "alert-1",
  workspace: faker.datatype.uuid(),
  account: faker.datatype.uuid(),
  integration: "78605d0b-93c2-4f51-93e0-cb5fcdf586ae",
  priority: "warning",
  count: 2,
  status: "firing",
  service: "alertmanager",
  source: "rtds-forestry-production",
  payload: {
    alerts: [
      {
        annotations: {
          description:
            "Pod gaia-production/gateway-production-gaia-gateway-5675d57599-j7f4p " +
            "(gaia-gateway) is in waiting state (reason: \"CrashLoopBackOff\").",
          runbook_url: "https://runbooks.prometheus-operator.dev/runbooks/kubernetes/kubepodcrashlooping",
          summary: "Pod is crash looping.",
        },
        endsAt: "0001-01-01T00:00:00Z",
        fingerprint: "20e3f6c7dea9dc35",
        generatorURL:
          "http://prometheus-kube-prometheus-prometheus.monitoring:9090/graph?g0.expr=max_over_time%28kube_pod_" +
          "container_status_waiting_reason%7Bjob%3D%22kube-state-metrics%22%2Cnamespace%3D~%22.%2A%22" +
          "%2Creason%3D%22CrashLoopBackOff%22%7D%5B5m%5D%29+%3E%3D+1&g0.tab=1",
        labels: {
          alertname: "KubePodCrashLooping",
          container: "gaia-gateway",
          endpoint: "http",
          instance: "10.112.149.73:8080",
          job: "kube-state-metrics",
          namespace: "gaia-production",
          pod: "gateway-production-gaia-gateway-5675d57599-j7f4p",
          prometheus: "monitoring/prometheus-kube-prometheus-prometheus",
          reason: "CrashLoopBackOff",
          service: "prometheus-kube-state-metrics",
          severity: "warning",
          uid: "6cb89be5-6f76-443d-bd80-d3136de6e6cb",
        },
        startsAt: "2022-06-02T00:17:57.735Z",
        status: "firing",
      },
    ],
    commonAnnotations: {
      runbook_url: "https://runbooks.prometheus-operator.dev/runbooks/kubernetes/kubepodcrashlooping",
      summary: "Pod is crash looping.",
    },
    commonLabels: {
      alertname: "KubePodCrashLooping",
      endpoint: "http",
      instance: "10.112.149.73:8080",
      job: "kube-state-metrics",
      namespace: "gaia-production",
      prometheus: "monitoring/prometheus-kube-prometheus-prometheus",
      reason: "CrashLoopBackOff",
      service: "prometheus-kube-state-metrics",
      severity: "warning",
    },
    externalURL: "http://prometheus-kube-prometheus-alertmanager.monitoring:9093",
    groupKey: "{}/{severity!=\"none\"}:{alertname=\"KubePodCrashLooping\", service=\"prometheus-kube-state-metrics\"}",
    groupLabels: {
      alertname: "KubePodCrashLooping",
      service: "prometheus-kube-state-metrics",
    },
    receiver: "gaia-staging",
    status: "firing",
    truncatedAlerts: 0,
    version: "4",
  },
  started_at: 1524379940,
  created_at: 1524379940,
  updated_at: 1524379940,
});

//Define AlertEvents
db.alertEvents.create({
  id: "cdc54009-634c-4e3f-b5e3-afd3532c7f86",
  alert_id: "5621c58f-2b35-4a6b-a393-52ad9619be4d",
  type: "alert_created",
  data: {},
  created_at: 1680803833
});

//Define incident
db.incident.create({
  id: "3e371f37-e2aa-4a7a-acb1-ee33415e1e71",
  title: "incident-1",
  workspace: faker.datatype.uuid(),
  account: faker.datatype.uuid(),
  integration: faker.datatype.uuid(),
  priority: "P1",
  count: 2,
  status: "firing",
  service: "alertmanager",
  responder: [],
  source: "rtds-forestry-production",
  payload: {
    alerts: [
      {
        annotations: {
          description:
              "Pod gaia-production/gateway-production-gaia-gateway-5675d57599-j7f4p (gaia-gateway)" +
            " is in waiting state (reason: \"CrashLoopBackOff\").",
          runbook_url: "https://runbooks.prometheus-operator.dev/runbooks/kubernetes/kubepodcrashlooping",
          summary: "Pod is crash looping.",
        },
        endsAt: "0001-01-01T00:00:00Z",
        fingerprint: "20e3f6c7dea9dc35",
        generatorURL:
            "http://prometheus-kube-prometheus-prometheus.monitoring:9090/graph?g0.expr=max_over" +
          "_time%28kube_pod_container_status_waiting_reason%7Bjob%3D%22kube-state-metrics%22%2Cnamespace" +
          "%3D~%22.%2A%22%2Creason%3D%22CrashLoopBackOff%22%7D%5B5m%5D%29+%3E%3D+1&g0.tab=1",
        labels: {
          alertname: "KubePodCrashLooping",
          container: "gaia-gateway",
          endpoint: "http",
          instance: "10.112.149.73:8080",
          job: "kube-state-metrics",
          namespace: "gaia-production",
          pod: "gateway-production-gaia-gateway-5675d57599-j7f4p",
          prometheus: "monitoring/prometheus-kube-prometheus-prometheus",
          reason: "CrashLoopBackOff",
          service: "prometheus-kube-state-metrics",
          severity: "warning",
          uid: "6cb89be5-6f76-443d-bd80-d3136de6e6cb",
        },
        startsAt: "2022-06-02T00:17:57.735Z",
        status: "firing",
      },
      {
        annotations: {
          description:
              "Pod gaia-production/gateway-production-gaia-gateway-c7f8fddb5-2pngm (gaia-gateway) is" +
            " in waiting state (reason: \"CrashLoopBackOff\").",
          runbook_url: "https://runbooks.prometheus-operator.dev/runbooks/kubernetes/kubepodcrashlooping",
          summary: "Pod is crash looping.",
        },
        endsAt: "0001-01-01T00:00:00Z",
        fingerprint: "16d607e6b5c14e26",
        generatorURL:
            "http://prometheus-kube-prometheus-prometheus.monitoring:9090/graph?g0.expr=max_over_" +
          "time%28kube_pod_container_status_waiting_reason%7Bjob%3D%22kube-state-metrics%22%2Cnamespace%" +
          "3D~%22.%2A%22%2Creason%3D%22CrashLoopBackOff%22%7D%5B5m%5D%29+%3E%3D+1&g0.tab=1",
        labels: {
          alertname: "KubePodCrashLooping",
          container: "gaia-gateway",
          endpoint: "http",
          instance: "10.112.149.73:8080",
          job: "kube-state-metrics",
          namespace: "gaia-production",
          pod: "gateway-production-gaia-gateway-c7f8fddb5-2pngm",
          prometheus: "monitoring/prometheus-kube-prometheus-prometheus",
          reason: "CrashLoopBackOff",
          service: "prometheus-kube-state-metrics",
          severity: "warning",
          uid: "b6112a74-18a1-47b4-8418-77c24e545b66",
        },
        startsAt: "2022-06-02T00:17:57.735Z",
        status: "firing",
      },
      {
        annotations: {
          description: "Pod gaia-production/gaia-bot-telegram-5f6c9444dd-br6f5 (gaia-service) is" +
            " in waiting state (reason: \"CrashLoopBackOff\").",
          runbook_url: "https://runbooks.prometheus-operator.dev/runbooks/kubernetes/kubepodcrashlooping",
          summary: "Pod is crash looping.",
        },
        endsAt: "0001-01-01T00:00:00Z",
        fingerprint: "d8a0556534d0be64",
        generatorURL:
            "http://prometheus-kube-prometheus-prometheus.monitoring:9090/graph?g0.expr=max_over_" +
          "time%28kube_pod_container_status_waiting_reason%7Bjob%3D%22kube-state-metrics%22%2Cnamespace" +
          "%3D~%22.%2A%22%2Creason%3D%22CrashLoopBackOff%22%7D%5B5m%5D%29+%3E%3D+1&g0.tab=1",
        labels: {
          alertname: "KubePodCrashLooping",
          container: "gaia-service",
          endpoint: "http",
          instance: "10.112.149.73:8080",
          job: "kube-state-metrics",
          namespace: "gaia-production",
          pod: "gaia-bot-telegram-5f6c9444dd-br6f5",
          prometheus: "monitoring/prometheus-kube-prometheus-prometheus",
          reason: "CrashLoopBackOff",
          service: "prometheus-kube-state-metrics",
          severity: "warning",
          uid: "d6919b2f-7a32-4f02-9142-41188c5b1e0c",
        },
        startsAt: "2022-06-02T00:17:57.735Z",
        status: "firing",
      },
    ],
    commonAnnotations: {
      runbook_url: "https://runbooks.prometheus-operator.dev/runbooks/kubernetes/kubepodcrashlooping",
      summary: "Pod is crash looping.",
    },
    commonLabels: {
      alertname: "KubePodCrashLooping",
      endpoint: "http",
      instance: "10.112.149.73:8080",
      job: "kube-state-metrics",
      namespace: "gaia-production",
      prometheus: "monitoring/prometheus-kube-prometheus-prometheus",
      reason: "CrashLoopBackOff",
      service: "prometheus-kube-state-metrics",
      severity: "warning",
    },
    externalURL: "http://prometheus-kube-prometheus-alertmanager.monitoring:9093",
    groupKey: "{}/{severity!=\"none\"}:{alertname=\"KubePodCrashLooping\", service=\"prometheus-kube-state-metrics\"}",
    groupLabels: {
      alertname: "KubePodCrashLooping",
      service: "prometheus-kube-state-metrics",
    },
    receiver: "gaia-staging",
    status: "firing",
    truncatedAlerts: 0,
    version: "4",
  },
  started_at: 1524379940,
  created_at: 1524379940,
  updated_at: 1524379940,
  closed_at: 1524379940,
});

//Default Team

db.team.create({
  id: "40432d28-f2a2-4ba6-96fe-374614b11305",
  workspace: "",
  slug: "demo",
  name: "demo",
  description: "demo",
  created_at: 1524379940,
  updated_at: 1524379940
})

// Default Schedule

db.teamSchedule.create({
  id: "dc158f60-f331-11ed-a05b-0242ac120003",
  name: "schedule1",
  team: "40432d28-f2a2-4ba6-96fe-374614b11305",
  description: "schedule 1",
  created_at: 1524379940,
  updated_at: 1524379940
})

// Default Rotation
db.teamScheduleRotation.create({
  id: "3b47f365-dc5b-4b53-bae8-6ca826c7ba13",
  name: "Demo",
  schedule: "dc158f60-f331-11ed-a05b-0242ac120003",
  start_date: 1685451825,
  end_date: 1685527420,
  type: "daily",
  participants: [{
    kind: "user",
    id: "1"
  }],
  time_restriction: {
    type: "time-of-day",
    restrictions: [],
  },
  created_at: 1524379940,
  updated_at: 1524379940,
})
db.teamScheduleRotation.create({
  id: "0a4e8d27-5c73-42c5-aba4-429a46c1e6bb",
  name: "Demo2",
  schedule: "dc158f60-f331-11ed-a05b-0242ac120003",
  start_date: 1685977789,
  end_date: 1688558989,
  type: "daily",
  participants: [{
    kind: "user",
    id: "c782b831-3654-4249-a139-80591596033e"
  }],
  time_restriction: {
    type: "time-of-day",
    restrictions: [
      { start_hour: 0, end_hour: 23, start_minute: 0, end_minute:59 }
    ],
  },
  created_at: 1524379940,
  updated_at: 1524379940,
})

//default override
db.teamScheduleOverride.create({
  id: "b15b2079-2046-4374-a9a6-b40eea7ca0cd",
  user: "",
  rotations: ["3b47f365-dc5b-4b53-bae8-6ca826c7ba13"],
  schedule: "dc158f60-f331-11ed-a05b-0242ac120003",
  start_date: 1684865632,
  end_date: 1684865632,
  created_at: 1524379940,
  updated_at: 1524379940,
})

// default Infrastructure

db.infrastructure.create({
  id: "9a17bdef-9229-43e2-9458-e8f2198288c6",
  slug: "Inf",
  name: "inf-name",
  workspace: "a1dd4097-38a3-4364-bb69-f4b0f1db030b",
  description: "Demo",
  created_at: 1684865632,
  updated_at: 1684865632,
})

db.infrastructure.create({
  id: "189abc6c-0c46-11ee-be56-0242ac120002",
  slug: "Inf-2",
  name: "inf-name-2",
  workspace: "a1dd4097-38a3-4364-bb69-f4b0f1db030b",
  description: "Demo-2",
  created_at: 1684865632,
  updated_at: 1684865632,
})
