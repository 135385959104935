import React, { FC } from "react";
import moment from "moment/moment";
import { AlertEvent } from "../../../store/features/oncall.alert.slice";
import { Membership } from "../../../store/features/workspace.slice";
import { typeTimeStamp, useAppSelector } from "../../../app/hooks";
import Tooltip from "../../Tooltip";
import { Gravatar } from "../../Gravatar";
import { selectResponderByKindAndID } from "../../../app/selectors";

type IProps = {
  workspace: string
  integration: string
  item: AlertEvent
};

const AlertEventTimeLine: FC<IProps> = ({ workspace, integration, item }) => {
  const member = useAppSelector(
    (state) => selectResponderByKindAndID(state, { workspace: workspace, kind: "user", id: item.data?.user || "" })
  )
  const chatsState = useAppSelector((state) => state.onCallIntegrationState)?.chats[integration]

  const eventTypes: any = {
    alert_created: {
      title: "Alert created",
      icon: "fa-bell",
      message: "created"
    },
    alert_count_increased: {
      title: "Alert count",
      icon: "fa-bell",
      message: "count increased: "
    },
    alert_resolved_automatically: {
      title: "Alert resolved automatically",
      icon: "fa-circle-check",
      message: "resolved automatically"
    },
    alert_resolved_manually: {
      title: "Alert resolved manually",
      icon: "fa-circle-check",
      message: "resolved manually by: "
    },
    alert_status_changed: {
      title: "Alert status changed",
      icon: "fa-triangle-exclamation",
      message: "status changed: "
    },
    alert_acknowledged: {
      title: "Alert acknowledged",
      icon: "fa-user-plus",
      message: "acknowledged by: "
    },
    alert_unacknowledged: {
      title: "Alert unacknowledged",
      icon: "fa-user-minus",
      message: "unacknowledged by: "
    },
    alert_notify_channel: {
      title: "Alert notify channel",
      icon: "fa-bullhorn",
      message: "notify channel: "
    },
    alert_notify_user: {
      title: "Alert notify user",
      icon: "fa-user-check",
      message: "notify user: "
    },
    alert_assigning_responder_automatically: {
      title: "Alert assigning responder automatically",
      icon: "fa-user-plus",
      message: "assigning responder automatically"
    },
    alert_assigning_responder_manually: {
      title: "Assigning responder manually",
      icon: "fa-user-plus",
      message: "assigning responder manually: "
    },
    alert_remove_responder: {
      title: "Removed responder",
      icon: "fa-user-minus",
      message: "excluded responder: "
    },
  }

  return (
    <div className="timeline-item d-flex">
      <div className="text-dark active-icon">
        <i className={"event-icon fas " + eventTypes[item?.type]?.icon}/>
        <div className="active-icon-fa"/>
      </div>

      <div className="d-block w-100 ms-4 text-secondary border-bottom">
        <div className="d-flex justify-content-between">
          <div className="fw-bolder text-uppercase">{eventTypes[item?.type]?.title}</div>
          <Tooltip placement="top" text={moment(item?.created_at).format("MMM DD, YYYY HH:mm a")} children={
            <div className="text-secondary timeline-container text-end">
              <span className={"w-100"}>{typeTimeStamp(item?.created_at)}</span>
            </div>
          }/>

        </div>
        {
          <div className="py-2">
            <span>Alert {eventTypes[item?.type]?.message}</span>
            <span>
              {(() => {
                switch (item?.type) {
                  case "alert_count_increased":
                    return <span>{item.data?.count}</span>
                  case "alert_resolved_manually":
                    return (
                      <span>
                        <Gravatar
                          email={(member as Membership)?.email || ""}
                          size={20}
                          imageClassName="rounded-circle"/>
                        <span>&nbsp;{(member as Membership)?.username}</span>
                      </span>
                    )
                  case "alert_status_changed":
                    return <span>{item.data?.prev_status + " to " + item.data?.current_status}</span>
                  case "alert_acknowledged":
                    return (
                      <span>
                        <Gravatar
                          email={(member as Membership)?.email || ""}
                          size={20}
                          imageClassName="rounded-circle"/>
                        <span>&nbsp;{(member as Membership)?.username}</span>
                      </span>
                    )
                  case "alert_unacknowledged":
                    return (
                      <span>
                        <Gravatar
                          email={(member as Membership)?.email || ""}
                          size={20}
                          imageClassName="rounded-circle"/>
                        <span>&nbsp;{(member as Membership)?.username}</span>
                      </span>
                    )
                  case "alert_notify_channel":
                    const chat = chatsState?.filter(i => i.id === item.data.chat || "")[0];
                    return <span>{item.data?.target + ": " + !!chat && chat?.name}</span>
                  case "alert_notify_user":
                    return (
                      <span>{item.data?.target + " "}
                        {
                          <span>
                            <Gravatar
                              email={(member as Membership)?.email || ""}
                              size={20}
                              imageClassName="rounded-circle"/>
                            <span>&nbsp;{(member as Membership)?.username}</span>
                          </span>
                        }
                      </span>
                    )
                  case "alert_assigning_responder_automatically":
                    return <span>{item.data?.kind + " " + item.data?.responder}</span>
                  case "alert_assigning_responder_manually":
                    return <span>{item.data?.kind + " " + item.data?.responder}</span>
                  case "alert_remove_responder":
                    return <span>{item.data?.kind + " " + item.data?.responder}</span>
                  default:
                    return null
                }
              })()}
            </span>
          </div>
        }
      </div>
    </div>
  );
};

export default AlertEventTimeLine;
