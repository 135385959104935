import { useAppSelector } from "../../app/hooks";
import { useListForWorkspaceQuery } from "../../store/api/integration.api";
import { Breadcrumbs, WorkspaceIntegrationList } from "./../../components"
import { Suspense } from "../../components";

const WorkspaceIntegrationsPage = () => {
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const integrationState = useAppSelector((state) => state.integrationState);
  const workspace = workspaceState.current ?? ""
  const integrationsQuery = useListForWorkspaceQuery({ workspace: workspace ?? "" });
  return (
    <section>
      <div>
        <Breadcrumbs/>
      </div>
      <div className="mb-4">
        <h2>Integrations</h2>
      </div>
      <div>
        <Suspense
          query={integrationsQuery}
          loader={<div>Loading data...</div>}
          fallback={<div>Failed data loading</div>}>
          <WorkspaceIntegrationList workspace={workspace} integrations={integrationState.workspaceIntegrations}/>
        </Suspense>
      </div>
    </section>
  );
};

export default WorkspaceIntegrationsPage;
