import React, { FC, useState } from "react";
import { Membership } from "../../../store/features/workspace.slice";
import { Team } from "../../../store/features/oncall.team.slice";
import Select, { MultiValue, OnChangeValue } from "react-select";

interface Participants {
  kind: string
  id: string
}

interface Resp {
  label: string;
  value: string;
  kind?: string;
}

type Props = {
  participants: Participants[];
  users: Membership[];
  teams: { [key: string]: Team};
  onHandler: (items: Participants[]) => void
};
const FormOnCallScheduleParticipants: FC<Props> = ({ users, teams, participants, onHandler }) => {
  const [values, setValues] = useState<MultiValue<any>>(participants || []);
  const onChangeHandler = (val: OnChangeValue<any, any>) => {
    setValues(val)
    onHandler(val.map((item: Resp) => {
      return { kind: item.kind, id: item.value }
    }))
  }

  const options: Resp[] = []
  const responders: Resp[] = []

  const mbsp = {} as { [key: string]: Membership }
  users?.forEach(m => {
    options.push({ value: m.account, label: m.username, kind: "user" })
    mbsp[m.account] = m
  })
  Object.keys(teams).forEach(key => {
    options.push({ value: teams[key].id, label: teams[key].name, kind: "team" })
  })
  values?.forEach(r => {
    if (r.kind === "user" && mbsp[r.id])
      responders.push({ value: r.id, label: mbsp[r.id].username, kind: "user" })
    if (r.kind === "team" && teams[r.id])
      responders.push({ value: r.id, label: teams[r.id].name, kind: "team" })
  })

  const excludeFromOptions = (responders: Resp[], options: Resp[]): Resp[] =>  {
    const items: { [key: string]: boolean } = {};
    responders.forEach(r => items[`${r.value}:${r.kind}`] = true)
    return options.filter(o => !items[`${o.value}:${o.kind}`])
  }

  const excludeFromValues = (responders: Participants[], options: Resp[]): Resp[] =>  {
    const items: Resp[] = [];
    options.map(item => responders.find(elem => elem.id === item.value ? items.push(item) : null))
    return items
  }

  return (
    <div className="flex-fill d-flex row">
      <Select
        isMulti={true}
        value={excludeFromValues(participants, options)}
        onChange={onChangeHandler}
        options={excludeFromOptions(responders, options)}
      />
    </div>
  );
};

export default FormOnCallScheduleParticipants;
