import { Breadcrumbs, Suspense } from "../../components";
import { useAppSelector } from "../../app/hooks";
import { useCreateInfrastructureMutation, useListInfrastructureQuery } from "../../store/api/infrastrucrture.api";
import InfrastructureList from "../../components/workspace/WorkspaceInfrastructure/InfrastructureList";
import Modal from "../../components/Modal";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import FormInfrastructureCreate from "../../components/forms/FormInfrastructureCreate";

interface Data {
  name: string
  slug: string
  description?: string
}

const WorkspaceInfrastructureListPage = () => {
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState.current ?? ""
  const infrastructureQuery = useListInfrastructureQuery({ workspace: workspace ?? "" });
  const infrastructureState = useAppSelector((state) => state.infrastructureState)?.infrastructures;
  const matchWorkspace = workspaceState.workspaces[workspace]?.id
  const [infrastructureCreate,
    { isError, error, isSuccess }] = useCreateInfrastructureMutation();

  function submitHandler (data: Data) {
    infrastructureCreate({ workspace, data })
  }

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      refSuccess.current = true;
      Modal.closeModal("create-infrastructure-modal")()
      toast.success("Infrastructure create successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);
  return (
    <section>
      <div>
        <Breadcrumbs component={"infrastructures"} entities={[{ name: "infrastructures" }]}/>
      </div>
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2>Infrastructure</h2>
        <Modal
          id="create-infrastructure-modal" title="Create Infrastructure"
          btnOpenClassName="btn btn-sm btn-primary mx-2"
          btnOpenName="Create infrastructure" className="modal-dialog-centered"
          hideFooter={true}>
          <div>
            <FormInfrastructureCreate onSubmit={(data) => submitHandler(data)} />
          </div>
        </Modal>
      </div>
      <div>
        <Suspense
          query={infrastructureQuery}
          loader={<div>Loading data...</div>}
          fallback={<div>Failed data loading</div>}>
          <InfrastructureList workspace={workspace} items={infrastructureState[matchWorkspace ?? ""]} />
        </Suspense>
      </div>
    </section>
  );
};

export default WorkspaceInfrastructureListPage;
