import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Account = {
  id: string;
  username: string;
  email: string;
  active?: boolean;
  created_at?: number;
  updated_at?: number;
}

interface IAccountState {
  error: string | null;
  loading: boolean;
  account: Account | null;
}

const initialState: IAccountState = {
  error: null,
  loading: false,
  account: null,
};

export const accountSlice = createSlice({
  initialState,
  name: "account",
  reducers: {
    setAccount: (state, action: PayloadAction<Account>) => {
      state.account = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = accountSlice;
// Extract and export each action creator by name
export const { setAccount } = actions;
// Export the reducer, either as a default or named export
export default reducer;

