import React, { FC } from "react";
import { AlertEvent } from "../../../store/features/oncall.alert.slice";
import AlertEventView from "./AlertEvent.view";

type IProps = {
  workspace: string
  integration: string
  items: AlertEvent[]
};

const AlertEventsList: FC<IProps> = ({ workspace, integration, items }) => {
  return (
    <div className="">
      <div className="activity-container mt-0">
        <ul className="activity-list mt-1 mb-0 w-100">
          {Object.keys(items).map((key, index) => (
            <li key={index} className="activity-item py-1">
              <AlertEventView item={items[index]} workspace={workspace} integration={integration}/>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AlertEventsList;
