import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import type {
  EscalationPolicyRequest,
  EscalationPolicyResponse,
  EscalationPolicyUpdateRequest,
  TeamCreateRequest,
  TeamFetchRequest,
  TeamListRequest,
  TeamRemoveRequest,
  TeamResponse,
  TeamUpdateRequest,
  TeamMemberCreateRequest,
  TeamMemberResponse,
  TeamMemberListRequest,
  TeamMemberRemoveRequest,
  TeamScheduleRequest,
  TeamScheduleResponse,
  RemoveTeamScheduleRequest,
  TeamScheduleUpdateRequest,
  TeamScheduleRotationResponse,
  TeamScheduleRotationRequest,
  TeamScheduleRotationListRequest,
  RemoveTeamScheduleRotation,
  TeamScheduleRotationUpdateRequest,
  TeamScheduleOverrideListRequest,
  TeamScheduleOverrideResponse,
  TeamScheduleOverrideCreateRequest,
  RemoveTeamScheduleOverride,
  UpdateTeamScheduleOverride
} from "./types/oncall"
import {
  removeMember,
  removeTeam,
  setEscalationPolicy,
  setMember,
  setMemberList, setSchedule, setScheduleList,
  setTeam,
  setTeamList,
  removeSchedule,
  updateSchedule,
  setScheduleRotation,
  setScheduleRotationList,
  updateScheduleRotation,
  removeScheduleRotation,
  setScheduleOverrideList,
  setScheduleOverride,
  removeScheduleOverride,
  updateScheduleOverride,
} from "../features/oncall.team.slice";

const transformResponse = (item: TeamResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformEscalationResponse = (item: EscalationPolicyResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformMemberResponse = (item: TeamMemberResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}
const transformResponseSchedule = (item: TeamScheduleResponse) => {
  item.created_at *= 1000;
  item.updated_at *= 1000;
  return item
}

export const onCallTeamApi = createApi({
  reducerPath: "onCallTeamApi",
  baseQuery: customFetchBase,
  tagTypes: ["OnCallTeams"],
  endpoints: (builder) => ({
    listTeam: builder.query<TeamResponse[], TeamListRequest>({
      query(req: TeamListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: TeamResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponse)
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "OnCallTeams" as const, id })),
            { type: "OnCallTeams", id: "LIST" },
          ]
          : [{ type: "OnCallTeams", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTeamList(data));
        } catch (error) {
        }
      },
    }),
    fetchTeam: builder.query<TeamResponse, TeamFetchRequest>({
      query(req: TeamFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}`,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTeam(data));
        } catch (error) {
        }
      },
    }),
    createTeam: builder.mutation<TeamResponse, TeamCreateRequest>({
      query(req: TeamCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTeam(data));
        } catch (error) {
        }
      },
    }),
    updateTeamInfo: builder.mutation<TeamResponse, TeamUpdateRequest>({
      query(req: TeamUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTeam(data));
        } catch (error) {
        }
      },
    }),
    deleteTeam: builder.mutation<void, TeamRemoveRequest>({
      query(req: TeamRemoveRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeTeam(args.team));
        } catch (error) {
        }
      },
    }),
    fetchEscalationPolicy: builder.query<EscalationPolicyResponse, EscalationPolicyRequest>({
      query(req: EscalationPolicyRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/escalation_policy`,
          credentials: "include",
        };
      },
      transformResponse: transformEscalationResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEscalationPolicy(data));
        } catch (error) {
        }
      },
    }),
    updateEscalationPolicy: builder.mutation<EscalationPolicyResponse, EscalationPolicyUpdateRequest>({
      query(req: EscalationPolicyUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/escalation_policy`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformEscalationResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setEscalationPolicy(data));
        } catch (error) {
        }
      },
    }),
    createTeamMember: builder.mutation<TeamMemberResponse, TeamMemberCreateRequest>({
      query(req: TeamMemberCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/member`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformMemberResponse,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setMember({ team: args.team,member:data }));
        } catch (error) {
        }
      },
    }),
    listTeamMember: builder.query<TeamMemberResponse[], TeamMemberListRequest>({
      query(req: TeamMemberListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/member`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: TeamMemberResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformMemberResponse)
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setMemberList(data));
        } catch (error) {
        }
      },
    }),
    deleteTeamMember: builder.mutation<void, TeamMemberRemoveRequest>({
      query(req: TeamMemberRemoveRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/member/${req.account}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeMember( { team: args.team, account: args.account }));
        } catch (error) {
        }
      },
    }),
    createSchedule: builder.mutation<TeamScheduleResponse, TeamScheduleRequest>({
      query(req: TeamScheduleRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponseSchedule,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSchedule(data));
        } catch (error) {
        }
      },
    }),
    deleteTeamSchedule: builder.mutation<void, RemoveTeamScheduleRequest>({
      query(req: RemoveTeamScheduleRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeSchedule( { team: args.team, schedule: args.schedule }));
        } catch (error) {
        }
      },
    }),
    listTeamSchedule: builder.query<TeamScheduleResponse[], TeamMemberListRequest>({
      query(req: TeamMemberListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: TeamScheduleResponse[] }) => {
        if (!response?.items) return [];
        return response.items.map(transformResponseSchedule)
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setScheduleList(data));
        } catch (error) {
        }
      },
    }),
    updateTeamScheduleInfo: builder.mutation<TeamScheduleResponse, TeamScheduleUpdateRequest>({
      query(req: TeamScheduleUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      transformResponse: transformResponseSchedule,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateSchedule(data));
        } catch (error) {
        }
      },
    }),
    createScheduleRotation: builder.mutation<TeamScheduleRotationResponse, TeamScheduleRotationRequest>({
      query(req: TeamScheduleRotationRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/rotation`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setScheduleRotation(data));
        } catch (error) {
        }
      },
    }),
    listTeamScheduleRotations: builder.query<TeamScheduleRotationResponse[], TeamScheduleRotationListRequest>({
      query(req: TeamScheduleRotationListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/rotation`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: TeamScheduleRotationResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setScheduleRotationList(data));
        } catch (error) {
        }
      },
    }),
    deleteTeamScheduleRotation: builder.mutation<void, RemoveTeamScheduleRotation>({
      query(req: RemoveTeamScheduleRotation) {
        return {
          url:
            `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/rotation/${req.rotation}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeScheduleRotation( { schedule: args.schedule, rotation: args.rotation }));
        } catch (error) {
        }
      },
    }),
    updateTeamScheduleRotationInfo: builder.mutation<TeamScheduleRotationResponse, TeamScheduleRotationUpdateRequest>({
      query(req: TeamScheduleRotationUpdateRequest) {
        return {
          url:
            `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/rotation/${req.id}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateScheduleRotation(data));
        } catch (error) {
        }
      },
    }),
    listTeamScheduleOverride: builder.query<TeamScheduleOverrideResponse[], TeamScheduleOverrideListRequest>({
      query(req: TeamScheduleOverrideListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/override`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: TeamScheduleOverrideResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setScheduleOverrideList(data));
        } catch (error) {
        }
      },
    }),
    createScheduleOverride: builder.mutation<TeamScheduleOverrideResponse, TeamScheduleOverrideCreateRequest>({
      query(req: TeamScheduleOverrideCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/override`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setScheduleOverride(data));
        } catch (error) {
        }
      },
    }),
    deleteTeamScheduleOverride: builder.mutation<void, RemoveTeamScheduleOverride>({
      query(req: RemoveTeamScheduleOverride) {
        return {
          url:
            `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/override/${req.override}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeScheduleOverride( { schedule: args.schedule, override: args.override }));
        } catch (error) {
        }
      },
    }),
    updateTeamScheduleOverrideInfo: builder.mutation<TeamScheduleOverrideResponse, UpdateTeamScheduleOverride>({
      query(req: UpdateTeamScheduleOverride) {
        return {
          url:
            `v1/workspace/${req.workspace}/oncall/team/${req.team}/schedule/${req.schedule}/override/${req.override}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateScheduleOverride(data));
        } catch (error) {
        }
      },
    }),
  }),
});

export const {
  useListTeamQuery,
  useCreateTeamMutation,
  useFetchTeamQuery,
  useUpdateTeamInfoMutation,
  useDeleteTeamMutation,
  useFetchEscalationPolicyQuery,
  useUpdateEscalationPolicyMutation,
  useCreateTeamMemberMutation,
  useListTeamMemberQuery,
  useDeleteTeamMemberMutation,
  useCreateScheduleMutation,
  useListTeamScheduleQuery,
  useDeleteTeamScheduleMutation,
  useUpdateTeamScheduleInfoMutation,
  useCreateScheduleRotationMutation,
  useListTeamScheduleRotationsQuery,
  useDeleteTeamScheduleRotationMutation,
  useUpdateTeamScheduleRotationInfoMutation,
  useListTeamScheduleOverrideQuery,
  useCreateScheduleOverrideMutation,
  useDeleteTeamScheduleOverrideMutation,
  useUpdateTeamScheduleOverrideInfoMutation,
} = onCallTeamApi;

