interface PersistentStorage {
  getItem(key: string): string | null

  setItem(key: string, value: any): void

  removeItem(key: string): void

  on(key: string, handler: EventHandler): void

  off(key: string, handler: EventHandler): void
}

let stg: PersistentStorage | null = null

type EventHandler = (newValue: string | null, oldValue: string | null) => void

class Events {
  private readonly handlers = new Map<string, Array<EventHandler>>();

  constructor() {
    return this
  }

  get(key: string): Array<EventHandler> | undefined {
    if (!key || !this.handlers.has(key)) {
      return undefined
    }
    return this.handlers.get(key)
  }

  has(key: string) {
    return Object.prototype.hasOwnProperty.call(this.handlers, key);
  }

  set(key: string, handler: EventHandler) {
    let arr = this.handlers.get(key)
    if (!arr) {
      arr = new (Array<EventHandler>)()
    }
    arr.push(handler);
    this.handlers.set(key, arr)
    return handler;
  }

  remove(key: string) {
    if (this.handlers.has(key)) {
      this.handlers.delete(key);
    }
  };

  removeHandler(key: string, handler: EventHandler) {
    if (!this.handlers.has(key)) return

    let arr = this.handlers.get(key);
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === handler) {
          arr.splice(i, 1);
          this.handlers.set(key, arr)
          break
        }
      }
    }
  };
}


class storage implements PersistentStorage {
  localStorageSupported: boolean;
  events: Events

  constructor() {
    this.localStorageSupported = typeof window["localStorage"] != "undefined" && window["localStorage"] != null;
    this.events = new (Events)()

    const watcher = (event: any) => {
      if (!event) return;
      if (event.newValue === "undefined") return;
      let handlers = this.events.get(event.key);
      if (!handlers || !handlers.forEach) return;
      handlers.forEach((func) => {
        func(event.newValue, event.oldValue)
      });
    }

    window.addEventListener("storage", watcher, false);
  }

  // add value to storage
  setItem(key: string, item: string) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, item);
    }
  }

  // get one item by key from storage
  getItem(key: string): string | null {
    if (this.localStorageSupported) {
      return localStorage.getItem(key);
    } else {
      return null;
    }
  }

  // remove value from storage
  removeItem(key: string) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key);
    }
  }

  // clear storage (remove all items from it)
  clear() {
    if (this.localStorageSupported) {
      localStorage.clear();
    }
  }

  on(key: string, handler: EventHandler) {
    this.events.set(key, handler)
  }

  off(key: string, handler: EventHandler) {
    if (!handler) return this.events.remove(key);
    this.events.removeHandler(key, handler);
  }
}

export function LocalStorage() {
  if (stg) return stg;
  stg = new (storage)()
  return stg
}
