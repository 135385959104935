import { rest } from "msw";
import { SessionResponse, SignInData, SignUpData } from "../../store/api/types/auth";
import { AccountResponse } from "../../store/api/types/account";
import { faker } from "@faker-js/faker";

import { db } from "../db";

export const getAuthHandler = rest.post<SignInData, SessionResponse>("/v1/session", async (req, res, ctx) => {
  const { login, password } = await req.json()
  const field = login.indexOf("@") === -1 ? "username" : "email";
  const account = db.account.findFirst({
    where: {
      [field]: {
        equals: login
      }
    }
  })
  if (!account) {
    return res(
      ctx.status(404),
      ctx.json({
        status: "not found",
        message: "account not found"
      }),
    )
  }
  if (account.password !== password) {
    return res(
      ctx.status(400),
      ctx.json({
        status: "Bad request",
        message: "incorrect password"
      }),
    )
  }
  return res(
    ctx.json({
      access_token: account.username,
      id: "c782b831-3654-4249-a139-80591596033e",
      username: account.username,
      email: account.email,
    })
  )
});

export const getAccountHandler = rest.get<AccountResponse>("/v1/account", async (req, res, ctx) => {
  const parts: string[] = req.headers.get("authorization")?.split(" ") || []
  if (!(parts.length === 2)) {
    return res(
      ctx.status(401),
      ctx.json({
        status: "Unauthorized",
        message: "Unauthorized"
      }),
    )
  }
  let token = parts[1]
  const account = db.account.findFirst({
    where: {
      username: {
        equals: token
      }
    }
  })
  if (!account) {
    return res(
      ctx.status(401),
      ctx.json({
        status: "Unauthorized",
        message: "Unauthorized"
      }),
    )
  }
  return res(
    ctx.json({
      id: account.id,
      username: account.username,
      email: account.email,
      active: account.active,
      created_at: account.created_at,
      updated_at: account.updated_at,
    })
  )
});

export const postAccountHandler = rest.post<SignUpData>("/v1/account", async (req, res, ctx) => {
  const { username, email, password } = await req.json()
  const haveAccount = db.account.findFirst({
    where: {
      username: {
        equals: username
      }
    }
  })
  if (haveAccount) {
    return res(
      ctx.status(401),
      ctx.json({
        status: "Unauthorized",
        message: "Unauthorized"
      }),
    )
  }
  const account = db.account.create({
    id: faker.datatype.uuid(),
    username: username,
    email: email,
    password: password,
    active: false,
    created_at: 1524379940,
    updated_at: 1524379940,
  })
  return res(
    ctx.json({
      access_token: account.username,
      id: faker.datatype.uuid(),
      username: account.username,
      email: account.email,
    })
  );
});
