import { FC, useEffect, useState } from "react";
import Select, { OnChangeValue } from "react-select"
import { User } from "../../store/features/oncall.user.slice";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { useCreateTeamMemberMutation } from "../../store/api/oncall.team.api";
import { Membership } from "../../store/features/workspace.slice";
import { RootState } from "../../app/store";
import { connect } from "react-redux";

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
  members: { [p:string]: User };
  memberships: Membership[]
}
interface IProps {
  team: string
}

type Props = StateProps & IProps;

const OnCallTeamMemberAdd: FC<Props> = ({ workspace, team, members, memberships }) => {
  const [createUsers, { isError, error, isSuccess }] = useCreateTeamMemberMutation();
  const membersTeam = useAppSelector((state) => state.onCallTeamState).team_members[team] || [];
  const [value, setValue] = useState({ label: "Select member", value: "" });
  const filteredMembers = memberships.filter(item => findInObjectByKey(members, item.account))
  function findInObjectByKey(obj: { [id: string]: User }, value: string) {
    return Object.keys(obj).find((key) => obj[key].account === value);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully add member", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  const onChangeHandler = (val: OnChangeValue<any, any>) => {
    setValue(val)
  }

  const onAddHandler = () => {
    createUsers({ workspace,team, data: { account: value.value } });
    setValue({ label: "Select member", value: "" })
  }

  return (
    <div className="d-flex mx-2 align-items-center">
      <div className="me-2">
        <Select
          value={value}
          onChange={onChangeHandler}
          options={filteredMembers
            .filter(item => !membersTeam.find(elem => elem.account === item.account))
            .map(item => ({ value: item.account, label: item.username }))}
        />
      </div>
      <div>
        <button className="btn btn-primary"
          disabled={!value.value}
          onClick={onAddHandler}>
          Add
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: RootState , ownProps: IProps): StateProps {
  const workspace = state.workspaceState?.current || "";
  const memberships = state.workspaceState.memberships[workspace] || []
  const members = state.onCallUserState.users || {}

  return { ...ownProps,workspace, memberships, members }
}
export default connect(mapStateToProps)(OnCallTeamMemberAdd);