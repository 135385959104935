import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { TeamScheduleRotationRestrictions } from "../../../store/features/oncall.team.slice";
import Select, { OnChangeValue } from "react-select";

export interface IProps {
  restrictions: TeamScheduleRotationRestrictions[]
  onChange: (val: TeamScheduleRotationRestrictions[]) => void
}

const defaultRestrict: TeamScheduleRotationRestrictions[] = [{
  start_day: "",
  start_hour: 0,
  start_minute: 0,
  end_day: "",
  end_hour: 0,
  end_minute: 0,
}]

const FormRestrictionWeek: FC<IProps> = ({ restrictions, onChange }) => {
  const [restrict, setRestrict] = useState(restrictions?.length !== 0 ? restrictions : defaultRestrict)
  useEffect(() => {
    setRestrict(restrictions?.length !== 0 ? restrictions : defaultRestrict)
  }, [setRestrict, restrictions] );

  const weekOptions = [
    {
      label: "Monday",
      value: "monday"
    },
    {
      label: "Tuesday",
      value: "tuesday"
    },
    {
      label: "Wednesday",
      value: "wednesday"
    },
    {
      label: "Thursday",
      value: "thursday"
    },
    {
      label: "Friday",
      value: "friday"
    },
    {
      label: "Saturday",
      value: "saturday"
    },
    {
      label: "Sunday",
      value: "sunday"
    },
  ]

  function StartTimeHandler(index: number, e: ChangeEvent<HTMLInputElement>) {
    let target = parseTime(e.target.value)
    setRestrict(state => {
      const value = [...state.slice(0, index), {
        ...state[index],
        start_hour: target.h,
        start_minute: target.m,
      }, ...state.slice(index + 1)]
      onChange(value)
      return value
    })
  }
  function endTimeHandler(index: number, e: ChangeEvent<HTMLInputElement>) {
    let target = parseTime(e.target.value)
    setRestrict(state => {
      const value = [...state.slice(0, index), {
        ...state[index],
        end_hour: target.h,
        end_minute: target.m,
      }, ...state.slice(index + 1)]
      onChange(value)
      return value
    })
  }

  const onStartDayChangeHandler = (index: number, val: OnChangeValue<any, any>) => {
    setRestrict(state => {
      const value = [...state.slice(0, index), {
        ...state[index],
        start_day: val.value,
      }, ...state.slice(index + 1)]
      onChange(value)
      return value
    })
  }
  const onEndDayChangeHandler = (index: number, val: OnChangeValue<any, any>) => {
    setRestrict(state => {
      const value = [...state.slice(0, index), {
        ...state[index],
        end_day: val.value,
      }, ...state.slice(index + 1)]
      onChange( value )
      return value
    })
  }

  const onStepAddHandler = () => {
    setRestrict(state => {
      const newState = [...state,
        {
          start_day: "",
          start_hour: 0,
          start_minute: 0,
          end_day: "",
          end_hour: 0,
          end_minute: 0,
        }]
      onChange( newState )
      return newState
    })
  };

  const onRestrictRemoveHandler = (index: number) => {
    setRestrict(state => {
      const newState: TeamScheduleRotationRestrictions[] = [...state.slice(0, index), ...state.slice(index + 1)]
      onChange(newState)
      return newState
    })
  };
  function parseTime(str: string) {
    const time = str.match(/(\d+)(?::(\d\d))?(?::(\d\d))?\s*(p?)/i);
    if (!time) {
      return { h: 0, m: 0 };
    }
    let h = parseInt(time[1], 10);
    let m = parseInt(time[2])
    return { h, m }
  }

  function  TimeFormat(time: number) {
    return (time < 10) ? `0${time}` : `${time}`;
  }

  return (
    <div>
      {
        (restrict || []).map((item, index) => {
          const defaultStartDay = weekOptions.find(elem => elem.value === item.start_day)
          const defaultEndDay = weekOptions.find(elem => elem.value === item.end_day)
          return (
            <div className="row d-flex align-items-center" key={index}>
              <div className="col-5">
                <div className="d-flex">
                  <div className="col-6">
                    <label className="form-label mb-0">From</label>
                    <Select
                      defaultValue={defaultStartDay}
                      onChange={(v) => onStartDayChangeHandler(index, v)}
                      options={weekOptions}/>
                  </div>
                  <div className="col-6 ms-2">
                    <label className="form-label mb-0" htmlFor="startTimeInputWeek">Start time</label>
                    <input
                      onChange={(v) => StartTimeHandler(index, v)}
                      value={`${TimeFormat(item.start_hour || 0)}:${TimeFormat(item.start_minute || 0)}`}
                      type="time" id="startTimeInputWeek" className="form-control"/>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="d-flex">
                  <div className="col-6">
                    <label className="form-label mb-0">To</label>
                    <Select
                      defaultValue={defaultEndDay}
                      onChange={(v) => onEndDayChangeHandler(index, v)}
                      options={weekOptions}/>
                  </div>
                  <div className="col-6 ms-2">
                    <div>
                      <label className="form-label mb-0" htmlFor="endTimeInputWeek">End time</label>
                      <input
                        onChange={(v) => endTimeHandler(index, v)}
                        value={`${TimeFormat(item?.end_hour || 0)}:${TimeFormat(item?.end_minute || 0)}`}
                        type="time" id="endTimeInputWeek" className="form-control"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1 d-flex align-items-center justify-content-center mt-4">
                <i className="far fa-trash-alt cursor-pointer" onClick={() => onRestrictRemoveHandler(index)}/>
              </div>
            </div>
          )
        })
      }
      <div className="text-secondary cursor-pointer">
        <i className="fa fa-plus"/>
        <span className="text-primary-hover ms-1" onClick={onStepAddHandler}>Add restriction</span>
      </div>
    </div>
  );
};

export default FormRestrictionWeek;
