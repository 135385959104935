import { FC, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useUpdatePasswordMutation } from "../../store/api/auth.api";
import { useLocation, useNavigate } from "react-router-dom";

const FormSchema = object({
  current: string()
    .min(1, "Current password is required")
    .min(6, "Current password must be more than 6 characters")
    .max(32, "Current password must be less than 32 characters"),
  password: string()
    .min(1, "New password is required")
    .min(6, "New password must be more than 6 characters")
    .max(32, "New password must be less than 32 characters"),
  confirm: string()
    .min(1, "Confirm password is required")
    .min(6, "Confirm password must be more than 6 characters")
    .max(32, "Confirm password must be less than 32 characters"),
}).refine((data) => !!data.current && data.current !== data.password, {
  message: "New password and current password equals",
  path: ["password"],
}).refine((data) => !!data.password && data.password === data.confirm, {
  message: "Passwords don't match",
  path: ["confirm"],
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  onSubmit?: () => void;
}

const FormAccountUpdatePassword: FC<IProps> = ({ onSubmit }) => {
  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const [updatePassword, { isError, error, isSuccess }] = useUpdatePasswordMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const from = ((location.state as any)?.from.pathname as string) || "/";

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("Password updated", {
        position: "bottom-right",
      });
      navigate(from);
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [navigate, error, isError, isSuccess, from]);

  useEffect(() => {
    if (!isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    updatePassword(values);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-floating mb-4">
            <input type="password" id="currentInput" className="form-control" placeholder="Enter current"
              {...register("current")}/>
            <label className="form-label" htmlFor="currentInput">
              {
                errors.current?.message
                  ? <small className="text-danger">{errors.current?.message}</small>
                  : <small className="text-secondary">Enter current</small>
              }
            </label>
          </div>

          <div className="form-floating mb-4">
            <input type="password" id="passwordInput" className="form-control" placeholder="Enter password"
              {...register("password")}/>
            <label className="form-label" htmlFor="passwordInput">
              {
                errors.password?.message
                  ? <small className="text-danger">{errors.password?.message}</small>
                  : <small className="text-secondary">Enter password</small>
              }
            </label>
          </div>

          <div className="form-floating mb-4">
            <input type="password" id="confirmInput" className="form-control" placeholder="Enter confirm"
              {...register("confirm")}/>
            <label className="form-label" htmlFor="confirmInput">
              {
                errors.confirm?.message
                  ? <small className="text-danger">{errors.confirm?.message}</small>
                  : <small className="text-secondary">Enter confirm</small>
              }
            </label>
          </div>

          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">Update Password</button>
          </div>

        </form>
      </FormProvider>
    </div>
  );
};

export default FormAccountUpdatePassword;
