import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import {
  InfrastructureChannelCreateRequest,
  InfrastructureChannelRemoveRequest,
  InfrastructureChannelResponse,
  InfrastructureChannelsRequest,
  InfrastructureChannelUpdateRequest,
  InfrastructureCreateRequest,
  InfrastructureFetchRequest,
  InfrastructureListRequest,
  InfrastructureRespondRequest,
  InfrastructureResponse,
  InfrastructureUpdateRequest,
} from "./types/infrastructure";
import {
  removeInfrastructureChannel,
  setInfrastructure,
  setInfrastructureChanel,
  setInfrastructureChannelList,
  setInfrastructureList,
  setInfrastructureUpdate, updateInfrastructureChannel
} from "../features/infrastructure.slice";

export const infrastructureApi = createApi({
  reducerPath: "infrastructureApi",
  baseQuery: customFetchBase,
  tagTypes: ["Infrastructures"],
  endpoints: (builder) => ({
    listInfrastructure: builder.query<InfrastructureResponse[], InfrastructureListRequest>({
      query(req: InfrastructureListRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: InfrastructureResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructureList(data));
        } catch (error) {
        }
      },
    }),
    createInfrastructure: builder.mutation<InfrastructureResponse, InfrastructureCreateRequest>({
      query(req: InfrastructureCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructure(data));
        } catch (error) {
        }
      },
    }),
    fetchInfrastructure: builder.query<InfrastructureResponse, InfrastructureFetchRequest>({
      query(req: InfrastructureFetchRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}`,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructure(data));
        } catch (error) {
        }
      },
    }),
    updateInfrastructureInfo: builder.mutation<InfrastructureResponse, InfrastructureUpdateRequest>({
      query(req: InfrastructureUpdateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}`,
          method: "PUT",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructureUpdate(data));
        } catch (error) {
        }
      },
    }),
    respondInfrastructure: builder.mutation<InfrastructureResponse, InfrastructureRespondRequest>({
      query(req: InfrastructureRespondRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/responder`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructure(data));
        } catch (error) {
        }
      },
    }),
    createInfrastructureChannel: builder.mutation<InfrastructureChannelResponse, InfrastructureChannelCreateRequest>({
      query(req: InfrastructureChannelCreateRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/chat`,
          method: "POST",
          body: req.data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructureChanel(data));
        } catch (error) {
        }
      },
    }),
    fetchInfrastructureChannelList: builder.query<InfrastructureChannelResponse[], InfrastructureChannelsRequest>({
      query(req: InfrastructureChannelsRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/chat`,
          credentials: "include",
        };
      },
      transformResponse: (response: { items: InfrastructureChannelResponse[] }) => {
        if (!response?.items) return [];
        return response.items
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setInfrastructureChannelList(data));
        } catch (error) {
        }
      },
    }),
    deleteInfrastructureChannel: builder.mutation<void, InfrastructureChannelRemoveRequest>({
      query(req: InfrastructureChannelRemoveRequest) {
        return {
          url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/chat/${req.chat}`,
          method: "DELETE",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(removeInfrastructureChannel({ infrastructure: args.infrastructure ,chat: args.chat }));
        } catch (error) {
        }
      },
    }),
    updateInfrastructureChannelRules:
      builder.mutation<InfrastructureChannelResponse, InfrastructureChannelUpdateRequest>({
        query(req: InfrastructureChannelUpdateRequest) {
          return {
            url: `v1/workspace/${req.workspace}/infrastructure/${req.infrastructure}/chat/${req.chat}`,
            method: "PUT",
            body: req.data,
            credentials: "include",
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(updateInfrastructureChannel({ infrastructure: args.infrastructure, channel: data }));
          } catch (error) {
          }
        },
      }),

  }),
});

export const {
  useListInfrastructureQuery,
  useCreateInfrastructureMutation,
  useFetchInfrastructureQuery,
  useUpdateInfrastructureInfoMutation,
  useRespondInfrastructureMutation,
  useCreateInfrastructureChannelMutation,
  useFetchInfrastructureChannelListQuery,
  useDeleteInfrastructureChannelMutation,
  useUpdateInfrastructureChannelRulesMutation,
} = infrastructureApi;
