import { FC, useState } from "react";
import { TimePicker } from "../index";
interface watchdogData {
  enabled: boolean,
  interval: string
}
export interface IProps {
  data: watchdogData
  onHandler: (data: {enabled: boolean, interval: string}) => void
}

const FormOnCallIntegrationWatchdog: FC<IProps> = ({ data, onHandler }) => {
  const [watchdog, setWatchdog] = useState(data.enabled || false)
  const [interval, setInterval] = useState(data.interval || "00h05m")

  const updateWatchdog = () => {
    const data = { enabled: watchdog, interval: interval }
    onHandler(data)
  }
  function enabledWatchdog() {
    setWatchdog(!watchdog)
    const data = { enabled: !watchdog, interval: interval }
    if (watchdog) {
      onHandler(data)
    }
    else onHandler(data)
  }
  const handleChangeInterval = (v: string) => {
    setInterval(v);
  }

  return (
    <div>
      <div className="form-check form-switch mb-2">
        <input
          checked={watchdog} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
          onChange={() => enabledWatchdog()} />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Watchdog {watchdog ? "enable" : "disable"}
        </label>
      </div>

      <div className={`collapse ${watchdog ? "show" : ""}`}>
        <div className="my-3 d-flex align-items-center">
          <span className="me-2">Set interval: </span>
          <TimePicker value={interval} onChange={(v) => handleChangeInterval(v)}/>
          <div className="d-flex ms-2">
            <button type="submit" className="btn btn-primary btn-sm" onClick={() => updateWatchdog()}>Apply</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default FormOnCallIntegrationWatchdog;
