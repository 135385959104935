import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IntegrationRule = {
  escalation: string,
  is_default: boolean,
  pattern: string
  order?: number,
}
export type IntegrationResponder = {
  kind: string,
  id: string,
}
export type IntegrationsChatFilterConditions = {
  field: string,
  not: boolean,
  operation: string,
  expected_value: string
}

export type IntegrationsChatFilter = {
  type: string,
  conditions: IntegrationsChatFilterConditions[],
}

export type IntegrationChat = {
  id: string,
  name: string,
  integration: string,
  provider: string,
  chat: string,
  description?: string
  filter?: IntegrationsChatFilter
  created_at: number,
  updated_at: number
}
export type IntegrationChats = {
  integration: string,
  chats: IntegrationChat[],
}

export type IntegrationBindings = {
  status: Map<string, string>,
}

export type IntegrationSpec = {
  bindings: IntegrationBindings,
  rules: IntegrationRule[],
}

export type Integration = {
  id: string;
  name: string;
  type: string;
  hash: string;
  infrastructure?: string
  description?: string;
  active: boolean;
  spec: IntegrationSpec;
  responder?: IntegrationResponder;
  created_at?: number;
  updated_at?: number;
}
export type IntegrationTemplate = {
  id: string
  integration: string
  name: string
  payload: string
  created_at: number
  updated_at: number
}

interface IOnCallIntegrationState {
  error: string | null;
  loading: boolean;
  integrations: { [id: string]: Integration };
  chats: { [id: string]: IntegrationChat[] };
  templates: { [id: string]: IntegrationTemplate[] }
}

const initialState: IOnCallIntegrationState = {
  error: null,
  loading: true,
  integrations: {},
  chats: {},
  templates: {},
};

export const onCallIntegrationSlice = createSlice({
  initialState,
  name: "onCallIntegration",
  reducers: {
    setIntegrationList: (state, action: PayloadAction<Integration[]>) => {
      const newState = state
      for (let key in action.payload) {
        newState.integrations[action.payload[key].id] = action.payload[key]
      }
      newState.loading = false
      return newState
    },
    setIntegration: (state, action: PayloadAction<Integration>) => {
      state.integrations[action.payload.id] = action.payload;
      state.loading = false
    },
    setIntegrationChatList: (state, action: PayloadAction<IntegrationChats>) => {
      state.chats[action.payload.integration] = action.payload.chats
      state.loading = false
    },
    updateIntegrationChat: (state, action: PayloadAction<{ integration: string, chat: IntegrationChat }>) => {
      state.chats[action.payload.integration] = state.chats[action.payload.integration].map(elem => {
        if (elem.id === action.payload.chat.id) {
          return action.payload.chat
        }
        return elem
      })
    },
    setIntegrationChat: (state, action: PayloadAction<{ integration: string, chat: IntegrationChat }>) => {
      state.chats[action.payload.integration].push(action.payload.chat)
      state.loading = false
    },
    removeIntegrationChat: (state, action: PayloadAction<{ integration: string, chat: string }>) => {
      state.chats[action.payload.integration] = state.chats[action.payload.integration].filter(elem => {
        return elem.id !== action.payload.chat
      })
      state.loading = false
    },
    setIntegrationTemplateList: (state, action: PayloadAction<IntegrationTemplate[]>) => {
      state.templates = {};
      (action.payload || []).forEach((item) => {
        if (!state.templates[item.integration])
          state.templates[item.integration] = [];
        state.templates[item.integration].push(item)
      })
      state.loading = false
    },
    setIntegrationTemplate: (state, action: PayloadAction<IntegrationTemplate>) => {
      if (!state.templates[action.payload.integration]) {
        state.templates[action.payload.integration] = []
      }
      state.templates[action.payload.integration].push(action.payload)
      state.loading = false
    },
    removeIntegrationTemplate: (state, action: PayloadAction<{ integration: string, template: string }>) => {
      state.templates[action.payload.integration] =
        state.templates[action.payload.integration].filter(elem => {
          return elem.id !== action.payload.template
        })
      state.loading = false
    },
    updateIntegrationTemplate: (state, action: PayloadAction<IntegrationTemplate>) => {
      state.templates[action.payload.integration] = state.templates[action.payload.integration]
        .map(elem => {
          if (elem.id === action.payload.id) {
            return action.payload
          }
          return elem
        })
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = onCallIntegrationSlice;
// Extract and export each action creator by name
export const {
  setIntegrationList,
  setIntegration,
  setIntegrationChatList,
  setIntegrationChat,
  removeIntegrationChat,
  updateIntegrationChat,
  setIntegrationTemplateList,
  setIntegrationTemplate,
  updateIntegrationTemplate,
  removeIntegrationTemplate,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

