import React, { FC, useEffect, useState } from "react";
import { Gravatar } from "../../Gravatar";
import Tooltip from "../../Tooltip";

import "./styles.scss"

export type Responder = {
  id: string;
  name: string;
  email?: string;
  kind: string;
}

type IProps = {
  responders: Responder[]
  options: Responder[]
  onSelect?: (r: Responder) => void
  onRemove?: (r: Responder) => void
};

const excludeFromOptions = (responders: Responder[], options: Responder[]): Responder[] =>  {
  const items: { [key: string]: boolean } = {};
  responders.forEach(r => items[`${r.id}:${r.kind}`] = true)
  return options.filter(o => !items[`${o.id}:${o.kind}`])
}

const FormResponders: FC<IProps> = ({ responders, options, onSelect, onRemove }) => {

  const [userOptions, setUserOptions] = useState<Responder[]>([])
  const [teamOptions, setTeamOptions] = useState<Responder[]>([])
  const [userSelectedResponders, setUserSelectedResponders] = useState<Responder[]>([])
  const [teamSelectedResponders, setTeamSelectedResponders] = useState<Responder[]>([])

  useEffect(() => {
    setUserSelectedResponders((responders || []).filter(r => r.kind === "user"))
    setTeamSelectedResponders((responders || []).filter(r => r.kind === "team"))
  }, [responders])

  useEffect(() => {
    setUserOptions(excludeFromOptions(userSelectedResponders, (options || []).filter(r => r.kind === "user")))
    setTeamOptions(excludeFromOptions(teamSelectedResponders, (options || []).filter(r => r.kind === "team")))
  }, [userSelectedResponders, teamSelectedResponders, options])

  const onChangeHandler = (responder: Responder) => {
    onSelect && onSelect(responder)
  }

  const onRemoveHandler = (responder: Responder) => {
    onRemove && onRemove(responder)
  }

  return (
    <div className="responder-group">

      <div
        className="responder-group-item">
        <div className="dropdown" role="button" data-bs-toggle="dropdown">
          <div aria-hidden="true" className="responder-group-icon">
            <img src="/images/ico/oncall/add-responder.svg" alt="" width="32" height="32"/>
          </div>

          <ul className="dropdown-menu">
            {(!userOptions.length && !teamOptions.length) && (
              <li className="dropdown-item cursor-pointer p-2">
                <span>there is none</span>
              </li>
            )}

            {(!!userOptions.length) && (
              <div>
                <div className="text-secondary px-2 py-1">
                  <strong>Users</strong>
                </div>
                {userOptions.map((user, index) => (
                  <li key={`user-${index}`} className="dropdown-item cursor-pointer p-2"
                    onClick={() => onChangeHandler(user)}>
                    <Gravatar email={user.email || ""} size={32} imageClassName="rounded-circle"/>
                    <span className="ms-1 text-truncate">{user.name}</span>
                  </li>
                ))}
              </div>
            )}

            {(!!teamOptions.length) && (
              <div>
                <div className="text-secondary px-2 py-1">
                  <strong>Teams</strong>
                </div>
                {teamOptions.map((team, index) => (
                  <li key={`team-${index}`} className="dropdown-item cursor-pointer p-2"
                    onClick={() => onChangeHandler(team)}>
                    <i className="fa fa-users pe-1"/>
                    <span className="ms-1 text-truncate">{team.name}</span>
                  </li>
                ))}
              </div>
            )}
          </ul>
        </div>
      </div>

      {userSelectedResponders.map((d, index) => (
        <div key={`team-${index}`} className="responder-group-item">
          <Tooltip placement="bottom" text={d.name || ""} children={
            <div className="responder-avatar">
              <Gravatar email={d.email || ""} size={32}
                className="responder-avatar-bordered"
                imageClassName="rounded-circle"/>
              <div className="responder-group-remove icon" onClick={() => onRemoveHandler(d)}>
                <i className="fa fa-times"/>
              </div>
            </div>
          }
          />
        </div>
      ))}

      {teamSelectedResponders.map((d, index) => (
        <div key={`team-${index}`} className="responder-group-item">
          <Tooltip placement="bottom" text={d.name || ""} children={
            <div className="responder-avatar">
              <span className="responder-avatar-bordered responder-team-ico">
                <i className="fa fa-users"/>
              </span>
              <div className="responder-group-remove icon" onClick={() => onRemoveHandler(d)}>
                <i className="fa fa-times"/>
              </div>
            </div>
          }
          />
        </div>
      ))}

    </div>
  );
};

export default FormResponders;
