import React, { FC, useEffect, useState } from "react";
import { TimePicker } from "../../../components";
import Tooltip from "../../Tooltip";
import Select, { components, OnChangeValue, OptionProps, SingleValueProps } from "react-select";
import SetResponder from "./SetResponder";
import AssignFromSchedule from "./AssignFromSchedule";

interface SelectOption {
  label: string;
  value: string;
  connected: boolean;
}

const actions: { [id: string]: SelectOption } = {
  set_responder:
    { value: "set_responder", label: "Set Responder", connected: true },
  assign_responder_from_schedule:
    { value: "assign_responder_from_schedule", label: "Assign Responder In", connected: true },
};

interface IProps {
  onChange: (steps: EscalationPolicyStepItem[]) => void
  team: string
  steps: EscalationPolicyStepItem[]
}

interface EscalationPolicyStepItem {
  type: string
  delay: string
  data: any;
  enabled?: boolean
}

const SingleValue = ({ children, ...props }: SingleValueProps<SelectOption>) => (
  <components.SingleValue {...props}>
    <div className="d-flex">
      <div className="w-100">{children}</div>
      <div className="flex-shrink-1" style={{ zIndex: 0 }}>
        {!props.data.connected && (
          <Tooltip text="Integration not connected" placement="right" trigger="hover focus">
            <i className="fa-solid fa-circle-exclamation text-warning"/>
          </Tooltip>
        )}
      </div>
    </div>
  </components.SingleValue>
);

const Option = ({ children, ...props }: OptionProps<SelectOption>) => (
  <components.Option {...props}>
    <div className="d-flex">
      <div className="w-100">{children}</div>
      <div className="flex-shrink-1">
        {props.data.connected && (
          <Tooltip text="Ready to use" placement="right" trigger="hover focus">
            <i className="fa-solid fa-circle-check text-success"/>
          </Tooltip>
        )}
      </div>
    </div>
  </components.Option>
);


const FormEscalationPolicySteps: FC<IProps> = ({ team, steps, onChange }) => {
  const [stepsValue, setStepsValue] = useState(steps || []);

  useEffect(() => {
    setStepsValue(steps || [])
  }, [setStepsValue, steps]);

  const onChangeDelayStepChangeHandler = (index: number, val: string) => {
    setStepsValue(state => {
      const newState = [...state.slice(0, index), { ...state[index], delay: val }, ...state.slice(index + 1)]
      onChange(newState)
      return newState
    })
  }

  const onStepChangeHandler = (index: number, val: OnChangeValue<any, any>) => {
    setStepsValue(state => {
      const newState = [...state.slice(0, index), {
        ...state[index],
        type: val.value,
        data: null
      }, ...state.slice(index + 1)]
      onChange(newState)
      return newState
    })
  }

  const onStepAddHandler = () => {
    setStepsValue(state => {
      const newState = [...state, { type: "", delay: "00h05m", data: null }]
      onChange(newState)
      return newState
    })
  };

  const onStepRemoveHandler = (index: number) => {
    setStepsValue(state => {
      const newState = [...state.slice(0, index), ...state.slice(index + 1)]
      onChange(newState)
      return newState
    })
  };

  const responderChangeHandler = (index: number,  responder: string) => {
    setStepsValue(state => {
      const newState = [
        ...state.slice(0, index),
        { ...state[index],
          data: { responder: responder } },
        ...state.slice(index + 1)
      ]
      onChange(newState)
      return newState
    })
  }

  const scheduleChangeHandler = (index: number,  schedule: string) => {
    setStepsValue(state => {
      const newState = [
        ...state.slice(0, index),
        { ...state[index],
          data: { schedule: schedule } },
        ...state.slice(index + 1)
      ]
      onChange(newState)
      return newState
    })
  }

  const options = Object.keys(actions).map((key) => {
    return { ...actions[key] };
  });

  return (
    <div className="form-escalation-policy">
      <div className="mb-4">
        <div className="list-group list-group-flush">
          {(stepsValue || []).map((item, index) => {
            const selectedOption = options.find(
              option => option.value === item.type
            );
            return (
              <div key={index}
                className="d-flex align-items-center list-group-item px-0">
                <div className="flex-grow-1 me-2 col">
                  <Select
                    value={selectedOption}
                    onChange={(v) => onStepChangeHandler(index, v)}
                    components={{ Option, SingleValue }}
                    options={options}/>
                </div>

                <div className="flex-grow-1 me-2 col">
                  {item.type === "set_responder" && (
                    <SetResponder team={team}
                      value={item.data?.responder || null}
                      onChange={v => responderChangeHandler(index, v)}/>)}
                  {item.type === "assign_responder_from_schedule" && (
                    <AssignFromSchedule team={team}
                      value={item.data?.schedule || null}
                      onChange={v => scheduleChangeHandler(index, v)}/>)}
                </div>

                <div className="mx-2">after</div>
                <div>
                  <TimePicker value={item.delay} onChange={(v) => onChangeDelayStepChangeHandler(index, v)}/>
                </div>
                <div className="ms-4">
                  <i className="far fa-trash-alt cursor-pointer" onClick={() => onStepRemoveHandler(index)}/>
                </div>
              </div>
            )
          })}
        </div>

        <span className="text-secondary cursor-pointer">
          <i className="fa fa-plus"/>
          <span className="text-primary-hover ms-1" onClick={onStepAddHandler}>Add escalation step</span>
        </span>
      </div>
    </div>
  );
};

export default FormEscalationPolicySteps;
