import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Incident = {
  id: string;
  title: string;
  priority: string;
  status: string;
  description?: string;
  created_at?: number;
  updated_at?: number;
}

interface IOnCallIncidentState {
  error: string | null;
  loading: boolean;
  incidents: { [id: string]: Incident };
}

const initialState: IOnCallIncidentState = {
  error: null,
  loading: true,
  incidents: {},
};

export const onCallIncidentSlice = createSlice({
  initialState,
  name: "onCallIncident",
  reducers: {
    setIncidentList: (state, action: PayloadAction<Incident[]>) => {
      const newState = state
      for (let key in action.payload) {
        newState.incidents[action.payload[key].id] = action.payload[key]
      }
      newState.loading = false
      return newState
    },
    setIncident: (state, action: PayloadAction<Incident>) => {
      state.incidents[action.payload.id] = action.payload;
      state.loading = false
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = onCallIncidentSlice;
// Extract and export each action creator by name
export const { setIncidentList, setIncident } = actions;
// Export the reducer, either as a default or named export
export default reducer;

