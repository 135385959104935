import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ChatOps = {
  id: string;
  name: string;
  provider: string;
  created_at?: number;
  updated_at?: number;
}

interface IOnCallChatOpsState {
  error: string | null;
  loading: boolean;
  chats: { [id: string]: ChatOps };
}

const initialState: IOnCallChatOpsState = {
  error: null,
  loading: true,
  chats: {},
};

export const onCallChatOpsSlice = createSlice({
  initialState,
  name: "onCallChatOps",
  reducers: {
    setChatOpsList: (state, action: PayloadAction<ChatOps[]>) => {
      const newState = state
      for (let key in action.payload) {
        newState.chats[action.payload[key].id] = action.payload[key]
      }
      newState.loading = false
      return newState
    },
    setChatOps: (state, action: PayloadAction<ChatOps>) => {
      state.chats[action.payload.id] = action.payload;
      state.loading = false
    },
    removeChatOps: (state, action: PayloadAction<string>) => {
      delete state.chats[action.payload];
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = onCallChatOpsSlice;
// Extract and export each action creator by name
export const { setChatOpsList, setChatOps, removeChatOps } = actions;
// Export the reducer, either as a default or named export
export default reducer;

