import { FC } from "react";

interface IProps {
  workspace: string;
}

const FormIntegrationGitlabConnect: FC<IProps> = () => {
  return (
    <div className="text-center">
      Coming soon...
    </div>
  );
};

export default FormIntegrationGitlabConnect;
