import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <section className="container content">
      <div className="text-center">
        <h3 className="mt-5">404 page not found</h3>
        <p className="m-5">We are sorry but the page you are looking for does not exist.</p>
        <Link to="/" className="btn btn-primary btn-lg text-white">GO TO HOME</Link>
      </div>
    </section>
  );
};

export default NotFoundPage;
