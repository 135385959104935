import { createElement, FC, useState } from "react";
import { IntegrationItem, IntegrationElems } from "./components"
import { WorkspaceIntegrations } from "../../../store/features/integration.slice";

import "./styles.scss"

interface IProps {
  workspace: string;
  integrations: WorkspaceIntegrations;
}

const FormWorkspaceIntegrations: FC<IProps> = ({ workspace, integrations }) => {

  const [elem, setComponent] = useState<IntegrationItem>()
  const selectComponentHandler = (item: IntegrationItem) => {
    setComponent(item)
  }

  const getStyles = (item: IntegrationItem) => {
    let className = []
    if (item?.provider === elem?.provider) className.push("integration-selected")
    if (!!integrations[item.provider]) className.push("integration-connected")
    return className.join(" ")
  }

  return (
    <div>
      <div className="d-flex flex-row mb-3">
        {IntegrationElems.map((item, index) => (
          <div key={index}
            className={`integration p-2 w-25 ${getStyles(item)}`}
            onClick={() => selectComponentHandler(item)}>
            <div className="card px-5 py-4 text-center cursor-pointer">
              <div><i className={`${item.icon} fa-2x`}/></div>
              <div>{item.name}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-center mt-5">
        {elem && createElement(elem.component, { workspace: workspace })}
      </div>
    </div>
  );
};

export default FormWorkspaceIntegrations;
