const Loader = () => {
  return (
    <div className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div>
            <div className="d-flex pt-3 justify-content-center">
              <div className="spinner">
                <div className="double-bounce1"/>
                <div className="double-bounce2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
