import { createElement, FC } from "react";
import { PayloadElems } from "./components"

interface IProps {
  service: string
  payload?: any
}

const FormAlertPayload: FC<IProps> = ({ payload, service }) => {
  if (!PayloadElems[service]) return null;

  return createElement(PayloadElems[service].component, { payload: payload });
};
export default FormAlertPayload;
