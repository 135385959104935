import React, { FC } from "react";
import { Alert } from "../../store/features/oncall.alert.slice";
import { useAppSelector } from "../../app/hooks";

type IProps = {
  item: Alert
  onHandler: (alert: string, status: string) => void
};

const FormOnCallAlertStatusChange: FC<IProps> = ({ item, onHandler }) => {
  const accountState = useAppSelector((state) => state.accountState);
  const responder = item.responders?.filter(i => i.id === accountState.account?.id)[0]
  const onHandleData = (alert: string, status: string) => onHandler(alert, status)

  return (
    <div className="btn-group">
      <button className="dropdown dropdown-item" type="button" data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-offset="30,-20">
        <span><i className="fas fa-ellipsis"/></span>
      </button>
      <ul className="dropdown-menu dropdown-menu-dark" data-bs-dropdown-min-width="128px">
        {item.status === "firing"
          ? <li className="w-100">
            {(!responder || !responder.acknowledge)
              ? <div>
                <button className="dropdown-item cursor-pointer"
                  onClick={() => onHandleData(item.id, "acknowledged")}>Ack
                </button>
                <button className="dropdown-item cursor-pointer"
                  onClick={() => onHandleData(item.id, "resolved")}>Resolve
                </button>
              </div>
              : <div>
                <button className="dropdown-item cursor-pointer"
                  onClick={() => onHandleData(item.id, "unack")}>unAck
                </button>
                <button className="dropdown-item cursor-pointer"
                  onClick={() => onHandleData(item.id, "resolved")}>Resolve
                </button>
              </div>
            }
          </li>
          : <span className="ms-2">none</span>
        }
      </ul>
    </div>
  );
}

export default FormOnCallAlertStatusChange;
