import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import type { RootState } from "../../app/store";
import { Membership } from "../../store/features/workspace.slice";
import Select, { OnChangeValue, MultiValue } from "react-select"
import { User } from "../../store/features/oncall.user.slice";
import { useCreateUsersMutation } from "../../store/api/oncall.user.api";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";

// props from connect mapStateToProps
interface StateProps {
  workspace: string;
  memberships: Membership[];
}

type Props = StateProps;

const OnCallUserAdd: FC<Props> = ({ workspace, memberships }) => {
  const [createUsers, { isError, error, isSuccess }] = useCreateUsersMutation();
  const userState = useAppSelector((state) => state.onCallUserState);
  const [values, setValues] = useState<MultiValue<any>>([]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully add users", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setValues([])
    }
  }, [isSuccess]);

  const onChangeHandler = (val: OnChangeValue<any, any>) => {
    setValues(val)
  }

  const onAddHandler = () => {
    const users = values.map(i => i.value)
    createUsers({ workspace, data: { users: users } });
  }

  return (
    <div className="d-flex">
      <div className="flex-fill me-2">
        <Select
          isMulti={true}
          value={values}
          onChange={onChangeHandler}
          options={memberships
            .filter(item => !findInObjectByKey(userState.users, item.account))
            .map(item => ({ value: item.account, label: item.username }))}/>
      </div>
      <div>
        <button className="btn btn-primary"
          disabled={!values.length}
          onClick={onAddHandler}>
          Add
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = function (state: RootState): StateProps {
  const workspace = state.workspaceState?.current || "";
  const memberships = state.workspaceState.memberships[workspace] || []

  return { workspace, memberships }
}

function findInObjectByKey(obj: { [id: string]: User }, value: string) {
  return Object.keys(obj).find((key) => obj[key].account === value);
}

export default connect(mapStateToProps)(OnCallUserAdd);
