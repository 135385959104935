import { FC, useEffect, useRef } from "react";
import { useConnectChatTelegramMutation, useInfoTelegramBotQuery } from "../../store/api/integration.api";
import { CopyBlock, paraisoLight } from "react-code-blocks";

interface IProps {
  workspace: string;
}

const FormIntegrationSlackConnect: FC<IProps> = ({ workspace }) => {
  const [connectChatTelegram, { data }] = useConnectChatTelegramMutation()
  const { data: botInfo } = useInfoTelegramBotQuery({ workspace: workspace })
  const firstRender = useRef<boolean>(false)

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      connectChatTelegram({ workspace: workspace })
    }
  })

  return (
    <div>
      <div className="mb-4">
        <p>
          If you already have a dedicated channel to use with OnCall, you can use the following activation
          code:
        </p>
        <div>
          {
            data?.code && (
              <CopyBlock
                text={data?.code}
                language={"text"}
                showLineNumbers={false}
                startingLineNumber={1}
                theme={paraisoLight}
                codeBlock
              />
            )
          }
        </div>
      </div>

      <div>
        <h5>Setup new channel</h5>
        <p>1. Open Telegram, create a new Private Channel and enable Sign Messages in settings.</p>
        <p>2. Create a new Discussion group. This group handles alert actions, comments and must be unique for each
          OnCall telegram channel.</p>
        <p>3. Connect the discussion group with the channel. In Manage Channel, click Discussion to find and add the
          freshly created group.</p>
        <p>
          4. Go to&nbsp;
          <a href={`https://t.me/${botInfo?.username}`} className="text-primary" target="_blank"
            rel="noopener noreferrer">@{botInfo?.username}</a>&nbsp; to add the OnCall bot to your contacts. Then add
          the bot to your channel as an Admin and allow it to Post
          Messages.
        </p>
        <p>5. Add the bot to the discussion group.</p>
        <p>6. Send this verification code to the channel and wait for the confirmation message:</p>
        <div className="mb-2">
          {
            data?.code && (
              <CopyBlock
                text={data?.code}
                language={"text"}
                showLineNumbers={false}
                startingLineNumber={1}
                theme={paraisoLight}
                codeBlock
              />
            )
          }
        </div>
        <p>7. Start to manage alerts in your team Telegram workspace.</p>
      </div>

    </div>
  );
};

export default FormIntegrationSlackConnect;
