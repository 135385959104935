import { primaryKey } from "@mswjs/data"
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"
import { Integration } from "../../store/features/oncall.integration.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

interface Provider {
  id: string
  name: string
  type: string
  description: string
}

interface Chat {
  id: string
  name: string
  integration: string
  provider: string
  chat: string
  filter?: {}
  created_at: number
  updated_at: number
}

interface Watchdog {
  id: string
  integration: string
  enabled: boolean
  interval: string
  created_at: number
  updated_at: number
}

interface Template {
  id: string
  integration: string
  name: string
  payload: string
  created_at: number
  updated_at: number
}

export const IntegrationModel = defineModel<Integration>(() => ({
  id: primaryKey(String),
  name: String,
  infrastructure: String,
  type: String,
  hash: String,
  description: String,
  active: Boolean,
  spec: Object,
  responder: Object,
  created_at: Number,
  updated_at: Number,
}))

export const IntegrationProviderModel = defineModel<Provider>(() => ({
  id: primaryKey(String),
  name: String,
  type: String,
  description: String,
}))

export const IntegrationChatModel = defineModel<Chat>(() => ({
  id: primaryKey(String),
  name: String,
  integration: String,
  provider: String,
  chat: String,
  filter: Object,
  created_at: Number,
  updated_at: Number
}))

export const IntegrationWatchdogModel = defineModel<Watchdog>(() => ({
  id: primaryKey(String),
  integration: String,
  enabled: Boolean,
  interval: String,
  created_at: Number,
  updated_at: Number,
}))

export const IntegrationTemplateModel = defineModel<Template>(() => ({
  id: primaryKey(String),
  integration: String,
  name: String,
  payload: String,
  created_at: Number,
  updated_at: Number,
}))
