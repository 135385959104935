import moment from "moment";

export interface Rotation {
  id: string
  name: string
  schedule: string
  start_date: number
  end_date?: number | null
  type: string
  participants: Participants[]
  time_restriction?: {
    type: string
    restrictions: Restriction[]
  } | null
}

export interface Restriction {
  start_day: string
  start_hour: number
  start_minute: number
  end_day: string
  end_hour: number
  end_minute: number
}

export interface Participants {
  kind: string
  id: string
}

export interface DutyScheduleItem {
  user: string,
  start: number,
  end: number,
}


interface Period {
  start: number,
  end: number,
}

export const TimeOfDayRestrictionType = "time-of-day"
export const WeekAndTimeOfDayRestrictionType = "weekday-and-time-of-day"

export function makeDutySchedules(startDate: number, endDate: number, rotation: Rotation): DutyScheduleItem[] {

  if (startDate > endDate) {
    const ed = endDate
    endDate = startDate
    startDate = ed
  }

  let shift: number;
  switch (rotation.type) {
    case "hourly":
      shift = moment.duration(1, "hours").asSeconds();
      break;
    case "daily":
      shift = moment.duration(24, "hours").asSeconds();
      break;
    case "weekly":
      shift = moment.duration(7, "days").asSeconds();
      break;
    default:
      shift = moment.duration(24, "hours").asSeconds();
  }

  let scheduleStart = moment.unix(rotation.start_date)
  let scheduleEnd = rotation.end_date ? moment.unix(rotation.end_date): null

  if (scheduleStart.unix() > endDate ||
    (scheduleEnd && ( scheduleEnd.unix() < scheduleStart.unix() || scheduleEnd.unix() < startDate))) {
    return []
  }

  let currentTime = moment(scheduleStart)
  if (startDate > scheduleStart.unix()) {
    currentTime = moment.unix(startDate)
  }
  if (!scheduleEnd || (scheduleEnd && scheduleEnd.unix() > endDate)) {
    scheduleEnd = moment.unix(endDate)
  }

  const startDayOfWeek = currentTime.weekday(0)

  scheduleStart = moment({
    year: currentTime.year(), month: currentTime.month(), day: startDayOfWeek.date(),
    hour: 0, minute: 0, second: 0, millisecond: 0,
  });

  let workShift = {
    period: shift,
    hour: moment.unix(rotation.start_date).hours(),
    minutes: moment.unix(rotation.start_date).minutes(),
  };

  let restrictions: Restriction[] = [];
  if (rotation.time_restriction && rotation.time_restriction.restrictions) {
    restrictions = rotation.time_restriction?.restrictions.slice();
    if (rotation.time_restriction.type === TimeOfDayRestrictionType) {
      let r = rotation.time_restriction.restrictions[0];
      if (r) {
        const item: Restriction = {
          start_day: r.start_day,
          start_hour: r.start_hour !== undefined ? r.start_hour : 0,
          start_minute: r.start_minute !== undefined ? r.start_minute : 0,
          end_day: r.end_day,
          end_hour: r.end_hour !== undefined ? r.end_hour : 23,
          end_minute: r.end_minute !== undefined ? r.end_minute : 59,
        }
        if (!r.start_hour && !r.start_minute) {
          item.start_hour = 0
          item.start_minute = 0
        }
        if (!r?.end_hour && !r?.end_minute) {
          item.end_hour = 23
          item.end_minute = 59
        }
        for (const day of longDayNames) {
          item.start_day = day
          item.end_day = day
          restrictions.push(item);
        }
      }
    } else {
      restrictions = (rotation.time_restriction?.restrictions || []).map(r => {
        const item = {
          start_day: r.start_day,
          start_hour: r.start_hour !== undefined ? r.start_hour : 0,
          start_minute: r.start_minute !== undefined ? r.start_minute : 0,
          end_day: r.end_day,
          end_hour: r.end_hour !== undefined ? r.end_hour : 23,
          end_minute: r.end_minute !== undefined ? r.end_minute : 59,
        }
        if (!r?.start_hour && !r?.start_minute) {
          item.start_hour = 0
          item.start_minute = 0
        }
        if (!r?.end_hour && !r?.end_minute) {
          item.end_hour = 23
          item.end_minute = 59
        }
        return item
      })
    }
  }

  let periods: Period[] = [];

  let start = moment(scheduleStart).weekday(0)
    .startOf("day")
    .hour(workShift.hour)
    .minute(workShift.minutes)
    .unix()

  let sf = workShift.hour * 60*60 + workShift.minutes*60

  for (let end = start + shift; end <= (scheduleEnd.unix() + shift + sf); end += shift) {

    if (!restrictions || restrictions.length === 0) {
      periods.push({ start: start, end: end })
      start = end
      continue
    }

    for (let i in restrictions) {
      const restriction = restrictions[i]

      const startDayOfWeek = moment.unix(start).weekday(0).startOf("day").unix()

      const startDay = moment.unix(startDayOfWeek)
        .add(moment().day(restriction.start_day).day(), "days")
        .startOf("day")

      let endDay = moment.unix(startDayOfWeek)
        .add(moment().day(restriction.end_day).day(), "days")
        .startOf("day")

      if (restriction.start_day === restriction.end_day
        && restriction.start_hour === restriction.end_hour && restriction.start_minute === restriction.end_minute) {
        endDay = endDay.add(1, "weeks")
      }

      let startSlot = moment(startDay).set({ hour:restriction.start_hour, minute:restriction.start_minute, second:0 })
      let endSlot = moment(endDay).set({ hour:restriction.end_hour, minute:restriction.end_minute, second:0 })

      if (endSlot.unix() > scheduleEnd.unix()) {
        endSlot = scheduleEnd
      }

      if (startSlot.unix() > endDate) {
        continue
      }

      if (start >= endSlot.unix()) {
        continue
      }

      if (end < startSlot.unix()) {
        continue
      }

      let p: Period = { start: start, end: end }

      if (p.start < startSlot.unix()) {
        p.start = startSlot.unix()
      }

      if (p.end > endSlot.unix()) {
        p.end = endSlot.unix()
      }

      if (p.start === p.end) {
        continue
      }

      periods.push(p)
    }

    start = end
  }

  periods.sort((i: any, j: any) => i.start - j.start);

  const periodG: Period[][] = []

  let st = scheduleStart;
  for (const p of periods) {
    let scheduleIndex = 0;

    let sf = workShift.hour * 60*60 + workShift.minutes*60

    for (let s = scheduleStart.unix() + sf; s <= scheduleEnd.unix() + shift + sf; s += shift) {
      const end = moment.unix(s);

      if ( p.start < rotation.start_date) {
        continue
      }

      if (p.start >= st.unix() && p.end <= end.unix()) {
        if ( p.end > scheduleEnd.unix()) {
          p.end = scheduleEnd.unix()
        }

        if (!periodG[scheduleIndex] || periodG[scheduleIndex].length === 0) {
          periodG[scheduleIndex] = [];
        }
        periodG[scheduleIndex].push(p);
      }
      st = end;
      scheduleIndex++;
    }
  }

  let pclean: Period[][] = [];
  for (let i = 0; i < periodG.length; i++) {
    let ps = periodG[i];
    if (!ps || ps.length === 0) {
      continue;
    }
    pclean.push(ps);
  }

  const result: DutyScheduleItem[] = []

  for (let i = 0; i < pclean.length; i++) {
    let user = rotation.participants[i % rotation.participants.length]
    for (let key in pclean[i]) {
      const p = pclean[i][key]
      result.push({
        user: user.id,
        start: p.start,
        end: p.end,
      })
    }
  }

  return result
}

const longDayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
