import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { TeamScheduleRotationRestrictions } from "../../../store/features/oncall.team.slice";

export interface IProps {
  restrictions: TeamScheduleRotationRestrictions[]
  onChange: (val: TeamScheduleRotationRestrictions[]) => void
}

const defaultRestrict: TeamScheduleRotationRestrictions[] = [{
  start_day: "",
  start_hour: 0,
  start_minute: 0,
  end_day: "",
  end_hour: 0,
  end_minute: 0,
}]

const FormRestrictionsDay: FC<IProps> = ({ restrictions, onChange }) => {
  const [restrict, setRestrict] = useState(restrictions?.length !== 0 ? restrictions : defaultRestrict)

  useEffect(() => {
    setRestrict(restrictions?.length !== 0 ? restrictions : defaultRestrict)
  }, [setRestrict, restrictions] );

  function StartTimeHandler(index: number, e: ChangeEvent<HTMLInputElement>) {
    let target = parseTime(e.target.value)
    setRestrict(state => {
      const value = [...state.slice(0, index), {
        ...state[index],
        start_hour: target.h,
        start_minute: target.m,
      }, ...state.slice(index + 1)]
      onChange(value)
      return value
    })
  }
  function endTimeHandler(index: number, e: ChangeEvent<HTMLInputElement>) {
    let target = parseTime(e.target.value)
    setRestrict(state => {
      const value = [...state.slice(0, index), {
        ...state[index],
        end_hour: target.h,
        end_minute: target.m,
      }, ...state.slice(index + 1)]
      onChange(value)
      return value
    })
  }

  function parseTime(str: string) {
    const time = str.match(/(\d+)(?::(\d\d))?(?::(\d\d))?\s*(p?)/i);
    if (!time) {
      return { h: 0, m: 0 };
    }
    let h = parseInt(time[1], 10);
    let m = parseInt(time[2])
    return { h, m }
  }

  function  TimeFormat(time: number) {
    return (time < 10) ? `0${time}` : `${time}`;
  }

  return (
    <div>
      {
        (restrict || []).map((item, index) => {
          return (
            <div className="row d-flex align-items-center" key={"day" + index}>
              <div className="col-4">
                <label className="form-label mb-0" htmlFor="startTimeInput">Start time</label>
                <input
                  value={`${TimeFormat(item?.start_hour || 0)}:${TimeFormat(item?.start_minute || 0)}`}
                  type="time" id="startTimeInput" className="form-control"
                  onChange={(v) => StartTimeHandler(index, v)} />
              </div>
              <div className="col-4">
                <label className="form-label mb-0" htmlFor="endTimeInput">End time</label>
                <input
                  value={`${TimeFormat(item?.end_hour || 0)}:${TimeFormat(item?.end_minute || 0)}`}
                  type="time" id="endTimeInput" className="form-control"
                  onChange={(v) => endTimeHandler(index, v)}/>
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

export default FormRestrictionsDay;
