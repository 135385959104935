import { Link, useParams } from "react-router-dom";
import { FormAccountChangePassword, FormAccountRecoveryPassword } from "./../components/forms";

const PasswordRecoveryPage = () => {
  let { code } = useParams();

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong rounded-sm">
              <div className="card-body p-5">

                {!!code && (
                  <div>
                    <div><h3 className="mb-5 text-center">Change password</h3></div>
                    <FormAccountChangePassword code={code}/>
                  </div>
                )}

                {!code && (
                  <div>
                    <div><h3 className="mb-5 text-center">Recovery password</h3></div>
                    <FormAccountRecoveryPassword/>
                  </div>
                )}

                <div className="d-flex justify-content-center mt-2">
                  <Link to="/signin" className="text-decoration-none">Back to Login</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordRecoveryPage;
