export const conditionOptions: { [id: string]: string } = {
  "alert:priority": "Alert priority",
  "pattern": "Pattern",
};

export const typeFilter: { [id: string]: string } = {
  "match-all": "Match all",
  "match-any-condition": "Match any condition",
  "match-all-conditions": "Match all conditions",
};
