import { ChangeEvent, FC, useEffect, useState } from "react";
import { OnChangeValue } from "react-select";
import { FormFilterRules } from "../index";
import FormEscalationPolicySteps from "../FormEscalationPolicySteps";
import { EscalationPolicyFilter, EscalationPolicyStep } from "./types"
import { conditionOptions } from "./constants"

import "./styles.scss"

export interface IProps {
  workspace: string
  team: string
  data?: any
  onChange?: (data: any) => void
  onSubmit: (data: any) => void
  onCancel: () => void
}

export interface FormData {
  name: string
  filter: EscalationPolicyFilter
  steps: EscalationPolicyStep[]
}

const FormEscalationPolicy: FC<IProps> = ({ team, data, workspace, onSubmit, onCancel }) => {

  const [stateValue, setStateValue] = useState<FormData>(definePropertyIfNotExists(data))

  useEffect(() => {
    setStateValue(definePropertyIfNotExists(data))
  }, [data, setStateValue])

  const changeNameHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setStateValue(state => ({ ...state, name: e.target.value }))
  }

  function changeFilterHandler(val: OnChangeValue<any, any>) {
    setStateValue(state => ({ ...state, filter: val }))
  }

  function changeStepsHandler(val: OnChangeValue<any, any>) {
    setStateValue(state => ({ ...state, steps: val }))
  }

  const cancelHandler = () => {
    onCancel && onCancel()
  }

  const applyHandler = () => {
    onSubmit(stateValue)
  }

  return (
    <div className="form-escalation-policy">
      <div className="mb-2">
        <label htmlFor="nameInput" className="form-label m-0">Enter name</label>
        <input name="name" type="text" className="form-control my-2"
          placeholder="Enter Name"
          value={stateValue.name}
          onChange={changeNameHandler}/>
      </div>

      <div className="mb-2">
        <label htmlFor="filterForm" className="form-label m-0">Filters</label>
        <FormFilterRules workspace={workspace}
          item={stateValue?.filter || {}}
          options={conditionOptions}
          onChange={changeFilterHandler}/>
      </div>

      <div className="mb-2">
        <label htmlFor="stepForm" className="form-label m-0">Steps</label>
        <FormEscalationPolicySteps team={team}
          steps={stateValue?.steps}
          onChange={changeStepsHandler}/>
      </div>

      <div className="d-flex justify-content-between">
        <button className="btn btn-danger" onClick={cancelHandler}>Cancel</button>
        <button className="btn btn-primary" onClick={applyHandler}>Apply</button>
      </div>
    </div>
  );
};

function definePropertyIfNotExists(data: any) {
  let newData = data ? { ...data } : {}
  if (!newData.name) newData.name = "";
  if (!newData.filter) newData.filter = { type: "match-all", conditions: [] };
  if (!newData.steps) newData.steps = [];
  return newData;
}

export default FormEscalationPolicy;
