import React, { ChangeEvent, FC, useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { useCreateTeamMutation } from "../../store/api/oncall.team.api";

const nameFormat = (str: string) => {
  return str
    .replace(/[^a-zA-Z0-9_\s]+/g, "")
}
const slugFormat = (str: string) => {
  return str
    .replace(/ +/g, " ")
    .trim()
    .replace(/\s/gi, "_")
    .toLowerCase()
}

const FormSchema = object({
  name: string()
    .min(3, "Name can not be empty")
    .transform(val => nameFormat(val).replace(/ +/g, " ")),
  slug: string()
    .min(3, "Slug can not be empty")
    .transform(val => slugFormat(nameFormat(val))),
  description: string()
    .max(1024, "Description is not valid")
    .optional(),
});

export type FormData = TypeOf<typeof FormSchema>;

export interface IProps {
  workspace: string
  onSubmit?: () => void;
}

const FormOnCallTeamCreate: FC<IProps> = ({ onSubmit, workspace }) => {
  const [teamCreate, { isError, error, isSuccess }] = useCreateTeamMutation();

  const methods = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const refSuccess = useRef<boolean>(false)

  useEffect(() => {
    if (isSuccess && !refSuccess.current) {
      refSuccess.current = true;
      onSubmit && onSubmit()
      toast.success("Add team successfully", {
        position: "bottom-right",
      });
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "bottom-right",
          })
        );
      } else {
        toast.error((error as any)?.data?.message, {
          position: "bottom-right",
        });
      }
    }
  }, [error, isError, isSuccess, onSubmit]);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = nameFormat(e.target.value)
  }

  const handleChangeSlug = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = slugFormat(nameFormat(e.target.value))
  }

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    teamCreate({
      workspace: workspace,
      data: values
    });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <div className="mb-2">
            <label className="form-label" htmlFor="nameInput">Name</label>
            <input type="text" id="nameInput" className="form-control" placeholder="Enter name"
              {...register("name")}
              onChange={handleChangeName}/>
            {errors.name?.message && <small className="text-danger">{errors.name?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="slugInput">Slug</label>
            <input type="text" id="slugInput" className="form-control" placeholder="Enter slug"
              {...register("slug")}
              onChange={handleChangeSlug}/>
            {errors.slug?.message && <small className="text-danger">{errors.slug?.message}</small>}
          </div>

          <div className="mb-2">
            <label className="form-label" htmlFor="descriptionInput">Description</label>
            <textarea id="descriptionInput" className="form-control" placeholder="Enter description" rows={4}
              {...register("description")}/>
            {errors.description?.message && <small className="text-danger">{errors.description?.message}</small>}
          </div>

          <div className="d-flex">
            <button type="submit" className="btn btn-primary">Apply</button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormOnCallTeamCreate;
