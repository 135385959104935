import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { OnCallAlertInfo } from "./../../components/oncall";
import { Breadcrumbs } from "../../components";
import React from "react";
import { useListIntegrationQuery } from "../../store/api/oncall.integration.api";
import { useFetchAlertQuery } from "../../store/api/oncall.alert.api";
import Suspense from "../../components/Suspense";
import Loader from "../../components/oncall/OnCallAlertList/Loader";

const OnCallAlertInfoPage = () => {
  const { alert } = useParams();
  const workspaceState = useAppSelector((state) => state.workspaceState);
  const workspace = workspaceState?.current
  const alertState = useAppSelector((state) => state.onCallAlertState);
  const integrationQuery = useListIntegrationQuery({ workspace: workspace || "" });

  const alertQuery = useFetchAlertQuery({ workspace: workspace || "", alert: alert || "" }, { pollingInterval: 10000 });

  const data = alertState.alerts[alert ?? ""]

  return (
    <div>
      <div>
        <Breadcrumbs component={"oncall"} entities={[{ name: "alerts", slug: "alerts" }, { name: data?.title }]}/>
      </div>
      <div className="mb-4">
        <h2>Alert Info</h2>
      </div>
      <Suspense query={integrationQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
        <Suspense query={alertQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
          <OnCallAlertInfo workspace={workspace ?? ""} alert={alert ?? ""}/>
        </Suspense>
      </Suspense>
    </div>
  );
};

export default OnCallAlertInfoPage;
