import { primaryKey } from "@mswjs/data"
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary"

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export interface Incident {
    id: string
    title: string
    workspace: string,
    account: string,
    integration: string,
    priority: string,
    count: number,
    status: string,
    service: string,
    responder: object,
    source: string,
    payload: object,
    started_at: number,
    created_at: number,
    updated_at: number,
    closed_at: number,
}

export const IncidentModel = defineModel<Incident>(() => ({
  id: primaryKey(String),
  title: String,
  workspace: String,
  account: String,
  integration: String,
  priority: String,
  count: Number,
  status: String,
  service: String,
  responder: Object,
  source: String,
  payload: Object,
  started_at: Number,
  created_at: Number,
  updated_at: Number,
  closed_at: Number,
}))