import React from "react";
import Loader from "./Loader";
import { useFetchAlertEventListQuery } from "../../../store/api/oncall.alert.api";
import { useAppSelector } from "../../../app/hooks";
import Suspense from "../../Suspense";
import AlertEventsList from "./AlertEvents.view";
import { selectAlertEventsByFilter } from "../../../app/selectors";
import { AlertEvent } from "../../../store/features/oncall.alert.slice";
import { useFetchIntegrationChatListQuery } from "../../../store/api/oncall.integration.api";

interface StateProps {
  workspace: string;
  integration: string;
  alert: string;
  type?: string
}

type Props = StateProps;

const AlertEventsContainer = (props: Props) => {
  const eventsQuery = useFetchAlertEventListQuery(
    { workspace: props.workspace, alert: props.alert },
    { pollingInterval: 10000 }
  );
  const chatsQuery = useFetchIntegrationChatListQuery({
    workspace: props.workspace,
    integration: props.integration
  });
  const alertEvents = useAppSelector((state) => selectAlertEventsByFilter(state, props));
  return (
    <Suspense query={chatsQuery} loader={<Loader/>} fallback={<div>Failed data loading</div>}>
      <Suspense query={eventsQuery} loader={<Loader/>} fallback={<div>Failed loading events</div>}>
        <AlertEventsList {...props} items={alertEvents as AlertEvent[]}/>
      </Suspense>
    </Suspense>
  );
};

export default AlertEventsContainer;

