import { primaryKey } from "@mswjs/data";
import { ModelDefinitionValue } from "@mswjs/data/lib/glossary";
import { Infrastructure } from "../../store/features/infrastructure.slice";

export function defineModel<T>(
  // eslint-disable-next-line no-unused-vars
  generator: () => { [key in keyof T]: ModelDefinitionValue }
) {
  return generator()
}

export const InfrastructureModel = defineModel<Infrastructure>(() => ({
  id: primaryKey(String),
  slug: String,
  name: String,
  workspace: String,
  description: String,
  created_at: Number,
  updated_at: Number,
}))
