import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AlertsFilter {
  limit?: number
  cursor?: number
  status?: string
  priority?: string
  integration?: string
}

export interface AlertEventsFilter {
  type?: string
  alert: string
}
export interface ResponderFilter {
  id?: string
  kind?: string
  workspace?: string
}

type Sortable = {
  created_at?: number
  integration?: string
}
interface IntegrationFilter {
  infrastructure?: string
}

export const selectAlertsByFilter = createSelector([
  (state: RootState) => state.onCallAlertState.alerts,
  (state: RootState, filter: AlertsFilter) => filter
], (alerts, filter) => {
  if (!filter) return alerts;
  let list = Object.entries(alerts).sort((a: [string, Sortable], b: [string, Sortable]) => {
    if (!a[1]?.created_at || !b[1]?.created_at) return -1
    return b[1].created_at - a[1].created_at
  })
  if (filter.status) list = list.filter(([_, item]) => item.status === filter.status)
  if (filter.status === "firing" && filter.priority !== "critical")
    list = list.filter(([_, item]) => item.priority === "critical" ? "" : item.status === filter.status)
  if (filter.priority) list = list.filter(([_, item]) => item.priority === filter.priority)
  if (filter.integration) list = list.filter(([_, item]) => item.integration === filter.integration)
  if (filter.limit) list = list.slice(0, filter.limit)

  return Object.fromEntries(list)
}
)

export const selectAlertEventsByFilter = createSelector([
  (state: RootState) => state.onCallAlertState.events,
  (state: RootState, filter: AlertEventsFilter) => filter
], (events, filter) => {
  if (!filter) return events
  let list = (events[filter?.alert] || []).slice()
  return list.sort((a: Sortable, b: Sortable) => {
    if (!a?.created_at || !b?.created_at) return -1
    return b.created_at - a.created_at
  })
}
)

export const selectResponderByKindAndID = createSelector([
  (state: RootState) => state.workspaceState.memberships,
  (state: RootState) => state.onCallTeamState.teams,
  (state: RootState, filter: ResponderFilter) => filter
], (memberships, teams, filter) => {
  if (!filter) return null
  if (filter.kind === "user") {
    let list = memberships[filter?.workspace || ""] || []
    return list.find(val => val.account === filter.id)
  }
  if (filter.kind === "team") {
    return teams[filter?.id || ""]
  }
}
)

export const selectIntegrationByFilter = createSelector([
  (state: RootState) => state.onCallIntegrationState.integrations,
  (state: RootState, filter: IntegrationFilter) => filter
], (integrations, filter) => {
  let list = Object.entries(integrations).map(item => item[1])
  if (!filter) return list
  return list.filter(item => item.infrastructure === filter?.infrastructure)
}
)


