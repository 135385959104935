import React, { FC, useState } from "react";
import Select, { OnChangeValue } from "react-select";
import { NotificationPolicyConditions } from "../../../store/features/oncall.user.slice";
import { useListIntegrationQuery } from "../../../store/api/oncall.integration.api";
import { Suspense } from "../../index";
import { useAppSelector } from "../../../app/hooks";


export interface IProps {
  values: NotificationPolicyConditions;
  workspace: string
  onChange: (data: {not: boolean, operation: string, value: string | string[] }) => void
}

const IntegrationSelect: FC<IProps> = ({ values, onChange, workspace }) => {
  const integrationListQuery = useListIntegrationQuery({ workspace: workspace });
  const integrationState = useAppSelector((state) => state.onCallIntegrationState).integrations;
  const integration = Object.values(integrationState).map(item => {
    return {
      value: item.id,
      label: item.name
    }
  })
  const obj = {
    not: values.not || false,
    operation: Object.keys(values.operation)[0] || "",
    value: Object.values(values.operation)[0] || [],
  }
  const [stateValue, setStateValue] = useState(obj)
  const onStepConditionNotHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.not = val.value
      onChange(state)
      return state
    })
  }
  const onStepConditionOperationHandler = (val: OnChangeValue<any, any>) => {
    setStateValue(state => {
      state.operation = val.value
      state.value = []
      onChange(state)
      return state
    })
  }
  const onStepConditionValueHandler = (val: OnChangeValue<any, any>) => {
    const items = val.map((item: any) => item.value)
    setStateValue(state => {
      state.value = items
      onChange(state)
      return state
    })
  }
  const onStepConditionValueStringHandler = (val: string) => {
    setStateValue(state => {
      state.value = val
      onChange(state)
      return state
    })
  }

  const optionsNot = [{ value: false, label: "IS" }, { value:true, label: "NOT" }]
  const selectedOptionNot = optionsNot.find(item => {
    return item.value === stateValue.not
  })
  const optionsOperations = [{ value: "any", label: "Any" }, { value: "contains", label: "Contains" }]
  const selectedOptionOperation = optionsOperations.find((item, index )=> {
    return stateValue.operation === item.value
  })

  return (
    <div className="d-flex flex-wrap col-8 justify-content-between">
      <div className="col-3">
        <Select
          onChange={onStepConditionNotHandler}
          defaultValue={selectedOptionNot}
          options={optionsNot} />
      </div>
      <div className="col-3">
        <Select
          onChange={onStepConditionOperationHandler}
          defaultValue={selectedOptionOperation}
          options={optionsOperations} />
      </div>
      <div className="col-5">
        <Suspense query={integrationListQuery} loader={<div>Loading</div>}
          fallback={<div>Failed data loading</div>}>
          {stateValue.operation === "any"
            ? <Select
              isMulti={true}
              onChange={onStepConditionValueHandler}
              options={integration} />
            : <input
              defaultValue={stateValue.value}
              onChange={value=> onStepConditionValueStringHandler(value.target.value)}
              type="text"
              id="ruleInput"
              className="form-control"
              placeholder="Enter rule" />
          }
        </Suspense>
      </div>
    </div>
  );
};

export default IntegrationSelect;
