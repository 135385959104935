import { rest } from "msw";
import { db } from "../db";
import { AlertResponse, IncidentResponse } from "../../store/api/types/oncall";
import { faker } from "@faker-js/faker";
import { TeamScheduleMock, TeamScheduleRotationMock } from "../models/TeamModel";

export const getAlertList = rest.get("/v1/workspace/:slugOrID/oncall/alert", (req, res, ctx) => {
  const list = db.alert.getAll();
  const status = req.url.searchParams.get("status");
  const priority = req.url.searchParams.get("priority");
  let items = list;
  if (status && priority) {
    items = list.filter((item) => item.status === status && item.priority === priority);
  }
  if (status || priority) {
    items = list.filter((item) => item.status === status || item.priority === priority);
  }
  return res(
    ctx.json({ items: items }),
  );
});

export const getAlertInfo = rest.get<AlertResponse>("/v1/workspace/:slugOrID/oncall/alert/:alert",
  async (req, res, ctx) => {
    const id = await req.params.alert
    const alert = db.alert.findFirst({
      where: {
        id: {
          equals: id
        },
      }
    })
    return res(
      ctx.json(alert),
    );
  });

export const putAlertInfo = rest.put<AlertResponse>("/v1/workspace/:slugOrID/oncall/alert/:alert",
  async (req, res, ctx) => {
    const id = await req.params.alert
    const { status } = await req.json();
    const alert = db.alert.update({
      where: {
        id: {
          equals: id
        },
      },
      data: {
        status: status,
      },
    })
    return res(
      ctx.json(alert),
    );
  });

export const ackAlertInfo = rest.post<AlertResponse>("/v1/workspace/:slugOrID/oncall/alert/:alert/ack",
  async (req, res, ctx) => {
    const id = await req.params.alert
    const alert = db.alert.update({
      where: {
        id: {
          equals: id
        },
      },
      data: {
        responders: [{
          id: "c782b831-3654-4249-a139-80591596033e",
        }],
      },
    })
    return res(
      ctx.json(alert),
    );
  });
export const unAckAlertInfo = rest.delete<AlertResponse>("/v1/workspace/:slugOrID/oncall/alert/:alert/ack",
  async (req, res, ctx) => {
    const id = await req.params.alert
    const alert = db.alert.update({
      where: {
        id: {
          equals: id
        },
      },
      data: {
        responders: [{
          id: "",
        }],
      },
    })
    return res(
      ctx.json(alert),
    );
  });
export const getAlertEvents = rest.get("/v1/workspace/:slugOrID/oncall/alert/:alert/events", (req, res, ctx) => {
  const list = db.alertEvents.getAll();
  return res(
    ctx.json({ items: list }),
  );
});

export const getIncidentList = rest.get("/v1/workspace/:slugOrID/oncall/incident", (req, res, ctx) => {
  const list = db.incident.getAll();
  return res(
    ctx.json({ items: list }),
  );
});

export const getIncidentInfo =
  rest.get<IncidentResponse>("/v1/workspace/:slugOrID/oncall/incident/:incident",
    async (req, res, ctx) => {
      const id = await req.params.incident
      const incident = db.incident.findFirst({
        where: {
          id: {
            equals: id
          },
        }
      })
      return res(
        ctx.json(incident),
      );
    });

export const putIncidentInfo =
  rest.put<IncidentResponse>("/v1/workspace/:slugOrID/oncall/incident/:incident",
    async (req, res, ctx) => {
      const id = await req.params.incident
      const { status } = await req.json();
      const incident = db.incident.update({
        where: {
          id: {
            equals: id
          },
        },
        data: {
          status: status,
        },
      })
      return res(
        ctx.json(incident),
      );
    });

export const getIntegrationList = rest.get("/v1/workspace/:slugOrID/oncall/integration", (req, res, ctx) => {
  const list = db.integration.getAll();
  return res(
    ctx.json({ items: list }),
  );
});
export const getInfrastructureIntegrationList =
  rest.get("/v1/workspace/:slugOrID/oncall/integration",
    (req, res, ctx) => {
      const list = db.integration.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });

export const getIntegrationInfo =
  rest.get("/v1/workspace/:slugOrID/oncall/integration/:integration",
    async (req, res, ctx) => {
      const id = await req.params.integration
      const integration = db.team.findFirst({
        where: {
          id: {
            equals: id
          },
        }
      })
      return res(
        ctx.json(integration),
      );
    });
export const getIntegrationProvider =
  rest.get("/v1/oncall/provider",
    async (req, res, ctx) => {
      const list = db.integration_provider.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });

export const getIntegrationChat =
  rest.get("/v1/workspace/:slugOrID/oncall/integration/:integration/chat",
    async (req, res, ctx) => {
      const list = db.integration_chat.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });

export const getIntegrationWatchdog =
  rest.get("/v1/workspace/:slugOrID/oncall/integration/:integration/watchdog",
    async (req, res, ctx) => {
      const list = db.integration_watchdog.getAll();
      return res(
        ctx.json(list[0]),
      );
    });

export const getIntegrationTemplate =
  rest.get("/v1/workspace/:slugOrID/oncall/integration/:integration/template",
    async (req, res, ctx) => {
      const list = db.integration_template.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });

export const putIntegrationTemplate =
  rest.put("/v1/workspace/:slugOrID/oncall/integration/:integration/template/:template",
    async (req, res, ctx) => {
      const list = db.integration_template.getAll();
      return res(
        ctx.json(list[0]),
      );
    });
export const postIntegrationTemplate =
  rest.post("/v1/workspace/:slugOrID/oncall/integration/:integration/template",
    async (req, res, ctx) => {
      const list = db.integration_template.getAll();
      return res(
        ctx.json(list[0]),
      );
    });
export const deleteIntegrationTemplate =
  rest.delete("/v1/workspace/:slugOrID/oncall/integration/:integration/template/:template",
    async (req, res, ctx) => {
      return res(
        ctx.json({ }),
      );
    });

export const getTeamList = rest.get("/v1/workspace/:slugOrID/oncall/team", (req, res, ctx) => {
  const list = db.team.getAll();
  return res(
    ctx.json({ items: list }),
  );
});

export const getTeamInfo = rest.get("/v1/workspace/:slugOrID/oncall/team/:team",
  async (req, res, ctx) => {
    const slug = await req.params.team
    const team = db.team.findFirst({
      where: {
        slug: {
          equals: slug
        },
      }
    })
    return res(
      ctx.json(team),
    );
  });

export const getTeamMember = rest.get("/v1/workspace/:slugOrID/oncall/team/:team/member",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        id: "",
      }),
    );
  });

export const getTeamUser = rest.get("/v1/workspace/:slugOrID/oncall/user",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        id: "",
      }),
    );
  });

export const getTeamEscalation = rest.get("/v1/workspace/:slugOrID/oncall/team/:team/escalation_policy",
  async (req, res, ctx) => {
    return res(
      ctx.json({
        id: "",
      }),
    );
  });

export const getTeamScheduleList = rest.get("/v1/workspace/:slugOrID/oncall/team/:team/schedule", (req, res, ctx) => {
  const list = db.teamSchedule.getAll();
  return res(
    ctx.json({ items: list }),
  );
});

export const createTeamSchedule = rest.post<TeamScheduleMock>("/v1/workspace/:slugOrID/oncall/team/:team/schedule",
  async (req, res, ctx) => {
    const { name, description } = await req.json()
    const schedule = db.teamSchedule.create ({
      id: faker.datatype.uuid(),
      name: name,
      team: "40432d28-f2a2-4ba6-96fe-374614b11305",
      description: description,
      created_at: 1524379940,
      updated_at: 1524379940,
    })
    return res(
      ctx.json(schedule),
    );
  });

export const putScheduleInfo = rest.put<TeamScheduleMock>
("/v1/workspace/:slugOrID/oncall/team/:team/schedule/:schedule",
  async (req, res, ctx) => {
    const { name, description } = await req.json()
    const id: string | readonly string[] = req.params.schedule
    const schedule = db.teamSchedule.update({
      where: {
        "id": {
          equals: id
        },
      },
      data: {
        name: name,
        description: description,
      },
    })
    return res(
      ctx.json(schedule),
    );
  });

export const deleteTeamSchedule = rest.delete<TeamScheduleMock>
("/v1/workspace/:slugOrID/oncall/team/:team/schedule/:schedule",
  async (req, res, ctx) => {
    const id: string | readonly string[] = req.params.schedule
    const schedule = db.teamSchedule.delete({
      where: {
        "id": {
          equals: id,
        },
      },
    })
    return res(
      ctx.json(schedule),
    );
  });

export const getTeamScheduleRotationList =
  rest.get("/v1/workspace/:slugOrID/oncall/team/:team/schedule/:schedule/rotation",
    (req, res, ctx) => {
      const list = db.teamScheduleRotation.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });

export const createTeamScheduleRotation = rest.post<TeamScheduleRotationMock>
("/v1/workspace/:slugOrID/oncall/team/:team/schedule/:schedule/rotation",
  async (req, res, ctx) => {
    const { data } = await req.json()
    const schedule = db.teamSchedule.create ({
      id: faker.datatype.uuid(),
      name: data.name,
      created_at: 1524379940,
      updated_at: 1524379940,
    })
    return res(
      ctx.json(schedule),
    );
  });

export const getTeamScheduleOverrideList =
  rest.get("/v1/workspace/:slugOrID/oncall/team/:team/schedule/:schedule/override",
    (req, res, ctx) => {
      const list = db.teamScheduleOverride.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });