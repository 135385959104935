import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type Session = {
  token: string
}

interface ISessionState {
  token: string | null;
  isLogged: boolean;
}

const initialState: ISessionState = {
  token: null,
  isLogged: false,
};

export const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    logout: (state) => {
      state.token = null
      state.isLogged = false
    },
    setToken: (state, action: PayloadAction<Session>) => {
      state.token = action.payload.token;
      state.isLogged = !!action.payload.token;
    },
  },
});

const persistConfig = {
  key: "session",
  storage: storage,
};

// Extract the action creators object and the reducer
const { reducer, actions } = sessionSlice;
// Extract and export each action creator by name
export const { logout, setToken } = actions;
// Export the reducer, either as a default or named export
export default persistReducer(persistConfig, reducer);
