import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type WorkspaceIntegration = {
  id: string;
  name?: string;
  verified: boolean;
  connected_at: number;
}

export type AccountIntegration = {
  id: string;
  phone?: string;
  email?: string;
  name?: string;
  verified: boolean;
  connected_at: number;
}

export interface WorkspaceIntegrations {
  [name: string]: WorkspaceIntegration
}

export interface AccountIntegrations {
  [name: string]: AccountIntegration
}

export type AccountIntegrationItem = {
  type: string
  data: AccountIntegration
}

// Device
export type DeviceMeta = {
  os_name?: string
  os_version?: string
  model_name?: string
}

export interface DeviceItem {
  id: string
  name: string
  meta?: DeviceMeta
  created_at: number
  updated_at: number
}

export interface Devices {
  [name: string]: DeviceItem
}

interface IIntegrationState {
  error: string | null;
  accountIntegrations: AccountIntegrations;
  workspaceIntegrations: WorkspaceIntegrations;
  devices: Devices;
}

const initialState: IIntegrationState = {
  error: null,
  accountIntegrations: {},
  workspaceIntegrations: {},
  devices: {},
};

export const integrationSlice = createSlice({
  initialState,
  name: "integration",
  reducers: {
    setWorkspaceIntegrationList: (state, action: PayloadAction<WorkspaceIntegrations>) => {
      state.workspaceIntegrations = action.payload
    },
    setAccountIntegrationList: (state, action: PayloadAction<AccountIntegrations>) => {
      state.accountIntegrations = action.payload
    },
    setAccountIntegration: (state, action: PayloadAction<AccountIntegrationItem>) => {
      state.accountIntegrations[action.payload.type] = action.payload.data
    },
    setDeviceList: (state, action: PayloadAction<DeviceItem[]>) => {
      const newState = state
      for (let key in action.payload) {
        newState.devices[action.payload[key].id] = action.payload[key]
      }
      return newState
    },
    removeDevice: (state, action: PayloadAction<string>) => {
      delete state.devices[action.payload];
    },
  },
});

// Extract the action creators object and the reducer
const { reducer, actions } = integrationSlice;
// Extract and export each action creator by name
export const {
  setWorkspaceIntegrationList,
  setAccountIntegrationList,
  setAccountIntegration,
  setDeviceList,
  removeDevice
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

