import { rest } from "msw";
import { db } from "../db";
import { WorkspaceResponse, WorkspaceUpdateData } from "../../store/api/types/workspace";
import { faker } from "@faker-js/faker";

const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const getInfrastructureList = rest.get("/v1/workspace/:slugOrID/infrastructure", async (req, res, ctx) => {
  const list = db.infrastructure.getAll()
  return res(
    ctx.json({ items: list }),
  );
});

export const getInfrastructureInfo =
  rest.get<WorkspaceResponse>("/v1/workspace/:slugOrID/infrastructure/:infrastructure",
    async (req, res, ctx) => {
      const { infrastructure } = req.params
      const filed = regexExp.test(infrastructure as string) ? "id" : "slug"
      const infrastructureState = db.infrastructure.findFirst({
        where: {
          [filed]: {
            equals: infrastructure
          },
        }
      })
      return res(
        ctx.json(infrastructureState),
      );
    });

export const postInfrastructureCreate = rest.post("/v1/workspace/:slugOrID/infrastructure", async (req, res, ctx) => {
  const { name, slug, description } = await req.json()
  const infrastructure = db.infrastructure.create ({
    id: faker.datatype.uuid(),
    name: name,
    slug: slug,
    description: description,
    workspace: "a1dd4097-38a3-4364-bb69-f4b0f1db030b",
    created_at: 1524379940,
    updated_at: 1524379940,
  })
  return res(
    ctx.json(infrastructure),
  );
});

export const putInfrastructureInfo = rest.put<WorkspaceUpdateData>("/v1/workspace/:slugOrID", async (req, res, ctx) => {
  const { name, description } = await req.json()
  const { slugOrID } = req.params
  const filed = regexExp.test(slugOrID as string) ? "id" : "slug"
  const workspace = db.workspace.update({
    where: {
      [filed]: {
        equals: slugOrID
      },
    },
    data: {
      // Nullable properties can be updated to null.
      name: name,
      description: description,
    },
  })
  return res(
    ctx.json(workspace),
  );
});
export const deleteInfrastructureInfo = rest.delete("/v1/workspace/:slugOrID", async (req, res, ctx) => {
  return res(
    ctx.json({}),
  );
});

export const getInfrastructureIntegrationList
  = rest.get("/v1/workspace/:slugOrID/infrastructure/:infrastructure/integration",
    (req, res, ctx) => {
      const list = db.integration.getAll();
      return res(
        ctx.json({ items: list }),
      );
    });

export const getInfrastructureChannelList
  = rest.get("/v1/workspace/:slugOrID/infrastructure/:infrastructure/chat",
    (req, res, ctx) => {
      const list = [{
        id: "2a3dcbc3-dcd3-45b8-9867-01f73b1ec642",
        name: "hello",
        infrastructure: "9a17bdef-9229-43e2-9458-e8f2198288c6",
        provider: "",
        chat: "",
        filter: {},
        created_at: 123,
        updated_at: 123
      }]
      return res(
        ctx.json({ items: list }),
      );
    });
