import React, { FC } from "react";
import moment from "moment/moment";
import { User } from "../../../store/features/oncall.user.slice";
import { Membership } from "../../../store/features/workspace.slice";
import { Link } from "react-router-dom";

type IProps = {
  workspace: string;
  items: { [id: string]: User };
  memberships: Membership[];
  onRemove: (id: string) => void;
};

const ChatsList: FC<IProps> = ({ workspace, memberships, items, onRemove }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">CreatedAt</th>
            <th scope="col"/>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(items).map((key, index) => {
              const item = items[key]
              const username = memberships.find(mbs => mbs.account === item.account)?.username
              return (
                <tr key={index}>
                  <td>
                    <Link to={`/workspace/${workspace}/-/oncall/users/${username}`}>
                      {username}
                    </Link>
                  </td>
                  <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                  <td>
                    <i className="far fa-trash-alt cursor-pointer" onClick={() => onRemove(item.id)}/>
                  </td>
                </tr>
              )
            }
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default ChatsList;
